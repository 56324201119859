/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import debug from "debug";
import { nanoid } from "nanoid";
import Story from "../models/story";
import Blueprint from "./blueprint";
import Campfire from "./campfire";
import Character from "./character";
import copy from "./copy";
import * as directives from "./directives";
import PlayerManager from "./players";
import { StoryTag, generateEffectsWithSubstitutions, runFormula } from "./tag_effects";
import { applyEffect, directionWillModify, insertTags, shouldShowDirection } from "./tags";

const log = debug("read");
// const log = (...args) => console.log('<read>', ...args);

export default class Reader {
  constructor(story, player, settings, listener) {
    this.timer = null;
    this.mptimers = {};
    this.story = copy(story);
    if (!this.story.tags) {
      this.story.tags = [];
    }
    if (!this.story.blueprints) {
      this.story.blueprints = [];
    }

    // patch in from the object
    this.storyObject = new Story(copy(this.story));
    this.story.allTypes = this.storyObject.allTypes;
    this.story.allTags = this.storyObject.allTags;

    this.settings = settings || {};
    this.waitingGroups = new Set();
    this.waitingFor = {};
    this.players = new PlayerManager(settings);
    this.player = player || {
      tags: {},
      choices: [],
      microcosms: [],
      bookmark: null,
    };
    this.eventLock = false;
    this.processing = [];
    this.listeners = [];
    if (listener) {
      this.listeners.push(listener);
    }
    this.directives = directives.directives;
    this.init();
  }

  init() {
    // story state
    this.scene = null;
    this.directionIndex = -1;
    this.direction = null;
    this.finished = false;

    this.characters = [];
    this.tags = {};
    this.story.tags
      .filter((t) => t.default)
      .forEach((tag) => {
        if (tag.default) {
          if (tag.type === "tag" && (tag.format === "numeric" || !tag.format)) {
            this.tags[tag.id] = parseFloat(tag.default);
          } else {
            this.tags[tag.id] = tag.default;
          }
        }
      });

    // visual state
    this.afterPause = null;
    this.text = "";
    this.image = null;
    this.block = { directions: [], startType: null, endType: null };
    this.currentBlock = [];
    this.choices = [];
    this.microcosms = [];
    this.characterImage = null;
    this.characterAura = null;
    this.background = null;
    this.music = null;
    this.clip = null;

    if (!this.player.choices) {
      this.player.choices = [];
    }

    if (document._taglistener) {
      document.removeEventListener("tag", document._taglistener);
    }

    const taglistener = (e) => {
      const tag = this.story.tags.find((t) => t.name === e.detail.name);
      if (tag) {
        const value = e.detail.value;
        if (tag.format === "text") {
          this.tags[tag.id] = `${value}`;
        } else {
          this.tags[tag.id] = value;
        }
      }
    };
    document._taglistener = taglistener;
    document.addEventListener("tag", taglistener);
  }

  reset() {
    this.init();
    this.player.choices = [];
  }

  getStartScene() {
    let scene = this.story.scenes.find((s) => s && s.start);
    if (!scene && this.story.scenes.length > 0) {
      scene = this.story.scenes[0];
    }
    if (!scene) {
      scene = {
        id: 1,
        directions: [{ id: 1, type: "text", content: "Hello! You need to set a start scene" }],
      };
    }
    return scene;
  }

  async start() {
    // restore player permanent tags
    // this.notify('players', this.players.players);
    const choices = this.player.choices.filter((c) => c.scene);

    // convert choices to tags
    this.tags = copy(this.player.session || this.player.tags || {});
    this.initializeTags();

    if (choices.length === 0) {
      this.scene = this.getStartScene();
      this.notify("scene", this.scene.id);
      if (
        this.scene.act &&
        this.scene.directions.filter((d) => d.type === "choice").length ===
          this.scene.directions.length
      ) {
        this.randomizedDirection(this.scene);
      } else {
        await this.proceed();
      }
    } else {
      const count = choices.length;
      const lastChoice = choices[count - 1];
      const index = this.player.choices.indexOf(lastChoice);
      this.player.choices = this.player.choices.slice(0, index + 1);
      if (this.player.microcosms) {
        this.microcosms = copy(this.player.microcosms);
      }
      this.changeScene(lastChoice.scene, lastChoice.idx === undefined ? -1 : lastChoice.idx);
    }
  }

  process(lambda) {
    this.processing.push(lambda);
    if (!this._isProcessing) {
      this._isProcessing = true;
      while (this.processing.length > 0) {
        this.processing.shift()();
      }
      this._isProcessing = false;
    }
  }

  async next(playerId = null) {
    if (this.eventLock) {
      log("cannot proceed, eventlocked", this.eventLock);
      return;
    }

    let cont = true;
    if (playerId !== null && this.players.isMultiplayer()) {
      this.activatePartyByPlayer(playerId);
      this.notify("player-pick", {
        id: true,
        playerId,
        scene: this.scene.id,
        direction: this.direction.id,
      });
      cont = !!(await this.waitForParties([this.players.partyId], playerId));
    }

    if (cont) {
      log(` *> next [${playerId}]`);
      // this.process(() => this.proceed());
      return this.proceed();
    }
  }

  async choice(id, playerId = null) {
    clearTimeout(this.timer);
    let cont = true;

    if (this.players.isMultiplayer()) {
      this.activatePartyByPlayer(playerId);

      if (this.choices.length === 0) {
        log(" ?? choice, but not choices", playerId, id, this.choices, this.players.party);
        return -1;
      } else {
        log(" *> choice", playerId, id);
      }

      this.notify("player-pick", {
        id,
        playerId,
        scene: this.scene.id,
        direction: this.direction.id,
      });

      let waitFor = [this.players.partyId];
      if (this.direction.waitFor) {
        waitFor = this.direction.waitFor.map((p) => this.players.getPartyIdByName(p));
      }
      const votes = await this.waitForParties(waitFor, playerId, id);
      cont = false;

      if (votes) {
        if (this.direction.type === "vote") {
          let array = null;
          const tag = this.story.tags.find((t) => t.name === this.direction.array);
          if (!tag) {
            console.error(" >> tag", tag);
          } else if (this.tags[tag.id]) {
            array = this.tags[tag.id];
          }

          // count votes
          Object.keys(votes).forEach((party) => {
            votes[party].slice(0, this.direction.count || 1).forEach((vote) => {
              const item = array.find((i) => i.id === vote);
              if (item) {
                item[this.direction.attr] += 1;
              }
            });
          });

          const effects = [
            {
              tag: `${this.direction.array}`,
              op: "r", // TODO: reset votes
              value: copy(array),
            },
          ];
          this.recordChoice({ effects });
          this.proceed();
        } else {
          const tags = copy(this.tags);
          const playerVotes = [];
          const splits = {};

          for (const player in votes) {
            const vote = votes[player];
            const choice = this.choices.find((c) => c.id === vote);
            if (choice) {
              let partyId = null;

              if (choice.split) {
                tags.$player = tags.$players.find((p) => p.id === player);
                let groupname = null;
                groupname = runFormula(this.story, choice.split, tags);
                if (!groupname) {
                  groupname = choice.split;
                }
                if (groupname) {
                  partyId = this.players.getPartyIdByName(groupname);
                }
              } else {
                partyId = this.players.players.find((p) => p.id === player).party;
              }
              if (!splits[partyId]) {
                splits[partyId] = {
                  choice,
                  players: [],
                  effects: [],
                };
              }
              splits[partyId].players.push(player);
              if (choice.effects) {
                splits[partyId].effects = splits[partyId].effects.concat(
                  generateEffectsWithSubstitutions(
                    choice.effects,
                    player,
                    `${this.direction.array}.${choice.id}`,
                  ),
                );
              }
              // } else {
              //   playerVotes.push({ vote, player });
              // }
            }
          }

          id = [];

          if (playerVotes.length > 0) {
            const idIndex = Math.floor(Math.random() * playerVotes.length);
            const nextId = playerVotes[idIndex].vote;
            id.push({
              partyId: parseInt(this.players.partyId),
              choice: this.choices.find((c) => c.id === nextId),
              players: playerVotes.map((v) => v.player),
            });
          }

          if (Object.keys(splits).length > 0) {
            for (const partyId in splits) {
              id.push({
                partyId: parseInt(partyId),
                choice: splits[partyId].choice,
                players: splits[partyId].players,
                effects: splits[partyId].effects,
              });
            }
          }

          log("  > id", id);
          cont = true;
          log(` u> choice: [${id}] [${cont}] [${playerId}]`);
        }
      }
    }

    if (cont) {
      this.readChoice(id);
    }
  }

  async options(value, playerId = null) {
    log(` *> options "${value}" [${playerId}]`);
    this.activatePartyByPlayer(playerId);
    const values = await this.waitForParties([this.players.partyId], playerId, value);
    if (values) {
      const effects = [];
      for (const playerId in values) {
        for (const choice in values[playerId]) {
          const value = values[playerId][choice];
          const direction = this.currentBlock.find((d) => d.data.id == choice);
          if (direction) {
            for (const effect of direction.data.effects || []) {
              const neffect = copy(effect);
              if (/\$value\b/.test(neffect.value)) {
                neffect.value = neffect.value.replace(/\$value\b/g, value);
              }
              effects.push(neffect);
            }
          }
        }
      }
      if (effects.length > 0) {
        this.recordChoice({ effects });
      }
      this.proceed();
    }
  }

  async input(value, playerId = null) {
    log(` *> input "${value}" [${playerId}]`);
    this.activatePartyByPlayer(playerId);
    const values = await this.waitForParties([this.players.partyId], playerId, value);
    if (values) {
      let points = null;
      if (this.direction.points) {
        points = StoryTag.formula(this.story, this.tags, `${this.direction.points}`);
      }
      const effects = [];
      for (const player in values) {
        let pp = points;
        let tag = this.direction.content;
        if (/^\$player/.test(this.direction.content)) {
          tag = tag.replace("$player", "$" + player);
        }
        if (values[player] instanceof Object) {
          for (const key in values[player]) {
            let value = values[player][key];
            const reference = `${tag}.${key}.${this.direction.attr}`;
            const inst = StoryTag.generate(this.story, this.tags, reference);
            if (inst) {
              if (pp !== null) {
                const start = inst[this.direction.attr];
                const delta = value - start;
                if (delta >= pp) {
                  value = start + pp;
                  pp = 0;
                } else {
                  pp -= delta;
                }
              }
              if (pp === null || value !== 0) {
                effects.push({
                  tag: reference,
                  op: this.direction.op || ":",
                  value: value,
                });
              }
            }
          }
        } else {
          effects.push({ tag, op: ":", value: values[player] });
        }
      }
      this.recordChoice({ effects });

      return this.next();
    } else {
      return false;
    }
  }

  async proceed() {
    // this.players.clearChoices();

    if (this.choices.length === 0 && Date.now() < this.afterPause) {
      return false;
    }

    // start the story if the scene is blank
    if (!this.scene) {
      this.start();
    }

    if (this.scene) {
      // proceed through the blocks
      // find the next block that should be shown
      const directive = this.getNextDirective(this.directionIndex + 1);
      if (directive) {
        this.directionIndex = directive.index;
        this.direction = directive.data;

        let sub = directive;
        if (this.direction.type === "group") {
          sub = directive.direction;
        }

        // some tags will modify tags but choices apply effects after being used
        if (
          sub &&
          directive.shouldRecordEffects() &&
          !directive.willRedirect() &&
          directionWillModify(sub.data) &&
          !sub.data.array // array is handled manually
        ) {
          // process direction effects
          this.recordChoice(sub.data);
        }

        if (this.block.directions.length === 0) {
          this.notify("block-start");
        }

        // console.log(
        //   ` >> applying ${directive.data.type} (${this.scene.id}-${directive.data.id}) (party: ${this.players.partyId})`
        // );
        const redirected = directive.apply(this);
        if (!redirected) {
          this.block.directions.push(directive);
          if (directive.startsBlock()) {
            this.block.startType = directive.stackingType;
          }
          if (directive.endsBlock()) {
            this.block.startType = this.block.startType || directive.stackingType;
            this.block.endType = directive.stackingType;
          }

          const nextDirective = this.getNextDirective(this.directionIndex + 1);
          let isEmpty = !this.block.startType && !this.block.endType;
          let canStartBlock = false;
          let canEndBlock = false;

          if (nextDirective) {
            // can proceed if
            const startsBlock = nextDirective.startsBlock();
            canStartBlock =
              startsBlock &&
              // ...this block hasn't started
              (!this.block.startType ||
                // ...or fits in the current stack
                (nextDirective.allowStacking() &&
                  this.block.startType === nextDirective.stackingType));

            // or this block is started and ends a block
            canEndBlock =
              !startsBlock &&
              this.block.startType &&
              nextDirective.endsBlock() &&
              // ... this block hasn't ended
              (!this.block.endType ||
                // ...or fits in the current stack
                (directive.allowStacking() && this.block.endType === nextDirective.stackingType));
          }

          // finish story or scene
          if (!this.block.startType && this.choices.length === 0 && !nextDirective) {
            // console.log('   <> finish');
            this.endThread();
          }

          // continue rendering block
          else if (nextDirective && (isEmpty || canStartBlock || canEndBlock)) {
            // console.log('   <> proceed');
            return this.proceed();
          }

          // pause
          else {
            // console.log('   <> pausing');
            this.endBlock();
            this.placeSavepoint();
          }
        } // redirect
      } else {
        // console.log('   <> end thread');
        this.endThread();
      }
    }
    return true;
  }

  fastForwardThroughGroup(index) {
    let groupType = null;
    while (index < this.scene.directions.length) {
      index += 1;
      this.direction = this.scene.directions[index];
      if (groupType === null) {
        groupType = this.direction.type;
      } else if (groupType != this.direction.type) {
        index -= 1;
        break;
      }
    }
    return index;
  }

  placeSavepoint() {
    this.players.party.savepoint = {
      scene: this.scene.id,
      idx: this.directionIndex,
      microcosms: copy(this.microcosms),
      choices: copy(this.choices),
    };
  }

  endThread() {
    log(` ?> finish? (${this.scene.id}/${this.directionIndex})`, this.players.partyId);

    let finish = true;
    if (this.microcosms.length === 0 && this.players.isMultiplayer()) {
      if (this.players.party) {
        // && !this.players.party.separate) {
        this.notify("completed", this.players.party.id);

        this.players.parties.slice().forEach((party) => {
          if (this.players.players.filter((p) => p.party === party.id).length === 0) {
            this.players.closeParty(party);
          }
        });

        // this party has finished the story
        this.players.closeParty();
        if (this.players.parties.length > 1) {
          finish = false;
        }
      }
    }

    if (finish) {
      this.finish();
    }
  }

  endBlock() {
    log(" <> block cleared");
    this.notify("block-clear");
    this.currentBlock = this.block.directions.slice();
    this.block.directions.length = 0;
    this.block.endType = null;
    this.block.startType = null;
  }

  finish() {
    if (this.microcosms.length > 0) {
      const microcosm = this.microcosms.pop();
      log(" ** popping microcosm");
      this.changeScene(microcosm.scene, microcosm.idx);
    } else {
      for (const partyId in this.mppromises) {
        if (this.mppromises[partyId]) {
          this.mppromises[partyId].resolve(false);
        }
      }
      log("finished!");
      this.notify("finished", true);
      this.finished = true;
    }
  }

  waitFor(id, needs) {
    if (!this.waitingFor[id]) {
      this.waitingFor[id] = { needs: new Set(), has: new Set() };
    }
    const node = this.waitingFor[id];
    needs.forEach((group) => node.needs.add(group));
    log(" ww waiting for:", Array.from(node.needs).join(", "));

    node.has.add(this.players.party.name);
    this.waitingGroups.add(this.players.party.id);
    log(" ww now has:", Array.from(node.has).join(", "));

    this.notify("waiting", {
      needs: Array.from(node.needs),
      has: Array.from(node.has),
    });

    const result = new Set([...node.needs].filter((x) => !node.has.has(x)));
    return result.size === 0;
  }

  readChoice(id) {
    if (this.players.isMultiplayer()) {
      // if any choice has a split, split the party
      for (const group of id) {
        const party = this.players.getParty(group.partyId);
        if (party) {
          log(" == saving party", group.partyId);
          party.savepoint = {
            microcosms: copy(this.microcosms),
            choices: copy(this.choices),
            scene: this.scene.id,
            idx: this.directionIndex,
          };
          this.players.splitParty(group.partyId, group.players);
        } else {
          console.error("no party?", group, this.players.parties);
        }
      }
      this.notify("players", this.players.players);

      for (const group of id) {
        this.activateParty(group.partyId);
        if (group.choice.id) {
          this.continueChoice(group.choice.id, group.effects);
        } else {
          this.proceed();
        }
      }
    } else {
      this.continueChoice(id);
    }
  }

  continueChoice(id, effects = null) {
    this.players.clearChoices();

    // prevent false positives for infinite loop
    this._lastChangeSceneCount = 0;

    const choice = copy(this.choices.find((d) => d.id === id));
    if (choice) {
      log(" ** cleared choices");
      this.notify("pick", choice);
      this.choices.length = 0;
      this.endBlock();

      // modify the effects
      if (effects) {
        choice.effects = effects;
      } else if (choice.array) {
        let item = this.tags[choice.array].find((item) => item.id === choice.id);
        if (choice.effects) {
          choice.effects = generateEffectsWithSubstitutions(
            choice.effects,
            null,
            `${choice.array}.${choice.id}`,
          );
        }
      }

      // this.needs Break = false;
      if (choice.scene) {
        this.recordChoice(choice);
        this.applyMicrocosm(choice.scene, choice);
        this.changeScene(choice.scene);
      } else {
        if (directionWillModify(choice)) {
          this.recordChoice(choice);
        }
        this.proceed();
      }
    } else {
      console.error(
        `invalid choice: ${id}`,
        this.choices,
        this.choices.map((c) => c.id),
      );
      // throw new Error(`invalid choice: ${id} ${this.choices.map(c => c.id)}`);
    }
  }

  waitForParties(parties, playerId, choice = true) {
    const players = this.players.players
      .filter((p) => {
        return parties.indexOf(p.party) > -1;
      })
      .map((p) => p.id);
    const fire = Campfire.register(
      `waiting-${this.scene.id}-${this.direction.id}`,
      players,
      this.settings.choiceTimer,
    );
    const retval = fire.arrival(playerId, choice);
    this.notify("waiting", {
      scene: this.scene.id,
      direction: this.direction.id,
      needs: fire.needs,
      has: Array.from(fire.arrived),
    });
    return retval;
  }

  lockUntilEvent(name) {
    this.players.clearChoices();
    this.choices.length = 0;
    log("waiting for", name);
    this.eventLock = name;
  }

  // TODO: wait for event?
  event(name) {
    log(" *> event", name);
    this.eventLock = false;
    this.next();
  }

  recordChoice(userChoice) {
    // TODO: apply choice now instead of running through history?
    // CHANGEME
    if (userChoice.effects) {
      for (const effect of userChoice.effects.filter((e) => e.tag && e.op)) {
        applyEffect(this.story, effect, this.tags);
      }
      this.notify("tags", this.tags);
    }
    if (userChoice.scene) {
      this.player.choices.push({
        scene: userChoice.scene,
        idx: userChoice.idx,
        from: {
          scene: this.scene.id,
          idx: this.directionIndex,
        },
      });
      this.player.choices = this.player.choices.slice(-15);

      // only update session on scene change
      // so if reloaded it will remain consistent
      this.player.session = copy(this.tags);
    }
    this.notify("player", this.player);
  }

  getNextDirective(idx) {
    let i = idx;
    while (i >= 0 && i < this.scene.directions.length) {
      const direction = this.scene.directions[i];
      if (shouldShowDirection(this.story, direction, this.tags)) {
        const directive = this.directives[direction.type] || this.directives.empty;
        const retval = new directive(this, direction);
        retval.index = i;
        return retval;
      } else {
        // skip the whole group
        if (direction.type === "group") {
          i = this.fastForwardThroughGroup(i);
        }
      }
      i += 1;
    }
    return null;
  }

  initializeTags() {
    this.tags = {};

    if (this.player.session) {
      // if given an existing session continue with that
      this.tags = copy(this.player.session);
    } else {
      // start with global tags
      this.tags = copy(this.player.tags || {});

      // assign initial player data
      if (this.player.tags) {
        for (const key in copy(this.player.tags)) {
          this.tags[key] = this.player.tags[key];
        }
      }

      // TODO: player blueprint should be a blueprint not a type
      const playerType = this.story.allTypes.find((t) => t.id === "$player");
      let playerBlueprint = { id: "$player", name: "$player" };
      if ((this.story.settings || {}).playerBlueprint) {
        playerBlueprint = this.story.blueprints.find(
          (t) => t.id === this.story.settings.playerBlueprint,
        );
      }

      // assign player data
      let players = [{ id: "player", name: "player" }];
      if (this.players.isMultiplayer()) {
        players = this.players.players.map((p) => {
          return new Blueprint(playerType, playerBlueprint, copy(p));
        });
      } else {
        players = [new Blueprint(playerType, playerBlueprint, players[0])];
      }
      this.tags.$players = players;

      // initialize tag defaults && character data
      if (this.story.tags) {
        for (const tag of this.story.tags) {
          if (tag.type === "character" && this.story.characters) {
            const char = this.story.characters.find((char) => char.id === tag.character);
            if (char) {
              if (!this.characters[tag.id]) {
                this.characters[tag.id] = new Character(char);
              }
              if (this.tags[tag.id]) {
                this.characters[tag.id].setValues(this.tags[tag.id]);
              }
              this.tags[tag.id] = this.characters[tag.id].toObject();
            }
          } else if (tag.type === "tag" && tag.default) {
            if (tag.format !== "text") {
              this.tags[tag.id] = parseFloat(tag.default);
            } else {
              this.tags[tag.id] = tag.default;
            }
          } else if (tag.type === "ref") {
            if (tag.default) {
              const values = this.story.blueprints.find((b) => b.id === tag.default);
              const config = this.story.types.find((t) => t.id === values.meta);
              const instanceId = "__" + nanoid();
              this.tags[instanceId] = new Blueprint(config, values);
              this.tags[instanceId].data.iid = instanceId;
              this.tags[tag.id] = new Proxy(this.tags[instanceId], {});
            } else {
              this.tags[tag.id] = null;
            }
          } else if (tag.type === "array") {
            if (tag.referencing) {
              const array = [];
              const bps = (this.story.blueprints || []).filter((b) => b.meta === tag.referencing);
              const defaults = tag.default || [];
              const fill =
                tag.arrayFill === "all"
                  ? bps.filter((b) => defaults.indexOf(b.id) === -1)
                  : bps.filter((b) => defaults.indexOf(b.id) > -1);

              let index = 0;
              for (const values of fill) {
                const config = (this.story.types || []).find((t) => t.id === values.meta) || {};
                const instance = new Blueprint(config, values);
                // const key = tag.id + '$' + index;
                // this.tags[key] = instance;
                array.push(instance);
                index += 1;
              }
              this.tags[tag.id] = array;
            } else {
              this.tags[tag.id] = null;
            }
          } else if (tag.default) {
            this.tags[tag.id] = tag.default;
          }
          // console.log('tags', this.tags);
        }
      }
    }

    this.addTagsFromChoices();

    this.notify("tags", this.tags);
  }

  // CHANGEME
  addTagsFromChoices() {
    const choices = this.player.choices;
    const tagIndex = choices.reduce((a, b, i) => (b.tags ? i : a), -1);

    // // what is this doing?
    // for (const choice of choices.slice(tagIndex)) {
    //   const effects = choice.effects || [];
    //   if (!choice.tags) {
    //     for (const effect of effects.filter(e => e.tag && e.op)) {
    //       applyEffect(this.story, effect, this.tags);
    //     }
    //   } else {
    //     response = this._setupBlueprints(copy(choice.tags));
    //   }
    // }

    this._setupBlueprints(this.tags);

    // write back to players
    if (this.players.isMultiplayer()) {
      this.tags.$players.forEach((player) => {
        const inst = this.players.players.find((p) => p.id === player.id);
        player.$meta.attrs.forEach((attr) => {
          if (player[attr.name] !== undefined && inst[attr.name] !== player[attr.name]) {
            inst[attr.name] = player[attr.name];
          }
        });
      });
    }

    this.tags.$party = this.players.party;

    // response = this._collapseBlueprints(response);

    return this.tags;
  }

  _setupBlueprints(response) {
    // turn on blueprints
    for (const key in response) {
      const value = response[key];
      if (value) {
        if (value instanceof Array) {
          response[key] = value.map((r) => this._setupBlueprints({ $item: r }).$item);
        }
        if (!(value instanceof Blueprint) && value.$meta) {
          response[key] = Blueprint.parse(this.story, value);
        }
      }
    }
    return response;
  }

  _collapseBlueprints(response) {
    // turn on blueprints
    for (const key in response) {
      const value = response[key];
      if (value) {
        if (value instanceof Array) {
          response[key] = value.map((r) => this._collapseBlueprints({ $it: r }).$it);
        }
        if (value.$meta) {
          const data = value.data;
          data.$meta = value.$meta.id;
          data.$config = value.$config.id;
          response[key] = data;
        }
      }
    }
    return response;
  }

  startPause(ms) {
    this.afterPause = Date.now() + ms;
    this.notify("pause", ms);
    this.timer = setTimeout(() => this.next(), ms);
  }

  startTimer(ms, scene) {
    const timeout = ms || this.settings.defaultChoiceTimer || 10 * 1000;
    const myTimer = () => {
      this.notify("choice-timer", timeout);
      this.timer = setTimeout(() => {
        log("timer! choices cleared, scene change...");
        this.notify("choice-timer-timeout");
        this.choices.length = 0;
        if (scene) {
          this.applyMicrocosm(scene);
          this.changeScene(scene);
        } else {
          this.proceed();
        }
      }, timeout);
      return timeout;
    };
    if (this.settings.deferTimer) {
      this.notify("choice-timer-ready", myTimer);
    } else {
      myTimer();
    }
  }

  applyMicrocosm(sceneId, direction = null) {
    const microcosm = direction ? direction.microcosm : false;
    const scene = this.story.scenes.filter((a) => a).find((s) => s.id === sceneId);
    if (microcosm || (scene && scene.microcosm)) {
      this.microcosms.push({
        scene: this.scene.id,
        idx: this.directionIndex,
      });
    }
  }

  showMicrocoms() {
    console.log("\n === microcosms");
    for (const micro of this.player.microcosms) {
      const scene = this.story.scenes.find((a) => a.id === micro.scene);
      console.log("  - ", scene.title, micro);
    }
    console.log("\n");
  }

  changeScene(id, idx = -1) {
    // log(' << changing scene', id, this.players.partyId);

    // prevent infinite loops
    if (this._lastChangeScene !== id) {
      this._lastChangeScene = id;
    } else {
      this._lastChangeSceneCount = (this._lastChangeSceneCount || 0) + 1;
      const now = Date.now();
      if (this._lastChangeSceneCount > 5 && now - this._lastChangeSceneTime < 1500) {
        throw new Error("Infinite loop detected");
      }
      this._lastChangeSceneTime = now;
    }

    clearTimeout(this.timer);

    const scene = this.story.scenes.find((s) => `${s.id}` === `${id}`);
    if (scene) {
      // break is now satisfied
      // this.needs Break = false;
      this.scene = scene;
      this.notify("scene", id);
      if (scene.act) {
        this.randomizedDirection(scene);
      } else {
        this.directionIndex = idx;
        this.text = "";
        // log('changing scene, clearing choices');
        this.choices.length = 0;
        this.proceed();
      }
    } else {
      console.error(
        "invalid scene",
        "available:",
        this.story.scenes.map((s) => `${s.id}`),
        "missing:",
        id,
      );
      throw new Error(`invalid scene: ${id}`);
    }
  }

  addChoice(choice) {
    choice.direction = this.direction.id;
    this.choices.push(choice);
    this.notify("choice", choice);
  }

  changeText(textChange) {
    const value = copy(textChange);
    const context = {};
    for (const tag in this.characters) {
      context[tag] = this.characters[tag].toObject();
    }
    value.label = insertTags(value.label, this.story, this.tags, context);
    value.content = insertTags(value.content, this.story, this.tags, context);
    this.text = value.content;
    this.characterImage = {
      character: value.character,
      state: value.state,
    };
    this.notify("text", value);
  }

  setImage(value) {
    this.image = value.content;
    this.notify("image", value);
  }

  changeBackground(bg) {
    this.background = bg.content;

    this.notify("background", bg);
  }

  changeLighting(light) {
    this.lighting = light;
    this.notify("lighting", light);
  }

  changeClip(clip) {
    this.clip = clip;
    this.notify("audio", clip);
  }

  changeMusic(clip) {
    this.music = clip;
    this.notify("music", clip);
  }

  changeVideo(data) {
    this.notify("video", data);
  }

  randomizedDirection(scene, idx = 0) {
    // get matching start scenes

    const options = [];
    let directionType = null;
    for (let index = idx; index < scene.directions.length; index += 1) {
      const direction = scene.directions[index];
      if (directionType != null && direction.type !== directionType) {
        break;
      }
      if (shouldShowDirection(this.story, direction, this.tags)) {
        directionType = direction.type;
        options.push(direction);
      }
    }

    // add probability weights
    const total = options.reduce(
      (a, b) => a + (b.weight === undefined ? 1 : parseInt(b.weight, 10)),
      0,
    );
    let lots = [];
    for (const option of options) {
      let num = 1 / options.length;
      if (total > 0) {
        num = (option.weight === undefined ? 1 : option.weight) / total;
      }
      if (num > 0) {
        // of 10 lots for possible fractions
        lots = lots.concat(new Array(Math.floor(num * 10)).fill(option.id));
      }
    }

    // select next scene
    const nextId = lots[Math.floor(Math.random() * lots.length)];
    const next = options.find((o) => o.id === nextId);

    this.recordChoice(next);
    this.changeScene(next.scene);
  }

  activatePartyByPlayer(playerId) {
    const player = this.players.player(playerId);
    if (player) {
      this.activateParty(player.party);
    }
  }

  activateParty(partyId) {
    if (this.players.partyId !== partyId) {
      this.players.partyId = partyId;
      const party = this.players.party;
      this.tags.$party = this.players.party;

      if (party.savepoint) {
        this.scene = this.story.scenes.find((s) => s.id === party.savepoint.scene);
        this.directionIndex = party.savepoint.idx;
        this.direction = this.scene.directions[this.directionIndex];
        this.choices = copy(party.savepoint.choices);
        this.microcosms = copy(party.savepoint.microcosms) || [];
        // log(' -> activating party', party.id, !!party.savepoint, this.choices.map(c => c.id));
        // log(' -> activated party', party.name, this.scene.id, this.directionIndex);
      } else {
        log(" -- no savepoint");
      }
    }
  }

  clearMultiplayerTimers() {
    for (const key in this.mptimers) {
      clearTimeout(this.mptimers[key]);
      delete this.mptimers[key];
    }
  }

  notify(changed, data) {
    // console.log(' -*', changed, data);
    this.listeners.forEach((l) =>
      l({
        changed,
        value: data,
        party: this.players.partyId,
      }),
    );
  }
}
