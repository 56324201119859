import { useToasterStore } from "@morphosis/base/stores/toaster";

export default {
  unmounted() {
    if (this.storyListener) {
      this.$root.offInternal("db:story", this.storyListener);
    }
    if (this.sceneListener) {
      this.$root.offInternal("db:scene", this.sceneListener);
    }
  },
  methods: {
    setupSyncListener() {
      if (this.$root.allowSyncing && this.story.cloudSync) {
        this.storyListener = (packet) => {
          if (
            packet.action === "update" &&
            this.story.id === packet.id &&
            packet.updated
          ) {
            useToasterStore().add({
              channel: "update",
              message: `Updating this story`,
              timeout: 1500,
            });
            this.story.update(packet.data);
          }
        };
        this.sceneListener = (packet) => {
          if (
            packet.action === "update" &&
            this.scene.id === packet.id &&
            packet.updated
          ) {
            useToasterStore.add({
              channel: "update",
              message: `Updating this scene`,
              timeout: 1500,
            });
            this.scene.update(packet.data);
          }
        };
        this.$root.onInternal("db:story", this.storyListener);
        this.$root.onInternal("db:scene", this.sceneListener);
      }
    },
    upload(scene, force = false) {
      if (this.$root.allowSyncing && this.story.cloudSync && scene) {
        clearTimeout(this.uploadTimeout);
        this.uploadTimeout = setTimeout(
          async () => {
            if (!scene.data.uid) {
              scene.data.uid = this.$root.user.id;
              scene.data.story = this.story.id;
              await scene.save();
            }
            await scene.push();
          },
          force ? 0 : 1000,
        );
      }
    },
  },
};
