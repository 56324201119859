<style scoped>
  .sidebar {
    top: 0;
    left: 0;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    width: 0px;
    position: fixed;
    z-index: 10;
  }
  .sidebar > div {
    top: 0;
    left: 0;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    position: absolute;
  }
  .sidebar .backdrop {
    width: 100vw;
    background: var(--bg);
    opacity: 0.6;
    z-index: 1;
  }
  .sidebar .content {
    height: 100%;
    padding: 5px;
    width: 80vw;
    max-width: 350px;
    background: var(--bg);
    color: var(--text);
    z-index: 2;
    border-right: 4px solid var(--color1);
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(95% - 20px),
      calc(95% - 20px) 100%,
      0 100%
    );
  }
  .sidebar.open {
    width: 80vw;
    overflow: visible;
  }
  .sidebar .content li,
  .sidebar .content ul {
    padding: 0;
    margin: 0;
  }
  .sidebar .content a {
    padding: 15px;
    display: block;
    flex: 1 1 auto;
  }

  .sidebar .sidebar-fade-enter-active,
  .sidebar .sidebar-fade-leave-active {
    transition: opacity 250ms;
  }
  .sidebar .sidebar-fade-enter-from,
  .sidebar .sidebar-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0
  }
</style>


<template>
  <div class="sidebar" :class="{open: modelValue}">
    <transition appear name="sidebar-fade">
      <div key="backdrop" class="backdrop" @click="$emit('update:modelValue', false)" v-if="modelValue"></div>
    </transition>
    <transition appear name="rotate-slide">
      <div key="content" class="content" v-if="modelValue">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import Feedback from "./feedback.vue";

export default {
  name: "sidebar",
  components: {Feedback},
  props: {
    modelValue: { default: false}
  }
}
</script>
