<template>
  <router-link
    :to="{name: 'malpractice-invoice'}"
    class="malpractice-cost"
    v-if="shouldShow && !inMalpractice"
  >
    {{ $t('malpractice_cost.april-invoice-cost-tofixed-2', {cost: cost.toFixed(2) }) }}
  </router-link>
</template>

<script>
import MalpracticeMixin from './malpractice_mixin';

export default {
  name: 'MalpracticeCost',
  mixins: [MalpracticeMixin],
  data() {
    return {
      last: new Date(),
    };
  },
  watch: {
    now() {
      const now = new Date();
      if (this.$root.inAppEvent !== 'aprilfools' && this.$root.autoInAppEvent) {
        if (now.getMonth() === 3 && now.getDate() === 1) {
          this.$root.inAppEvent = 'aprilfools';
          this.put(`story_april-update`, null);
          console.log('*** april fools ***');
        }
      }

      // console.log('malpractice is starting?', !this.$root.eventDetails, !this.inMalpractice);
      if (
        this.$root.inAppEvent === 'aprilfools' &&
        ((this.$root.eventDetails || 0) < 2 && !this.inMalpractice)
      ) {
        if (!this.$root.tutorialId) {
          const dt = (now.getTime() - this.last.getTime()) / (1000 * 60 * 3)
          const roll = dt + Math.random() * 0.4;
          if (roll > 0.999) {
            this.last = new Date();
            this.$root.tutorialId = 'april-update';
          }
        } else {
          this.last = new Date();
        }
      }
    },
  },
}
</script>

<style scoped>
.malpractice-cost {
  position: fixed;
  top: 0;
  left: 50%;
  margin-left: -75px;
  width: 150px;
  background: var(--bg);
  color: var(--text);
  border: 4px solid var(--text);
  z-index: 50;
  padding: calc(var(--spacing) * 2);
  border-radius: var(--softness);
}
</style>
