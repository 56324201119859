<style scoped>
.insert-direction {
  flex: 0 0 auto;
  color: var(--text);
}
.direction-select {
  flex: 2 1 auto;
}
.before-select {
  flex: 0 1 200px;
}
select {
  font-size: 20px;
}
.direction-groups {
  flex-wrap: wrap;
  align-items: flex-start;
}
.direction-group {
  /* color: var(--color3_text);
  background: var(--color3); */
  margin: 5px;
  border-radius: var(--softness);
}
.direction-group-inner {
}
.direction-group.beta {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAYUlEQVQoU21QQQ7AMAga/3+0DYrWOnsxGAoCPj3ENIspeIEvJolYH1InOLh7JCElC5NEgGa3qaULpt1QtIfQE8iFt7rBk25Z1Fmu6L1UvqrEVyaxVTHt6FgheuG661c6yQeIgR4NElo2jgAAAABJRU5ErkJggg==);
}
.direction-group .header {
  font-weight: bold;
  -webkit-text-fill-color: var(--spark_text);
  justify-content: center;
  text-align: center;
  padding: calc(var(--spacing) / 2);
  margin: var(--spacing);
  margin-bottom: var(--spacing);
  border-radius: var(--softness);
}
.direction-type {
  flex-direction: row;
  min-width: 130px;
  /* max-width: 120px; */
  border-radius: 5px;
  padding: 5px;
  margin: 2px;
  font-size: 14px;
  align-items: center;
  cursor: pointer;
  /* position: relative; */
}
.direction-type .icon {
  flex: 0 0 45px;
}
.name {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.beta-tag {
  background: red;
  color: white !important;
  border-radius: 10px;
  -webkit-text-fill-color: white;
  transform: rotate(-10deg);
}
</style>


<template>
  <div class="insert-direction column">
    <b v-if="title">{{ title || $t('scene.add-direction') }}</b>
    <div class="direction-groups row center">
      <div
        class="direction-group accented"
        :class="{beta: group.beta}"
        v-for="group of groups"
        :key="group.name"
      >
        <div class="flex-column direction-group-inner">
          <div class="header group-name">
            {{ $t(`directionGroup.${group.name}`) }}
          </div>
          <div
            v-for="t of group.types.filter((t) => !t.overlayout || overlayout)" :key="t.name"
            @click="insertDirection(t.name)"
            :class="`direction-type-${t.name}`"
            class="direction-type"
          >
            <div class="center icon"><i :class="t.icon" class="fa-2x" /></div>
            <div class="name">{{ $t(`directionType.${t.name}`) }}</div>
            <span class="beta-tag small" v-if="t.beta">({{ $t('app.beta') }})</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import directions from './directions/index';

export default {
  name: "insert-direction",
  props: {
    title: {type: String, default: null},
    story: Object,
    scene: Object,
    index: {default: -1},
  },
  data() {
    return {
      before: true,
      newDirectionType: "",
      groups: Array.from(new Set(directions.map(d => d.group))).map(g => ({
        name: g,
        types: directions.filter(d => d.group === g),
      })),
    };
  },
  computed: {
    overlayout() {
      return this.story.settings.overlayout;
    },
  },
  watch: {
    newDirectionType() {
      if (this.newDirectionType) {
        this.insertDirection(this.newDirectionType, this.before);
      }
      this.newDirectionType = "";
    }
  },
  methods: {
    insertDirection(type) {
      this.$emit("create", type);
    },
  }
}
</script>
