<style scoped>
.tag {
  border-radius: 8px;
  background: var(--color3);
  color: var(--color3_text);
  padding: 3px 12px;
}
i {
  padding: 0;
  margin: 0;
}
</style>

<template>
  <span class="tag" v-if="value && value.op === '<t>'">
    &lt;t&gt;<b>{{ value.value }}</b>
  </span>
  <span class="tag" v-else-if="value && value.tag && value.op === '#'">
    #<tag-label :story="story" :reference="value.tag" />
  </span>
  <span class="tag flex-row flex-align-center" v-else>
    <tag-label :story="story" :reference="value.tag" />
    {{ operator }}
    <tag-label v-if="value.other" :story="story" :reference="value.other" />
    <tag-label v-else-if="value.value" :story="story" :reference="value.value" />
    <template v-else>1</template>
  </span>
</template>

<script>
import TagLabel from './tag_label.vue';

const OPS = {
  '>': '>=',
  '<': '<=',
  '<<>' : '<=',
  '<>>' : '>=',
  '<:>' : ':',
  '<!>' : '!',
  '<=>' : '=',
};

export default {
  name: 'TagEffect',
  components: {
    TagLabel,
  },
  props: {
    value: Object,
    story: { type: Object, required: true },
  },
  computed: {
    operator() {
      return OPS[this.value.op] ? OPS[this.value.op] : this.value.op;
    },
  },
};
</script>
