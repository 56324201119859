<template>
  <AdminList
    v-model="book.music"
    @update:action="emits('update:action', $event)"
    @update:selected="emits('update:selected', $event)"
    @pick="emits('update:modelValue', $event)"
    :action="props.action"
    :selected="props.selected || props.modelValue"
    :config="schema"
  />
</template>

<script setup lang="ts">
import Media from "@/models/media";
import { useStoryStore } from "@/stores/story";
import { AudioSource } from "@/stores/story_defs";
import { VvField } from "@morphosis/base/components/registry";
import AdminList from "../../admin/AdminList.vue";

const book = useStoryStore();
const props = defineProps<{ modelValue?: string; action?: string; selected?: string }>();
const emits = defineEmits(["update:modelValue", "update:action", "update:selected"]);

const schema = {
  searchFields: ["name"],
  listDisplay: ["blob_id|audio", "media_name"],
  form: [
    {
      type: "media",
      name: "blob_id",
      label: "File",
      validation: "required",
      attrs: {
        accept: "audio",
      },
    },
  ] as VvField[],
  fields: {
    async media_name(record: AudioSource) {
      const media = await Media.load(record.blob_id);
      return (media?.name ?? "").trim() || "Untitled";
    },
  },
};
</script>

<style scoped></style>
