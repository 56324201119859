<style scoped>
.codes-view {
  background: white;
}
.codes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.code {
  text-align: center;
  flex: 1 1 auto;
  margin: 50px;
  max-width: 200px;
  border: 1px solid #eee;
}
.code canvas {
  margin: auto;
}
@media print {
  a {
    display: none;
  }
}
</style>



<template>
  <div class="codes-view">
    <a href="javascript:history.back()">Back</a>
    <div class="codes">
      <h3>{{ $route.params.text }}</h3>
      <div class="code" v-for="(qr, i) of qrs" :key="i">
        <h4>{{ qr }}</h4>
        <qr :text="qr" />
      </div>
    </div>
  </div>
</template>

<script>
import QR from "./qr.vue";

export default {
  name: "qr-view",
  components: {
    qr: QR
  },
  data() {
    return {
      story: null,
    }
  },
  computed: {
    qrs() {
      if (this.story) {
        let codes = [];
        this.story.scenes.forEach((scene) => {
          codes = codes.concat(scene.choices.filter(c => c.qr).map(c => c.label));
        });
        return codes;
      }
      return [];
    }
  },
  async mounted() {
    this.story = await this.loadFullStory(this.$route.params.id);
  }
}
</script>
