<template>
  <button @click.prevent="copyToClipboard(text)">
    {{ copyText }}
  </button>
</template>

<script>
export default {
  name: 'CopyButton',
  props: {
    text: String,
    label: String,
  },
  data() {
    return {
      copyText: this.label || this.$t('story.copy-button-label'),
    };
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.text);
      this.copyText = this.$t('story.copied');
    },
  },
}
</script>