<template>
  <InputNumber v-model="value" :invalid="!!errors" />
</template>

<script setup lang="ts">
import InputNumber from "primevue/inputnumber";
import { useField } from "vee-validate";

const props = defineProps<{ name: string }>();

const { value, errors } = useField<number>(props.name);
</script>
