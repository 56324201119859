<template>
  <div v-if="!story">
    {{$t('app_status.loading')}}
    &mdash; {{ $route.params.story }}
  </div>
  <div v-else>
    <router-view class="page" ref="page" v-slot="{ Component }">
      <transition :name="transition">
        <component :is="Component" v-bind="$route.params" :story="story" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useStoryStore } from '@/stores/story';

export default {
  name: 'story',
  data() {
    return {
      story: null,
      loaded: false,
      transition: 'slide-forward',
    };
  },
  computed: {
    ...mapStores(useStoryStore),
  },
  watch: {
    '$route.path': function (n, old) {
      const count = path => path.split(/\//g).length;
      this.transition = count(n) >= count(old) ? 'slide-forward' : 'slide-back';
    },
  },
  async mounted() {
    const story = await this.MODELS.story.load(this.$route.params.story);
    this.storyStore.setTargetStory(story);

    this.story = story;
    if (!this.story) {
      console.error('loading story failed', this.$route.params.story);
      this.$router.push('/');
    }
  },
  unmounted() {
    if (this.story) {
      this.story.dispose();
    }
    this.storyStore.setTargetStory(null);
  },
};
</script>
