<template>
  <component
    v-if="object"
    :is="formType"
    :class="{
      'auto-form': true,
      'sub-form': sub,
      [`form-direction-${direction}`]: true,
    }"
    @submit.prevent="handleSubmit"
  >
    <template v-for="group of groups">
      <div v-if="group.label" class="form-row">
        <div class="help-spacer" />
        <div class="header">
          {{ group.label }}
        </div>
      </div>
      <div
        v-for="field of group.fields"
        :key="field.name"
        :class="{'form-row': true}"
      >
        <template v-if="field.type === 'checkbox'">
          <div class="empty"></div>
          <label class="checkbox">
            <!-- <component
              :is="customField"
              :value="object"
              :field="field"
              @update:value="handleUpdate($event)"
            /> -->
            <input
              type="checkbox"
              :checked="object[field.name]"
              @change="handleUpdate({[field.name]: $event.target.checked})"
            />
            <template v-if="labels">{{ field.label || field.name }}</template>
          </label>
        </template>
        <template v-else-if="field.type === 'help'">
          <div v-if="field.label" class="form-label">
            <label v-if="labels">{{ field.label }}</label>
          </div>
          <div class="help-spacer" />
        </template>
        <template v-else-if="field.type === 'hidden'">
          <input
            type="hidden"
            :value="object"
          >
        </template>
        <div
          v-else-if="field.type === 'inline'"
          class="form-row inline-form"
        >
          <div class="form-label">
            <label v-if="labels">{{ field.label || field.name }}</label>
          </div>
          <div class="inlines">
            <auto-form
              v-for="row in object[field.name]"
              :key="row.id"
              :value="row"
              :fields="field.fields"
              sub
              @update:value="handleInlineUpdate(field, row.id, $event)"
            />
          </div>
        </div>
        <template v-else-if="field.type === 'sub'" >
          <div class="empty"></div>
          <div class="form-row sub-form">
            <auto-form
              :value="object"
              :fields="field.fields"
              :sub="true"
              @update:value="handleUpdate($event)"
            />
          </div>
        </template>
        <slot
          v-else
          :name="field.type || 'field'"
          :field="field"
          :value="value"
        >
          <slot :name="`${field.type}-label`">
            <div class="form-label">
              <label
                v-if="labels"
                :for="field.name"
              >{{ field.label || field.name }}</label>
            </div>
          </slot>
          <slot :name="`${field.type}-field`">
            <component
              :is="myCustomField"
              :value="object"
              :field="field"
              @update:value="handleUpdate($event)"
            />
          </slot>
        </slot>
        <div v-if="field.help" class="form-row">
          <div class="help-spacer" />
          <div
            class="help"
          >
            {{ field.help }}
          </div>
        </div>
      </div>
    </template>
    <div
      v-if="!sub"
      class="form-row button-row"
    >
      <slot name="default">
        <span />
        <div class="flex-row">
          <button class="btn btn-primary">
            Save
          </button>
        </div>
      </slot>
    </div>
  </component>
</template>

<script>
import copy from '../../functions/copy';
import AutoField from './AutoField.vue';

export default {
  components: {
    AutoField,
  },
  props: {
    customField: { type: Object, default: null },
    fields: { type: Array, required: true },
    value: { type: Object, required: true },
    direction: { type: String, default: 'row' },
    labels: { type: Boolean, default: true },
    sub: { type: Boolean, default: false },
  },
  data() {
    return {
      formType: this.sub ? 'div' : 'form',
      object: null,
    };
  },
  computed: {
    myCustomField() {
      return this.customField || AutoField;
    },
    groups() {
      let group = null;
      const retval = [];
      let idx = 1;
      for (const field of this.fields) {
        const grouper = field.group || null;
        if (!group || group.label !== grouper) {
          idx += 1;
          group = { id: idx, label: grouper, fields: [] };
          retval.push(group);
        }
        group.fields.push(field);
      }
      return retval;
    },
  },
  watch: {
    value: {
      handler() {
        this.object = copy(this.value);
      },
      deep: true,
    },
  },
  mounted() {
    this.object = copy(this.value);
  },
  methods: {
    handleSubmit(e) {
      e.stopImmediatePropagation();
      this.$emit('submit', this.value);
    },
    handleInlineUpdate(field, id, data) {
      const value = copy(this.value);
      const idx = value[field.name].findIndex((r) => r.id === id);
      value[field.name][idx] = data;
      this.$emit('update:value', value);
    },
    handleUpdate(values) {
      Object.keys(values).forEach((prop) => {
        this.object[prop] = values[prop];
      });
      this.$emit('update:value', this.object);
    },
  },
};
</script>
