<style scoped>
.tabs {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  margin-bottom: 5px;
}
.tabs a {
  flex: 1 1 auto;
  text-align: center;
  font-weight: normal;
  color: var(--text);
}
#app .tabs a.active {
  font-weight: bold;
  background: var(--spark);
  color: var(--spark_text);
  border-radius: 10px 10px 0 0;
  outline: none;
}
.tabs a:focus, .tabs a:hover {
  background: var(--color3);
  color: var(--color3_text);
  border-radius: 10px;
  outline: none;
}
</style>

<template>
  <div class="tabs">
    <a href="#"
       @click.prevent="$emit('update:value', tab === value ? null : tab)"
       :class="{active: value == tab}"
       v-for="(label, tab) in tabs" :key="tab">
      {{ label }}
    </a>
  </div>
</template>

<script>
export default {
  name: "tab-switch",
  props: {
    value: {default: null},
    tabs: {type: [Object, Array]},
  }
}
</script>