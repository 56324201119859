<style scoped>
.text-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<template>
  <div>
    <a href="javascript:history.back()">Back</a>
    <div class="text-center">
      <h3>{{ $route.params.text }}</h3>
      <qr />
    </div>
  </div>
</template>

<script>
import QR from "./qr.vue";

export default {
  name: "qr-view",
  components: {
    qr: QR
  }
}
</script>
