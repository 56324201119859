<style scoped>
.operation-array {
  display: flex;
  flex-direction: column;
}
.operation-array .list {
  max-width: 100%;
}
.row {
  max-width: 100%;
  flex: 1 1 auto;
}
.handle {
  padding: 10px 10px;
  white-space: nowrap;
  flex-direction: row;
  align-items: center;
}
.max-width {
  max-width: 100%;
  min-width: 0;
}
</style>

<template>
  <div class="operation-array">
    <list
      group="operations"
      :value="data"
      :default-object="template"
      :add-text="$t('operation_array.add-tags')"
      @update:value="handleReplace"
    >
      <template v-slot:empty><span /></template>
      <template v-slot="{object, input, index}">
        <div class="flex-row max-width">
          <div class="handle flex-static">
            <i class="fa fa-arrows-v" />
            <!-- <span class="padded">{{ index + 1 }}</span> -->
          </div>
          <direction-effect
            :story="story"
            :index="index"
            :value="object"
            :effects="type === 'effects' || type === null"
            :requirements="type === 'requirements' || type === null"
            :extras="extras"
            @update:value="input($event)"
          />
        </div>
      </template>
    </list>
  </div>
</template>

<script>
import DirectionEffect from "./editor/direction_effect.vue";


export default {
  name: "operation-array",
  props: {
    value: Array,
    story: Object,
    extras: { type: Object, default: null },
    type: { type: String, default: null },
  },
  components: {
    DirectionEffect,
  },
  data() {
    let template = { tag: null, op: '#', value: null };
    if (this.type === 'effects') {
      template = { tag: null, op: ':', value: null };
    }
    return {
      template,
      newEntry: template,
      defaultValues: {"<": 1, ">": 1, "=": 1, "+": 1, "-": 1, ":": 1},
    }
  },
  computed: {
    data() {
      return this.value ? this.copy(this.value) : [];
    },
    tag() {
      return null;  // this.story.tags.find(t => t.name === this.object.tag);
    },
  },
  watch: {
    tag() {
      if (this.tag && this.tag.type === '') {
        this.handleUpdate(this.object, 'op', '=');
      }
    }
  },
  methods: {
    handleReplace(e) {
      this.$emit("update:value", e);
    },
    handleUpdate(object, field, e) {
      if (e.target) {
        object[field] = e.target.value;
      } else {
        object[field] = e;
      }
      this.$emit("update:value", this.data);
    },
    handleTag(tag, object) {
      if (! this.story.tags.find(t => t.name == tag)) {
        this.story.tags.push({name: tag, type: "tag"});
      }
      object.tag = tag;
      this.story.tags = Array.from(set);
      this.$emit("update:value", this.data);
    },
  }
}
</script>
