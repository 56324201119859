/* eslint-disable no-restricted-syntax */
import localforage from 'localforage';
import { nanoid } from "nanoid";
import copy from "../../functions/copy";
import Manager from "./base";

const STORES = {};

export default class LocalForageManager extends Manager {
  static get prefix() {
    return this.appPrefix || "";
  }

  static set prefix(v) {
    this.appPrefix = v;
  }

  get store() {
    const label = `${this.constructor.prefix}:${this.name}`;
    if (!STORES[label]) {
      STORES[label] = localforage.createInstance({ name: label });
    }
    return STORES[label];
  }

  async list(ref) {
    const retval = ref || [];
    await this.store.iterate((object) => {
      retval.push(object);
    });
    return retval;
  }

  async count() {
    let count = 0;
    await this.store.iterate(() => {
      count += 1;
    });
    return count;
  }

  async load(id) {
    return this.store.getItem(`${id}`);
  }

  async save(object) {
    let data = object;
    if (!object.id) {
      data = copy(object);
      data.id = nanoid();
    }

    try {
      await this.store.setItem(data.id, data);
    } catch (ex) {
      window.app.addAlert({
        title: "Failed to write to with mgLocalStorage driver",
        message: `${ex.message}`,
        ex,
      });
    }
    return data.id;
  }

  delete(id) {
    return this.store.removeItem(`${id}`);
  }
}
