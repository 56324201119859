import Model from '@morphosis/base/models/index';
import TsunamiManager from '@morphosis/base/models/managers/tsunami';

export default class BaseModel extends Model {
  static get defaultObject() {
    return {
      id: null,
      updated: new Date().toISOString(),
    };
  }

  static get ManagerClass() {
    return TsunamiManager;
  }

  static get allowSyncing() {
    return !!window.app.user?.email && window.app.$root.allowSyncing;
  }

  async sync(cloud, lazyPull = false) {
    if (this.constructor.allowSyncing) {
      return super.sync(cloud, lazyPull);
    }
    return null;
  }

  async push(opts = {}) {
    if (this.constructor.allowSyncing) {
      return super.push(opts);
    }
    return null;
  }

  async pushDelete() {
    if (this.constructor.allowSyncing) {
      return super.pushDelete();
    }
    return null;
  }

  /** returns a list of reasons why maybe you don't want to delete this */
  deleteCheck() {
    return [];
  }
}
