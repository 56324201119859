<style scoped>
</style>

<template>
  <div class="layout" key="layout">
    <auto-form
      :sub="true"
      :value="value"
      :fields="fields"
      @update:value="update"
    >
      <span />
    </auto-form>
  </div>
</template>

<script>
import * as globals from '../../global';

export default {
  props: {
    story: Object,
    scene: Object,
    value: Object,
  },
  data() {
    return {
    };
  },
  computed: {
    fields() {
      const speeds = globals.TYPE_SPEEDS.slice();
      speeds.shift({ name: "Don't change", value: undefined });

      const retval = [
        {
          name: 'layout',
          label: 'Active Panel',
          options: [
            { name: "Don't change", value: undefined },
            { name: "Bottom", value: "bottom" },
            { name: "Top", value: "top" },
            { name: "Left", value: "left" },
            { name: "Right", value: "right" },
            { name: "Overlay", value: "overlay" },
          ],
        },
        {
          name: 'persistence',
          label: 'Direction Persistence',
          options: [
            { name: "Don't change", value: undefined },
            { name: "Directions disappear after a text block", value: "block" },
            { name: "Directions disappear at the end of a scene", value: "scene" },
            { name: "Directions are cleared manually", value: "manual" },
          ],
        },
        // {
        //   name: 'speed',
        //   label: 'Typing Speed',
        //   options: [
        //     { name: "Don't change", value: undefined },
        //   ],
        // },
        // TODO: {
        //   name: 'speed',
        //   label: 'Default Text Speed',
        //   options: speeds,
        // },
        // TODO: animation
        // TODO: columns
      ];
      return retval;
    },
  },
  watch: {
  },
  methods: {
    handleEvent(value) {
      console.log('event', value);
    },
    update(value) {
      this.$emit('update', value);
    },
  },
};
</script>
