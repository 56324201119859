import Story from "@/models/story";
import copy from "@morphosis/base/functions/copy";
import { nanoid } from "nanoid";
import { computed } from "vue";

let changes = {};
let timeout = null;

export async function updateList(list, object, changes, defaults) {
  const newList = copy(list);
  if (!object.id) {
    object.id = nanoid();
    newList.push(object);
  } else {
    const idx = newList.findIndex((o) => o.id === object.id);
    newList[idx] = object;
  }
  if (changes) {
    const old = newList.find((o) => o.id === object.id);
    for (const prop in changes) {
      old[prop] = changes[prop];
    }
  }
  if (defaults) {
    const old = newList.find((o) => o.id === object.id);
    for (const prop in defaults) {
      if (!old[prop]) {
        old[prop] = defaults[prop];
      }
    }
  }
  return newList;
}

export function isDetail(action) {
  return ["edit", "detail", "delete"].includes(action);
}

export function updateRoute(router, newParams) {
  for (const prop in newParams) {
    changes[prop] = newParams[prop];
  }

  clearTimeout(timeout);
  timeout = setTimeout(() => {
    const route = router.currentRoute.value;
    const params = copy(route.params || {});
    for (const key in changes) {
      if (changes[key]) {
        params[key] = changes[key];
      } else if (params[key]) {
        params[key] = "";
      }
    }
    changes = {};

    const onDetail = isDetail(route.params.action);
    const toDetail = isDetail(params.action);

    // changed to edit, then push a route
    // if (!onDetail && toDetail) {
    //   router.push({ params, query: route.query });
    // } else if (onDetail && !toDetail) {
    //   // changed back to list
    //   router.back();
    // } else {
    // otherwise replace
    router.push({ params, query: route.query });
    // }
  }, 10);
}

export async function doSave(story: Story): Promise<void> {
  await story.saveAndPush();
}

export function createSaveHandler(story, prop, emit) {
  const objectList = computed({
    get: () => story[prop],
    set: (v) => {
      story[prop] = v;
      story.save();
      emit("update:story", story.id);
    },
  });
  async function handleSave(object, changes, defaults = {}) {
    story[prop] = await updateList(story[prop], object, changes, defaults);
    story.save();
    emit("update:id", object.id);
  }

  return { objectList, handleSave };
}
