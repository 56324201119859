<template>
  <div v-if="story">
    <app-header v-model:modelValue="open" :show-menu="false">
      <back-button :back="{name: 'story', params: {story: story.id}}" />
      Delete: {{ story.title }}
    </app-header>
    <div>
      <p class="text-left">
        Remove this story? <b>{{ story.title }}</b>,
        {{ story.scenes.length }} scene(s)
      </p>

      <form @submit.prevent="handleDelete">
        <p v-if="mine && story.fid">
          <input v-model="unpublish" type="checkbox" />
          Also remove backups from the server
          <span class="muted">{{ story.fid }}</span>
        </p>

        <div class="flex-row flex-space-between">
          <button>Permanently Delete</button>
          <router-link to="..">Cancel</router-link>
        </div>
      </form>
    </div>
  </div>
  <div v-else>
    Embarassing, can't find this story by id {{ $route.params.id }}
  </div>
</template>

<script>
export default {
  name: "delete-story",
  props: {
    story: { type: Object, required: true },
  },
  data() {
    return {
      unpublish: true,
    };
  },
  computed: {
    mine() {
      return this.story.uid == this.$root.user.id;
    },
  },
  async mounted() {},
  methods: {
    async handleDelete() {
      let response = null;
      let error = null;
      if (this.unpublish && this.story.fid && this.mine) {
        response = await this.ws(`public_remove`, { id: this.story.fid });
        this.$root.log("delete response", response);
      }
      await this.story.pushDelete();
      await this.story.delete();
      this.$stories.removeItem(this.story.id);
      this.$router.push({ name: "home" });
    },
  },
};
</script>
