<template>
  <div
    v-if="advanced"
    class="advanced flex-row flex-left flex-align-top flex-wrap padded"
  >
    <div class="flex-column padded">
      <b>{{ $t('empty_content.process-from-a-list') }}</b>
      <tag-picker
        :story="story"
        :value="value.array"
        :extras="{
          $players: true,
        }"
        type="array"
        @update:value="update({ array: $event })"
      />
    </div>
  </div>
  <div v-else class="empty-content flex-column control">
    <help-message name="empty-direction">
      {{ $t('direction.empty-help') }}
    </help-message>
    <div class="flex-row flex-align-center">
      <div class="flex-static">&nbsp; // </div>
      <AutogrowTextarea
        @update:modelValue="$emit('update', {content: $event})"
        :modelValue="value.content"
        :placeholder="$t('notes_content.record-notes-here')"
        ref="textarea"
        rows="1"
        class="stretch"
      />
    </div>
  </div>
</template>

<script>
import TagPicker from '../editor/tag_picker.vue';

export default {
  name: 'EmptyContent',
  components: {
    TagPicker,
  },
  props: {
    story: Object,
    scene: Object,
    value: Object,
    advanced: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    hasAdvanced() {
      return true;
    },
  },
  methods: {
    update(value) {
      this.$emit('update', value);
    },
  },
};
</script>

<style scoped>
#app .advanced {
  padding: 5px;
  margin: 5px;
  justify-content: flex-start;
}
#app .advanced > * {
  margin: 10px;
  border-radius: 5px;
}

.grow-wrap {
  display: grid;
  opacity: 0.7;
}
.grow-wrap .template {
  /* This is how textarea text behaves */
  white-space: pre-wrap;
  visibility: hidden;
  color: darkred !important;
  background: transparent !important;
  border: 2px dashed red;
  z-index: 1;
  touch-action: none;
  pointer-events: none;
  opacity: 0.8;
  margin-left: -2px;
}
.grow-wrap > * {
  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
  padding: 7px 8px;
  border-width: 2px;
  font-size: 0.9em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
</style>
