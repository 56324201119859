<template>
  <div class="height-transition">
    <!-- <div style="background: white">
      [height: {{ height }}]
    </div> -->
    <div class="outer" ref="outer">
      <div class="inner" ref="inner">
        <slot ref="slot"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { timer } from '../../utils/timer';

export default {
  name: 'HeightTransition',
  props: {
    startFromInitial: { type: Boolean, default: false },
    value: { type: Number, default: null },
    speed: { type: String, default: '300ms' },
  },
  data() {
    return {
      height: this.startFromInitial ? 'auto' : '0px',
      running: false,
      // height: '0px',
      timeout: null,
    }
  },
  computed: {
    stepMod() {
      return this.steps;
    },
  },
  watch: {
    value() {
      this.transition();
    }
  },
  async mounted() {
    this.$refs.outer.style.height = this.height;

    this._observers = [];
    if (window.MutationObserver) {
      const mutator = new window.MutationObserver(async (changes) => {
        for (const change of changes) {
          if (
            (change.target !== this.$refs.inner && change.target !== this.$refs.outer) ||
            change.attributeName !== "style"
          ) {
            // await timer(1);
            this.transition();
          }
        }
      });

      // Observe one or multiple elements
      mutator.observe(
        this.$refs.inner,
        {
          attributes: true,
          childList: true,
          subtree: true
        }
      );
      this._observers.push(mutator);
    }

    if (!this.startFromInitial) {
      await this.$nextTick();
      this.transition();
    }
  },
  unmounted() {
    this._observers.forEach((o) => o.disconnect());
    clearTimeout(this.timeout);
  },
  methods: {
    transition() {
      if (this.$refs.inner) {

        // prevent 1000 events from activating all at once
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.getHeight();
        }, 1);
      }
    },
    async getHeight() {
      if (!this.running) {
        this.running = true;
        const outer = this.$refs.outer;
        const element = this.$refs.inner;
        const original = getComputedStyle(element);
        const width = original.width;

        element.style.width = width;
        element.style.position = "absolute";
        element.style.visibility = "hidden";
        // element.style.height = "auto";

        const height = getComputedStyle(element).height;

        element.style.width = null;
        element.style.position = null;
        element.style.visibility = null;
        // element.style.height = this.height;

        // Force repaint to make sure the
        // animation is triggered correctly.
        // getComputedStyle(inner).height;

        // console.log('height change', this.height, height);
        if (this.height != height) {
          this.height = height;
          outer.style.height = height;
        }
        this.running = false;
      }
    },
    pxToInteger(value) {
      return parseInt(value.slice(0, -2));
    },
  }
}
</script>

<style scoped>
.outer {
  display: flex;
  justify-content: flex-start;
  /* overflow: hidden; */
  transition-property: height;
  transition-duration: v-bind(speed);
  transition-timing-function: cubic-bezier(0.010, 1.020, 0.655, 1.000);
  transition-delay: 0ms !important;
  padding: 0;
  margin: 0;
  margin-bottom: 0;
}
.inner {
  flex: 0 0 auto;
}
.margin {
  transition-property: marginBottom;
  transition-duration: calc(v-bind(speed) / 2);
  transition-timing-function: linear;
  transition-delay: 0ms !important;
}
</style>