<template>
  <div class="padded">
    <b class="text-left">{{ $t('cloud_safe.cloud-images-should-be-safe-for-most-audiences-to-view') }}</b><br>
    <span class="text-left">{{ $t('cloud_safe.explicity-the-following-types-of-images-are-not-allowed') }}</span>
    <ul>
      <li>- {{ $t('cloud_safe.nudity-or-sexually-explicit-content') }}</li>
      <li>- {{ $t('cloud_safe.extreme-gore-abuse-or-violence') }}</li>
      <li>- {{ $t('cloud_safe.child-sexual-abuse') }}</li>
      <li>- {{ $t('cloud_safe.spam-or-blank-images-use-a-background-color-instead') }}</li>
      <li>- {{ $t('cloud_safe.personal-information-or-doxing') }}</li>
      <li>- {{ $t('cloud_safe.copyrighted-material-unless-you-are-the-copyright-holder') }}</li>
    </ul>
    <span>{{ $t('cloud_safe.violating-images-will-be-removed-and-future-uploading-may-be-suspended') }}</span>

    <p>{{ $t('cloud_safe.you-can-use-whatever-images-you-wish-for-stories-on-your-local-device') }}</p>
  </div>
</template>