<style scoped>
  .choice-line {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
  }
  .choice-line select {
    font-size: 18px;
    flex: 1 1 auto;
  }
  .fa {
    transition: transform, 150ms;
  }
  .choice-line a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--color3_text);
    font-size: 20px;
    min-width: 35px;
    height: 35px;
    margin-left: 5px;
    padding: 0;
  }
  .choice-line a span {
    padding: 3px;
    margin-right: 8px;
  }
  .choice-line .unlinked-scene {
    text-align: left;
    padding: 6px;
    height: 40px;
    align-items: center;
    display: flex;
  }
  .choice-line .linked-scene {
    background: var(--color3);
    color: var(--color3_text);

    padding: 0px;
    border-radius: 12px;
    border: 2px solid var(--color2);
    flex: 1 1 auto;
    justify-content: space-between;
    max-width: 100%;
    font-weight: bold;
  }
  .choice-line .linked-scene .dot {
    margin: 0;
  }
  .choice-line .linked-scene span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color3_text);
  }
  .choice-line .fa-search {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .scene-line {
    cursor: pointer;
  }
  svg {
    margin-left: 5px;
  }
</style>

<template>
  <div class="choice-line">
    <div
      class="linked-scene row"
      tabindex="0"
      @keypress="handleSceneSearch"
    >
      <span
        v-if="linked"
        @click="handleSceneSearch"
        :id="`choice-line-${value.id}`"
        class="scene-line flex-row flex-align-center flex-space-between"
      >
        <span class="dot" :class="linked.color" />
        <span>{{ linkedTitle }}</span>
        <vi
          v-if="linked.microcosm"
          name="restore"
          :title="$t('choice_line.linked-scene-will-return-here-it-is-a-microcosm')"
        />
      </span>
      <span
        v-else
        @click="handleSceneSearch"
        :id="`choice-line-${value.id}`"
        class="scene-line unlinked-scene stretch"
      >
        <i class="fa fa-search" />
        {{$t('choice_line.not-linked')}}
      </span>
      <router-link
        v-if="value.scene"
        :to="{name: 'scene', params: {story: story.id, id: value.scene}}"
        @keypress.prevent="this.click()"
        class="static"
        tabindex="0"
      >
        <i v-if="!scene || value.scene !== scene.id" class="fa fa-arrow-circle-right" />
        <i v-else-if="scene.directions.length > 1" class="fa fa-repeat" />
        <i v-else class="fa fa-exclamation-triangle" />
      </router-link>
    </div>
    <scene-search-modal
      v-if="showModal"
      :story="story"
      :value="value.scene"
      @update:value="handleInput"
      @show="handleShow"
    />
  </div>
</template>

<script>
import SceneSearchModal from '../components/scene_search_popup.vue';

export default {
  name: 'choice-line',
  props: {
    story: Object,
    scene: Object,
    value: Object,
  },
  components: {
    SceneSearchModal,
  },
  data() {
    return {
      status: null,
      showModal: false,
      scenes: [],
    };
  },
  computed: {
    linked() {
      if (this.value.scene) {
        return this.scenes.find(s => s.id === this.value.scene);
      }
      return null;
    },
    linkedTitle() {
      if (this.linked) {
        return this.linked.title || this.sceneGetText(this.linked);
      }
    },

  },
  async mounted() {
    this.scenes = await this.story.rel_scenes.list();
  },
  methods: {
    async handleSceneSearch(scene) {
      this.showModal = true;
    },
    async handleInput(scene) {
      if (this.value.scene !== scene) {
        this.$emit('change', scene);
      }
      this.showModal = false;
    },
    handleShow(value) {
      this.showModal = value;
      this.$el.querySelector('[tabindex]').focus();
    },
  },
};
</script>
