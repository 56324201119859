import compress from "@/compress";

export async function cleanImage(file: string): Promise<{ result: string; error?: string }> {
  try {
    file = await compress(file, {
      // maxWidthOrHeight: 2048,
      maxWidthOrHeight: 3840,
      useWebWorker: window.app.platform !== "ios",
    });
    const str = await compress.getDataUrlFromFile(file);
    return { result: str, error: null };
  } catch (ex) {
    return { result: null, error: ex };
  }
}
