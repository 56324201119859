<style scoped>
.event-content {
  opacity: 0.8;
}
</style>


<template>
  <div class="event-content">
    <help-message name="story-event-explanation">
      {{ $t('event_content.events-are-for-stories-intended-to-be-used-in-external-applications-they-send-a-message-and-wait-for-the-program-to-be-resumed') }}
    </help-message>
    <input
      :value="value.name"
      class="stretch"
      :placeholder="$t('event_content.event-name')"
      @input="$emit('update', {name: $event.target.value})"
    />
    <textarea
      :value="value.content"
      rows="4"
      class="stretch input"
      :placeholder="$t('event_content.event-details')"
      @input="$emit('update', {content: $event.target.value})"
    />
  </div>
</template>

<script>
export default {
  name: 'notes-direction',
  props: {
    story: Object,
    value: Object,
  },
  data() {
    return {
    };
  },
};
</script>
