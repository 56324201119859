export default {
  data() {
    return {
      joinCode: localStorage.gameId || '',
    };
  },
  computed: {
    players() { return this.$root.players; },
    shared() { return this.$root.shared; },
    state() { return this.$root.state; },
    code() { return this.$root.gameId; },
  },
  async mounted() {
    await this.$socket.ensureConnected();
  },
  // TODO: figure out when to disconnect
  // unmounted() {
  //   if (this.$socket.connected) {
  //     this.$socket.close();
  //   }
  // },
  methods: {
  },
};
