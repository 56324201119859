<style scoped>
.media-uploader {
  width: 100%;
  align-items: stretch;
}
select,
input,
textarea {
  flex: 0 1 100%;
  max-width: 100%;
}
</style>

<template>
  <form class="auto-form responsive mellow" @submit.prevent="handleSubmit">
    <div class="form-row">
      <label>{{ $t("story_form.title") }}</label>
      <div>
        <input
          required
          v-model="props.value.title"
          maxlength="128"
          @change="update({ title: $event.target.value })"
        />
      </div>
    </div>
    <div class="form-row">
      <label>{{ $t("story_form.short-description") }}</label>
      <div>
        <textarea
          maxlength="80"
          v-model="props.value.short_description"
          @change="update({ short_description: $event.target.value })"
        />
      </div>
    </div>
    <div class="form-row">
      <label>{{ $t("story_form.description") }}</label>
      <div>
        <textarea
          maxlength="1000"
          v-model="props.value.description"
          rows="6"
          @change="update({ description: $event.target.value })"
        />
      </div>
    </div>
    <div class="form-row" v-if="props.value.attrs">
      <span />
      <label class="checkbox">
        <input
          type="checkbox"
          v-model="props.value.attrs.multiplayer"
          name="multiplayer"
          @change="update({ multiplayer: $event.target.value })"
        />
        {{ $t("choice_content.multiplayer") }}
      </label>
    </div>
    <div v-if="props.value.attrs?.multiplayer" class="form-row">
      <div class="padded"></div>
      <div>
        <div class="accented padded">
          <div>
            <b>{{ $t("story_form.players") }}</b>
          </div>
          <div class="flex-row flex-align-center">
            Min:
            <input
              name="player-min"
              type="number"
              min="1"
              max="4"
              v-model="props.value.attrs.players_min"
              placeholder="1"
              required
              @change="update({ players_min: $event.target.value })"
            />
            Max:
            <input
              name="player-max"
              type="number"
              min="2"
              max="32"
              v-model="props.value.attrs.players_max"
              placeholder="2"
              required
              @change="update({ players_max: $event.target.value })"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="form-row" v-if="props.value.attrs">
      <label>{{ $t("story_form.genre") }}</label>
      <div>
        <select v-model="props.value.attrs.genre" @change="update({ genre: $event.target.value })">
          <option value="" selected disabled>
            {{ $t("story_form.choose-genre") }}
          </option>
          <option v-for="o of genres" :key="o">
            {{ o }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-row" v-if="props.value.attrs">
      <label>{{ $t("story_form.language") }}</label>
      <div>
        <select
          v-model="props.value.attrs.language"
          @change="update({ language: $event.target.value })"
        >
          <option value="" selected disabled>
            {{ $t("story_form.choose-language") }}
          </option>
          <option v-for="o of languages" :key="o">
            {{ o }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-row" v-if="props.value.id">
      <label>{{ $t("story_form.cover") }}</label>
      <div class="column left">
        <media-uploader v-model:value="cover" :ratio="3" :use-blob="false" />
        <p>{{ $t("story_form.original-artwork-for-promoting-your-story") }}</p>
      </div>
    </div>
    <div class="form-row">
      <label></label>
      <button v-if="status" disabled class="button">
        <i class="fa fa-refresh fa-spin" /> {{ status }}
      </button>
      <button v-else-if="props.value.id" class="button">
        {{ $t("story_form.update-story") }}
      </button>
      <button v-else class="button">{{ $t("story_form.create-story") }}</button>
    </div>
  </form>
</template>

<script setup lang="ts">
import { i18n } from "@/i18n";
import { Story } from "@/stores/story_defs";
import copy from "@morphosis/base/functions/copy";
import { useHead } from "@unhead/vue";
import { computed, Ref, ref, watch } from "vue";

const props = defineProps<{
  value: Story;
}>();
useHead(() => {
  if (props.value) {
    return { title: props.value.title };
  }
  return { title: "New Story" };
});
const emit = defineEmits(["submit", "update:value"]);
const editing = ref(false);
const status: Ref<boolean | string> = ref(false);

const cover = computed({
  get() {
    if (/^http/.test(props.value.cover)) {
      return { permalink: props.value.cover };
    }
    return { permalink: props.value.cover };
  },
  set(cover) {
    if (cover.permalink) {
      props.value.cover = cover.permalink;
    } else {
      props.value.cover = cover.url;
    }
    update({ cover: props.value.cover });
  },
});

watch(
  () => props.value.multiplayer,
  () => {
    if (props.value.multiplayer) {
      props.value.players_min = 2;
      props.value.players_max = 2;
    } else {
      props.value.players_min = 1;
      props.value.players_max = 1;
    }
  },
);

function update(newValues) {
  if (props.value.data) {
    const value = copy(props.value.data);
    for (const key in newValues) {
      value[key] = newValues[key];
    }
    emit("update:value", value);
  }
}
function handleSubmit(e) {
  e.stopImmediatePropagation();
  status.value = i18n.global.t("story_form.saving");
  emit("submit");
}
</script>
