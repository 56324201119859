
export function typerSplitLine(line: string) : Array {
  let words = [];
  line = line;
  line = line.replace(/\t/g, '');
  line = line.replace(/<br ?\/?>/g, '');
  line = line.replace(/<p>/g, '');
  line = line.replace(/<\/p>/g, '\n').trim();
  line = line.replace(/&quot;/g, '"');

  // using an array to allow emoji which take two elements
  // somehow the "for-of" knows about this and compensates
  // but vue doesn't in the template compiler
  let word = {content: [], type: null};
  for (let char of line) {
    // tag
    let type = null;
    if (/\>/.test(char) && word.type === 'tag') {
      const content = word.content.join('') + char;
      let tagName = content.slice(1).split(/>/)[0];
      let attrs = {};

      if (/ /.test(tagName)) {
        const bits = tagName.split(/ /);
        tagName = bits[0];
        attrs = bits.slice(1);
        if (attrs) {
          attrs = attrs.map((attr) => attr.trim().split('=')).reduce((a, b) => {
            a[b[0]] = b[1].slice(1, -1);
            return a;
          }, {});
        }
      }

      // self closing
      if (['img'].includes(tagName)) {
          word.tag = tagName;
          word.attrs = attrs;
          word.content = [1];
          word.ended = true;
          word.type = 'tag'
          type = 'tag';
          char = 'image';
      } else {
        // find end tag
        const testEndTag = new RegExp(`\<\/${tagName}\>`);
        if (testEndTag.test(content)) {
          const inner1 = content.split('>').slice(1).join('>');
          const inner2 = inner1.split('<');
          const inner = inner2.slice(0, inner2.length - 1).join('<');

          if (['b', 'i', 's', 'r', 'em', 'strong'].includes(tagName)) {
            word.tag = tagName;
          } else {
            word.tag = 'span';
          }
          word.attrs = attrs;
          word.children = typerSplitLine(inner);
          word.ended = true;
        }
        // find self closing tag
        else if (/\/\>|\<br\>|\<hr\>$/.test(content)) {
          word.remove = true;
          word.ended = true;
        }
        type = 'tag';
      }
    }
    // end tag
    else if (/\</.test(char) || (word.type === 'tag' && !word.ended)) {
      type = 'tag';
    }
    // whitespace
    else if (/\s/.test(char)) {
      type = 'whitespace';
    }
    // text
    else {
      type = 'text';
    }
    if (!word.type) {
      word.type = type;
    }
    if (word.ended) {
      word.content.push(char);
      words.push(word);
      word = {content: []}
    } else if (type !== word.type) {
      if (word.content.length > 0) {
        words.push(word);
      }
      word = {content: [], type}
      word.content.push(char);
    } else {
      word.content.push(char);
    }
  }
  if (word.content.length > 0) {
    words.push(word);
  }
  words = words.filter((w) => !w.remove);
  return words
}
