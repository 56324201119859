<template>
  <transition name="fade">
    <div
      v-if="code && show"
      class="lockscreen flex-row flex-center flex-align-center"
      :style="{
        '--rad': `-${aspect}rad`,
      }"
    >
      <div class="background" />
      <div class="unlock accented card flex-column">
        <transition name="fade" mode="out-in">
          <label v-if="!error">Passcode</label>
          <label v-else><b>{{ error }}</b></label>
        </transition>
        <input v-model="password" type="password" maxlength="30" @blur="handlePasswordFail">
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'LockScreen',
  props: {
    code: String,
  },
  data() {
    return {
      password: '',
      show: false,
      aspect: Math.PI / 4,
      error: null,
    };
  },
  watch: {
    async show() {
      this.$emit('update:visible', this.show);
      if (this.show) {
        this.focus();
      }
    },
    password() {
      clearTimeout(this.passwordTimeout);
      clearTimeout(this.passwordClearTimeout);
      if (this.password === this.code) {
        this.show = false;
        this.password = '';
      } else {
        this.passwordTimeout = setTimeout(() => {
          this.handlePasswordFail();
        }, 1000 * 3);
      }
    },
  },
  async mounted() {
    this.aspect = Math.atan(window.innerHeight / window.innerWidth);
    this.makeVisible();
    document.addEventListener('pause', () => this.handlePause(), false);
    document.addEventListener('resume', () => this.handleResume(), false);
    document.addEventListener('visibilitychange', () => this.handleVisibilityChange(), false);
  },
  methods: {
    handlePasswordFail() {
      if (this.password !== this.code) {
        clearTimeout(this.passwordTimeout);
        clearTimeout(this.passwordClearTimeout);
        this.error = 'Incorrect Passcode';
        this.password = '';
        this.focus();
        this.passwordClearTimeout = setTimeout(() => {
          this.error = null;
        }, 1000);
      }
    },
    handleVisibilityChange() {
      if (document.hidden) {
        this.makeVisible();
      }
    },
    handlePause() {
      this.makeVisible();
    },
    handleResume() {
      this.makeVisible();
    },
    makeVisible() {
      if (this.code) {
        this.makeVisible();
      }
    },
    async focus() {
      await this.$nextTick();
      this.$el.querySelector('input').focus();
    },
  },
}
</script>

<style scoped>
.lockscreen {
  position: fixed;
  z-index: 1000;
  top: 0; right: 0; bottom: 0; left: 0;
}
.background {
  position: absolute;
  top: calc(min(-50vw, -50vh));
  right: calc(min(-50vw, -50vh));
  bottom: calc(min(-50vw, -50vh));
  left: calc(min(-50vw, -50vh));
  z-index: 1;
  animation: colorswitch 20s infinite;
  opacity: 0.7;
  background: linear-gradient(
    var(--rad),
    var(--spark) 0,
    var(--color3) 50%,
    var(--spark) 100%
  );
  background-size: 100% 200%;
  transform-origin: 50% 50%;
}
@keyframes colorswitch {
  0% {
    transform: rotate(0);
    background-position: 0% 0%;
  }
  50% {
    background-position: 0% 100%;
  }
  100% {
    transform: rotate(360deg);
    background-position: 0% 0%;
  }
}
.unlock {
  position: relative;
  z-index: 2;
  max-width: 300px;
  padding: 30px;
  border: 1px solid var(--color3);
  box-shadow: 0 0 10px 5px var(--color1);
}
</style>
