<template>
  <ObjectList
    v-model:list="objectList"
    @update:id="emit('update:id', $event)"
    @update:action="emit('update:action', $event)"
    :story="story"
    :id="props.id"
    :action="props.action"
    :show-highlight="props.showHighlight"
  >
    <template #highlight="{ object }">
      <div class="highlight flex-row flex-center flex-align-center">
        <BlobImage :blob="object.blob_id" :thumb="true" />
      </div>
    </template>
    <template #item="{ object }">
      <div class="item flex-column flex-center flex-align-center padded">
        <BlobImage :blob="object.blob_id" :thumb="true" />
        <div class="name">{{ object.name }}</div>
      </div>
    </template>
    <template #edit="{ object }">
      <div class="bg row">
        <form @submit.prevent class="grid2">
          <label for="name">{{ $t("backgrounds.form-field-name") }}</label>
          <input
            type="text"
            name="name"
            :value="object.name"
            @change="handleSave(object, { name: $event.target.value })"
          />
        </form>
        <media-uploader
          :value="object"
          @update:value="handleSaveExceptName(object, $event)"
          :use-cloud="story.cloudSync"
        />
        <div v-if="object.error" class="error" @click="object.error = null">
          {{ object.error }}
        </div>
      </div>
    </template>
  </ObjectList>
</template>

<script setup lang="ts">
import BlobImage from "@/components/blob_image.vue";
import type Story from "@/models/story";
import copy from "@morphosis/base/functions/copy";
import ObjectList from "../../admin/ObjectList.vue";
import { createSaveHandler } from "../utils";

const props = defineProps<{
  story: Story;
  action: string;
  id?: string;
  showHighlight?: boolean;
}>();

const emit = defineEmits(["update:story", "update:id", "update:action", "action"]);
const { objectList, handleSave } = createSaveHandler(props.story, "images", emit);

function handleSaveExceptName(object, values) {
  const withName = { name: values.name };
  const exceptName = copy(values);
  if (exceptName.name !== undefined) {
    delete exceptName.name;
  }
  handleSave(object, exceptName, withName);
}
</script>

<style scoped>
.item img {
  max-width: 130px;
  max-height: 130px;
  border-radius: var(--softness);
}
.hightlight img {
  max-width: 250px;
  max-height: 250px;
  background-size: contain;
  border-radius: var(--softness);
}
.name {
  white-space: wrap;
}
</style>
