<template>
  <Button v-if="value && typeof value !== 'string'" @click="value = undefined">
    {{ value.name }} <i class="pi pi-times" />
  </Button>
  <div v-else class="flex-column">
    <div v-if="value" class="p-1-b">{{ value }}</div>
    <FileUpload
      mode="basic"
      customUpload
      auto
      @uploader="handleUpload"
      :invalid="!!errors"
      :chooseLabel="value ? 'Change' : 'Choose'"
      :chooseIcon="value ? 'pi pi-refresh' : 'pi pi-plus'"
    />
  </div>
</template>

<script setup lang="ts">
import Button from "primevue/button";
import FileUpload from "primevue/fileupload";
import { useField } from "vee-validate";

const props = defineProps<{ name: string; placeholder?: string }>();

const { value, errors } = useField<File>(props.name);

async function handleUpload(e: { files: File[] }) {
  if (e.files.length === 1) {
    value.value = e.files[0];
  } else {
    value.value = null;
  }
}
</script>
