export default class BaseDriver {
  name: string;
  ext: string;
  constructor(name: string, ext: string) {
    this.name = name;
    this.ext = ext;
  }

  async ready(): Promise<boolean> {
    return true;
  }

  async length(): Promise<number> {
    throw new Error("Not implemented");
  }

  async iterate(predicate) {
    throw new Error("Not implemented");
  }

  async setItem(name, data) {
    throw new Error("Not implemented");
  }

  async getItem(name, def) {
    throw new Error("Not implemented");
  }

  async removeItem(name) {
    throw new Error("Not implemented");
  }
}
