<template>
  <div class="flex-column">
    <InputNumber v-bind="$attrs" v-model="value" :invalid="errors.length > 0" />
    <div class="p-2">
      <Slider v-bind="$attrs" v-model="value" :invalid="errors.length > 0"> </Slider>
    </div>
  </div>
</template>

<script setup lang="ts">
import InputNumber from "primevue/inputnumber";
import Slider from "primevue/slider";
import { useField } from "vee-validate";

const props = withDefaults(
  defineProps<{
    name: string;
    showTitle?: boolean;
    accept?: string;
  }>(),
  { accept: "image" }
);

const { value, errors } = useField<number>(props.name);
</script>

<style scoped>
i.text-icon {
  position: absolute;
  z-index: 2;
  margin-left: 0.7em;
}
.text-icon span {
  display: inline-block;
}
.text-icon span:first-child {
  opacity: 0.75;
  translate: -1px -1px;
}
.text-icon span:last-child {
  translate: -2px 1px;
  opacity: 0.5;
}
</style>
