<template>
  <div class="choice-timer-content">
    <help-message name="choice-timer">
      {{ $t('direction.choice-timer-help') }}
    </help-message>
    <div class="row">
      <i class="fa fa-clock-o static padded" />
      <input
        name="weight"
        class="stretch"
        :placeholder="defaultChoiceTimer"
        :value="value.seconds"
        min="0"
        max="99"
        step="1"
        type="number"
        @input="$emit('update', { seconds: $event.target.value })"
      />
      <b class="static padded">{{ $t('direction.seconds') }}</b>
    </div>
    <div class="row">
      <b class="static padded">{{ $t('direction.fallback') }}</b>
      <choice-line
        class="stretch"
        :value="value"
        :story="story"
        @change="$emit('update', { scene: $event })"
      />
    </div>
  </div>
</template>

<script>
import ChoiceLine from '../choice_line.vue';

export default {
  name: 'ChoiceTimeContent',
  components: { ChoiceLine },
  props: {
    story: Object,
    scene: Object,
    value: Object,
  },
  computed: {
    settings() {
      return this.story.settings || {};
    },
    defaultChoiceTimer() {
      return this.settings.defaultChoiceTimer || 10;
    },
  },
}
</script>

<style scoped>
</style>
