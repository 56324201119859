<template>
  <div class="directional-input">
    <div
      class="graph"
      ref="graph"
      @pointerdown.prevent="handleMove"
      @pointermove.prevent="handleMove"
      @contextmenu.prevent
      :style="{
        width: `${scale}px`,
        height: `${scale}px`,
      }"
    >
      <div
        class="puck"
        :style="{
          transform: `translate(${value.x * scale / 2 + scale / 2}px, ${value.y * scale / 2 + scale / 2}px)`,
        }"
      />
      <div class="cross-down" />
      <div class="cross-left" />
    </div>
    <div class="text-muted">{{ value.x.toFixed(1) }}, {{ value.y.toFixed(1) }}</div>
  </div>
</template>

<script>
export default {
  name: 'DirectionInput',
  props: {
    value: { type: Object, default() { return {x: 0, y: 0}; }, required: true },
    scale: { type: Number, default: 50 },
  },
  methods: {
    handleMove(e) {
      if (e.target && e.buttons > 0) {
        const box = this.$refs.graph.getBoundingClientRect();
        const retval = {
          x: ((this.scale * -0.5 + e.x - box.left - 4) / this.scale) * 2,
          y: ((this.scale * -0.5 + e.y - box.top - 4) / this.scale) * 2,
        };

        const dist = Math.sqrt(retval.x ** 2 + retval.y ** 2);
        if (dist > 1) {
          retval.x /= dist;
          retval.y /= dist;
        }
        this.$emit("update:value", retval);
      }
    },
  },
}
</script>

<style scoped>
.directional-input {
}
.graph {
  touch-action: none;
  position: relative;
  background: var(--color1);
  border-radius: 50%;
  border: 2px solid var(--color3);
  margin: 2px;
}
.cross-down, .cross-left {
  position: absolute;
  background: var(--color2);
}
.cross-down {
  margin-left: 50%;
  width: 2px;
  height: 100%;
}
.cross-left {
  margin-top: 50%;
  width: 100%;
  height: 2px;
}
.puck {
  position: absolute;
  background: var(--color1_text);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-top: -6px;
  margin-left: -6px;
  z-index: 2;
}
</style>