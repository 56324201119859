import { Deferred } from "@morphosis/base/utils/deferred";
import debug from "debug";

const log = debug("animation");

var framePromise = null;

function nextFrame(inside) {
  if (!framePromise) {
    framePromise = new Deferred();
    requestAnimationFrame(() => {
      log("frame");
      if (inside) {
        inside();
      }
      const promise = framePromise;
      framePromise = null;
      promise.resolve(true);
    });
  }
  return framePromise.promise;
}

class Effect {
  constructor(config, prev) {
    this.config = config;
    this.prev = prev;
  }
  get props() {
    return [];
  }
  async apply(el, display) {
    try {
      if (el) {
        if (this.prev && this.prev.duration < 30) {
          //   this.def = new Deferred();
          // }
          // if (this.prev && this.prev.def) {
          await nextFrame();
          await nextFrame();
          // await this.prev.def.promise;
          // log('done');
        }
        if (this.prev) {
          // await nextFrame(() => {
          this.prev.finish(el);
          // });
        }
        // await nextFrame(() => {
        this.applyTransition(el);
        this.start(el);
        // });
        // if (this.def) {
        // await nextFrame();
        // this.def.resolve();
        // }
        if (display) {
          await nextFrame();
          el.display = null;
        }
      } else {
        log("missing", el);
      }
    } catch (ex) {
      console.error(ex);
    }
  }
  applyTransition(el) {
    if (this.prev) {
      for (const prop of this.prev.props) {
        const easing = this.config.easing || "ease-in-out";
        const transition = `${prop} ${this.duration}ms ${easing} ${el.dataset.delay || 0}ms`;
        if (!el.style.transition) {
          el.style.transition = transition;
        } else {
          el.style.transition += `,${transition}`;
        }
      }
    }
    for (const prop of this.props) {
      const transition = `${prop} ${this.duration}ms`;
      if (!el.style.transition) {
        el.style.transition = transition;
      } else {
        el.style.transition += `,${transition}`;
      }
    }
  }
  start(el) {
    for (const prop of this.props) {
      el.style[prop] = this.config[prop];
      log(this.config.track, `applied ${prop}`, this.config[prop]);
    }
  }
  finish(el) {
    for (const prop of this.props) {
      el.style[prop] = null;
      log(this.config.track, `removed ${prop}`);
    }
  }
}

class Empty extends Effect {}

class Blur extends Effect {
  get props() {
    return ["filter"];
  }
  start(el) {
    el.style.filter = `blur(${this.config.blur * 5}px)`;
    log(this.config.track, `applied filter blur`, this.config.blur);
  }
}

class Color extends Effect {
  get props() {
    return ["color"];
  }
}

class Opacity extends Effect {
  get props() {
    return ["opacity"];
  }
  start(el) {
    el.style.opacity = this.config.opacity;
    log(this.config.track, `applied opacity`, el.className, this.config.opacity);
  }
}

class Background extends Effect {
  get props() {
    return ["background"];
  }
}

function nrand(v) {
  return Math.random() * (v * 2) - v;
}

class Transform extends Effect {
  get props() {
    return ["transform"];
  }
  start(el) {
    let x = 0;
    let y = 0;
    if (this.config.direction) {
      x = this.config.direction.x;
      y = this.config.direction.y;
    }
    if (this.config.randomize) {
      x += nrand(this.config.randomize);
      y += nrand(this.config.randomize);
    }
    const a = this.config.rotation || 0 + nrand(this.config.rotationRandomize);
    const s = this.config.scale || this.config.scale === 0 ? this.config.scale : 1;
    el.style.transform = `translate(${x * 100}px, ${y * 50}px) scale(${s}) rotate(${a}rad) `;
    // log(this.config.track, `applied transform`, el.style.transform);
  }
}

class Shadow extends Effect {
  get props() {
    return ["text-shadow"];
  }
  start(el) {
    let x = 0;
    let y = 0;
    if (this.config.direction) {
      x = this.config.direction.x;
      y = this.config.direction.y;
    }
    const len = (this.config.length || 0) * 5;
    const shadow = `${x * 3}px ${y * 3}px ${len * 2}px ${this.config.color}`;
    el.style["text-shadow"] = shadow;
    log(this.config.track, `applied shadow`, shadow);
  }
}

const effects = {
  background: Background,
  blur: Blur,
  color: Color,
  empty: Empty,
  opacity: Opacity,
  shadow: Shadow,
  transform: Transform,
};

export default class AnimationHandler {
  constructor(pipeline, duration = 1000, reverse = false) {
    let mypipeline = pipeline.slice();
    this.pipeline = [];
    this.deferred = [];
    this.duration = parseFloat(duration);
    this.reverse = reverse;
    const times = {};
    const totals = {};
    const prev = {};
    const durations = {};

    if (reverse) {
      mypipeline = mypipeline.reverse();
    }

    for (const pipe of mypipeline) {
      totals[pipe.track] = (totals[pipe.track] || 0) + parseFloat(pipe.duration);
    }
    for (const track in totals) {
      times[track] = 0;
      totals[track] = Math.max(1, totals[track]);
    }
    for (const pipe of mypipeline) {
      const effect = new effects[pipe.type || "empty"](pipe, prev[pipe.track]);
      const time = times[pipe.track];
      effect.starts = Math.floor(time);
      if (this.reverse) {
        effect.duration = durations[pipe.track] || 0;
        durations[pipe.track] = (parseFloat(pipe.duration) / totals[pipe.track]) * duration;
      } else {
        effect.duration = (parseFloat(pipe.duration) / totals[pipe.track]) * duration;
      }
      times[pipe.track] = time + effect.duration;
      // log('duration', pipe.track, pipe.type, effect.starts, effect.duration);
      this.pipeline.push(effect);
      prev[pipe.track] = effect;
    }
  }

  async start(el, loop = false) {
    if (this.duration < 16) {
      this.finished();
      return;
    }
    // if (! el) {
    //   console.error('no el?', el);
    //   return;
    // }
    const def = new Deferred();
    this.deferred.push(def);

    const prevStyle = el.style.cssText;
    const times = new Set();
    for (const pipe of this.pipeline) {
      times.add(pipe.starts);
    }
    const st = Date.now();
    for (const start of times) {
      const timeout = start - (Date.now() - st);
      if (timeout > 0) {
        await Promise.race([def.promise, new Promise((r) => setTimeout(r, timeout))]);
      }
      if (def.resolved) {
        break;
      }
      let display = false;
      if (!this.reverse) {
        // el.style.display = null;
        display = true;
      }
      for (const pipe of this.pipeline.filter((p) => p.starts === start)) {
        pipe.apply(el, display);
        display = false;
      }
    }
    if (!def.resolved) {
      const d = this.duration - (Date.now() - st);
      await Promise.race([def.promise, new Promise((r) => setTimeout(r, d))]);
    }
    // reset element
    if (el) {
      await nextFrame();

      // preserve other styles
      el.style = prevStyle;
      // el.style = null;

      if (this.reverse) {
        el.style.opacity = 0;
      }
      if (loop && !def.resolved) {
        log("looping");
        await this.start(el, loop);
      }
    } else {
      console.error("missing el", el);
    }
    def.resolve(!def.resolved);
    return def.promise;
  }

  async stop() {
    // console.error('stop');
    for (const def of this.deferred) {
      def.resolve(false);
    }
    await this.finished();
    this.deferred = [];
  }

  isFinished() {
    return this.deferred.every((d) => d.resolved);
  }

  async finished() {
    await Promise.all(this.deferred.map((d) => d.promise));
  }
}
