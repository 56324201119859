<style scoped>
  .title {
    font-size: 1.2em;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    flex: 0 0 auto;
    text-align: right;
    padding-left: 10px;
    /* margin-bottom: 5px; */
    padding: 0px;
    vertical-align: middle;
    text-align: center;
    min-height: 50px;
  }
  .title > .row {
    justify-content: stretch;
    align-items: center;
  }
  .fa-bars {
    padding: 10px;
  }
  .hide-wide i {
    visibility: hidden;
  }
  @media (max-width: 480px) {
    .hide-wide i {
      visibility: visible;
    }
  }
</style>

<template>
  <header id="app-header" class="title keyboard-crunch">
    <div class="flex-row">
      <router-link v-if="back" :to="{name: 'home'}">
        <i class="fa fa-arrow-left" />
      </router-link>
      <span class="row left stretch"><slot></slot></span>

      <div
        class="static center"
        :class="{'hide-wide': hideMenuWhenWide}"
        >
        <i v-if="showMenu" class="fa fa-bars" @click="handleClick" />
      </div>
    </div>
    <slot name="extra" />
  </header>
</template>

<script>
export default {
  name: 'app-header',
  props: {
    back: Object,
    modelValue: { default: false, type: Boolean },
    showMenu: { default: true, type: Boolean },
    hideMenuWhenWide: { default: false, type: Boolean },
  },
  methods: {
    handleClick() {
      this.$emit('update:modelValue', !this.modelValue);
    },
  },
};
</script>
