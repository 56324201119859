<template>
  <div ref="el" class="scroll-sticker" />
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';

const props = defineProps({
  scrollSelector: { default: null },
  tolerance: { default: 50 },
});

const el = ref(null as HTMLElement | null);
const paused = ref(false);
let scrollerReference = -1;
let unpauseReference = -1;
let prevHeight = -1;
let parent = null;


onMounted(() => {
  if (props.scrollSelector) {
    parent = el.value.closest(props.scrollSelector);
  } else {
    parent = el.value.parentElement;
  }
  if (parent) {
    parent.addEventListener('scroll', scrollToBottom);
    parent.addEventListener('mousemove', pauseScroller);
    parent.addEventListener('touchmove', pauseScroller);
    parent.addEventListener('wheel', pauseScroller);
  }
  startScroller();
});
onUnmounted(() => {
  clearTimeout(unpauseReference);
  if (parent) {
    parent.removeEventListener('scroll', scrollToBottom);
    parent.removeEventListener('mousemove', pauseScroller);
    parent.removeEventListener('touchmove', pauseScroller);
    parent.removeEventListener('wheel', pauseScroller);
  }
  stopScroller();
});

// todo: when text finishes...?
// setTimeout(() => this.stopScroller(), 100);

// todo: when scrolling to the bottom
// this.stopScroller();

function pauseScroller() {
  paused.value = true
  unpauseReference = window.setTimeout(() => {
    paused.value = false
  }, 20)
}

function startScroller() {

  scrollerReference = requestAnimationFrame(() => {
    startScroller();
    scrollToBottom();
  });
}
function scrollToBottom(event=null) {
  if (el.value && !paused.value) {
    const parentBox = parent.getBoundingClientRect();
    const box = el.value.getBoundingClientRect();
    const diff = parentBox.bottom - box.bottom;
    const aDiff = Math.abs(diff)
    const scrollDiff = parent.scrollHeight - prevHeight;

    if (scrollDiff > 0) {
      if (aDiff > 0 && aDiff < props.tolerance + scrollDiff) {
        parent.scrollTop -= diff; // scroll to bottom
      }
      prevHeight = parent.scrollHeight;
    }
  }
}
function stopScroller() {
  cancelAnimationFrame(scrollerReference);
}
</script>

<style scoped>
.scroll-sticker {
  flex: 0 0 auto;
  height: 0px;
}
</style>
