<template>
  <div class="automated-cursor-container">
    <!-- <div class="foredrop" /> -->
    <div
      class="automated-cursor"
      :style="{left: `${x}px`, top: `${y}px`}"
      v-if="visible"
    >
      <svg
        style="width:35px;height:35px"
        viewBox="0 0 24 24"
        class="cursor"
        id="automated-clip"
      >
        <path
          stroke="backgroundColor"
          stroke-width="3"
          stroke-linecap="round"
          fill="currentColor"
          d="M13.64,21.97C13.14,22.21 12.54,22 12.31,21.5L10.13,16.76L7.62,18.78C7.45,18.92 7.24,19 7,19A1,1 0 0,1 6,18V3A1,1 0 0,1 7,2C7.24,2 7.47,2.09 7.64,2.23L7.65,2.22L19.14,11.86C19.57,12.22 19.62,12.85 19.27,13.27C19.12,13.45 18.91,13.57 18.7,13.61L15.54,14.23L17.74,18.96C18,19.46 17.76,20.05 17.26,20.28L13.64,21.97Z"
        />
      </svg>
      <div class="ring" v-if="ring" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutomatedCursor',
  data() {
    return {
      x: 108,
      y: 128,
      el: null,
      ring: false,
      visible: true,
    };
  },
  mounted() {
    console.log('listening to broadcast');
    this.broadcast = new BroadcastChannel('automation')
    this.broadcast.onmessage = async (e) => {
      console.log('> automation', e.data);
      if (e.data.event === 'automate') {
        const result = await this.automate(e.data.value);
        this.broadcast.postMessage({ event: 'result', value: result });
      }
    };
    this.broadcast.postMessage({ type: 'hello' });
    // window.automation = this;
  },
  unmounted() {
    this.broadcast.close();
  },
  methods: {
    async automate(commands) {
      for (const command of commands) {
        try {
          console.log(' >> command', command.action, command.args);
          await this[command.action](...command.args);
        } catch(ex) {
          console.log(' *> command error', ex);
          return {status: 'error', error: ex};
        }
      }
      return {status: 'success'};
    },
    async reset() {
      await this.$root.resetAutomation();
    },
    async patch(packet) {
      await this.$root.patchStoryAutomated(packet);
    },
    async navigate(url) {
      console.log('navigate to', url);
      if (this.$route.path !== url) {
        this.$router.push(url);
        await this.pause(300);
      }
    },
    async pause(timeout) {
      await new Promise(r => setTimeout(r, timeout));
    },
    async goto(xOrId, y) {
      if (typeof xOrId === 'string') {
        const el = document.querySelector(xOrId);
        el.scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
        await new Promise(r => setTimeout(r, 300));
        const box = el.getBoundingClientRect();
        this.el = el;
        this.x = box.left + box.width / 2;
        this.y = box.top + box.height / 2;
      } else {
        this.x = xOrId;
        this.y = y;
      }
      await new Promise(r => setTimeout(r, 600));
    },
    async click(xOrId, y) {
      if (xOrId) {
        await this.goto(xOrId, y);
      }
      const el = this.el || await this.getElement('click');

      // show ring
      this.ring = true;
      clearTimeout(this._ring);
      this._ring = setTimeout(() => this.ring = false, 1100);
      await new Promise(r => setTimeout(r, 700));

      console.log('click', el);
      el.click();
      await new Promise(r => setTimeout(r, 400));
    },
    async type(text) {
      const el = this.el || await this.getElement();
      const box = el.getBoundingClientRect();
      await this.goto(this.x, this.y + box.height / 2 - 5);
      let content = '';
      el.click();
      el.focus();
      for (const char of text) {
        content += char;
        el.value = content;
        el.dispatchEvent(new KeyboardEvent('keydown', {'key': char}));
        el.dispatchEvent(new KeyboardEvent('keypress', {'key': char}));
        el.dispatchEvent(new KeyboardEvent('keyup', {'key': char}));
        el.dispatchEvent(new InputEvent('input'));
        await new Promise(r => setTimeout(r, 32));
      }
      el.dispatchEvent(new CustomEvent('input'));
      el.blur();
    },
    async select(value) {
      const el = await this.getElement();
      el.focus();
      el.value = value;
      console.log('el', el, value);
      el.dispatchEvent(new CustomEvent('input'));
      el.blur();
    },
    async getElement(prop = null) {
      this.visible = false;
      await this.$nextTick();
      let el = document.elementFromPoint(this.x, this.y);
      let input = el.parentElement.querySelector('input,textarea,select');
      if (input) {
        el = input;
      }
      this.visible = true;
      if (prop) {
        let count = 0;
        while (el && count <= 3) {
          if (el[prop]) {
            break;
          } else {
            el = el.parentElement;
          }
          count += 1;
        }
      }
      console.log('element', el);
      return el;
    }
  },
};
</script>

<style scoped>
.automated-cursor {
  position: absolute;
  transition-property: left, top, color, stroke;
  transition-duration: 600ms;
  transition-timing-function: ease-in-out;
  animation: colored-cursor 3s infinite;
  z-index: 101;
  touch-action: none;
}
.foredrop {
  background: rgba(255, 0, 0, 0.0);
  position: fixed;
  touch-action: none;
  top: 0; left: 0; right: 0; bottom: 0;
  z-index: 1000;
}
.cursor {
  margin-top: -2px;
  margin-left: -10px;
  z-index: 1;
}
.ring {
  border-radius: 100%;
  width: 120px;
  height: 120px;
  margin-top: -60px;
  margin-left: -60px;
  position: absolute;
  background-position: center;
  background-image: radial-gradient(
    circle at center,
    transparent 0%,
    transparent 5%,
    white 5%,
    white 6%,
    black 6%,
    black 10%,
    transparent 10%
  );
  background-size: 10000%;
  background-repeat: none;
  animation: background-zoom 1.5s ease-in-out;
}
@keyframes colored-cursor {
  0%   { color: white; stroke: #444 }
  50%  { color: #ccc;  stroke: black }
  100% { color: white; stroke: #444 }
}
@keyframes background-zoom {
  0%   { background-size: 10000%; }
  50%  { background-size: 100%; }
  100% { background-size: 10000%; }
}
</style>
