<template>
  <div class="operation-line">
    <tag-picker
      class="flex-static"
      :story="story"
      :value="value.tag"
      :extras="extraSansDollars"
      :mutating="!requirements"
      @update:value="handleUpdate('tag', $event)"
      v-if="value.op != '<t>'"
    />
    <div class="row" v-if="tag && tag.type === 'action' && tag.results && tag.results.length > 0">
      <span>is</span>
      <select
        :value="value.value"
        @input="handleUpdate('value', $event.target.value)"
      >
        <option value="">{{ $t('direction_effect.action-result') }}</option>
        <option v-for="result of tag.results" :key="result.tag" :value="result.label">
          {{ result.label }}
        </option>
      </select>
    </div>
    <div class="flex-row" v-else>
      <select
        :value="value.op"
        class="operation-select flex-stretch"
        @input="handleUpdate('op', $event)"
        :placeholder="$t('direction_effect.operation')"
      >
        <option :value="null">{{ $t('direction_effect.requirement-or-effect') }} </option>
        <optgroup :label="$t('direction_effect.requirements')" v-if="requirements">
          <option value="#">{{ $t('direction_effect.has-any-value-except-0') }}</option>
          <template v-if="tag && tag.type === 'array'">
            <option value="[ ]">{{ $t('direction_effect.is-empty') }}</option>
            <option value="[>]">{{ $t('direction_effect.is-longer-than') }}</option>
            <option value="[*]">{{ $t('direction_effect.isnt-empty') }}</option>
          </template>
          <template v-else-if="isText">
            <option value="==">{{ $t('direction_effect.is') }}</option>
            <option value="!=">{{ $t('direction_effect.isnt') }}</option>
            <option value="[v]">{{ $t('direction_effect.contains') }}</option>
            <option value="[^]">{{ $t('direction_effect.doesnt-contain') }}</option>
            <option value="<>>">{{ $t('direction_effect.is-greater-or-equal-to-0') }}</option>
            <option value="<<>">{{ $t('direction_effect.is-equal-or-lesser-than-0') }}</option>
            <option value="<=>">{{ $t('direction_effect.is-same-as') }}</option>
            <option value="<!>">{{ $t('direction_effect.isnt-the-same-as') }}</option>
            <option value="<t>">{{ $t('direction_effect.evaluate-formula') }}</option>
          </template>
          <template v-else>
            <option value="=">{{ $t('direction_effect.is-equal-to') }}</option>
            <option value=">">{{ $t('direction_effect.is-greater-or-equal-to') }}</option>
            <option value="<">{{ $t('direction_effect.is-equal-or-lesser-than') }}</option>
            <option value="<>>">{{ $t('direction_effect.is-greater-or-equal-to-0') }}</option>
            <option value="<<>">{{ $t('direction_effect.is-equal-or-lesser-than-0') }}</option>
            <option value="<=>">{{ $t('direction_effect.is-same-as') }}</option>
            <option value="<!>">{{ $t('direction_effect.isnt-the-same-as') }}</option>
            <option value="<t>">{{ $t('direction_effect.evaluate-formula') }}</option>
          </template>
          <!-- <option value="<c>">Copy</option> -->
        </optgroup>

        <optgroup :label="$t('direction_effect.effects')" v-if="effects">
          <template v-if="tag?.type === 'story-object-ref'">
            <option value=":">{{ $t('direction_effect.set-to') }}</option>
            <option value="<:>">{{ $t('direction_effect.set-to-the-value-of') }}</option>
            <option value="<f>">{{ $t('direction_effect.set-to-formula-result') }}</option>
          </template>
          <template v-else-if="tag && tag.type === 'array'">
            <!-- <option value=":">Assign</option> -->
            <option value="#<">{{ $t('direction_effect.push-to-end') }}</option>
            <option value="#>">{{ $t('direction_effect.shift-off-start') }}</option>
            <option value="#:">{{ $t('direction_effect.transfer-list') }}</option>
            <!-- <option value="[?]">Pop randomly</option> -->
            <!-- <option value="[=]">Subscript</option> -->
            <!-- <option value="[v]">Push to end</option> -->
            <!-- <option value="[^]">Pop from end</option> -->
          </template>
          <template v-else-if="referencing === '$player' && tag.name[0] === '$'">
            <!-- nothing -->
          </template>
          <template v-else-if="tag && tag.type === 'ref'">
            <option value=":">{{ $t('direction_effect.reference-instance') }}</option>
            <option value="x">{{ $t('direction_effect.remove-reference') }}</option>
          </template>
          <template v-else-if="tag && tag.type === 'instance'">
            <!-- <option value=":">Assign new instance</option>
            <option value="*">Transfer instance</option>
            <option value="x">Clear instance</option> -->
          </template>
          <template v-else-if="tag && tag.type === 'boolean'">
            <option value=":">{{ $t('direction_effect.set-to') }}</option>
          </template>
          <template v-else-if="isText">
            <option value=":">{{ $t('direction_effect.set-to') }}</option>
            <option value="+">{{ $t('direction_effect.add-to-list') }}</option>
            <option value="-">{{ $t('direction_effect.remove-from-list') }}</option>
            <option value=".">{{ $t('direction_effect.append') }}</option>
            <option value="<.>">{{ $t('direction_effect.append-the-value-of') }}</option>
            <option value="<:>">{{ $t('direction_effect.set-to-the-value-of') }}</option>
            <option value="<+>">{{ $t('direction_effect.add-the-value-of') }}</option>
            <option value="<->">{{ $t('direction_effect.sub-the-value-of') }}</option>
            <option value="<f>">{{ $t('direction_effect.set-to-formula-result') }}</option>
            <option value="x">{{ $t('direction_effect.erase') }}</option>
          </template>
          <template v-else>
            <option value=":">{{ $t('direction_effect.set-to') }}</option>
            <option value="+">{{ $t('direction_effect.gives') }}</option>
            <option value="-">{{ $t('direction_effect.takes') }}</option>
            <option value="<:>">{{ $t('direction_effect.set-to-the-value-of') }}</option>
            <option value="<+>">{{ $t('direction_effect.add-the-value-of') }}</option>
            <option value="<->">{{ $t('direction_effect.sub-the-value-of') }}</option>
            <option value="<f>">{{ $t('direction_effect.set-to-formula-result') }}</option>
            <option value="x">{{ $t('direction_effect.erase') }}</option>
          </template>
        </optgroup>

      </select>
      <!-- Subscript -->
      <div v-if="value.op === '[=]'">
        <!-- <input
          type="number"
          min="1"
          :mutating="!requirements"
          :value="value.value"
          @update:value="handleUpdate('value', $event)"
        >
        <tag-picker
          name="other"
          :story="story"
          :mutating="!requirements"
          :value="value.other"
          @update:value="handleUpdate('other', $event)"
        /> -->
      </div>
      <div
        v-else-if="tag?.type === 'story-object-ref' && value.op === ':'"
        class="flex-row flex-align-center"
      >
        <select name="default" :value="value.value" @change="handleUpdate('value', $event.target.value)">
          <option :value="null"> -- None --</option>
          <option :value="o.id" v-for="o of story[tag.referencing]">
            {{ o.label || o.name }}
          </option>
        </select>
      </div>
      <div
        v-else-if="tag?.type === 'story-object-ref' && value.op === '<:>'"
        class="flex-row flex-align-center"
      >
        <tag-picker
          name="other"
          :mutating="!requirements"
          :type="'story-object-ref'"
          :story="story"
          :value="value.other"
          @update:value="handleUpdate('other', $event)"
        />
      </div>
      <div v-else-if="tag && tag.type === 'array' && ['#>', '#<'].indexOf(value.op) > -1" class="flex-row flex-align-center">
        <tag-picker
          name="other"
          :mutating="!requirements"
          :type="tag.reference"
          :story="story"
          :value="value.other"
          @update:value="handleUpdate('other', $event)"
        />
      </div>
      <div v-else-if="referencing && value.op === ':'" class="flex-row flex-align-center">
        <tag-picker
          name="value"
          :mutating="!requirements"
          :type="referencing"
          :story="story"
          :value="value.value"
          :extras="extras"
          @update:value="handleUpdate('value', $event)"
        />
      </div>
      <div v-else-if="showFormula" class="flex-row flex-stretch">
        <input
          type="text"
          :value="value.value"
          @input="handleUpdate('value', $event)"
        >
        <button @click.prevent="$refs[`formula:${value.id}`].show()">
          <vi name="formula" />
        </button>
        <teleport to="#app">
          <formula-popup
            :ref="`formula:${value.id}`"
            :story="story"
            :target="null"
            :value="value.value"
            @update:value="handleUpdate('value', $event)"
          />
        </teleport>
      </div>
      <tag-picker
        name="other"
        :story="story"
        :mutating="!requirements"
        :value="value.value"
        :extras="extras"
        @update:value="handleUpdate('value', $event)"
        v-else-if="value.op && value.op[0] == '<' && value.op != '<'"
      />
      <template v-else-if="value.op != 'x'">
        <input
          v-if="isText"
          type="text"
          size="3"
          :value="value.value"
          @input="handleUpdate('value', $event)"
        >
        <input
          v-else-if="defaultValues[value.op] !== undefined"
          type="number"
          step="1"
          size="3"
          :value="value.value"
          @input="handleUpdate('value', $event)"
          :placeholder="defaultValues[value.op]"
        >
      </template>
    </div>
  </div>
</template>

<script>
import TagPicker from './tag_picker.vue';

export default {
  name: 'DirectionEffect',
  props: {
    value: { type: Object, required: true },
    index: { type: Number, required: false },
    story: { type: Object, required: true },
    effects: { type: Boolean, default: true },
    requirements: { type: Boolean, default: true },
    extras: { type: Object, default: null },
  },
  components: {
    TagPicker,
  },
  data() {
    return {
      showPopup: false,
      defaultValues: {
        '<': 1, '>': 1, '=': 1, '+': 1, '-': 1, ':': 1,
      },
    };
  },
  computed: {
    extraSansDollars() {
      const retval = {};
      for (const key in this.extras) {
        if (!/\$/.test(key)) {
          retval[key] = this.extras[key]
        }
      }
      return retval;
    },
    data() {
      return this.value ? this.copy(this.value) : [];
    },
    tag() {
      let retval = null;
      if (this.value.tag) {
        const bits = this.value.tag.split('.');
        retval = this.story.allTags.find(t => t.id === bits[0]);
        if (retval) {
          bits.slice(1).forEach((bit) => {
            if (retval.referencing) {

              let referencing = retval.referencing;
              if (referencing === '$current') {
                const array = this.story.allTags.find((c) => c.id === this.extras.$item);
                if (array) {
                  referencing = array.referencing;
                }
              }

              const type = this.story.allTypes.find((t) => t.id === referencing);
              if (type) {
                retval = type.attrs.find((a) => a.name === bit);
              }
            }
          });
        }
      }
      return retval;
    },
    format() {
      return (this.tag ? this.tag.format : null) || 'numeric';
    },
    sameFormatTags() {
      return this.story.allTags.filter(
        t => (t.format || 'numeric') === this.format,
      );
    },
    isText() {
      return this.tag && (this.tag.type === 'text' || this.tag.format === 'text');
    },
    showFormula() {
      return ['<t>', '<f>'].indexOf(this.value.op) > -1;
    },
    referencing() {
      if (this.tag) {
        let referencing = this.tag.referencing;
        if (this.tag.referencing === '$current') {
          const array = this.story.allTags.find((c) => c.id === this.extras.$item);
          if (array) {
            referencing = array.referencing;
          }
        }
        return referencing;
      }
      return null;
    },
  },
  watch: {
    tag() {
      if (this.tag && this.tag.type === 'action' && this.value.op != '==') {
        this.handleUpdate('op', '==');
      }
    },
  },
  mounted() {
  },
  methods: {
    handleReplace(e) {
      this.$emit("update:value", e);
    },
    handleUpdate(field, e) {
      const value = this.copy(this.value);
      if (e && e.target) {
        value[field] = e.target.value;
      } else {
        value[field] = e;
      }
      this.$emit("update:value", value);
    },
  },
};
</script>

<style scoped>
.operation-line {
  max-width: 100%;
}
.operation-line .operation-select {
  flex: 1 1 auto;
}
.operation-line input {
  flex: 1 1 auto;
}
</style>
