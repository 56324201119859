<template>
  <div class="flex-column">
    <div class="flex-row flex-align-center">
      <AudioRecorder
        v-if="media"
        :value="audio"
        :hintIsLoading="!!audio"
        :name="media.name"
        :isOpen="true"
        @update:value="saveAudio"
      />
      <div v-if="frozenMedia" class="frozen flex-row m-1-l flex-align-center flex-static">
        <i class="fa fa-snowflake-o" @click="frozenEl.play()" />
        <Blob_audio :blob="frozenMedia.id" ref="frozenEl" />
      </div>
      <div class="extra-icons flex-row flex-left flex-stretch">
        <a v-if="media && !media.cloud" href="#" @click.prevent="pushMedia">
          <i class="fa fa-cloud-upload" />
        </a>
        <a v-else @click.prevent="pushMedia">
          <i class="fa fa-cloud" />
        </a>

        <router-link
          v-if="media && media.cloud"
          :to="{ name: 'gallery-item', params: { id: media.id } }"
        >
          {{ media.name }}
        </router-link>
        <span v-else-if="media">
          {{ media.name }}
        </span>
      </div>
      <!-- <span
        @click.prevent="effectsOpen = !effectsOpen"
        class="magic-tab"
        :class="{ active: effectsOpen }"
      >
        <i class="padded fa fa-magic" />
      </span> -->
    </div>
    <!-- <EffectBoard
      v-if="effectsOpen"
      v-model:effects="effects"
      v-model:effectProfileId="ep"
      :url="audio"
      :story="story"
      :character="character"
      class="effects-board"
    /> -->
  </div>
</template>

<script setup lang="ts">
import type { Ref } from "vue";

import AudioRecorder from "@morphosis/base/components/audio/AudioRecorder.vue";
import { computed, onMounted, ref, shallowRef, watch } from "vue";
import Media from "../../models/media";
// import EffectBoard from "./EffectBoard.vue";
import Blob_audio from "../blob_audio.vue";
import { Effect } from "./effects";

const props = defineProps<{
  story: object;
  value: string;
  frozen?: string;
  character: object;
  characterName: string;
  text: string;
  effectProfileId: { type: [number, string]; default: null };
  cloud: { type: boolean; default: false };
}>();
const emit = defineEmits(["update:value", "update:effectProfileId", "update:characterProfileId"]);
// const effectsOpen = ref(false);
const effects: Ref<Effect[]> = ref([]);

const media = shallowRef(null);
const frozenMedia = shallowRef(null);
const frozenEl = ref();
const audio = shallowRef(null);
const ep = computed({
  get: () => {
    return props.effectProfileId;
  },
  set: (v) => {
    emit("update:effectProfileId", v);
  },
});

watch(media, async () => {
  if (media.value) {
    const url = await media.value.getUrl();
    audio.value = url;
  }
});
watch(props, loadMedia);

onMounted(async () => {
  await loadMedia();
});

async function loadMedia() {
  // media.value = null;

  let file = null;
  if (props.value) {
    file = await Media.load(props.value);
    // if (audio.value && props.cloud && !media.value.cloud) {
    //   console.warn('pushing vocals to cloud');
    //   await media.value.push();
    //   media.value.cloud = true;
    //   media.value.cloudSafe = true;
    //   media.value.save();
    // }
    // if (!media.value) {
    //   media.value.pull();
    // }
  }
  if (!file) {
    file = new Media();
    file.type = "audio/mp3";
  }
  file.name = generateName();

  // reactive update
  media.value = file;

  if (props.frozen) {
    frozenMedia.value = await Media.load(props.frozen);
  }
}

function generateName() {
  const convert = new RegExp("[ ,]", "g");
  const cleanedName = (props.characterName || "narrator").replace(convert, "_").slice(0, 15);
  const cleanedText = (props.text || "empty").replace(convert, "_").slice(0, 30);
  return `${cleanedName}-${cleanedText}.mp3`;
}

async function saveAudio(data) {
  if (!data) {
    if (media.value.id) {
      await media.value.delete();
      emit("update:value", null);
    }
    audio.value = "";
  } else {
    const isPrivate = !props.cloud;
    await media.value.importBlob(data, isPrivate);
    audio.value = await media.value.getDataUrl();
    emit("update:value", media.value.id);
    if (props.cloud) {
      await media.value.push();
    }
  }
}

async function pushMedia() {
  if (media.value) {
    console.log("push");
    media.value.cloud = true;
    media.value.cloudSafe = true;
    await media.value.save();
    await media.value.push();
    console.log("done");
  }
}
</script>

<style scoped>
.extra-icons {
  white-space: nowrap;
  /* text-overflow: ellipsis; */
  display: block;
}
.magic-tab {
  opacity: 1;
  color: var(--text);
}
.magic-tab.active {
  background: var(--color2);
}
.effects-board {
  background: var(--color2);
  padding: calc(2 * var(--spacing)) var(--spacing);
}
</style>
