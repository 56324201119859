<template>
  <transition-group
      appear
      v-bind:name="name"
      v-bind:css="false"
      v-bind:tag="tag"
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:after-enter="afterEnter"
      v-on:before-leave="beforeLeave"
      v-on:leave="leave"
      v-on:after-leave="afterLeave">
    <slot></slot>
  </transition-group>
</template>

<script>

function nextFrame() {
  return new Promise(r => {
    requestAnimationFrame(r);
  });
}

export default {
  name: 'transition-queue',
  props: {
    tag: {type: String, default: "div"},
    name: String,
    mode: String,
    delay: {
      type: [String, Number],
      coerce: Number,
      default: 100
    },
    removeDelay: {
      default: 0
    }
  },
  data() {
    return {
      queue: Promise.resolve()
    }
  },
  methods: {
    isElementInViewport (el) {
      let rect = el.getBoundingClientRect();
      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.top <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
          rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
      );
    },
    async apply(el, type, delay, done) {
      this.queue = this.queue.then(async () => {

        // reveal the element
        el.classList.remove("hide");

        // if in view, animate it
        if (this.isElementInViewport(el)) {

          // frame 2
          await nextFrame();
          el.classList.remove(`${this.name}-${type}`);
          el.classList.add(`${this.name}-${type}-to`);

          // listen for end
          let _listener = async () => {
            await nextFrame();
            el.classList.remove(`${this.name}-${type}-active`);
            el.classList.remove(`${this.name}-${type}-to`);

            el.removeEventListener("transitionend", _listener);
          };
          el.addEventListener("transitionend", _listener, true);

          // wait for next element
          await new Promise(r => setTimeout(r, delay));
          done();

        } else {
          // not in view, don't animate
          el.classList.remove(`${this.name}-${type}-active`);
          el.classList.remove(`${this.name}-${type}`);
          done();
        }
      });
    },
    applyOneFrame(el, classname) {
      el.classList.add(classname);
    },

    // --------
    // ENTERING
    // --------
    beforeEnter(el) {
      if (this.delay) {
        el.classList.add("hide");
        el.classList.add(`${this.name}-enter`);
        el.classList.add(`${this.name}-enter-active`);
      }
    },
    // the done callback is optional when
    // used in combination with CSS
    enter(el, done) {
      if (this.delay) {
        this.apply(el, "enter", this.delay, done);
      } else {
        done();
      }
    },
    afterEnter(el) {
    },

    // --------
    // LEAVING
    // --------
    beforeLeave(el) {
      if (this.removeDelay) {
        el.classList.add(`${this.name}-leave`);
        el.classList.add(`${this.name}-leave-active`);
      }
    },
    // the done callback is optional when
    // used in combination with CSS
    leave(el, done) {
      if (this.removeDelay) {
        el.style.transition = `all ${this.removeDelay}ms`
        this.apply(el, "leave", this.removeDelay, done);
      } else {
        el.style.display = "none";
        done();
      }
    },
    afterLeave(el) {
    }
  }
}
</script>
