<style scoped>
  .list-detail {
    display: grid;
    grid-template-columns: 1fr 2fr;
    flex: 1 1 auto;
    height: 100%;
  }
  .list-column {
    background: var(--color2);
    color: var(--color2_text);
    flex: 0 1 auto;
  }
  .buttons {
    flex: 0 0 auto;
    padding: 10px;
    background: var(--color1);
    color: var(--color1_text);
  }
  @media (max-width: 480px) {
    .list-detail {
      display: grid;
      grid-template-columns: 1fr;
    }
    .list-detail .list-column,
    .list-detail .detail {
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }
    .list-detail .list-column {
      z-index: 1;
      transition: transform 260ms;
      transform: translate(-120%, 0) rotate(-6deg);
      transform-origin: bottom right;
    }
    .list-detail .list-column.open {
      transform: translate(0, 0);
    }
    .detail.empty {
      display: none;
    }
  }
  .list-detail .list-item {
    align-items: stretch;
    color: var(--color2_text);
    flex: 0 0 auto;
    padding: 15px;
    border-bottom: 1px solid var(--color3);
  }
  .list-detail .list-item:last-child {
    border-bottom: none;
  }
  .list-detail .detail {
    height: 100%;
    position: relative;
  }
</style>

<template>
  <div class="list-detail">

    <div class="list-column no-scroll" :class="{open,}">
      <slot name="sidebar">
        <div class="list column scroll">
          <div class="list-item"
              :class="selected && selected == object ? 'active' : ''"
              v-for="object of modelValue.filter(a => a)"
              :key="object.id">
            <slot name="list" :object="object" :active="selected && selected == object">
              {{ object }}
            </slot>
          </div>
          <div class="static" v-if="modelValue.length === 0">
            <slot name="list-empty"><p>{{ $t('list_detail.empty') }}</p></slot>
          </div>
        </div>

        <div class="buttons center" v-if="!noAdd">
          <slot name="footer">
            <button class="static" @click="addObject">
              <i class="fa fa-plus" />
              <slot name="button">{{ addText === null ? $t('list.add-button-label') : addText }}</slot>
            </button>
          </slot>
        </div>
      </slot>
    </div>

    <div class="detail scroll scrollsnap" v-if="selected">
      <a class="close" href="#" @click.prevent="removeObject(selected)">&times;</a>
      <slot name="detail" v-bind="{object: selected}">
        {{ selected }}
      </slot>
    </div>
    <div class="detail empty" v-else>
      <slot name="detail-empty" v-bind="{object: selected}">
        {{ selected }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListDetail",
  props: {
    modelValue: Array,
    idProp: {type: String, default: "id"},
    addText: {type: String, default: null},
    noAdd: {type: Boolean, default: false},
    defaultObject: {type: Object, default: () => {name: "unnamed"}},
    removePrompt: {type: String, default: "Remove {name}?"},
    selected: {type: Object},
  },
  data() {
    return {
      open: !this.selected,
    };
  },
  watch: {
    selected() {
      if (this.selected) {
        this.open = false;
      } else {
        this.open = true;
      }
    }
  },
  methods: {
    addObject() {
      let list = this.copy(this.modelValue);
      let object = this.copy(this.defaultObject);
      if (! object.id) {
        let id = this.$root.id();
        object.id = id;
      }

      list.push(object);
      this.$emit("add", object);
      this.$emit("update:modelValue", list);
    },
    removeObject(object) {
      let message = this.removePrompt.slice(0);
      let name = "this";
      if (object) {
        if (object.name) {
          name = object.name;
        }
        if (object.title) {
          name = object.title;
        }
      }
      message = message.replace("{name}", name);
      if (this.noRemovePrompt || confirm(message)) {
        let idx = this.modelValue.findIndex(a => a.id == object.id || Number.isNaN(a.id));
        if (idx > -1) {
          let list = this.copy(this.modelValue);
          list.splice(idx, 1);
          this.$emit("remove", object);
          this.$emit("update:modelValue", list);
        }
      }
    }
  }
}
</script>