<template>
  <searching-modal
    :config="{
      keys: ['name', 'category'],
      includeMatches: true,
      findAllMatches: true,
    }"
    :value="typing"
    v-model:filter="typing"
    :actions="actions"
    :options="tags"
    @show="$emit('show', $event)"
    @update:filter="typing = $event"
    @update:value="handleInput"
    @action="handleInput"
  >
    <template v-slot:header>Search Tags</template>

    <template v-slot:none>
      <div class="search stretch top" v-if="story.tags.length === 0">
        <p>
          {{ $t('tag_popup.your-story-doesnt-have-any-tags-yet') }}<br /><br />
          <b><router-link :to="{ name: 'tags', params: { story: story.id } }">
            {{ $t('tag_popup.manage-tags') }}
          </router-link></b>
        </p>
      </div>
      <div class="search stretch top" v-else>
        <p>{{ $t('tag_popup.no-tags-match') }}</p>
      </div>
    </template>

    <template v-slot:pre-search>
      <div v-if="characterMatch" class="padded stretch row">
        {{ $t('tag_popup.please-choose-a-character-trait') }}
      </div>
    </template>

    <template v-slot:default="{ result }">
      <span v-if="result.item.id === 'edit-tag'">
        <i class="fa fa-pencil" /> {{ $t('tag_popup.edit-typing', { name: typing }) }}
      </span>
      <span v-else-if="result.item.id === 'create-tag'">
        <i class="fa fa-plus" /> {{ $t('tag_popup.create-typing', { name: typing }) }}
      </span>
      <span v-else-if="result.item.id === 'clear-tag'">
        <i class="fa fa-times" /> {{ $t('tag_popup.clear-typing', { name: typing }) }}
      </span>
      <div class="block result" v-else>
        <div class="flex-row flex-align-center">
          <vi v-if="/\$/.test(result.item.id)" name="array" />
          <vi v-else-if="result.item.icon" :name="result.item.icon" />
          <vi v-else name="tag" />

          <span v-if="result.item.category" class="category">
            {{ result.item.category }}/
          </span>
          <b>{{ result.item.name }}</b>
          <span class="flex-stretch"></span>
          <span v-if="result.item && result.item.type === 'ref'">
            {{ findResultTag(result) ? findResultTag(result).name : ' ~~ ' }}
          </span>
          <span v-else>{{ result.item.type }}</span>
        </div>
      </div>
    </template>
  </searching-modal>
</template>

<script>
import SearchingModal from './searching_modal.vue';

export default {
  name: 'TagModal',
  components: {
    SearchingModal,
  },
  props: {
    target: String,
    story: Object,
    value: String,
    extras: {
      type: Object,
      default() {
        return {};
      },
    },
    mutating: { type: Boolean, default: true },
    type: { default: 'all' },
    excludes: { type: Array, default() { return []; } },
  },
  data() {
    let typing = this.value;
    const object = this.findTag(this.story, typing);
    if (object) {
      typing = object.display || object.name;
      if (object.trait) {
        typing += '.' + object.trait;
      }
    }
    return {
      status: null,
      results: null,
      typing,
      error: null,
    };
  },
  computed: {
    actions() {
      const retval = [];
      if (this.typing && !/[\$]/.test(this.typing)) {
        if (this.value && (this.match || this.characterMatch)) {
          retval.push({ id: 'edit-tag' });
          retval.push({ id: 'clear-tag' });
        } else if (!(this.match || this.characterMatch) && !/[\.]/.test(this.typing)) {
          retval.push({ id: 'create-tag' });
        }
      }
      return retval;
    },
    characterMatch() {
      const search = /\./.test(this.typing)
        ? this.typing.split('.')[0]
        : this.typing;
      return this.story.tags.find(
        (t) => t.type === 'character' && t.name === search
      );
    },
    match() {
      const terms = (this.typing || '').split(/ |\//g);
      let results = this.tags.slice();
      for (const term of terms) {
        results = results.filter((r) => r.category === term || r.name === term);
      }
      if (results.length > 0) {
        return results[0];
      }
    },
    tags() {
      let tags = this.copy(this.story.allTags);
      const extras = this.extras || {};

      // replace $current
      tags.filter((tag) => {
        if (tag.referencing === '$current') {
          const array = this.story.allTags.find((c) => c.id === extras.$item);
          if (array) {
            tag.referencing = array.referencing;
          } else {
            const index = tags.findIndex((t) => t.id === tag.id);
            tags.splice(index, 1);
          }
        }
      });

      const tagsIgnoringImmutable = tags;
      tags = tags.filter((t) => !this.mutating || !t.immutable);
      if (this.type !== 'all') {
        tags = tags.filter((t) => (
          (t.type === 'ref' && t.referencing === this.type) ||
          t.type === this.type
        ));
      }
      if (this.excludes.length > 0) {
        tags = tags.filter((t) => !this.excludes.includes(t.type));
      }

      if (this.extras && this.extras.$value) {
        tags.push({
          id: '$value',
          name: '$value',
          type: 'number',
        });
      }

      for (const tag of tags.filter((t) => t.type === 'character')) {
        const char = this.story.characters.find((c) => c.id === tag.character);
        if (char) {
          const attrs = char.attributes.filter((attr) => this.type === 'all' || attr.type === this.type);
          for (const attr of attrs) {
            tags.push({
              id: `${tag.id}.${attr.name}`,
              name: `${tag.name}.${attr.name}`,
              type: attr.type,
              category: tag.category,
              character: tag.character,
              attr: attr,
            });
          }
        }
      }
      tags = tags.filter((t) => t.type !== 'character');

      // TODO: show sub ref attributes
      for (const tag of tagsIgnoringImmutable.filter((t) => t.type === 'ref')) {
        const char = this.story.allTypes.find((c) => c.id === tag.referencing);
        if (char) {
          const attrs = char.attrs.filter((attr) => (
            (this.type === 'all' || attr.type === this.type) &&
            (!this.mutating || !attr.immutable)
          ));
          for (const attr of attrs) {
            tags.push({
              id: `${tag.id}.${attr.name}`,
              name: `${tag.name}.${attr.name}`,
              type: attr.type,
              category: tag.category,
              character: tag.character,
              attr: attr,
            });
          }
        }
      }
      return tags;
    },
  },
  watch: {
    value() {
      this.typing = this.value;
    },
  },
  async mounted() {
    if (this.formula) {
      this.parseFormula();
      this.autogrow();
    }

    // auto select
    await this.$nextTick();
    const input = this.$refs.typing;
    if (input) {
      input.select();
    }
  },
  methods: {
    async handleInput(item) {
      if (item.id === 'create-tag') {
        this.createTag(this.typing);
      } else if (item.id === 'edit-tag') {
        if (this.characterMatch) {
          if (this.match) {
            this.$router.push({
              name: 'character-attribute',
              params: {
                id: this.match.attr.id,
                character: this.match.character,
                story: this.story.id,
              },
            });
          } else {
            this.$router.push({
              name: 'character',
              params: {
                id: this.characterMatch.character,
                story: this.story.id,
              },
            });
          }
        } else if (this.match) {
          this.$router.push({
            name: 'tags',
            params: { id: this.match.id, story: this.story.id },
          });
        }
      } else if (item.id === 'clear-tag') {
        this.$emit("update:value", null);
        this.$emit('show', false);
      } else {
        this.$emit("update:value", item ? item.id : null);
      }
    },
    findResultTag(result) {
      return this.story.allTypes.find((t) => t.id === result.item.referencing);
    },
    async createTag(rawName) {
      if (rawName && !this.error) {
        const name = rawName.trim();
        if (/^\$/.test(name)) {
          this.error = this.$t('formula_popup.tags-are-not-allowed-to-start-with');
        } else if (this.story.tags.find((t) => t.name === name)) {
          this.error = this.$t('tags.duplicate-tag-warning', [name]);
        } else {
          const id = this.$root.id();
          this.story.tags.push({ name, id, format: '', type: 'tag' });
          this.$emit("update:value", id);
          this.$emit('show', false);
          await this.story.saveAndPush();
        }
      }
    },
    handleCreate() {
      this.status = 'creating...';
      this.$emit('create', this.typing);
      this.$emit('show', false);
    },
    handleChoice(item) {
      this.$emit("update:value", item.id);
      this.$emit('show', false);
    },
  },
};
</script>

<style scoped>
#app .close-button {
  display: inline-block;
  flex: 0 0 auto;
  margin: 0;
  font-size: 25px;
  height: 30px;
  width: 30px;
  padding: 0;
  place-self: flex-end;
  align-self: flex-start;
  text-align: right;
  margin: 0;
}
a {
  padding: 0;
}
.result.row {
  padding: 5px;
  margin: 3px;
}
.search {
  max-width: 100%;
}
.result {
  margin: 0;
  padding: 6px;
}
.result svg {
  margin-right: 5px;
  display: inline-block;
}
.category {
  flex: 0 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 10px;
}
.top {
  align-self: flex-start;
}
.label {
  background: var(--color2);
  color: var(--color2_text);
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
}
.title,
.content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.icon {
  flex: 0 0 auto;
  padding: 5px;
  margin-right: 9px;
  font-size: 21px;
}
.action {
  padding: 5px;
  opacity: 0.8;
}
.missing .block {
  padding: 6px;
}
.text-header {
  margin-bottom: 5px;
}
.formula-input {
  min-width: max-content;
  min-height: max-content;
}
</style>
