<style scoped>
#app .notes-content {
  padding-bottom: 0;
}
input, textarea, .template {
  resize: none;
  padding: 5px;
  font-size: 14px;
  border-radius: 0px;
  border: none;
  background: var(--color1);
  color: var(--color1_text);
  overflow: hidden;
  margin-bottom: 5px;
}

.comment-marker {
  color: var(--color3);
  padding: 10px;
}
.autogrow-textarea {
  border: none;
  margin: 10px 0;
}

.tasks {
  padding: 0;
  margin: 10px;
  border-radius: var(--softness);
}
.task {
  background: var(--color3);
  color: var(--color3_text);
}
.task:nth-child(even) {
  background: var(--color2);
  color: var(--color2_text);
}
.task input[type=checkbox] {
  width: auto;
}
.task a {
  padding: 3px;
}
.add-row form {
  margin: 5px;
  margin-left: -5px;
  border-radius: 5px;
  padding: 10px;
  width: auto;
  align-self: flex-start;
  flex: 0 0 auto;
  margin: 0;
}
/* .add-row * {
  background: var(--color2);
  color: var(--color2_text);
} */
.add-row input {
  width: auto;
  font-size: 1.0em;
  margin: 0;
  margin-right: 5px;
  border: none;
  padding: 5px 10px;
  background: var(--color2);
  color: var(--color2_text);
  border-radius: 15px;
}
.add-row input::placeholder {
  color: var(--color2_text);
  opacity: 0.75;
}
.add-row button {
  padding: 3px 10px;
}
</style>


<template>
  <div class="notes-content">
    <help-message name="story-notes-explanation">
      {{ $t('notes_content.notes-help') }}
    </help-message>
    <div class="flex-row flex-align-center">
      <span class="comment-marker">//</span>
      <autogrow-textarea
        :modelValue="value.content"
        :placeholder="$t('notes_content.record-notes-here')"
        @update:modelValue="$emit('update', {content: $event})"
      />
    </div>
    <DraggableList
      class="tasks"
      draggable=".task"
      :value="value.tasks"
      @update:value="handleReorder"
    >
      <template #item="{object}">
        <div
          class="task flex-row flex-left flex-align-center padded"
          :key="object.id"
        >
          <div class="flex-static padded">
            <input
              class="flex-static"
              type="checkbox"
              :checked="object.complete"
              @change="handleComplete($event, object)"
            >
          </div>
          <div v-if="task.complete" class="flex-static handle ellipsis">
            <span class="strike">{{ object.content }}</span>
          </div>
          <div v-else class="flex-stretch handle ellipsis">{{ object.content }}</div>
          <div class="flex-stretch handle">
          </div>
          <a class="flex-static" @click="handleDelete(object)">&times;</a>
        </div>
      </template>
    </DraggableList>
    <div v-if="editing" class="add-row flex-row flex-left flex-static">
      <form @submit.prevent="handleAddTask" class="flex-row flex-static flex-left">
        <input :placeholder="$t('notes_content.add-task')" v-model="newTask" required>
        <button><i class="fa fa-plus" /></button>
      </form>
    </div>
  </div>
</template>

<script>
import DraggableList from '@morphosis/base/components/DraggableList.vue';

export default {
  name: 'notes-direction',
  components: {
    DraggableList,
  },
  props: {
    story: Object,
    value: Object,
    editing: Boolean,
  },
  data() {
    return {
      task: '',
      newTask: '',
      previousCharacter: null,
      editLevel: null,
    };
  },
  methods: {
    handleAddTask() {
      const tasks = this.copy(this.value.tasks || []);
      const id = tasks.reduce((a, b) => Math.max(a, b.id) + 1, 0);
      tasks.push({
        id,
        complete: false,
        content: this.newTask
      });
      this.newTask = '';
      console.log('tasks', tasks);
      this.$emit('update', {tasks});
    },
    handleComplete($event, task) {
      const tasks = this.copy(this.value.tasks || []);
      const idx = tasks.findIndex(t => t.id == task.id);
      tasks[idx].complete = $event.target.checked;
      this.$emit('update', {tasks});
    },
    handleDelete(task) {
      const tasks = this.copy(this.value.tasks || []);
      const idx = tasks.findIndex(t => t.id == task.id);
      tasks.splice(idx, 1);
      this.$emit('update', {tasks});
    },
    handleReorder(tasks) {
      this.$emit('update', {tasks});
    },
  },
};
</script>
