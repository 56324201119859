<style scoped>
.help-message {
  background: var(--color1);
  padding: 15px;
  margin: 10px;
  position: relative;
  color: var(--color1_text);
  box-shadow: 0 0px 5px var(--color1_text);
  border: 1px solid var(--spark);
  border-radius: 10px;
  font-size: 1.1em;
  flex: 0 0 auto;
  max-width: 100%;
}
.content {
  text-indent: 15px;
  display: block;
}
.fa-info-circle {
  top: 5px;
  left: 5px;
  position: absolute;
  float: left;
  font-size: 1.2em;
  color: var(--spark);
}
.close {
  float: right;
  font-size: 1.2em;
}
.help-message i {
  margin: 0;
  padding: 0;
}
</style>

<template>
  <div v-if="!hide" class="help-message">
    <a class="close" href="#" @click.prevent="closeMessage">&times;</a>
    <i class="fa fa-info-circle" />
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTutorialStore } from '@/stores/tutorials';
import { computed } from 'vue';

const tutorials = useTutorialStore();
const props = defineProps<{
  name: string,
  advanced?: boolean,
}>();

const hide = computed(() => props.advanced || tutorials.completed[props.name]);

const closeMessage = () => {
  tutorials.completed[props.name] = true;
};

</script>

