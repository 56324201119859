import { automation, createEmptyTestStory, createTutorial, navigateToStories, navigateToTestStory, text } from '../tools';

export default createTutorial({
    title: 'Multiplayer',
    scenes: [
      {
        directions: [
          text(`Let's get started on a multiplayer story`),
          automation([createEmptyTestStory, navigateToStories]),
          automation([{ action: 'navigate', args: ['/story/test/'] }]),
          text(`All stories can be played multiplayer.
When a story has multiple readers, all players vote on choices and then one of the votes is chosen randomly.
As the author you can customize a story to split groups and make a more dynamic story.`),
          text(`First, you'll need to mark the story as multiplayer if you haven't already`),
          automation([
            { action: 'click', args: ['.story-details a'] },
            { action: 'click', args: ['[name=multiplayer]'] },
            { action: 'goto', args: ['[name=player-max]'] },
            { action: 'type', args: ['4'] },
          ]),
          text(`Awesome, now let's make a simple story`),
          automation([
            {
              action: 'patch',
              args: [
                {
                  attrs: { multiplayer: true },
                  scenes: [
                    {
                      id: '1', directions: [
                        { id: '1', type: 'text', content: 'The haunted house looks spooky' },
                        { id: '2', type: 'choice', scene: '2', label: 'Go in' },
                        { id: '3', type: 'choice', scene: '3', label: 'Too scary, stay here' },
                      ],
                    },
                    {
                      id: '2', directions: [
                        { id: '1', type: 'text', content: 'The house is full of spiders' },
                        { id: '2', type: 'redirect', scene: '4' }
                      ],
                    },
                    {
                      id: '3', directions: [
                        { id: '1', type: 'text', content: 'Outside is also spooky' },
                        { id: '2', type: 'redirect', scene: '4' }
                      ]
                    },
                    {
                      id: '4', directions: [
                        { id: '1', type: 'choice', label: 'Run!' },
                        { id: '2', type: 'text', content: 'You survive... barely' }
                      ]
                    },
                  ],
                  start: true,
                }
              ]
            },
            navigateToTestStory,
          ]),
          text(`When you enable multiplayer you'll see an extra tab on directions`),
          automation([{ action: 'goto', args: ['.multiplayer-tab'] }]),
          text(`It looks like this. Now let's split the group...`),
          automation([
            { action: 'click', args: ['.multiplayer-tab'] },
            { action: 'goto', args: ['[name=change-group]'] },
            { action: 'type', args: ['fearless'] },
          ]),
          text(`You can name the group whatever you want. Anyone who picks this choice will divert from the main story path now`),
          automation([
            { action: 'click', args: ['.multiplayer-tab'] },
          ]),
          text(`Let's setup another group for the next choice`),
          automation([
            { action: 'click', args: ['.direction:nth-child(3) .multiplayer-tab'] },
            { action: 'goto', args: ['[name=change-group]'] },
            { action: 'type', args: ['chickens'] },
            { action: 'click', args: ['.direction:nth-child(3) .multiplayer-tab'] },
          ]),
          text(`We can also join these groups back into the main party`),
          automation([{ action: 'navigate', args: ['/story/test/scene/4/'] }]),
          text(`This is the final scene in the story, let's rejoin the groups`),
          automation([
            { action: 'click', args: ['.multiplayer-tab'] },
            { action: 'goto', args: ['.new-entry input'] },
            { action: 'type', args: [' '] },
            { action: 'goto', args: ['.entry-0 input'] },
            { action: 'type', args: ['fearless'] },
            { action: 'goto', args: ['.entry-1 input'] },
            { action: 'type', args: ['chickens'] },
          ]),
          text(`This choice will wait for every player in both of the groups specified before the story will continue, but it won't put them in the same group unless we *change group*`),
          automation([
            { action: 'goto', args: ['[name=change-group]'] },
            { action: 'type', args: ['main'] },
            { action: 'click', args: ['.multiplayer-tab'] },
          ]),
          text(`There, now the players will be joined into the same group.
There are the basics! Try it out!`),
        ]
      },
    ],
  });