<template>
  <div class="flex-left p-1-y p-1-l">
    <Checkbox v-model="value" binary :invalid="errors.length > 0" class="flex-static" />
  </div>
</template>

<script setup lang="ts">
import Checkbox from "primevue/checkbox";
import { useField } from "vee-validate";

const props = defineProps<{ name: string }>();

const { value, errors } = useField<string>(props.name);
</script>
