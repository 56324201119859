import { nextTick } from "vue";
import { useUserStore } from "../stores/user/users";
import { useWebsocketStore } from "../stores/websocket";
import { RemoteCall, jsonapi } from "./jsonapi";

// websocket api
export async function ws(opts: RemoteCall) {
  const user = useUserStore();
  const websocket = useWebsocketStore();

  await user.login();

  if (!websocket.socket.connected) {
    websocket.socket.connect();
    await websocket.socket.promise;
    await nextTick();
  }

  let retval = await websocket.send({ event: opts.event, data: opts.data });
  if (
    retval.error &&
    (retval.code === "login" || retval.token == "fail" || retval.code == "token-invalid")
  ) {
    user.current.token = "";
    user.current.updated = new Date().toISOString();
    await user.login();
    retval = await websocket.send({ event: opts.event, data: opts.data });
  }
  return retval;
}

export async function http(opts: RemoteCall) {
  const user = useUserStore();

  await user.login();

  const data = opts.data || {};
  if (opts.user && opts.user.token) {
    data.token = opts.user.token;
  }
  if (opts.user) {
    data.token_user = opts.user.id;
  }

  let retval = await jsonapi({ user: user.current, ...opts });
  if (
    retval.error &&
    (retval.code === "login" || retval.token == "fail" || retval.code == "token-invalid")
  ) {
    console.log("refreshing login", user.current.token);
    await user.renewLogin(true);
    console.log("refreshed login", user.current.token);
    data.token = user.current.token;
    retval = await jsonapi({ user: user.current, ...opts });
  }
  return retval;
}
