<style scoped>
.autogrow-textarea {
  flex: 1 1 auto;
  margin-bottom: 5px;
  border: 3px solid var(--spark);
  border-radius: 12px;
  overflow: hidden;
}
.options {
  /* color: var(--color3);
  background: var(--color3_text); */
  color: var(--spark);
  background: var(--spark_text);
  font-size: 2px;
  padding: 10px;
}
.options i {
  font-size: 19px;
}
textarea {
  resize: none;
  padding: 6px 9px;
  margin: 0;
  border: none;
  font-size: 15px;
  background: var(--bg);
  color: var(--text);
  overflow: hidden;
  min-height: 0 !important;
  border-radius: 10px;
}
.focused textarea {
  border-radius: 10px 10px 0 0;
}
.editing-panel {
  padding: 5px;
  background: var(--color3);
  color: var(--color3_text);
  border-radius: 0 0 12px 12px;
}
.text-style,
.text-speed {
  padding: 5px;
  margin: 2px;
  text-align: center;
}
.text-speed {
  text-align: center;
}
.edit-options {
  flex: 1 1 auto;
}
.edit-options a {
  padding: 15px;
  margin: 0;
}

.grow-wrap {
  display: grid;
  min-height: 0;
  overflow: hidden;
  flex: 1 1 auto;
}
.grow-wrap textarea {
  word-break: break-word;
}
.grow-wrap .template {
  visibility: hidden;
  /* color: red !important; */

  white-space: pre-wrap;
  word-break: break-word;
  background: transparent !important;
  z-index: 1;
  touch-action: none;
  pointer-events: none;
  opacity: 0.8;
}
.grow-wrap > * {
  /* Place on top of each other */
  display: block;
  margin: 0;
  grid-area: 1 / 1 / 2 / 2;
  padding: calc(var(--spacing) * 1.5) calc(var(--spacing) * 2);
  /* border: 2px solid red;
  border-width: 2px; */
  font-size: 20px;
  text-align: left;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
</style>

<template>
  <div
    class="autogrow-textarea"
    :class="{ focused }"
    @focus="focused = true"
    @blur="focused = false"
  >
    <div class="flex-row">
      <div class="grow-wrap">
        <textarea
          rows="1"
          :tabindex="tabindex"
          v-model="data"
          :placeholder="placeholder"
          :style="story && textStyle(effectiveStyle)"
          @click="$emit('click', $event)"
          @select="$emit('select', $event)"
          @keydown="$emit('keydown', $event)"
          @keypress="$emit('keypress', $event)"
          class="flex-stretch"
          ref="text"
          :maxlength="maxlength"
        />

        <!-- that char after {{ modelValue }} is critical, it makes newlines layout properly -->
        <div class="template" :style="story && textStyle(effectiveStyle)">
          {{ modelValueMinusSpace
          }}<template v-if="modelEndsWithSpace || modelEndsWithNewline">&nbsp</template>
        </div>
      </div>
    </div>

    <transition appear name="fade-quick">
      <div v-if="story && focused" class="editing-panel flex-row">
        <div class="edit-options row">
          <a href="#" v-if="$root.flags.clipboard && modelValue" @click="handleCut">
            <i class="fa fa-cut" />
          </a>
          <a href="#" v-if="$root.flags.clipboard && modelValue" @click="handleCopy">
            <i class="fa fa-copy" />
          </a>
          <confirm-button
            v-if="$root.flags.clipboard"
            @confirm="handlePaste"
            :skip="!modelValue || modelValue.trim().length == 0"
            class="static"
            tag="a"
          >
            <i class="fa fa-paste" />
            <template v-slot:confirm>
              {{ $t("autogrow_text.overwrite") }}
            </template>
          </confirm-button>
        </div>
        <div class="edit-options" v-if="story.tags.length > 0">
          <tag-picker
            v-model:value="tag"
            :placeholder="$t('autogrow_text.insert-tag-0')"
            :story="story"
            :show-edit="false"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { TYPE_SPEEDS } from "../../global";
import TagPicker from "./tag_picker.vue";

export default {
  name: "autogrow-textarea",
  components: {
    TagPicker,
  },
  props: {
    modelValue: String,
    type: { type: String, default: "text" },
    placeholder: String,
    styleName: {},
    speed: Number,
    story: Object,
    noExtras: { default: false },
    noSpeed: { default: false },
    maxlength: Number,
    tabindex: String,
  },
  data() {
    return {
      focused: false,
      pending: "",
      data: this.modelValue,
      tag: null,
      speeds: TYPE_SPEEDS,
    };
  },
  computed: {
    modelEndsWithSpace() {
      if (!this.data) {
        return 0;
      }
      const group = / $/g.exec(this.data);
      return group && group[0].length;
    },
    modelEndsWithNewline() {
      if (!this.data) {
        return 0;
      }
      const group = /\n$/g.exec(this.data);
      return group && group[0].length;
    },
    modelValueMinusSpace() {
      if (this.modelEndsWithSpace > 0) {
        return this.data.slice(0, -this.modelEndsWithSpace);
      }
      return this.data;
    },
    effectiveStyle() {
      if (this.story) {
        let retval = this.story.styles.find((s) => s.id == this.styleName);
        if (!retval && this.type === "choice") {
          retval = this.story.styles.find((s) => s.default_choice);
        }
        if (!retval) {
          retval = this.story.styles.find((s) => s.default);
        }
        return retval;
      }
      return null;
    },
  },
  watch: {
    data() {
      this.$emit("update:modelValue", this.data);
    },
    modelValue() {
      this.data = this.modelValue;
    },
    tag() {
      if (this.tag) {
        this.insertAtCursor();
      }
    },
  },
  methods: {
    getSelectionStart() {
      return this.$el.querySelector("textarea").selectionStart;
    },
    focus() {
      this.$el.querySelector("textarea").focus();
    },
    insertAtCursor() {
      let tag = this.story.tags.find((t) => t.id === this.tag);
      if (tag) {
        const el = this.$refs.text;
        let modelValue = el.modelValue;
        if (el.selectionStart || el.selectionStart == "0") {
          const startPos = el.selectionStart;
          const endPos = el.selectionEnd;
          modelValue =
            modelValue.substring(0, startPos) +
            "{{" +
            tag.name +
            "}}" +
            modelValue.substring(endPos, modelValue.length);
        } else {
          modelValue += myValue;
        }
        this.$emit("update:modelValue", modelValue);
        this.tag = null;
      }
    },
    async handleCut() {
      await this.$root.clipboardCopy(this.modelValue);
      this.$emit("update:modelValue", "");
    },
    async handleCopy() {
      await this.$root.clipboardCopy(this.modelValue);
    },
    async handlePaste() {
      const modelValue = await this.$root.clipboardPaste();
      this.$emit("update:modelValue", modelValue);
    },
  },
};
</script>
