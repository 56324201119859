import basename from "@morphosis/base/functions/basename";
import copy from "@morphosis/base/functions/copy";
import { debounce } from "@morphosis/base/utils/debounce";
import debug from "debug";
import { nanoid } from "nanoid";
import prettyBytes from "pretty-bytes";
import { toRaw } from "vue";
import Model from "./base";
import Media from "./media";
import Scene from "./scene";
import { encodeMp3 } from "./stories/encodeMp3";
// import { chainEffects } from "@/functions/audio/effect_profiles";

const log = debug("story");

export default class Story extends Model {
  static get objectName() {
    return "story";
  }

  static get defaultObject() {
    return {
      id: null,
      fid: null,
      uid: null,
      shared: false,
      cloudSync: true,
      title: null,
      short_description: null,
      description: null,
      updated: new Date().toISOString(),
      lastExport: null,
      savesSinceExport: 0,
      wc: 0,
      lastwc: 0,
      types: [],
      blueprints: [],
      scenes: [],
      characters: [],
      tags: [],
      styles: [],
      backgrounds: [],
      animation: [],
      audio: [],
      music: [],
      images: [],
      media: [],
      effectProfiles: [],
      attrs: {
        genre: "",
        language: "",
        multiplayer: false,
        players_min: 1,
        players_max: 1,
      },
      settings: {},
      cover: null,
      ordering: 1,
      landing: null,
    };
  }

  static get fields() {
    return {
      // cover: new Model.fieldtypes.ImageField(),
      rel_scenes: new Model.fieldtypes.Relation(Scene, "scenes"),
    };
  }

  get systemTypes() {
    const setting = this.settings.playerBlueprint;
    const defaultPlayerType = {
      id: "$player",
      name: "$player",
      attrs: [
        { name: "username", type: "text" },
        { name: "name", type: "text", immutable: true },
        { name: "main", type: "boolean", immutable: true },
      ],
    };
    let playerType = null;
    let playerBlueprint = this.blueprints.find((bp) => bp.id === setting);
    if (playerBlueprint) {
      playerType = this.types.find((type) => type.id === playerBlueprint.meta);
      if (playerType) {
        playerType = copy(playerType);
        for (const prop in defaultPlayerType) {
          if (defaultPlayerType[prop] instanceof Array) {
            for (const row of defaultPlayerType[prop]) {
              const existing = playerType[prop].find((r) => r.name === row.name);
              if (existing) {
                for (const attr in row) {
                  existing[attr] = row[attr];
                }
              } else {
                playerType[prop].push(row);
              }
            }
          } else {
            playerType[prop] = defaultPlayerType[prop];
          }
        }
      }
    }
    if (!playerType) {
      playerType = defaultPlayerType;
    }
    return [
      playerType,
      {
        id: "$party",
        name: "$party",
        attrs: [{ name: "name", type: "text", immutable: true }],
      },
      // {
      //   id: '$character', name: '$character', attrs: [
      //     { name: 'id', type: 'text', immutable: true },
      //   ],
      // },
    ];
  }

  get systemTags() {
    const retval = [{ id: "$item", name: "$item", type: "ref", referencing: "$current" }];
    if (this.attrs.multiplayer) {
      return retval.concat([
        {
          id: "$players",
          name: "$players",
          type: "array",
          referencing: "$player",
        },
        {
          id: "$player",
          name: "$player",
          type: "ref",
          referencing: "$player",
          immutable: true,
        },
        {
          id: "$party",
          name: "$party",
          type: "instance",
          referencing: "$party",
          immutable: true,
        },
      ]);
    }
    return retval;
  }

  get allTypes() {
    return [].concat(this.types, this.systemTypes);
  }

  get allTags() {
    return [].concat(this.tags, this.systemTags);
  }

  get emotes() {
    return this.characters.reduce((a, b) => {
      const emotes = copy(b.emotes);
      emotes.forEach((e) => {
        e.label = `${b.name}: ${e.name}`;
        e.character = b.id;
      });
      return a.concat(emotes);
    }, []);
  }

  async countSceneWords() {
    return (await this.sceneList()).map((s) => s.wc || 0).reduce((a, b) => a + b, 0);
  }

  async createScene(extra = {}) {
    const retval = new Scene({
      story: this.id,
      directions: [{ id: 1, type: "text", content: "" }],
    });
    retval.update(extra);
    await retval.save();
    const scenes = new Set(this.scenes);
    scenes.add(retval.id);
    this.scenes = Array.from(scenes);
    this.updated = new Date().toISOString();
    await this.save();

    this.rel_scenes.addToCache(retval);

    if (this.cloudSync) {
      await retval.push();
      await this.push();
    }
    return retval;
  }

  async sceneList() {
    return (await Promise.all(this.scenes.map((s) => Scene.load(s)))).filter((a) => a);
  }

  async getStartScene() {
    const scenes = await this.rel_scenes.list();
    let scene = scenes.find((s) => s && s.start);
    if (!scene && scenes.length > 0) {
      scene = scenes[0];
    }
    return scene;
  }

  async freezeAudio() {
    console.log(">>> Freezing audio...");
    const { Offline, Player, ToneAudioBuffer, getDestination } = await import("tone");
    // const { chainEffects } = await import("@/functions/audio/effect_profiles");
    const scenes = toRaw(await this.rel_scenes.list()).map((s) => toRaw(s));
    let storyChanged = true;
    for (const scene of scenes) {
      let sceneChanged = false;
      for (const direction of scene.directions) {
        let profile = null;
        let frozen = null;
        let shouldFreeze = false;
        // is audio not frozen or does it need to be updated?
        if (direction.type === "text" && direction.audio) {
          profile = this.getEffectProfile(direction);
          frozen = direction.frozenAudio && (await Media.load(direction.frozenAudio));
          if (frozen && profile) {
            shouldFreeze = await this.shouldRefreezeAudio(direction, profile, frozen);
          } else if (profile) {
            shouldFreeze = true;
          }
          console.log("freeze", shouldFreeze, frozen, direction.frozenAudio);
        }

        // if (frozen && frozen.cloud) {
        //   console.log("remove from cloud", frozen.id);
        //   await frozen.pushDelete();
        // }

        if (!profile && direction.frozenAudio) {
          console.log("removed unneeded frozen audio", profile, frozen);
          direction.frozenAudio = undefined;
          if (frozen) {
            await frozen.delete();
          }
          sceneChanged = true;
        }

        if (shouldFreeze) {
          const media = await Media.load(direction.audio);
          if (!media) {
            console.error("missing audio", direction);
            continue;
          }
          const url = await media.getUrl();

          let audioBuffer = new ToneAudioBuffer();
          await audioBuffer.load(url);

          audioBuffer = await Offline(async (context) => {
            const player = new Player();
            const dest = getDestination();
            await player.load(url);
            if (profile) {
              // await chainEffects(player, profile, dest, context);
            } else {
              // player.chain(dest);
            }

            context.transport.start();
            await player.start();
          }, audioBuffer.duration + 1);

          let blob = null;
          console.time("mp3");
          const ab = audioBuffer.get();
          try {
            blob = await new Promise((resolve) => {
              encodeMp3(ab, 128, null, (blob2) => {
                resolve(blob2);
              });
            });
          } catch (e) {
            console.error(e);
            blob = null;
          }
          console.timeEnd("mp3");

          if (blob) {
            if (!frozen || frozen.id === media.id) {
              frozen = new Media();
            }

            // clear these, from previous bug
            // if (frozen.cloud) {
            //   console.log("deleteing form cloud", frozen.id);
            //   await frozen.pushDelete();
            // }

            frozen.cloud = false;
            frozen.cloudSafe = false;
            await frozen.importBlob(blob, true);
            frozen.name = `[F]${media.name}`;
            frozen.type = media.type;
            frozen.isPrivate = true;
            frozen.updated = new Date().toISOString();
            await frozen.save();

            // test play audio
            // new Audio(await frozen.getUrl()).play();

            direction.frozenAudio = frozen.id;
            sceneChanged = true;
            // window.app.addAlert({
            //   level: "info",
            //   message: `"${media.name}" frozen`,
            // });
          } else {
            console.log("failed to freeze audio", ab);
          }
        }
      }
      if (sceneChanged) {
        console.log("save scene", scene, scene.save);
        await scene.save();
        storyChanged = true;
      }
    }
    if (storyChanged) {
      await this.saveJson(true);
    }
    console.log("<<< freeze done");
  }

  getEffectProfile(direction) {
    const character = this.characters.find((item) => item.id === direction.character);
    const profileId = direction.ep || direction.effectProfileId || character?.effectProfileId;
    return this.effectProfiles.find((item) => item.id === profileId);
  }

  async shouldRefreezeAudio(direction, profile, media) {
    const updated = new Date(media?.updated || 1);
    return (
      profile &&
      (!direction.frozenAudio ||
        updated < new Date(profile.updated) ||
        updated < new Date(direction.textUpdated))
    );
  }

  uploadFile(type, file, attrs = {}, sync = true) {
    if (!attrs.id) {
      attrs.id = nanoid();
    }
    let reader = new FileReader();
    reader.onload = async () => {
      const name = basename(file.name);
      if (this.cloudSync && sync) {
        const response = await Media.objects.push(
          { id: attrs.name || name },
          { content: reader.result, public: true },
        );
        if (response) {
          attrs.permalink = response.url;
          if (response.error) {
            attrs.error = response.error;
          } else if (attrs.error) {
            delete attrs.error;
          }
        } else {
          attrs.error = "Failed to upload file";
        }
      }
      if (!attrs.name) {
        attrs.name = name;
      }
      await this.proxyFile(type, reader.result, attrs);
    };
    reader.readAsDataURL(file);
  }

  async proxyFile(type, url, attrs = {}) {
    if (!attrs.id) {
      attrs.id = nanoid();
    }

    const parts = url.split("/");
    attrs.name = parts[parts.length - 1];
    if (/\?|\#/.test(attrs.name)) {
      attrs.name = attrs.name.split(/\?|\#/)[0];
    }
    const media = await Media.importurlToBlob(url);
    attrs.blob_id = media.id;
    if (this[type].indexOf(attrs) === -1) {
      this[type].push(attrs);
    }
    await this.saveAndPush();
  }

  async deleteFile(type, id) {
    // remove story reference
    const index = this[type].findIndex((i) => i.id === id);
    if (index > -1) {
      const ref = this[type][index];
      this[type].splice(index, 1);

      // remove server reference
      try {
        // if (this.story.cloudSync) {
        await Media.objects.pushDelete(ref.name);
        // }
      } catch (ex) {
        // it's ok
      }
      await this.saveAndPush();
    }
  }

  async fixMissingMedia(type) {
    this.loading = true;
    for (const image of this[type]) {
      if (image.image && !image.blob_id) {
        image.blob_id = image.image;
        await this.save();
      }
    }
    for (const image of this[type].filter((i) => i.permalink)) {
      const img = await Media.load(image.blob_id);
      if (!img) {
        const response = await Media.importBlob(image.permalink, image.blob_id);
        log("missing media", response);
      }
    }
    this.loading = false;
  }

  async saveAndPush(now = false) {
    if (now || (await debounce("story-save-and-push", 1000))) {
      const id = await this.save(now);
      if (this.cloudSync) {
        await this.push(now);
      }
    }
  }

  async save(now = false) {
    await this.saveJson(now);
    await super.save();
  }

  async delete() {
    const promises = [];
    for (const sceneId of this.data.scenes) {
      promises.push(Scene.objects.delete(sceneId));
    }
    await Promise.all(promises);
    await window.app.$stories.removeItem(this.id);
    return super.delete();
  }

  async saveJson(now) {
    const scenes = await this.rel_scenes.list();

    this.update({
      savesSinceExport: (this.savesSinceExport || 0) + 1,
      wc: scenes.reduce((a, b) => a + b.wc, 0),
    });

    const json = copy(this.data);
    json.scenes = scenes.map((s) => toRaw(s.data));
    // const scenes = await Promise.all(this.scenes.map(
    //   s => Scene.objects.load(s)
    // ));
    // json.scenes = scenes;

    if (now) {
      await window.app.saveStory(json);
    } else {
      window.app.saveStoryDebounced(json);
    }

    return json;
  }

  async pushAll() {
    for (const sceneId of this.data.scenes) {
      const scene = await Scene.objects.load(sceneId);
      scene.story = this.id;
      scene.updated = new Date().toISOString();
      await Scene.objects.push(scene);
    }
    return this.push();
  }

  async pushDelete() {
    const promises = [];
    for (const sceneId of this.data.scenes) {
      promises.push(Scene.objects.pushDelete(sceneId));
    }
    await Promise.all(promises);
    return super.pushDelete();
  }

  async publish(unlisted = true) {
    const blob = await app.exportStory(this.id);

    if (blob.size >= 21000000) {
      throw new Error(`File is too big to publish (${prettyBytes(blob.size)})`);
    }
    const content = await new Promise((r) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        r(reader.result);
      };
    });

    const attrs = copy(this.attrs);
    attrs.version = app.maxStoryVersion;
    attrs.scenes = this.scenes.length;

    await app.$socket.ensureConnected();

    let call = "$sendwait";
    let timeoutOrMethod = 1000 * 60 * 10;
    if (true || app.platform !== "web") {
      call = "http";
      timeoutOrMethod = "POST";
    }
    const response = await app[call](
      "file_post",
      {
        path: unlisted ? undefined : "cyoa/stories",
        id: this.fid || null,
        title: this.title,
        short_description: this.short_description,
        description: this.description,
        cover: this.cover,
        content_type: unlisted ? `unlisted/${app.id()}` : "application/cyoa",
        content,
        attrs,
      },
      timeoutOrMethod,
    );

    if (response && !response.error) {
      this.fid = response.id;
      this.shared = unlisted;
      await this.saveAndPush();
    } else {
      let error = null;
      if (/413/.test(response ? response.error : "")) {
        error =
          "Sorry, your story is too large to post on the server, this is usually caused by image and audio files. " +
          "Try using smaller or compressed images or use fewer assets";
      } else {
        error =
          "Error uploading story, if the problem persists, please send a message to the developer";
        if (!response) {
          error += " (Server did not respond)";
        } else if (response.error) {
          error += " (" + response.error + ")";
        }
      }
      throw new Error(error);
    }
  }

  toString() {
    return this.title || "Untitled Story";
  }
}
