export default [
  { group: 'standard', name: "text", icon: "fa fa-align-justify" },
  { group: 'standard', name: "choice", icon: "fa fa-hand-pointer-o" },
  { group: 'standard', name: "redirect", icon: "fa fa-arrow-right" },
  { group: 'standard', name: "return", icon: "fa fa-arrow-left" },
  { group: 'standard', name: "notes", icon: "fa fa-sticky-note" },
  { group: 'standard', name: 'layout', icon: 'fa fa-cogs', overlayout: true },
  { group: 'standard', name: 'clear', icon: 'fa fa-remove', overlayout: true },
  { group: 'media', name: "background", icon: "fa fa-paint-brush" },
  { group: 'media', name: "image", icon: "fa fa-picture-o" },
  { group: 'media', name: "audio", icon: "fa fa-volume-down" },
  { group: 'media', name: "music", icon: "fa fa-music" },
  { group: 'media', name: "video", icon: "fa fa-youtube" },
  // {group: 'media', name: "lighting", icon: 'fa fa-lightbulb-o'},
  { group: 'battle', name: "battle", icon: "fa fa-bomb", beta: true },
  { group: 'battle', name: "contestant", icon: "fa fa-user", beta: true },
  { group: 'advanced', name: "choice-timer", icon: "fa fa-clock-o" },
  { group: 'advanced', name: "input", icon: "fa fa-font" },
  { group: 'advanced', name: 'option', icon: 'fa fa-check-square', beta: true, overlayout: true },
  { group: 'advanced', name: "pause", icon: "fa fa-pause" },
  { group: 'advanced', name: "empty", icon: "fa fa-circle-thin" },
  { group: 'advanced', name: "group", icon: 'fa fa-cube' },
  { group: 'advanced', name: 'achievement', icon: 'fa fa-trophy', beta: true },
  // { group: 'advanced', name: 'party', icon: 'fa fa-group', beta: true },
  { group: 'advanced', name: "event", icon: 'fa fa-bolt' },
];
