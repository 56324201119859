<template>
  <transition-group
    appear
    mode="out-in"
    tag="div"
    v-bind:css="false"
    v-on:before-enter="beforeEnter"
    v-on:enter="enter"
    v-on:after-enter="afterEnter"
    v-on:before-leave="beforeLeave"
    v-on:leave="leave"
    v-on:after-leave="afterLeave"
  >
    <slot></slot>
  </transition-group>
</template>

<script>
import { Deferred } from "@morphosis/base/utils/deferred";
import Animation from "../utils/animations";

function nextFrame() {
  return new Promise((r) => {
    requestAnimationFrame(r);
  });
}

export default {
  name: "animation",
  props: {
    id: String,
    animations: Array,
  },
  watch: {
    id() {
      this.setup();
    },
    animations() {
      this.setup();
    },
  },
  created() {
    this.def = new Deferred();
  },
  mounted() {
    this.setup();
  },
  methods: {
    setup() {
      this.def.reset();
      this.config = this.animations.find((a) => a.id === this.id);
      if (this.config) {
        this.forward = new Animation(this.config.pipeline, this.config.duration);
        this.reverse = new Animation(this.config.pipeline, this.config.duration, true);
      }
      this.def.resolve();
    },

    // ENTERING
    beforeEnter(el) {
      el.style.display = null;
    },
    // the done callback is optional when
    // used in combination with CSS
    async enter(el, done) {
      console.log("el", !!this.forward, this.id);
      if (this.id) {
        await this.def.promise;
      }
      if (this.forward) {
        this.forward.stop();
        this.promise = this.forward.start(el);
      }
      await this.promise;
      done();
    },
    afterEnter(el) {},

    // LEAVING
    beforeLeave(el) {},
    // the done callback is optional when
    // used in combination with CSS
    async leave(el, done) {
      if (this.reverse) {
        this.reverse.stop();
        this.promise = this.reverse.start(el);
      }
      await this.promise;
      done();
    },
    afterLeave(el) {
      el.style.transition = null;
      el.style.opacity = 0;
    },
  },
};
</script>
