function base64UrlToUint8Array(base64UrlData) {
  const padding = '='.repeat((4 - base64UrlData.length % 4) % 4);
  const base64 = (base64UrlData + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');
  const rawData = atob(base64);
  const buffer = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    buffer[i] = rawData.charCodeAt(i);
  }
  return buffer;
}

export default {
  data() {
    return {
      pushToken: null,
      vapidPublicKey: null,
      currentPublicKey: localStorage.vapidPublicKey,
      hasServiceWorker: false,
      lastRegistration: localStorage['push-registrationUpdated'],
    };
  },
  async created() {
    if (!this.http) {
      console.error('api_mixin is required for push notifications');
    }
    await this.$root.loggedIn;
    if (!window.cordova) {
      this.registering = this.registerServiceWorker();
      await this.registering;
    } else {
      // console.log('cordova registration');
      // await this.registerCordovaNotifications();
    }
  },
  methods: {
    async registerCordovaNotifications() {
      await cordova.plugins.firebase.messaging.requestPermission();
      this.pushToken = await cordova.plugins.firebase.messaging.getToken();
      console.log('Got device token: ', this.pushToken);
      if (this.pushToken) {
        await this.http('push_subscribe', {
          sub: JSON.stringify({ type: 'FCM', token: this.pushToken }),
        });
      }
      cordova.plugins.firebase.messaging.onMessage(async (e) => {
        console.log('New foreground FCM message: ', e);
        // this.$root.addAlert({
        //   title: e.title,
        //   message: e.body,
        //   link: '/story?tab=notifications'
        // });
      });
      cordova.plugins.firebase.messaging.onBackgroundMessage(async (e) => {
        console.log('New background FCM message: ', e);
        this.$root.refreshNotifications();
        const extras = e.payload ? JSON.parse(e.payload) : {};
        await this.$nextTick();
        if (extras && extras.link) {
          this.$router.push(extras.link);
        } else {
          this.$router.push('/story?tab=notifications');
        }
      });
    },
    async getApplicationKey() {
      try {
        const response = await this.http('push_key', {});
        if (response.key) {
          this.vapidPublicKey = response.key;
          localStorage.vapidPublicKey = this.vapidPublicKey;
        }
      } catch (ex) {
        console.warn(ex);
      }
    },
    async registerServiceWorker() {
      return;
      // TODO:
      if ('serviceWorker' in navigator && window.Notification) {
        let url = '/sw.js';
        if (import.meta.env.NODE_ENV === 'production') {
          url = 'https://tsunami.darkchocolate.dev/static/cyoa/sw.js';
        }
        this.serviceWorker = await navigator.serviceWorker.register(url);
        this.hasServiceWorker = true;

        if (
          !localStorage.noPushNotifications
          && Notification.permission === 'granted'
        ) {
          this.subscribeToPushNotifications();
        }
      }
    },
    async subscribeToPushNotifications() {
      // if (this.lastRegistration > Date.now() - 1000 * 60 * 60 * 24 * 3) {
      //   return;
      // }
      if (!this.vapidPublicKey) {
        await this.getApplicationKey();

        // save this date
        this.lastRegistration = Date.now();
        localStorage['push-registrationUpdated'] = this.lastRegistration;
      }

      if (this.hasServiceWorker && this.vapidPublicKey) {
        let sub = await this.serviceWorker.pushManager.getSubscription();
        const key = base64UrlToUint8Array(this.vapidPublicKey);

        if (sub && this.currentPublicKey !== this.vapidPublicKey) {
          await sub.unsubscribe();
          sub = null;
        }

        if (!sub) {
          try {
            sub = await this.serviceWorker.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: key,
            });
          } catch (ex) {
            console.error(ex);
          }
        }

        if (sub) {
          await this.http('push_subscribe', {
            sub: JSON.stringify(sub.toJSON()),
          });
        }
      } else {
        console.error('problem', this.hasServiceWorker, this.vapidPublicKey);
      }
    },
    async unsubscribe() {
      if (this.hasServiceWorker) {
        const sub = await this.serviceWorker.pushManager.getSubscription();
        if (sub) {
          await sub.unsubscribe();
          await this.http('push_unsubscribe');
        }
      }
    },
    async refreshSubscription() {
      if (this.hasServiceWorker) {
        const sub = await this.serviceWorker.pushManager.getSubscription();
        if (sub) {
          await this.http('push_subscribe', {
            sub: JSON.stringify(sub.toJSON()),
          });
        }
      }
    },
    async testPushNotification(title, message, link) {
      await this.http('push_test', {
        user: this.$root.user.id,
        title,
        message,
        extras: link ? { link } : null,
      });
    },
  },
};
