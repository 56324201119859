export class Deferred {
  resetCount: number = -1;
  resolved: boolean = false;
  promise: Promise<boolean> | undefined;
  resolve: ((any?) => any) | undefined;
  reject: ((any?) => any) | undefined;

  constructor() {
    this.reset();
  }

  reset() {
    this.resetCount += 1;
    this.resolved = false;
    this.promise = new Promise((resolve, reject) => {
      this.resolve = (a) => {
        this.resolved = true;
        resolve(a);
      };
      this.reject = (a) => {
        this.resolved = true;
        reject(a);
      };
    });
  }
}
