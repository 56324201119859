<template>
  <div class="action result" :style="textStyle(choiceStyle)">
    <div v-if="character.action">
      <div>
        <typer :text="`${character.sheet.name} uses ${character.action.name}...`" />
        <typer
          v-if="showAction"
          :text="character.results && character.results.success ? 'Success!' : 'Failure!'"
        />
      </div>

      <!-- show in a different step -->
      <div v-if="character.results && character.results.success">
        <div
          v-for="(mod, target) in visibleEffects"
          :key="target"
          class="block"
        >
          {{ mod.name }}: {{ character.attr(mod.attr).name }}
          <falling-numbers
            :start="mod.before"
            :delta="mod.effectiveAmount"
          />
        </div>
      </div>
    </div>
    <div v-else-if="!character.alive()">
      {{ $t('battle_effects.character-sheet-name-is-dead', { character_name: character.sheet.name}) }}
    </div>
    <typer v-else :text="`${character.sheet.name} does nothing`" />
    <div class="stretch spacer" />
    <button @click="$emit('click')">{{ $t('battle_effects.next') }}</button>
  </div>
</template>

<script>
import { textStyle } from '../utils/tags';
import { timer } from '../utils/timer';
import FallingNumbers from './falling_number.vue';

export default {
  name: 'BattleResults',
  components: {
    FallingNumbers,
  },
  props: {
    story: { type: Object, required: true },
    contestants: { type: Array, required: true },
    character: { type: Object, required: true },
  },
  data() {
    return {
      showAction: false,
      showEffects: [],
    };
  },
  computed: {
    choiceStyle() { return this.story.styles.find(s => s.default_choice); },
    visibleEffects() {
      const retval = {};
      for (const key of this.showEffects) {
        retval[key] = this.character.results.modifiers[key];
      }
      return retval;
    },
  },
  async mounted() {
    // pause for dramatic action reveal
    await timer(1500);
    this.showAction = true;

    // pause for each effect
    if (this.character.results && this.character.results.success) {
      for (const name in this.character.results.modifiers) {
        await timer(1000);

        const target = this.contestants.find(c => c.id === name);
        if (target) {
          const image = target.sheet.emotes.find(e => e.name === 'default');
          if (image) {
            this.$emit('character-image', {image: image, aura: image.aura, position: target.team === 'protagonist' ? 'left' : 'right'});
          }
        }
        this.showEffects.push(name);
      }
    }
  },
  methods: {
    textStyle,
  }
}
</script>

<style scoped>
.action.result {
  text-align: left;
  justify-content: flex-start;
}
</style>