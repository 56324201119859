/* eslint-disable guard-for-in */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-syntax */
import copy from "../functions/copy";
import BaseField from "./fields/base";
import ImageField from "./fields/image";
import TsunamiManager from "./managers/tsunami";
import OneRelation from "./relations/one_relation";
import Relation from "./relations/relation";

export default class Model {
  static VERSION = 0;

  data: {
    id: string;
    [key: string]: any;
  };

  static get objectName() {
    return this.name || "document";
  }

  static get defaultObject() {
    return {};
  }

  static get fields() {
    return {};
  }

  static get ManagerClass() {
    return this._ManagerClass || Model.DefaultManagerClass;
  }

  static set ManagerClass(v) {
    this._ManagerClass = v;
    this.manager = null;
  }

  static get objects() {
    if (!this.manager) {
      this.manager = new this.ManagerClass(this.objectName);
    }
    return this.manager;
  }

  static async list<T extends typeof Model>(this: T): Promise<InstanceType<T>[]> {
    const retval = await this.objects.list();
    const objects: InstanceType<T>[] = retval.map((d) => new this(d));
    await Promise.all(
      objects.map(async (o) => {
        if (await this.shouldMigrate(o)) {
          await this.migrate(o);
        }
      })
    );
    return objects;
  }

  static async count() {
    return this.objects.count();
  }

  static async load<T extends typeof Model>(id: string): Promise<InstanceType<T>> {
    // console.log(' >> loading', this.name, id, this.constructor.name, new (this)());
    const data = await this.objects.load(id);
    if (data && data.id) {
      const object: InstanceType<T> = new this(data);
      if (await this.shouldMigrate(object)) {
        await this.migrate(object);
      }
      return object;
    }
    // console.warn('failed to load', this.objectName, id, data);
    return null;
  }

  static async shouldMigrate<T extends typeof Model>(object: InstanceType<T>) {
    return this.VERSION && (!object._version || object._version < this.VERSION);
  }

  static async migrate<T extends typeof Model>(object: InstanceType<T>) {
    // override this
    return null;
  }

  get objects() {
    return this.constructor.objects;
  }

  get id(): string {
    return this.data.id;
  }

  get _version(): string {
    return this.data._version;
  }

  get editable() {
    return !this._server || this._server.level !== "viewer";
  }

  constructor(data) {
    if (!data) {
      this.data = {};
    } else {
      this.data = copy(data);
    }
    // this.relations = {};
    this.beforeDefaultProperties();
    const defaultObject = this.constructor.defaultObject;

    this.fields = this.constructor.fields;
    for (const key in this.fields) {
      this.fields[key].createProperty(this, key);
    }

    for (const key in defaultObject) {
      if (this.data[key] === undefined) {
        this.data[key] = defaultObject[key];
        // console.log('missing', key, this.data[key]);
      }
      if (!this[key] && !this.fields[key]) {
        Object.defineProperty(this, key, {
          get: () => this.data[key],
          set: (v) => {
            this.data[key] = v;
          },
        });
      }
    }
    this.afterDefaultProperties();
  }

  dispose() {
    const fields = this.constructor.fields;
    for (const key in fields) {
      fields[key].dispose();
    }
  }

  beforeDefaultProperties() {
    // define relations here?
  }

  afterDefaultProperties() {
    // define relations here?
  }

  // defineRelation(name, model, fieldname) {
  //   const field = fieldname || name;
  //   this.relations[field] = new Relation(this, name, model);
  //   Object.defineProperty(this, field, {
  //     get: () => this.relations[field],
  //     set: (v) => { throw new Error('cannot set relation'); },
  //   });
  // }

  // async filter(ref, filters) {
  //   const retval = ref || [];
  //   for (const object of await this.constructor.objects.list()) {
  //     let ok = true;
  //     if (filters) {
  //       for (const name in filters) {
  //         if (object[name] !== filters[name]) {
  //           ok = false;
  //           break;
  //         }
  //       }
  //     }
  //     if (ok) {
  //       retval.push(object);
  //     }
  //   }
  //   return retval;
  // }

  update(data) {
    for (const key in data) {
      this.data[key] = data[key];
    }
  }

  updateMissingDefaults() {
    const defaultObject = this.constructor.defaultObject;
    for (const key in defaultObject) {
      if (this.data[key] === undefined) {
        this.data[key] = defaultObject[key];
        console.log("missing2", key, this.data[key]);
      }
    }
  }

  updateProperties() {}

  async load(id: string) {
    const data = await this.objects.load(id, this.data);
    if (data) {
      this.data = data;
    }
    if (await this.constructor.shouldMigrate(this)) {
      await this.constructor.migrate(this);
    }
  }

  async unload() {
    return this.objects.unload(this.id);
  }

  async save() {
    const object = copy(this.data);
    this.data.id = await this.objects.save(object);
    return this.data.id;
  }

  async saveAndPush() {
    await this.save();
    await this.push();
    return this.data.id;
  }

  async delete() {
    return this.objects.delete(this.id);
  }

  static async syncAll(callback = null) {
    return this.objects.syncAll(this, callback);
  }

  async sync(server, lazyPull = false) {
    const updated = this.data.updated;
    const serverUpdated = (this.data._server || {}).updated || 1;
    const bytes = this.data.byte_size;
    this.data = await this.objects.sync(this.data, server, lazyPull);
    if (
      this.data.updated !== updated ||
      this.data._server.updated !== serverUpdated ||
      this.data.byte_size !== bytes
    ) {
      console.log(" -> synced", this.constructor.name, this.id);
      await this.save();
      return true;
    }
    return false;
  }

  async push(opts = {}) {
    console.warn(" <- pushed", this.constructor.name, this.id);
    return this.objects.push(this.data, opts);
  }

  async pushDelete() {
    return this.objects.pushDelete(this.id);
  }

  async syncGroupAsEditors(group, level = null) {
    const response = await this.listEditors();
    console.log(this.id, "response", response);
    const editors = response.members;
    console.log(this.id, "existing editors3", editors, group);

    // add members
    const adds = group.members.filter(
      (m) => m && m.user_id && m.joined && !editors.find((e) => e.user_id === m.user_id)
    );
    const updates = group.members
      .map((m) => editors.find((e) => e.user_id === m.user_id))
      .filter((a) => a && a.user_id);
    const deletes = editors.filter((e) => !group.members.find((m) => m.user_id === e.user_id));

    console.log(
      this.id,
      "add/update/delete",
      level,
      adds.map((a) => a.user_id),
      updates.map((a) => a.user_id),
      deletes.map((a) => a.user_id)
    );

    await Promise.all([
      ...adds.map(async (m) => {
        await this.addEditor({
          doc: this.id,
          user: m.user_id,
          level: level || "viewer",
        });
      }),
      ...updates.map(async (m) => {
        await this.changeEditor({
          doc: this.id,
          user: m.user_id,
          level: level || "viewer",
        });
      }),
      ...deletes.map(async (m) => {
        await this.deleteEditor({
          doc: this.id,
          user: m.user_id,
        });
      }),
    ]);
  }

  async listEditors() {
    return this.objects.listEditors(this.data);
  }

  async addEditor(opts) {
    return this.objects.addEditor(this.data, opts);
  }

  async changeEditor(opts) {
    return this.objects.changeEditor(this.data, opts);
  }

  async removeEditor(opts) {
    return this.objects.removeEditor(this.data, opts);
  }

  static get fieldtypes() {
    return {
      BaseField,
      ImageField,
      Relation,
      OneRelation,
    };
  }
}

Model.DefaultManagerClass = TsunamiManager;
