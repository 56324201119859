<template>
  <div class="search-form flex-static">
    <div class="padded flex-row flex-align-center">
      <input
        class="filter-input"
        :placeholder="placeholder || $t('story_browser.search')"
        ref="typing"
        v-model="filter"
        @keydown="handleKeypress"
      />
      <vi name="magnify" />
    </div>
    <slot name="pre-search" :filter="filter" />
  </div>
</template>

<script>
import Fuse from "fuse.js";

export default {
  props: {
    placeholder: { type: String, required: false },
    config: {
      type: Object,
      default() {
        return null;
      },
    },
    actions: {
      type: Array,
      default() {
        return [];
      },
    },
    options: { type: Array, required: true },
    value: {
      type: [Number, String],
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      status: null,
      filter: this.value,
      pending: null,
      results: null,
      max: 15,
      selected: -1,
    };
  },
  watch: {
    async value() {
      this.filter = this.value;
      this.status = null;
    },
    options() {
      this.loadFuse();
    },
    actions() {
      this.loadOptions();
    },
    filter() {
      this.$emit("update:filter", this.filter);
      this.max = 15;
      this.selected = -1;
      this.loadOptions();
    },
    selected() {
      this.$emit("update:selected", this.selected);
    },
  },
  async mounted() {
    this.loadFuse();
    await this.$nextTick();
    const input = this.$refs.typing;
    if (input) {
      input.select();
    }
  },
  methods: {
    loadFuse() {
      this.fuse = new Fuse(this.options.slice(), this.config);
      this.loadOptions();
    },
    loadOptions() {
      let results = this.actions.map((a) => ({ item: a }));
      if (this.filter) {
        results = results.concat(this.fuse.search(this.filter));
      } else {
        results = results.concat(
          this.fuse._docs.map((c) => {
            return { item: c };
          }),
        );
      }
      this.results = results;
      this.$emit("update:results", results);
    },
    handleKeypress(e) {
      if (e.key === "Escape") {
        this.$emit("escape", true);
      } else if (e.key === "ArrowDown") {
        this.selected += 1;
        if (this.selected >= this.results.length) {
          this.selected = this.results.length - 1;
        }
      } else if (e.key === "ArrowUp") {
        this.selected -= 1;
        if (this.selected < 0) {
          this.selected = -1;
        }
      } else if (e.key === "Enter") {
        if (this.selected < 0) {
          this.selected = 0;
        }
        if (this.results.length > 0) {
          e.preventDefault();
          this.$emit("update:value", this.results[this.selected].item);
        }
      } else {
        this.selected = 0;
      }
    },
  },
};
</script>

<style scoped>
.search-form {
  position: relative;
}
input {
  margin: 0;
}
.search-form > div > svg {
  margin: 10px;
  position: absolute;
  right: 10px;
}
</style>
