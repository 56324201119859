import BaseField from '../fields/base';

export default class OneRelation extends BaseField {

  constructor(model) {
    super();
    this.model = model;
  }

  getValue(object, key) {
    return this;
  }

  setValue(object, key, v) {
    console.log('setting foreign key', v);
    if (typeof (v) === 'object') {
      this.object.data[key] = v.id;
    } else {
      // eslint-disable-next-line no-param-reassign
      this.object.data[key] = v;
    }
  }

  get value() {
    return this.object.data[this.key];
  }

  async object() {
    if (this.value && !this._object) {
      this._object = await this.model.load(this.value);
    }
    return this._object || null;
  }

  async dispose() {
    if (this._object) {
      await this._object.dispose();
    }
    this._object = null;
  }
}
