<style scoped>
code {
  background: white;
  border: 1px dashed lightcoral;
  color: darkred;
  padding: 5px;
  display: inline-block;
  margin: 0 5px;
}
</style>


<template>
  <div>
    <p>Remove choice? <code>{{ $route.params.choice.label }}</code></p>
    <form @submit="handleRemove">
      <button>Yes, Remove!</button>
      <router-link :to="{name: 'choices'}">Cancel</router-link>
    </form>
  </div>
</template>

<script>
export default {
  name: "remove-effect",
  props: {
    scene: Object
  },
  methods: {
    handleRemove() {
      let idx = this.scene.choices.findIndex(
        a => a == this.$route.params.choice);
      if (idx > -1) {
        this.scene.choices.splice(idx, 1);
        this.save(this.story);
      }
      this.$router.push({name: "choices"});
    }
  }
}
</script>
