export default function bitdown(text, story, variables = {}) {
  const markers = [
    { startTag: "\\*\\*", tag: "b" },
    { startTag: "\\*", tag: "i" },
    { startTag: "~~", tag: "s" },
    { startTag: "@", tag: "r" },
    {
      startTag: "\\$#\\$",
      endTag: "\\$#\\$",
      tag: 'span style="display:block;padding:5px;text-shadow:none;color:red;background:black"',
    },
  ];
  if (story) {
    markers.push({
      startTag: "\\!\\[",
      endTag: "\\]",
      render: (tag, inside) => {
        let [source, size] = inside.split(/,| /);

        // find a image reference
        const tags = story.tags || [];
        let variable = tags.find(
          (tag) =>
            tag.name === source && tag.type === "story-object-ref" && tag.referencing === "images",
        );
        if (variable) {
          // found image variable
          source = variables[variable.id] || variable.default;
        }

        // find size variable
        variable = tags.find(
          (tag) =>
            tag.name === size && tag.type === "tag" && (!tag.format || tag.format === "numeric"),
        );
        if (variable) {
          // found image variable
          size = variables[variable.id] || variable.default;
        }

        // find an image
        const image = story.images.find((i) => i.id === source || i.name === source);
        if (image) {
          size = size ? ` width="${parseInt(size)}"` : "";
          return `<img src="${image.blob_id}"${size}>`;
        }
        return "";
      },
    });
    for (const style of story?.styles || []) {
      if (style.name) {
        let textStyle = "";
        for (const [key, value] of [
          ["foreground", "color"],
          ["font", "font-family"],
          ["size", "font-size"],
        ]) {
          if (style[key]) {
            const styleValue = `${style[key] || ""}`.replace(/ /g, "");
            textStyle += `${value}:${styleValue};`;
          }
        }
        markers.push({ startTag: `#${style.name}#`, tag: `span style="${textStyle}"` });
      }
    }
  }
  for (const marker of markers) {
    text = matchMarker(
      marker.startTag,
      marker.endTag || marker.startTag,
      marker.tag,
      text,
      marker.render,
    );
  }

  return text;
}

function matchMarker(startTag, endTag, tag, text, renderFunc = null) {
  const char = startTag.replace(/\\/g, "").charAt(0);
  const regex = new RegExp(`${startTag}(\\S[^${char}\n]*?(?<=\\S))${endTag}`, "g");
  let htmlEnd = tag;
  if (/ /.test(htmlEnd)) {
    htmlEnd = htmlEnd.split(/ /)[0];
  }
  text = text.replace(regex, (full, inside) => {
    if (renderFunc) {
      return renderFunc(tag, inside);
    } else {
      return `<${tag}>${inside}</${htmlEnd}>`;
    }
  });
  // console.log('match', marker, tag, char, text, regex);
  return text;
}
