<template>
  <div class="audio-recorder flex-column flex-static">
    <div class="flex-row flex-align-center">
      <vi v-if="!props.isOpen && !status" name="microphone" @click="status = Status.Menu" />
      <vi name="stop" v-else-if="status === Status.Playing" @click="justStopAudio" />
      <template v-else-if="status === Status.Recording">
        <vi name="recordoutline" @click="stopAudio()" />
        <span v-if="time && start">{{ ((time - start) / 1000).toFixed(1) }}s</span>
        <AudioMeter :value="volume" :max="100" direction="E" />
      </template>
      <template v-else-if="props.isOpen || status === 'menu'">
        <template v-if="audio">
          <a v-if="audio" @click.prevent="audio.play" href="#"><vi name="play" /></a>
          <span v-if="isProcessing">
            <i class="fa fa-refresh fa-spin" />
          </span>
          <a v-if="value" :href="value" title="Download Recording" :download="name"
            ><vi name="download"
          /></a>
          <a v-if="value" @click.prevent="resetAudio" title="Delete Recording" href="#"
            ><vi name="trash"
          /></a>
          |
        </template>
        <span v-if="isProcessing">
          <i class="fa fa-refresh fa-spin" />
        </span>
        <template v-else>
          <a @click.prevent="recordAudio" title="Start Recording" href="#"><vi name="record" /></a>
          <a @click.prevent="uploadAudio" title="Upload Recording" href="#"><vi name="upload" /></a>
          <!-- <span v-if="audio">{{ audio.duration.toFixed(0) }}s</span> -->
        </template>
        <a v-if="!props.isOpen && status" @click.prevent="closeMenu" title="Close Menu" href="#">
          <vi name="close" />
        </a>
      </template>
    </div>
    <input ref="file" type="file" accept="audio/*" @change="handleUpload" />
    <!-- <audio v-if="value" :src="value" controls></audio> -->
  </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, onUnmounted, ref, watch, type Ref } from "vue";
import { debounce } from "../../utils/debounce";
import AudioMeter from "./AudioMeter.vue";

enum Status {
  Ready = "ready",
  Playing = "playing",
  Menu = "menu",
  Recording = "recording",
}

let recorder: MediaRecorder | null = null;
const props = defineProps({
  value: String,
  name: { default: "recording.mp3" },
  hintIsLoading: { type: Boolean, default: false },
  isOpen: { type: Boolean, default: false },
});
const emits = defineEmits(["update:value"]);

const file = ref();
const start: Ref<number> = ref(0);
const time: Ref<number> = ref(0);
const status: Ref<Status> = ref(Status.Ready);
let interval: number = -1;
const chunks = ref<Blob[]>([]);
const device = navigator.mediaDevices.getUserMedia({ audio: true });
const audio: Ref<HTMLAudioElement | null> = ref(null);
const audioContext = new AudioContext();
const volume = ref(0);
const isProcessing: Ref<boolean> = ref(false);

watch(
  () => props.value,
  async () => {
    if (await debounce("prepare-audio")) {
      prepareAudio();
    }
  }
);
watch(
  () => props.hintIsLoading,
  () => {
    console.log("hint: is loading");
    if (props.hintIsLoading) {
      isProcessing.value = true;
    }
  }
);

async function prepareAudio() {
  if (status.value !== Status.Recording) {
    if (props.value) {
      console.log("processing start");
      isProcessing.value = true;
      await nextTick();

      const retval = new Audio();
      retval.onplay = () => {
        status.value = Status.Playing;
      };
      retval.onpause = () => {
        if (status.value === Status.Playing) {
          status.value = Status.Menu;
        }
      };
      retval.onended = () => {
        if (status.value === Status.Playing) {
          status.value = Status.Menu;
        }
      };
      retval.ondurationchange = () => {
        audio.value = null;
        audio.value = retval;
      };

      retval.oncanplay = () => {
        console.log("loading");
        if (isProcessing.value) {
          isProcessing.value = false;
        }
      };
      retval.src = props.value;
    }
  } else {
    audio.value = null;
    isProcessing.value = false;
  }
}

async function recordAudio() {
  let stream = null;
  try {
    stream = await device;
  } catch (ex) {
    window.app.addAlert({
      title: "Failed to initialize audio device",
      message: "" + ex,
    });
  }
  if (!stream) {
    window.app.addAlert({
      title: "Failed to initialize audio device",
      message: "No audio device",
    });
  } else {
    console.log("stream", stream);
    recorder = new MediaRecorder(stream);
    const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);
    const analyserNode = audioContext.createAnalyser();
    mediaStreamAudioSourceNode.connect(analyserNode);

    recorder.ondataavailable = (e) => {
      isProcessing.value = true;
      chunks.value.push(e.data);
      if (recorder?.state === "inactive") {
        const blob = new Blob(chunks.value, { type: "audio/mp3" });

        var a = new FileReader();
        a.onload = function (e: ProgressEvent<FileReader>) {
          if (e.target) {
            emits("update:value", e.target.result);
            chunks.value.length = 0;
          }
        };
        a.readAsDataURL(blob);
      }
    };

    // start
    status.value = Status.Recording;
    start.value = Date.now();
    const pcmData = new Uint8Array(analyserNode.frequencyBinCount);

    const onFrame = () => {
      interval = window.requestAnimationFrame(onFrame);
      time.value = Date.now();

      analyserNode.getByteTimeDomainData(pcmData);
      let sumSquares = 0.0;
      let vol = 0.0;
      pcmData.every((amplitude) => {
        sumSquares += amplitude * amplitude;
        vol = (Math.max(vol, Math.abs(amplitude - 127)) / 127) * 100;
      });
      // volume.value = Math.sqrt(sumSquares / pcmData.length) * 100000;
      volume.value = vol;
    };
    onFrame();
    recorder.start();
  }
}

function stopAudio() {
  // stop
  if (recorder) {
    recorder.stop();
  }
  window.cancelAnimationFrame(interval);
  status.value = Status.Menu;
}

function justStopAudio() {
  if (audio.value) {
    audio.value.pause();
    audio.value.currentTime = 0;
  }
}

function resetAudio(e) {
  e.stopImmediatePropagation();
  if (confirm("Delete audio file?")) {
    emits("update:value", null);
  }
}

function uploadAudio(e) {
  e.stopImmediatePropagation();
  file.value.click();
}

function handleUpload() {
  if (file.value.files.length > 0) {
    const reader = new FileReader();
    reader.onload = function (e) {
      if (e.target) {
        emits("update:value", e.target.result);
      }
    };
    reader.readAsDataURL(file.value.files[0]);
  }
  file.value.value = null;
}

function closeMenu() {
  stopAudio();
  if (audio.value) {
    audio.value.pause();
    audio.value.currentTime = 0;
  }
  status.value = Status.Ready;
}

onMounted(() => {
  if (props.hintIsLoading) {
    console.log("hint is loading2");
    isProcessing.value = true;
  }
  prepareAudio();
});

onUnmounted(() => {
  stopAudio();
});
</script>

<style scoped>
.audio-recorder {
  border-radius: var(--softness);
  background: var(--color3);
  color: var(--color3_text);
}
button {
  margin: 0;
  padding: 0;
}
input {
  display: none;
}
svg {
  margin: 8px;
}
a {
  padding: 0;
}
</style>
