export default class BaseField {
  createProperty(object, key, fieldname = null) {
    this.initialize(object, key);
    const field = fieldname || key;
    Object.defineProperty(object, field, {
      get: () => this.getValue(object, key),
      set: (v) => { this.setValue(object, key, v); },
    });
  }

  initialize(object, key) {
    this.object = object;
    this.key = key;
  }

  getValue(object, key) {
    return this.object.data[key];
  }

  setValue(object, key, v) {
    // eslint-disable-next-line no-param-reassign
    this.object.data[key] = v;
  }

  async serialize() {
    return this.object.data[this.key];
  }

  async dispose() {

  }
}
