<template>
  <div
    @click.prevent="$emit('click', $event)"
    @pointermove="handlePointerMove"
    :key="object.id"
    class="drawing-thumb"
    :class="{ [`thumb-${category}`]: true }"
    :style="{
      ['--imgsize']: `${size}px`,
      // 'background-image': thumbnail ? `url(${thumbnail})` : '',
    }"
  >
    <Blob_image :blob="object.id" />
    <div v-if="object.isAudio" class="flex-row flex-align-center flex-center music">
      <vi name="music" />
    </div>
    <!-- <span v-else>{{ object.id }}</span> -->
    <span class="name flex-row">
      <span v-if="object.permalink" class="permalink">
        <i class="fa fa-link" />
      </span>
      <span v-if="object.name" class="ellipsis">{{ object.name }}</span>
    </span>
    <span v-if="object.isImage && size >= 100" class="size">
      {{ object.width }} x {{ object.height }}
      <!-- ({{ thumbnail.length }} bytes) -->
    </span>
    <span v-else class="size">
      {{ object.type }}
    </span>
  </div>
</template>

<script>
import Blob_image from "@/components/blob_image.vue";

export default {
  components: { Blob_image },
  props: {
    object: { type: Object },
    size: { type: Number, default: 100 },
  },
  data() {
    return {
      thumbnail: null,
    };
  },
  async mounted() {
    // this.thumbnail = await this.object.getThumbnail();
  },
  computed: {
    category() {
      console.log("type", this.object.type);
      return (this.object.type || "").split("/")[0];
    },
  },
  methods: {
    handlePointerMove(e) {
      // console.log(e.pointerType, e.buttons);
      if (e.pointerType === "touch" || e.buttons > 0) {
        this.$emit("pointermove", e);
      }
    },
  },
};
</script>

<style scoped>
.drawing-thumb {
  border: 2px solid var(--color1_text);
  background: var(--color1);
  border-radius: var(--softness);
  margin: 5px;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  width: var(--imgsize);
  height: var(--imgsize);
  padding: 1px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  aspect-ratio: 1 / 1;
  cursor: pointer;
}
.drawing-thumb .name {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: var(--color1);
  padding: 3px;
  font-size: 13px;
  opacity: 0.75;
  white-space: nowrap;
  overflow: hidden;
}
.thumb-audio .name {
  white-space: nowrap;
}

.permalink {
  margin-right: 5px;
  display: inline-block;
}

.drawing-thumb .size {
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--color1);
  border-radius: calc(var(--softness) * (60 / 100));
  padding: 2px;
  font-size: 11px;
  opacity: 0.8;
}
.music > svg {
  width: 45%;
  height: 45%;
  background: var(--spark);
  color: var(--spark_text);
  border-radius: 50%;
  padding: 10px;
  opacity: 0.7;
}
</style>
