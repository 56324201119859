<template>
  <BaseAutoForm
    @submit="emit('submit', $event)"
    @update:value="emit('update:value', $event)"
    :value="props.value"
    :customField="AutoField"
    :fields="props.fields"
    :direction="props.direction"
    :labels="props.labels"
    :sub="props.sub"
  >
    <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"/>
    </template>
  </BaseAutoForm>
</template>

<script setup>
import BaseAutoForm from "@morphosis/base/components/forms/AutoForm.vue";
import AutoField from './auto_field.vue';

const emit = defineEmits(['submit', 'update:value']);
const props = defineProps({
  fields: { type: Array, required: true },
  value: { type: Object, required: true },
  direction: { type: String, default: 'row' },
  labels: { type: Boolean, default: true },
  sub: { type: Boolean, default: false },
});
</script>

<style scoped>
.form {
  width: 100%;
  place-self: stretch;

  display: grid;
  grid-template-columns: 150px 1fr;
}
.row {
  display: contents;
  width: 100%;
  margin-top: 3px;
  flex-wrap: wrap;
}
.row.subs {
  flex-wrap: nowrap;
}
.row .help-message {
  flex: 1 1 auto;
  margin: 5px 0 20px 0;
}
.form label {
  flex: 0 0 100%;
  white-space: normal;
}
.form input, .form select {
  flex: 1 1 auto;
  width: auto;
  min-width: 1em;
}
.checkbox, .buttons {
  flex: 0 0 auto;
  text-align: left;
  justify-content: flex-start;
  /* margin-left: 30%; */
  padding: 5px 0;
}
</style>
