// requires Websocket mixin
// requires UserMixin
import { mapStores } from "pinia";
import { useToasterStore } from "../stores/toaster";
import { useUserStore } from "../stores/user/users";
import { useWebsocketStore } from "../stores/websocket";

export default {
  data() {
    return {
      syncModels: [],
    };
  },
  computed: {
    connected() {
      return this.websocketStore.socket.connected;
    },
    ...mapStores(useUserStore, useWebsocketStore, useToasterStore),
  },
  watch: {
    connectionPreference() {
      this.put("stayOnline", this.connectionPreference);
    },
  },
  mounted() {
    this.websocketStore.socket.on("login", async (packet) => {
      if (packet.id && !packet.error) {
        await this.syncAllModels();
      }
    });
    this.setupListeners();
  },
  methods: {
    async syncAllModels() {
      if (this.websocketStore.socket.connected && this.$root.allowSyncing) {
        // eslint-disable-next-line no-restricted-syntax
        for (const model of this.syncModels) {
          // eslint-disable-next-line no-await-in-loop
          await this.syncModel(model);
        }
        this.toasterStore.add({
          channel: "sync",
          title: "Syncing",
          message: "Cloud synchronized",
          className: "mini",
          timeout: 1,
        });
      }
    },
    async syncModel(model) {
      try {
        if (!this.syncModels.includes(model)) {
          this.syncModels.push(model);
        }
        if (this.websocketStore.socket.connected) {
          await this.websocketStore.send({ event: "db_listen", data: { model: model.objectName } });
          await this.syncAll(model);
        } else {
          console.warn("sync model disconnected");
        }
      } catch (ex) {
        console.error(ex);
      }
    },
    async syncAll(Model) {
      this.toasterStore.add({
        channel: "sync",
        title: "Syncing",
        message: `Syncing ${Model.name}`,
        className: "mini",
        timeout: -1,
      });
      const clouds = await Model.objects.pullList();

      // eslint-disable-next-line no-restricted-syntax
      for (const cloud of clouds) {
        // eslint-disable-next-line no-await-in-loop
        let instance = await Model.load(cloud.id);
        let created = false;
        let updated = false;
        if (!instance) {
          instance = new Model({ id: cloud.id, updated: -1 });
          created = true;
        } else {
          updated = true;
        }

        // eslint-disable-next-line no-await-in-loop
        let saved = null;
        if (instance.cloud !== false && instance.cloudSafe !== false) {
          saved = await instance.sync(cloud, true);
        }
        if (saved) {
          // eslint-disable-next-line no-await-in-loop
          await instance.save();
          this.toasterStore.add({
            channel: "sync",
            title: "Syncing",
            message: `Syncing ${Model.name} (${instance})`,
            className: "mini",
            timeout: -1,
          });
          this.$root.emitInternal(`db:${Model.objectName}`, {
            action: "update",
            id: instance.id,
            data: instance.data,
            updated,
            created,
          });
        }
      }
    },
    async setupListeners() {
      this.dbUpdateListener = async (packet) => {
        if (packet.client === localStorage.clientid) {
          // console.info('removing packet from self', localStorage.clientid);
          return;
        }

        const Model = this.syncModels.find((m) => m.objectName === packet.model);
        if (Model) {
          const data = packet.url
            ? {
                id: packet.id,
                content: packet.url,
                name: packet.name,
                type: packet.content_type,
              }
            : JSON.parse(packet.content);
          let instance = await Model.load(packet.id);
          let created = false;
          let updated = false;
          this.toasterStore.add({
            channel: "update",
            title: "Update",
            message: `Syncing ${Model.name} (${instance})`,
            className: "mini",
            timeout: -1,
          });

          if (instance) {
            if (!data.id) {
              data.id = packet.id;
            }
            if (!data.updated) {
              data.updated = new Date().toISOString();
            }
            console.log("syncing", data, instance);
            const saved = await instance.sync(data, true);
            if (saved) {
              // fix missing id
              if (!instance.id) {
                instance.data.id = packet.id;
              }
              await instance.save();
              // console.log('synced saved', saved, instance);
              updated = true;
            }
          } else {
            instance = new Model(data);
            created = true;
          }
          // console.log('db update', packet, data, instance);

          this.$root.emitInternal(`db:${packet.model}`, {
            action: "update",
            id: packet.id,
            data,
            created,
            updated,
          });
          await instance.save();

          this.toasterStore.add({
            channel: "update",
            title: "Update",
            message: `Synced ${Model.name} (${instance})`,
            className: "mini",
            timeout: 1000,
          });
        } else {
          console.log("no model", packet);
        }
      };
      await this.websocketStore.socket.on("db_update", this.dbUpdateListener);

      this.dbDeleteListener = (packet) => {
        console.log("db delete", packet);
        const Model = this.syncModels.find((m) => m.objectName === packet.model);
        if (Model) {
          Model.objects.delete(packet.id);
          this.$stories.removeItem(packet.id);
          this.$root.emitInternal(`db:${packet.model}`, {
            action: "delete",
            id: packet.id,
          });
        } else {
          console.log("no model", packet);
        }
      };
      await this.websocketStore.socket.on("db_delete", this.dbDeleteListener);
    },
  },
};
