import Character from "@/utils/character";
import Reader from "@/utils/reader";
import { nanoid } from "nanoid";
import { defineStore } from "pinia";
import { Ref, computed, ref, shallowRef, watch } from "vue";

export interface Contestant {
  character: string;
  team: string;
}

export const useReaderStore = defineStore("reader", () => {
  const reader: Ref<Reader | null> = shallowRef(null);
  const characters: Ref<{ [key: string]: Character }> = ref({});
  const contestants: Ref<Contestant[]> = ref([]);

  const story = computed(() => {
    return reader.value?.story;
  });
  const combatants = computed(() => {
    console.log("getting combatants", reader.value, story.value?.id, contestants.value.length);
    const retval = [];
    if (contestants.value && story.value) {
      for (const value of contestants.value) {
        const char = value.character;
        if (!characters.value[char]) {
          const sheet = story.value.characters.find((c) => `${c.id}` === `${char}`);
          if (sheet) {
            characters.value[char] = new Character(sheet);

            const tagged = story.value.tags.find(
              (t) => t.type === "character" && t.character === sheet.id,
            );
            if (tagged) {
              characters.value[char].setValues(this.tags[tagged.id]);
            }

            characters.value[char].id = nanoid();
            characters.value[char].team = value.team;
          } else {
            console.error("this character has no sheet", char, story.value.characters);
          }
        }
        console.log("character", char, characters.value);
        if (characters.value[char]) {
          if (value.reset) {
            characters.value[char].reset();
          } else {
            characters.value[char].modifiers = [];
          }
          retval.push(characters.value[char]);
        }
      }
    } else {
      console.log("no story");
    }
    return retval;
  });

  watch(story, function () {
    contestants.value = [];
    characters.value = {};
  });

  return {
    reader,
    story,
    contestants,
    combatants,
  };
});
