import Model from "./base";

function countWords(str) {
  if (str) {
    const arr = str.toString().split(/[ -]/);
    return arr.filter((word) => word !== "").length;
  }
  return 0;
}

export default class Scene extends Model {
  static get objectName() {
    return "scene";
  }

  static get defaultObject() {
    return {
      id: null,
      title: null,
      directions: [],
      start: false,
      microcosm: false,
      act: false,
      color: null,
      updated: new Date().toISOString(),
      story: null,
      wc: 0,
    };
  }

  getLabel(width = 50) {
    const d = this.directions.find((d) => d.type === "text");
    return ((d ? d.content : null) || `(Scene ${this.id})`)
      .slice(0, width)
      .replace("\n", " ")
      .trim();
  }

  async deleteCheck() {
    const Story = (await import("./story")).default;
    if (this.story) {
      const story = await Story.load(this.story);
      if (story && story.scenes.indexOf(this.id) > -1) {
        return [`Scene used by story ${story}`];
      }
    } else {
      for (const story of await Story.objects.list()) {
        if (story.scenes.indexOf(this.id) > -1) {
          this.story = story.id;
          await this.save();
          if (story.cloudSync) {
            await this.push();
          }
          return [`Scene used by story ${story}`];
        }
      }
    }

    return [];
  }

  async save() {
    await this.countWords();
    return super.save();
  }

  async countWords() {
    let wc = 10; // for the scene itself

    // if (this.start) {
    //   const Story = (await import('./story')).default;
    //   if (this.story) {
    //     const story = await Story.load(this.story);
    //     if (story) {
    //       for (const char of (story.characters || [])) {
    //         wc += 10 * char.emotes.length; // for the char itself
    //         wc += 1000 * char.emotes.filter(e => e.blob_id).length;
    //         wc += countWords(char.name);
    //         wc += countWords(char.bio);
    //       }
    //       for (const object of story.styles) {
    //         wc += 10; // for the object
    //       }
    //       for (const key of ['images', 'backgrounds', 'audio', 'music']) {
    //         for (const object of (story[key] || [])) {
    //           wc += 1000; // for the art. a picture is worth a 1000 words
    //         }
    //       }
    //       for (const object of story.tags) {
    //         wc += countWords(object.name);
    //       }
    //     }
    //   }
    // }

    wc += countWords(this.title);
    for (const d of this.directions) {
      // wc += 1; // for the direction itself
      wc += countWords(d.content);
      wc += countWords(d.label);
      // wc += countWords(d.scene); // for the link if any
      // if (d.character) {
      //   wc += 5;
      // }
      // if (d.effects) {
      //   for (const effect of d.effects) {
      //     wc += 20; // for the effect itself
      //     wc += countWords(effect.value) * 2;
      //   }
      // }
    }
    if (this.wc != wc) {
      this.wc = wc;
      return true;
    }
    return false;
  }

  toString() {
    return this.title || this.getLabel();
  }
}
