
export function cancelableTimer(func, timeout) {
  const object = {};
  const promise = new Promise((resolve) => {
    object.resolve = (value = true) => {
      clearTimeout(object.timer);
      resolve(func(value));
    };
  });
  object.promise = promise;
  object.timer = setTimeout(object.resolve, timeout);
  return object;
}


export default class Campfire {
  static register(signal, parties, timeout=null) {
    if (!Campfire.signals[signal]) {
      Campfire.signals[signal] = new Campfire(signal, parties, timeout);
    }
    return Campfire.signals[signal];
  }

  constructor(signal, needs, timeout=null) {
    this.signal = signal;
    this.needs = needs;
    this.timeout = timeout;
    this.arrived = new Set();
    this.gifts = {};
    this.timer = null;
  }

  arrival(party, gift=true) {
    this.arrived.add(party);
    this.gifts[party] = gift;
    // console.log(` >> ${this.signal} ^^ ${party} arrived, waiting for ${this.needs.filter(n => !this.arrived.has(n))}`);
    if (this.needs.filter(n => !this.arrived.has(n)).length === 0) {
      return this.cleanup();
    }
    if (this.timeout) {
      // console.log('employing fallback');
      if (this.timer) {
        this.timer.resolve(false);
      }
      this.timer = cancelableTimer(() => {
        return this.cleanup();
      }, this.timeout);
      return this.timer.promise;
    }
    return false;
  }

  cleanup() {
    delete Campfire.signals[this.signal];
    return this.gifts;
  }
};

Campfire.signals = {};
