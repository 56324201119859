<template>
  <div class="battle">
      <help-message name="audio-clips-explanation">
        {{ $t('battle_content.battles-enter-a-different-reading-mode-where-the-player-can-battle-against-other-characters-place-battle-participants-above-in-contestant-directions') }}
      </help-message>
    <!-- <div class="teams">
      <div
        class="team"
        v-for="team of teams"
        :key="team.name"
      >
        <b>{{ team.name }}</b>
      </div>
    </div> -->
    <div class="rounds">
      <label>
        {{ $t('battle_content.stop-battle-after') }}
        <input
          :value="value.rounds"
          placeholder="∞"
          type="number"
          step="1"
          min="0"
          @input="$emit('update', {'rounds': $event.target.value})"
        >
        {{ $t('battle_content.rounds') }}
      </label>
      <label>
        {{ $t('battle_content.save-result-to') }}
        <tag-picker
          :value="value.result"
          :story="story"
          @update:value="$emit('update', {result: $event})"
        />
      </label>
    </div>
  </div>
</template>

<script>
import TagPicker from "../editor/tag_picker.vue";

export default {
  name: 'text-direction',
  components: {
    TagPicker,
  },
  props: {
    story: Object,
    value: Object,
  },
  data() {
    return {
      teams: [
        {
          name: this.$t('battle_content.protagonists'),
          key: 'protagonists',
        },
        {
          name: this.$t('battle_content.antagonists'),
          key: 'antagonists',
        },
      ],
    };
  },
  computed: {
  },
  watch: {
    character() {
    },
  },
  methods: {
  }
};
</script>

<style scoped>
.teams {
  display: grid;
  grid-template-columns: auto;
}
input {
  text-align: center;
  width: 3em;
}
</style>
