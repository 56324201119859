<template>
  <div
    class="battle"
    v-if="protagonists.length > 0 && antagonists.length > 0"
  >
    <!-- <div>Step: {{ step }} | {{ character ? character.sheet.attributes : '-' }}</div> -->
    <div v-if="step === 'action'" class="choose-action">
      <div v-if="character" class="directions flex row" :style="textStyle(defaultStyle)">
        <span class="stretch">{{ character ? character.sheet.name : '...' }}</span>
        <span
          v-for="attr of character.sheet.attributes.filter(a => a.display)"
          :key="attr.id"
          class="attr"
        >
          {{ character.value(attr.abbrev) }}
          {{ attr.abbrev }}
        </span>
      </div>
      <transition-group
        v-if="character"
        name="rotate-slide"
        tag="div"
        appear
      >
        <div
          class="action-container"
          v-for="(action, idx) of character.sheet.actions"
          :key="action.id"
          :style="{'transition-delay': `${idx * 100}ms`}"
          @click="disabled[action.name] ? null : waiting(action.name)"
        >
          <div
            class="action"
            :class="{ disabled: disabled[action.name] }"
            :style="textStyle(choiceStyle)"
          >
            <div class="static">{{ action.name }}</div>
            <div class="flex column">
              <div
                v-if="action.cost > 0 && action.costAttr"
                class="cost-marker row right"
              >
                <span>{{ action.cost }} {{ action.costAttr }}</span>
              </div>
              <div class="reason" v-if="disabled[action.name]">
                {{ disabled[action.name] }}
              </div>
              <div class="description" v-else-if="action.description">
                {{ action.description }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="action-container"
          v-if="Object.values(disabled).filter(a => a).length === character.sheet.actions.length"
          key="$NONE"
          :style="{'transition-delay': `${character.sheet.actions.length * 100}ms`}"
          @click="waiting()"
        >
          <div
            class="action"
            :style="textStyle(choiceStyle)"
          >
            {{ $t('battle.do-nothing') }}
          </div>
        </div>

      </transition-group>
      <div v-else>Loading...</div>
    </div>
    <div v-else-if="step === 'targets'" class="choose-targets">
      <div class="directions" :style="textStyle(defaultStyle)">
        <typer text="Choose your targets:" />
      </div>
      <transition-group
        v-if="character"
        name="rotate-slide"
        appear
        tag="div"
      >
        <div
          class="action"
          v-for="(target, idx) of targets"
          :key="target.id"
          :style="{'transition-delay': `${idx * 100}ms`}"
          @click="waiting(target.id)"
        >
          <div class="row left" :style="textStyle(choiceStyle)">
            <div>{{ target.sheet.name }}</div>
            <i v-if="!target.alive()">{{ $t('battle.dead') }}</i>
          </div>
        </div>
        <div key="$cancel" class="action" @click="cancel()">
          <div class="row left" :style="textStyle(choiceStyle)">
            <i class="fa fa-arrow-left" /> {{ $t('battle.change-action') }}
          </div>
        </div>
      </transition-group>
      <div v-else>Loading...</div>
    </div>
    <div v-else class="show-results">
      <battle-results
        v-if="character"
        :key="character.id"
        :story="story"
        :character="character"
        :contestants="contestants"
        @character-image="$emit('character-image', $event)"
        @click="waiting()"
      />
      <!-- todo: process events in a different step -->
    </div>
  </div>
  <template v-else-if="started">
    <!-- leaving this empty, the battle is over -->
  </template>
  <div v-else class="battle broken accented">
    <div v-if="antagonists.length === 0 && protagonists.length === 0">
      {{ $t('battle.there-was-supposed-to-be-a-battle-here-but-it-looks-like-no-contestants-showed-up') }}
    </div>
    <div v-else>
      {{ $t('battle.there-was-supposed-to-be-a-battle-here-but-only-one-side-showed-up') }}
    </div>
    <button @click.prevent="$emit('finished', 0)">{{ $t('battle.proceed') }}</button>
  </div>
</template>

<script>
import { plot, round } from '../utils/battle';
import { textStyle } from '../utils/tags';
import BattleResults from './battle_results.vue';

export default {
  components: {
    BattleResults,
  },
  props: {
    story: { type: Object, required: true },
    contestants: { type: Array, required: true },
    rounds: { default: null },
  },
  data() {
    return {
      character: null,
      disabled: {},
      targets: [],
      step: 'choose',
      started: false,
      waiting: null,
    };
  },
  computed: {
    roundsNum() { return this.rounds ? parseInt(this.rounds, 10) : 0; },
    protagonists() { return this.contestants.filter(c => c.team.toLowerCase() === 'protagonist'); },
    antagonists() { return this.contestants.filter(c => c.team.toLowerCase() !== 'protagonist'); },
    defaultStyle() { return this.story.styles.find(s => s.default); },
    choiceStyle() { return this.story.styles.find(s => s.default_choice); },
  },
  mounted() {
    if (this.protagonists.length > 0 && this.antagonists.length > 0) {
      this.startBattle();
    }
  },
  methods: {
    waitForInput() {
      return new Promise((resolve, reject) => {
        this.waiting = resolve;
        this.cancel = reject;
      }).then(r => {
        this.cancel = null;
        this.waiting = null;
        return r;
      });
    },
    async chooseActions() {
      this.step = 'action';
      for (const char of this.protagonists) {
        this.character = char;
        char.action = null;
        while (char.action === null) {
          try {
            this.step = 'action';

            // check for available actions
            let possibleCount = 0;
            for (const action of char.sheet.actions) {
              const possible = char.canPerform(action.name);
              if (possible.possible) {
                possibleCount += 1;
              }
              this.disabled[action.name] = possible.possible ? false : possible.reason;
            }

            const image = char.sheet.emotes.find(e => e.name === 'default');
            if (image) {
              this.$emit('character-image', {image: image, aura: image.aura, position: 'left'});
            }

            if (possibleCount === 0) {
              await this.waitForInput();
              char.action = false;
            } else {
              // eslint-disable-next-line no-await-in-loop
              const name = await this.waitForInput();
              char.action = {
                name,
                // eslint-disable-next-line no-await-in-loop
                targets: await this.chooseTargets(char, name),
              };
            }
          } catch (ex) {
            console.log('cancel', ex);
          }
        }
        console.log('action', char.sheet.name, char.action.targets);
      }
      this.character = null;
      console.log('all actions have been selected');
    },
    async chooseTargets(char, action) {
      this.step = 'targets';

      const object = char.sheet.actions.find(a => a.name === action);
      const targets = [];
      const targetQty = object.targetQuantity || 1;
      for (let x = 0; x < targetQty; x += 1) {
        if (object.targets === 'friendly') {
          this.targets = this.contestants.filter(c => c.team === char.team);
        } else if (action.targets === 'any' || action.targets === 'random') {
          this.targets = this.contestants;
        } else {
          this.targets = this.contestants.filter(c => c.team !== char.team);
        }

        // choose only target if there is only one
        if (targetQty == 1 && this.targets.length === 1) {
          targets.push(this.targets[0].id);
        } else {
          // eslint-disable-next-line no-await-in-loop
          const target = await this.waitForInput();
          targets.push(target);
        }
      }
      return targets;
    },
    async showResults(results) {
      this.step = 'results';
      for (const char of results) {
        this.character = char;

        const image = char.sheet.emotes.find(e => e.name === 'default');
        if (image) {
          this.$emit('character-image', {image: image, aura: image.aura, position: char.team === 'protagonist' ? 'left' : 'right'});
        }

        // eslint-disable-next-line no-await-in-loop
        await this.waitForInput();
      }
      this.character = null;
    },
    async startBattle() {
      this.started = true;

      // choose ai options
      for (const c of this.contestants) {
        c.action_order = c.sheet.actions.map(a => ({ name: a.name, probability: a.aiProbability || 1 }));
      }

      let rounds = 0;
      while (!this.roundsNum || rounds < this.roundsNum) {
        plot(this.contestants);

        // eslint-disable-next-line no-await-in-loop
        await this.chooseActions();

        const results = round(this.contestants);

        // eslint-disable-next-line no-await-in-loop
        await this.showResults(results.results);
        if (results.finished) {
          break;
        }
        rounds += 1;
      }

      const pAlive = this.protagonists.some(p => p.alive());
      const aAlive = this.antagonists.some(a => a.alive());
      let winner = 0;
      if (pAlive && !aAlive) {
        winner = 1;
      } else if (aAlive && !pAlive) {
        winner = -1;
      }
      this.$emit('finished', winner);
    },
    playerName(target) {
      return this.contestants.find(f => f.id === target).sheet.name;
    },
    textStyle,
  },
};
</script>

<style scoped>
.battle {
  height: 100%;
  color: rgb(0, 0, 0);
  white-space: normal;
}
.battle .directions {
  padding: 15px 10px;
  margin: 5px;
  background: rgba(255, 255, 255, 0.8);
  text-shadow: rgb(255, 255, 255) 0px 0px 5px;
  color: rgb(0, 0, 0);
  border-radius: 5px;
  flex: 0 0 auto;
}
.action {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  margin: 5px;
  margin-top: 0;
  padding: 10px;
  background: rgba(255, 255, 255, 0.8);
  color: black;
  border-radius: 5px;
  text-align: center;
  flex: 1 1 auto;
  display: flex;
}
.action.disabled {
  opacity: 0.5;
}
.action .reason {
  opacity: 0.9;
}
.action .description,
.action .reason {
  opacity: 0.8;
  font-size: 80%;
}
.cost-marker {
  border-radius: 7px;
  padding: 4px;
  font-size: 75%;
  color: black;
  background: white;
  border: 1px solid grey;
  flex: 0 0 1px;
  place-self: flex-end;
}
.battle.broken {
  margin: 10px;
  padding: 10px;
}
.battle.broken div {
  margin-bottom: 5px;
}
.attr {
  background: var(--color2);
  color: var(--color2_text);
  text-shadow: none;
  padding: 5px;
  font-size: 90%;
  margin-left: 5px;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
</style>
