<template>
  <InputText v-model="value" :invalid="!!errors" />
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext";
import { useField } from "vee-validate";

const props = defineProps<{ name: string }>();

const { value, errors } = useField<string>(props.name);
</script>
