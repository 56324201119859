<template>
  <div class="page">
    <div class="no-scroll">
      <app-header :show-menu="false">
        <back-button
          :back="
            props.action === 'edit'
              ? { name: 'backgrounds', params: { story: props.story.id, id: props.id }}
              : { name: 'story', params: { story: props.story.id }}
          "
        />
        {{ story.title }}: {{ $t("story.backgrounds") }}
      </app-header>
      <div class="scroll responsive-max">
        <BackgroundList
          :story="props.story"
          :action="props.action"
          :id="props.id"
          :size="250"
          @update:id="updateRoute(router, { id: $event })"
          @update:action="updateRoute(router, { action: $event })"
          @update:story="doSave"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useHead } from "@unhead/vue";
import { useRouter } from "vue-router";

import BackgroundList from "@/components/editor/storyObjects/lists/BackgroundList.vue";
import { updateRoute, doSave } from "@/components/editor/storyObjects/utils";
import Story from "@/models/story.js";

useHead({ title: "backgrounds" });
const router = useRouter();
const props = defineProps({
  story: { type: Story, required: true },
  id: { type: String, default: null },
  action: { type: String, default: null },
});
</script>
