<template>
  <popover
    id="formula-popup"
    ref="popup"
    :name="name"
    color-theme="mellow"
    :dialog-class="{wide: lines > 1}"
  >
    <!-- @input="$emit('update:value', $event)" -->
    <!-- :target="target" -->
    <div class="left relative" @keydown.capture="handleKeydown">
      <form class="padded fixed">
        <div class="column">
          <div class="text-header flex-row flex-space-between">
            <typer :text="$t('formula_popup.type-your-formula')" />
            <div class="flex-stretch" />
            <div class="flex-row flex-static">
              <button @click.prevent="$refs.popup.hide()" tabindex="0" type="button">
                <i class="fa fa-close" />
              </button>
            </div>
          </div>
        </div>
        <div v-if="error" class="padded">
          <div class="error">{{ error }}</div>
        </div>
        <autogrow-textarea
          ref="formula"
          class="formula-input"
          rows="1"
          v-model:modelValue="formula"
          tabindex="0"
          @select="handleSelection"
          @click="handleSelection"
          @keydown="handleSelection"
        />
      </form>
      <div v-if="match" class="result row space-between">
        <div class="block">
          <b>{{ match.name }}</b>
          <span>({{ match.format || 'Numeric' }})</span>
        </div>
        <router-link class="label label-primary" :to="{name: 'tags', params: {story: story.id, id: match.id}}">
          <i class="fa fa-pencil" /> edit
        </router-link>
      </div>
      <div class="search left top" v-else-if="results">
        <div class="result" v-for="result of results" :key="result.item.id">
          <div class="row">
            <a
              class="tag-link title stretch"
              @click.prevent="insertTag(result.item.name)"
            >
              {{ result.item.name }}
            </a>
          </div>
        </div>
      </div>
      <div class="missing" v-if="missingVariables.length > 0">
        <div class="result block" v-for="name of missingVariables.filter(n => n.length > 2)" :key="name">
          <i class="fa fa-exclamation-triangle" /> {{ name }}
          <span class="label label-primary" @click="createTag(name)">
            <i class="fa fa-plus-circle" /> create
          </span>
        </div>
      </div>
    </div>
  </popover>
</template>

<script>
import { Parser } from 'expr-eval';
import Fuse from 'fuse.js'

const options = {
  keys: ['name'],
  includeMatches: false,
  findAllMatches: false,
}

export default {
  name: 'FormulaPopup',
  props: {
    name: String,
    value: String,
    target: String,
    story: Object,
    context: Object,
    promise: Object,
  },
  data() {
    return {
      status: null,
      formula: this.value || '',
      pending: null,
      expression: null,
      results: null,
      variables: [],
      typing: null,
      error: null,
    };
  },
  computed: {
    lines() {
      return (this.formula || '').split('\n').length;
    },
    missingVariables() {
      const retval = this.variables
        .filter(v => this.context && !this.context[v])
        .filter(v => !this.story.tags.find(t => t.name == v))
        .filter(v => v[0] !== '$');
      return retval;
    },
    match() {
      return this.story.tags.filter(t => !t.type || t.type === 'tag').find(t => t.name === this.typing);
    },
  },
  watch: {
    formula() {
      this.parseFormula();
    },
    typing() {
      if (this.fuse && this.typing) {
        this.results = this.fuse.search(this.typing);
      } else {
        this.results = [];
      }
    },
  },
  async mounted() {
    if (this.formula) {
      this.parseFormula();
    }
    this.status = null;
    this.loadFuse();

    await this.$nextTick();
    this.$refs.formula.focus();
  },
  methods: {
    async handleSelection(e) {
      await new Promise(r => requestAnimationFrame(r));
      if (this.formula) {
        const { start, end } = this.getSelection();
        this.typing = this.formula.slice(start, end).trim();
      }
    },
    async createTag(rawName) {
      if (rawName && !this.error) {
        const name = rawName.trim();
        if (/^\$/.test(name)) {
          this.error = this.$t('formula_popup.tags-are-not-allowed-to-start-with');
        } else if (this.story.tags.find(t => t.name === name)) {
          this.error = this.$t('tags.duplicate-tag-warning', { name });
        } else {
          const id = this.$root.id();
          this.story.tags.push({ name, id, format: '', type: "tag" });
          // this.$emit("update:value", id);
          await this.story.saveAndPush();
        }
      }
    },
    insertTag(tag) {
      const {start, end} = this.getSelection();
      this.formula = this.formula.slice(0, start) + tag + this.formula.slice(end);
      this.typing = tag;
    },
    parseFormula() {
      this.error = null;
      if (this.formula) {
        const parser = new Parser();
        try {
          this.expression = parser.parse(this.formula);
        } catch (ex) {
          this.error = this.$t('formula_popup.something-is-wrong-with-your-formula-ex', { error: ex });
        }
        if (this.expression) {
          this.variables = this.expression.variables();
        } else {
          this.variables = [];
        }
        if (!this.error) {
          this.$emit("update:value", this.formula);
        }
      }
    },
    handleCreate() {
      this.status = 'creating...';
      this.$emit('create', this.typing);
    },
    loadFuse() {
      this.fuse = new Fuse(this.story.tags.filter(t => t.type === 'tag'), options);
      if (this.typing) {
        this.results = this.fuse.search(this.typing);
      } else {
        this.results = [];
      }
    },
    getSelection() {
      if (this.formula && this.$refs.formula) {
        let start = this.$refs.formula.getSelectionStart();
        let char = this.formula[start];
        if (/\s/.test(char)) {
          start -= 1;
          char = this.formula[start];
        }
        let end = start;
        while (start > 0 && !/\s/.test(char)) {
          start -= 1;
          char = this.formula[start];
        }
        if (/\s/.test(char)) {
          start += 1;
        }
        char = this.formula[end];
        while (end < this.formula.length && !/\s/.test(char)) {
          end += 1;
          char = this.formula[end];
        }
        return {start, end};
      }
      return {start: 0, end: 0};
    },
    async handleCancel() {
      this.$emit('show', false);
    },
    handleKeydown(e) {
      if (e.key === 'Escape') {
        this.$emit('show', false);
      }
    },
    show() {
      this.$refs.popup.show();
    },
  },
}
</script>

<style scoped>
a {
  padding: 0;
}

.search {
  max-width: 100%;
}
.result {
  margin: 0;
  padding: 6px;
}
.result a {
  padding: 2px;
  font-weight: bold;
}
.top {
  align-self: flex-start;
}
.label {
  background: var(--color2);
  color: var(--color2_text);
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
}
.title, .content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.icon {
  flex: 0 0 auto;
  padding: 5px;
  margin-right: 9px;
  font-size: 21px;
}
.action {
  padding: 5px;
  opacity: 0.8;
}
.missing .block {
  padding: 6px;
}
.text-header {
  margin-bottom: 5px;
}
</style>
