<style scoped>
  .feedback-view, .feelings {
    text-align: right;
    display: block;
    flex: 0 0 auto;
    width: 100%;
    max-width: 400px;
  }
  .problems, .review {
    padding: 10px;
    text-align: left;
    display: flex;
    flex-direction: column;
  }
  .md-icon-button {
    margin-left: 25px;
  }
  .feedback-input {
    flex: 1 1 auto;
    width: 100%;
  }
  .centered {
    text-align: center;
  }
  button {
    margin: 5px;
    display: inline-block;
    padding: 5px;
    font-size: 20px;
    flex: 0 0 auto;
  }
  textarea {
    border-radius: 12px;
    border: 3px solid var(--spark);
    padding: 8px;
    margin-bottom: 3px;
  }
  .quiz {
    text-align: center;
  }
  .quiz p {
    font-size: 18px;
  }
  .quiz svg {
    width: 40px;
    height: 40px;
  }
  .quiz-list li {
    font-size: 20px;
    padding: 15px;
    border-bottom: none;
  }
  #app .thanks {
    border: none;
    justify-content: space-around;
  }
  .thanks a {
    background: var(--spark);
    color: var(--spark_text);
    border: 2px solid var(--color3);
    border-radius: 5px;
    padding: 5px;
  }
</style>

<template>
  <div class="feedback-view">
    <div class="accented thanks" v-if="tab == 'rate'">
      <p class="left">
        {{$t('feedback.review-plea')}}
      </p>
      <div class="right">
        <a @click="feedbackGiven = true; tab = null;"
            :href="$root.reviewUrl"
            target="_system"
            class="centered">
          <i class="fa fa-thumbs-o-up" /> {{$t('feedback.rate-this-app')}}
        </a>
      </div>
      <p class="left">
        {{$t('feedback.donate-plea')}}
      </p>
      <div class="right">
        <router-link class="static centered" :to="{name: 'settings', params: {tab: 'About'}}">
          <i class="fa fa-star" /> {{$t('feedback.support-us')}}
        </router-link>
      </div>
      <br>
      <a class="centered" href="#" @click="feedbackGiven = true; tab = null;">
        {{$t('feedback.nope-im-already-awesome')}}
      </a>
    </div>
    <div class="quiz" v-else-if="tab == 'show'">
      <div class="flex row">
        <div>
          <p>
            {{ $t('feedback.discord-pitch') }}
          </p>

          <a href="https://discord.gg/Daz4jwp" target="_system" class="flex center">
            <vector-image name="discord" />
            <span class="padded">{{$t('morphosis-games-discord')}}</span>
            <img src="../assets/images/jackson.png" width="40">
          </a>
          <hr>

        </div>

        <div @click.prevent="tab = null" class="padded">&times;</div>
      </div>
      <ul class="quiz-list">
        <li v-for="suggestion in suggestions" :key="suggestion.text"
            @click="tab = 'comment'; quizSuggestion = suggestion.text">
          {{ suggestion.text }}
        </li>
      </ul>
    </div>
    <div class="quiz-feedback" v-else-if="tab == 'comment'">
      <p class="error" v-if="error">{{ error }}</p>
      <textarea v-model="quizSuggestion" rows="3" />
      <input name="email" type="email" :placeholder="$t('feedback.contact-email-optional')" v-model="email" />
      <div class="row">
        <button @click="submitFeedback(quizSuggestion)">{{ $t('feedback.submit') }}</button>
        <a href="#" @click.prevent="tab = null">{{ $t('import_backup.cancel') }}</a>
      </div>
    </div>
    <div class="row" v-else-if="tab == 'success'">
      <a href="#" @click.prevent="tab = 'show'" class="center stretch">
        {{$t('feedback.thanks-for-your-feedback')}}
      </a>
      <a class="md-icon-button static" @click="toggleTab('rate')">😊</a>
    </div>
    <div class="row centered" v-else>
      <a href="https://discord.gg/Daz4jwp" target="_system">
        <vector-image name="discord" />
      </a>
      <a href="#" @click.prevent="tab = 'show'" class="center stretch">
        <img :src="images.Feedback">
      </a>
      <a class="md-icon-button static" @click="toggleTab('rate')">
        <img :src="images.Happy">
      </a>
    </div>
  </div>
</template>

<script>
import Discord from '../assets/images/discord.svg';
import Feedback from '../assets/images/feedback.svg';
import Happy from '../assets/images/happy.svg';

export default {
  name: 'feedback',
  data() {
    return {
      images: { Discord, Feedback, Happy },
      tab: null,
      feedback: '',
      email: '',
      error: null,
      quizSuggestion: null,
      suggestions: [
        { text: this.$t('feedback.i-have-a-feature-suggestion') },
        { text: this.$t('feedback.i-found-a-bug') },
      ],
    };
  },
  methods: {
    toggleTab(tab) {
      if (this.tab === tab) {
        this.tab = null;
      } else {
        this.tab = tab;
      }
    },
    async submitFeedback(feedback) {
      this.error = null;
      feedback = feedback.trim();
      if (feedback) {
        feedback = `
          user: ${this.$root.user.username} -- ${this.$root.user.id}<br>
          email: ${this.email}<br>
          feedback: ${feedback}<br>
          version: ${this.$root.version}<br>
          platform: ${this.$root.platform}`;
        this.tab = 'success';
        await this.http('support_message', { subject: `Message: ${this.tab ? this.tab : 'Quiz'}`, message: feedback });
      } else {
        this.error = this.$t('feedback.please-type-something-before-sending');
      }
    },
  },
};
</script>
