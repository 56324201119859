<template>
  <DraggableList
    class="list-cont"
    :class="{empty: value.length === 0}"
    draggable=".list-item"
    :value="value"
    :handle="'.handle'"
    @update:value="$emit('update:value', $event)"
  >
    <transition-group name="fade" tag="div" appear class="list flex-static">
      <div
        class="list-item flex-row flex-static"
        v-for="(object, idx) of value"
        :key="object.id"
        :style="{'transition-delay': `${idx * 50}ms`}"
      >
        <div class="list-item-object">
          <slot
            :object="object"
            :index="idx"
            :rindex="value.length - idx + 1"
          >
            {{ object }}
          </slot>
        </div>
        <a v-if="!noRemove" class="flex-static delete" href="#" @click.prevent="removeObject(object)">
          &times;
        </a>
      </div>
    </transition-group>
    <div class="empty" v-if="value.length === 0" key='empty'>
      <slot name="empty"></slot>
    </div>
    <div class="flex-stretch" />
    <div class="footer flex-static" key="footer">
      <slot name="footer"></slot>
    </div>
  </DraggableList>
</template>

<script>
import DraggableList from './DraggableList.vue';

export default {
  name: 'list',
  props: {
    value: Array,
    noDragging: { type: Boolean, default: true },
    noRemove: { type: Boolean, default: false },
  },
  components: {
    DraggableList,
  },
  methods: {
    removeObject(object) {
      this.$emit('remove', object);
    },
  },
};
</script>

<style scoped>
.list-cont {
  margin: 0 var(--padding);
}
.list-item {
  flex: 1 1 auto;
}
.mellow .list-item {
  border-top: 1px solid var(--color1_text);
}
.accented .list-item {
  border-top: 1px solid var(--color2_text);
}
.list-item:first-child {
  border-top: none;
}
</style>
