<template>
  <div class="choice-content">
    <div class="flex-row flex-align-center">
      <div v-if="isUnderExploreBackground" class="flex-static">
        <button
          @click="showExplorer = true"
          :class="{
            accented: glyphs.length > 0,
            padded: glyphs.length > 0,
          }"
          class="flex-row flex-align-center"
        >
          <span v-if="glyphs.length > 0">{{ glyphs.length }} </span>
          <vi name="selectdrag" />
        </button>
        <popover name="choiceExplorer" v-model:value="showExplorer" @show="showExplorer = $event">
          <div class="bg-selector flex-column">
            <div class="flex-row flex-static flex-align-center">
              <vi name="selectdrag" @click="selectmode = 'dot'" class="button icon" />
              <!-- <div>
                ** {{ selectedId }} ** {{ selectmode }} **
              </div> -->
              <div class="flex-row flex-align-center scroll-x">
                <div
                  v-for="glyph of glyphs" :key="glyph.id"
                  class="flex-row flex-static card accented glyph-card"
                  :class="{selected: selectedId === glyph.id}"
                  @click="selectedId = selectedId === glyph.id ? null : glyph.id; handleMoveToSelected()"
                >
                  {{ glyph.type }}
                  {{ glyph.size.toFixed(1) }}
                </div>
                <button v-if="glyphs.length > 0" @click="glyphs = []" class="button">
                  Delete All &times;
                </button>
              </div>
            </div>
            <div class="card glyph-form accented flex-row flex-wrap flex-align-center flex-static" v-if="glyph">
              <div class="flex-static">
                #{{ glyph.id }}
              </div>
              <div>
                <label>zoom:</label>
                <input
                  type="number"
                  :value="glyph.size"
                  @input="updateGlyph({size: parseFloat($event.target.value)})"
                  size="3" min="0.5" max="100" step="0.1"
                >
              </div>
              <div>
                <label>hidden:</label>
                <input
                  type="checkbox"
                  :checked="glyph.hidden"
                  @change="updateGlyph({hidden: $event.target.checked})"
                >
              </div>
              <div>
                <button @click.prevent="handleDelete()" class="button icon">
                  <i class="fa fa-trash" />
                </button>
              </div>
            </div>
            <div
              @pointerup.prevent="handleExplore"
              @pointerdown.prevent="handleExplore"
              @pointermove.prevent="handleExplore"
              @pointercancel.prevent="handleExplore"
              @wheel.prevent="handleExplore"
              @click="handleClick"
              class="bg"
              ref="bg"
            >
              <blob-image
                v-if="explore"
                :style="{
                  width: `${explore.width * explore.scale}px`,
                  height: `${explore.height * explore.scale}px`,
                  transform: `translate(${explore.x}px, ${explore.y}px)`
                }"
                :blob="exploreBackground.blob_id"
                class="flex-stretch"
              />
              <template v-if="explore">
                <div
                  v-for="glyph of glyphs"
                  @click.prevent="selectedId = glyph.id"
                  :key="glyph.id"
                  class="glyph-dot"
                  :class="{selected: glyph.id === selectedId }"
                  :style="{
                    transform: `
                      translate(${glyph.x * explore.scale + explore.x}px,
                                ${glyph.y * explore.scale + explore.y}px)
                      scale(${explore.scale / glyph.size})
                    `,
                  }"
                />
              </template>
            </div>
          </div>
        </popover>
      </div>
      <div class="flex-column">
        <div class="flex-row flex-align-center">
          <span v-if="prevBackground && glyphs.length > 0" class="padded">
            //
          </span>
          <autogrow-textarea
            v-if="!scene.act"
            :placeholder="$t('direction.type-choice-text-here')"
            :modelValue="value.label"
            :style-name="value.style"
            :story="story"
            :type="value.type"
            :no-speed="true"
            tabindex="0"
            @update:style-name="update({ style: $event })"
            @update:modelValue="update({ label: $event })"
            class="flex-stretch"
          />
        </div>
      </div>
    </div>
    <div v-if="isUnderExploreBackground && isFirstGlyph" class="flex-column">
      <div class="flex-row flex-align-center">
        <label>
          <i class="fa fa-compass" />
          Explore Prompt
        </label>
        <autogrow-textarea
          :placeholder="'Explore Background'"
          :modelValue="value.prompt"
          @update:modelValue="update({ prompt: $event })"
          class="flex-stretch"
        />
      </div>
    </div>
    <choice-line
      v-if="!value.array"
      :story="story"
      :scene="scene"
      :value="value"
      @change="update({ scene: $event })" />
  </div>
</template>

<script>
import ChoiceLine from '../choice_line.vue';
import InputArray from '../input_array.vue';
import TagPicker from '../editor/tag_picker.vue';
import Media from '../../models/media';
import Explore from '../../utils/explore';

export default {
  name: 'ChoiceContent',
  components: {
    TagPicker,
    ChoiceLine,
    InputArray,
  },
  props: {
    story: Object,
    scene: Object,
    value: Object,
  },
  data() {
    return {
      showChoiceExplorer: true,
      exploreMedia: null,
      explore: null,
      showExplorer: false,
      selectmode: null,
      selectedId: -1,
    };
  },
  computed: {
    glyphs: {
      get() { return this.value.glyphs || []; },
      set(v) { this.update({ glyphs: v }); },
    },
    glyph() {
      return this.value.glyphs?.find((g) => g.id === this.selectedId);
    },
    firstGlyph() {
      return this.scene.directions.find((d) => d.type === 'choice' && d.glyphs?.length > 0);
    },
    isFirstGlyph() {
      return this.firstGlyph?.id === this.value.id;
    },
    myIndex() {
      return this.scene.directions.findIndex((d) => d.id === this.value.id);
    },
    prevBackground() {
      return this.scene.directions.slice(0, this.myIndex).reverse().find((d) => d.type === 'background');
    },
    exploreBackground() {
      if (!this.prevBackground) {
        return null;
      }
      return this.story.backgrounds.find((b) => {
        return (b.id === this.prevBackground.content || b.id === this.prevBackground.blob_id);
      });
    },
    isUnderExploreBackground() {
      if (this.exploreBackground) {
        return this.prevBackground.size === 'explore';
      }
      return false;
    },
    hasAdvanced() {
      return true;
    },
  },
  watch: {
    async showExplorer() {
      this.createExplorer();
    },
    selectmode() {
      if (this.selectmode) {
        this.selectedId = -1;
      }
    },
  },
  async mounted() {
    for (const glyph of this.glyphs) {
      glyph.size = parseFloat(glyph.size);
    }
    // this.createExplorer();
  },
  methods: {
    updateGlyph(props) {
      const glyphs = this.copy(this.glyphs);
      const glyph = glyphs.find((g) => g.id === this.glyph.id);
      for (const prop in props) {
        glyph[prop] = props[prop];
      }
      this.glyphs = glyphs;
    },
    update(value) {
      this.$emit('update', value);
    },
    handleClick(event) {
      const el = event.target.closest('div');
      const box = el.getBoundingClientRect();
      if (this.selectmode === 'dot') {
        const glyphs = this.glyphs.slice();
        const id = glyphs.reduce((a, b) => Math.max(a, b.id), 0) + 1;
        glyphs.push({
          id,
          type: this.selectmode,
          x: (event.x - box.x - this.explore.x) / this.explore.scale,
          y: (event.y - box.y - this.explore.y) / this.explore.scale,
          size: this.explore.scale,
        });
        this.glyphs = glyphs;
        this.selectedId = id;
        this.selectmode = null;
      }
    },
    handleMoveToSelected() {
      if (this.glyph) {
        this.explore.scale = Math.min(100, Math.max(1, this.glyph.size));
        this.explore.x = this.explore.screen.x / 2 - this.glyph.x * this.explore.scale;
        this.explore.y = this.explore.screen.y / 2 - this.glyph.y * this.explore.scale;
      }
    },
    handleDelete() {
      const glyphs = this.glyphs.slice();
      const idx = this.glyphs.findIndex((g) => g.id === this.glyph.id);
      glyphs.splice(idx, 1);
      this.glyphs = glyphs;
    },
    async createExplorer() {
      if (this.exploreBackground && this.exploreBackground.blob_id) {
        this.exploreMedia = await Media.load(this.exploreBackground.blob_id);
      }

      if (this.exploreMedia) {
        await this.$nextTick();
        let box = this.$refs.bg.getBoundingClientRect();
        this.explore = new Explore({
          width: this.exploreMedia.width,
          height: this.exploreMedia.height,
        }, { x: box.width, y: box.height });
        this.explore.offset.x = box.x;
        this.explore.offset.y = box.y;
        // setting to a lower min causes
        // translation anomalies
        // this.explore.minScale = 1;
        this.explore.maxScale = 100;

        // wait a moment and adjust the box in case it was resizing
        // due to the image loading or something
        await this.$nextTick();
        box = this.$refs.bg.getBoundingClientRect();
        this.explore.changeContainer({ x: box.width, y: box.height })
      } else {
        this.explore = null;
      }
    },
    async handleExplore(e) {
      if (this.explore) {
        this.explore.handle(e);
      }
    },
  },
};
</script>

<style scoped>
.choice-content {
  padding-bottom: 5px;
}
.icon {
  margin-right: 5px;
}
#app .advanced {
  padding: 5px;
  margin: 5px;
  justify-content: flex-start;
}
#app .advanced > * {
  margin: 10px;
  border-radius: 5px;
}
label {
  margin-right: 5px;
}
#app input[type='text'] {
  max-width: 10em;
  margin: 0;
}
.bg-selector {
  width: calc(100vw - 40px);
}
.bg {
  flex: 1 1 auto;
  display: block;
  min-height: 400px;
  overflow: hidden;
  position: relative;
  touch-action: none;
}

.glyph-dot {
  position: absolute; top: 0; left: 0;
  width: 100px;
  height: 100px;
  margin-left: -50px;
  margin-top: -50px;
  border-radius: 100%;
  border: 5px solid var(--color3);
  background: var(--color3_50);
  opacity: 0.8;
  cursor: pointer;
}
.glyph-dot.selected {
  border: 5px solid var(--spark);
  background: var(--spark_50);
}
.glyph-card {
  cursor: pointer;
}
#app .glyph-card.selected {
  background: var(--color2_50);
  color: var(--color2_text_50);
}
input[type="number"] {
  width: 3em;
}
.glyph-form {
  justify-content: stretch;
  align-items: stretch;
}
.glyph-form > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 auto;
  text-align: center;
}
</style>
