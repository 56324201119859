<template>
  <div class="media-uploader flex-column">
    <div v-if="error" class="error">
      {{ error }}
    </div>
    <div v-if="!tab" class="uploader-main flex-row flex-center flex-align-center">
      <img v-if="thumbnail" :src="thumbnail" class="image flex1" />
      <img
        v-else-if="object.url || object.permalink"
        :src="object.url || object.permalink"
        class="image flex1"
      />
      <div v-else-if="accept === 'image'" class="image placeholder flex-static" />
      <div v-else-if="accept === 'audio' && instance" class="audio-sample flex-static">
        <div v-if="instance && !audioLoaded" class="block flex-static">
          <button class="flex-row flex-align-center flex-center" @click="audioLoaded = true">
            <vi name="package" />
          </button>
        </div>
        <div v-if="instance && audioLoaded" class="block flex-static">
          <button class="flex-row flex-align-center flex-center" @click="audioLoaded = false">
            <vi name="unpackage" />
          </button>
        </div>
      </div>

      <div class="options flex-column flex-align-top padded">
        <b v-if="showTitle && object.name" class="flex-static text-left">
          <span class="ellipsis">{{ object.name || "Unnamed" }}</span>
          <span v-if="instance" class="ellipsis muted">{{ instance.type }}</span>
        </b>
        <div v-if="audioLoaded" class="padded">
          <blob-audio v-if="instance" controls autobuffer tabindex="-1" :blob="instance.id" />
          <!-- @canplaythrough="handleLoaded" -->
        </div>

        <a v-if="instance" @click.prevent="handleEdit">
          <i class="fa fa-pencil" /> {{ $t("detail.edit") }}
        </a>
        <a v-if="object.blob_id" @click.prevent="tab = 'remove'">
          <i class="fa fa-trash" /> {{ $t("media_uploader.remove") }}
        </a>
        <a v-if="object.blob_id && !instance" @click.prevent="tab = 'repair'">
          <i class="fa fa-wrench" /> {{ $t("media_uploader.repair") }}
        </a>
        <template v-else>
          <a v-if="!instance" @click.prevent="tab = 'upload'" class="upload">
            <i class="fa fa-upload" /> {{ $t("backgrounds.form-field-upload") }}
          </a>
          <a @click.prevent="tab = 'switch'" v-if="alternates && alternates.length > 0">
            <i class="fa fa-exchange" /> {{ $t("media_uploader.switch") }}
          </a>
          <a v-if="!instance" @click.prevent="tab = 'url'" class="url-button">
            <i class="fa fa-link" /> {{ $t("media_uploader.url") }}
          </a>
          <a v-if="!instance" @click.prevent="switchToGallery" class="gallery-button">
            <i v-if="accept === 'image'" class="fa fa-paint-brush" />
            <i v-if="accept === 'audio'" class="fa fa-music" />
            {{ $t("media_uploader.gallery") }}
          </a>
        </template>
        <a v-if="object.blob_id && instance" @click.prevent="handlePush">
          <i class="fa fa-arrow-right" /> Push Sync
        </a>
      </div>
    </div>
    <div v-else-if="tab === 'gallery'" class="padded flex-column flex-left">
      <popover @show="handlePopoverShown" :include-close-button="true" :value="true">
        <gallery :size="120" :popup="true" :accept="accept" @select="handleGalleryImport($event)" />
      </popover>
      <a @click.prevent="tab = null">{{ $t("import_backup.cancel") }}</a>
    </div>
    <div v-else-if="tab === 'switch'" class="padded flex-column flex-left">
      <div>{{ $t("media_uploader.switch") }}</div>
      <div class="switch-list flex-row flex-wrap">
        <div v-for="object of alternates" :key="object.id">
          <a @click.prevent="handleSwitch(object)" v-if="accept === 'image'">
            &dash; {{ object.name }}
          </a>
          <div v-else-if="accept === 'audio'" class="flex-column">
            <blob-audio controls :blob="object.blob_id" />
            <a @click.prevent="handleSwitch(object)">{{ object.name }}</a>
          </div>
        </div>
      </div>
      <a @click.prevent="tab = null">&times; {{ $t("media_uploader.cancel") }}</a>
    </div>
    <div v-else-if="instance && tab === 'remove'" class="padded flex-column flex-left">
      <div>{{ $t("media_uploader.remove-media") }}</div>
      <b>{{ instance.name || object.blob_id }} ({{ instance.type }})</b>
      <div class="padded">
        <button @click="removeImage">{{ $t("media_uploader.confirm") }}</button>
      </div>
      <a @click.prevent="tab = null">&times; {{ $t("media_uploader.cancel") }}</a>
    </div>
    <div v-else-if="tab === 'upload' || tab === 'repair'" class="padded flex-column flex-left">
      <label>{{ $t("backgrounds.form-field-upload") }}</label>
      <input
        type="file"
        class="flex-static"
        ref="upload"
        :accept="`${accept}/*`"
        @change="uploadImage"
      />

      <div class="flex-row flex-right flex-align-center mellow padded">
        <label class="flex-align-center flex-row flex-static">
          <i class="fa fa-cloud" />
          <span @click.prevent="$refs.cloudSafe.show()" class="cloudSafeNotice padded">
            {{ $t("media_uploader.cloud-safe") }}
          </span>
          <input type="checkbox" v-model="cloudSafe" />
          <popover ref="cloudSafe" target=".cloudSafeNotice">
            <cloud-safe />
          </popover>
        </label>
        <label v-if="cloudSafe" class="flex-align-center flex-static">
          <span class="padded">{{ $t("media_uploader.send-to-cloud") }}</span>
          <input type="checkbox" v-model="cloud" />
        </label>
      </div>
      <a @click.prevent="tab = null">&times; {{ $t("media_uploader.cancel") }}</a>
    </div>
    <div v-else-if="tab === 'url'" class="padded flex-column flex-left">
      <label>{{ $t("media_uploader.url-permalink") }}</label>
      <input type="url" v-model="url" />
      <div class="flex-row padded">
        <button @click.prevent="importImage" class="confirm-url">
          {{ $t("media_uploader.confirm") }}
        </button>
        <a @click.prevent="tab = null">&times; {{ $t("media_uploader.cancel") }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import basename from "@morphosis/base/functions/basename";
import Media from "../../models/media";
import Gallery from "../../views/drawings/gallery.vue";
import CloudSafe from "../content/cloud_safe.vue";

export default {
  name: "MediaUploader",
  components: {
    Gallery,
    CloudSafe,
  },
  props: {
    value: Object,
    showTitle: { type: Boolean, default: true },
    alternates: { type: Array, default: null },
    accept: { type: String, default: "image" },
    useBlob: { type: Boolean, default: true },
    useCloud: { type: Boolean, default: false },
  },
  data() {
    const defaultObject = {};
    if (this.useBlob) {
      defaultObject.blob_id = null;
    } else {
      defaultObject.url = null;
    }
    return {
      object: this.value ? this.copy(this.value) : defaultObject,
      instance: null,
      url: null,
      tab: null,
      src: null,

      // cloud accounts will be destroyed by the first image uploaded
      // setting to false until there is a way to know if they have more storage
      // or till everyone has more storage
      cloud: false, // TODO: this.useCloud,
      cloudSafe: false,
      error: null,
      defaultObject,
      audioLoaded: false,
      thumbnail: null,
    };
  },
  watch: {
    async "object.blob_id"() {
      if (this.object.blob_id && (!this.instance || this.instance.blob_id !== this.instance.id)) {
        this.instance = await Media.load(this.object.blob_id);
        if (this.instance) {
          this.thumbnail = await this.instance.getThumbnail();
        }
      } else {
        this.instance = null;
      }
    },
    value: {
      handler() {
        this.object = this.value ? this.copy(this.value) : this.defaultObject;
      },
      deep: true,
    },
    tab() {
      if (this.tab === "url") {
        this.url = this.object.permalink;
      }
    },
  },
  async mounted() {
    await this.$root.ready;
    if (this.object.blob_id) {
      this.instance = await Media.load(this.object.blob_id);
      if (this.instance) {
        this.thumbnail = await this.instance.getThumbnail();
      }
    }
  },
  methods: {
    handlePopoverShown(e) {
      if (!e) {
        this.tab = null;
      }
    },
    async switchToGallery() {
      this.tab = "gallery";
    },
    async removeImage() {
      // if (this.instance && confirm('Remove from gallery as well?')) {
      //   await this.instance.delete();
      // }
      this.clearImage();
      this.instance = null;
      this.tab = null;
    },
    clearImage() {
      this.thumbnail = null;
      this.$emit("update:value", {
        id: this.object.id,
        name: "",
        blob_id: null,
        permalink: null,
      });
    },
    async uploadImage(ev) {
      this.error = null;
      const file = ev.target.files[0];
      const ext = file.name.split(".").pop().toLowerCase();
      const name = basename(file.name);
      let result = null;

      if (this.accept === "image") {
        result = (await this.cleanImage(file)).result;
      } else {
        result = await new Promise((r) => {
          let reader = new FileReader();
          reader.onload = async () => r(reader.result);
          reader.readAsDataURL(file);
        });
      }

      let permalink = null;
      if (result) {
        ev.value = null;

        if (this.cloudSafe && this.cloud) {
          const response = await Media.objects.push(
            { id: name },
            { content: result, model: "media", public: true, ext },
          );
          if (response) {
            if (response.error) {
              this.error = response.error;
              return;
            } else {
              permalink = response.url;
            }
          } else {
            this.error = this.$t("media_uploader.failed-to-upload-file");
            return;
          }
        }

        this.clearImage();
        await this.$nextTick();
        const retval = {
          id: this.value.id,
          permalink,
          name,
        };
        if (this.useBlob) {
          const media = await Media.importBlob(result, this.value.blob_id);
          media.permalink = permalink;
          media.name = file.name;
          media.cloud = permalink && this.cloud;
          media.cloudSafe = media.cloud && this.cloudSafe;
          await media.save();
          this.instance = media;
          this.value.blob_id = media.id;
        } else {
          retval.url = result;
          retval.permalink = permalink;
        }
        this.$emit("update:value", retval);
        this.tab = null;
      }
    },
    async importImage(e) {
      this.error = null;
      const value = this.url;
      if (value) {
        let url = this.fixCDNPermalink(value);
        let blob = null;
        const name = basename(url);
        try {
          const media = await Media.importBlob(url, this.value.blob_id);
          media.permalink = url;
          await media.save();
          blob = media.id;
        } catch (ex) {
          alert(this.$t("backgrounds.bad-permalink"));
        }
        if (blob) {
          this.clearImage();
          await this.$nextTick();
          this.$emit("update:value", {
            id: this.value.id,
            permalink: url,
            blob_id: blob,
            name,
          });
          this.tab = null;
        }
      }
    },
    async handleGalleryImport(image) {
      this.$emit("update:value", {
        id: this.value.id,
        permalink: image.permalink,
        blob_id: image.id,
        name: image.name,
      });
      this.instance = image;
      this.tab = null;
    },
    async handleEdit() {
      this.$router.push({
        name: "gallery-item",
        params: {
          id: this.instance.id,
        },
        query: {
          back: this.$route.path,
        },
      });
    },
    async handlePush() {
      this.instance.updated = new Date().toISOString();
      await this.instance.save();
      await this.instance.push();
    },
  },
};
</script>

<style scoped>
.media-uploader {
  margin: 5px;
  min-width: 250px;
  padding: 5px;
  border-radius: 5px;
  flex-wrap: nowrap;
}
.ellipsis {
  max-width: 200px;
}
.image {
  min-width: 90px;
  max-width: 120px;
  max-height: 120px;
  object-fit: cover;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
}
.audio {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
}
.placeholder {
  width: 90px;
  height: 90px;
  background: linear-gradient(40deg, var(--spark), var(--color3));
  border: 5px dotted var(--spark_text);
  opacity: 0.7;
}
a {
  padding: 8px;
}
.options a {
  white-space: nowrap;
}
.uploader-main {
  flex-wrap: nowrap;
}
.switch-list {
  background: var(--color2);
  border-radius: 10px;
}
.switch-list > div {
  margin: 5px;
  padding: 5px;
  flex: 0 0 auto;
  border: 2px solid;
  color: var(--color3_text);
  background: var(--color3);
  border-radius: 10px;
}
.switch-list > div a {
  color: var(--color3_text);
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
}
.image-grid > * {
}
</style>
