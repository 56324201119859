<template>
  <canvas />  
</template>

<script>
import QRCode from 'qrcode';

export default {
  name: "qr",
  props: {
    text: String,
  },
  mounted() { 
    QRCode.toCanvas(this.$el, this.text || this.$route.params.text, function (error) {
      if (error) console.error(error)
    });
  }
}
</script>

