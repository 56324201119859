
<template>
  <div v-if="story">
    <app-header>
      <router-link :to="{name: 'story', params: {story: story.id}}">
        <i class="fa fa-arrow-left" />
      </router-link>
      Share
    </app-header>
    <div class="scroll responsive-max">
      <p>Create sharable link for `{{ story.title }}`?</p>
      <p>Anyone with the link will be able to download and read your story.</p>
      <p>You will be able to publish updates and revisions</p>

      <div v-if="error" class="error">
        {{ error }}
      </div>
      <form
        v-if="!status"
        @submit.prevent="handlePublish"
        class="flex-row flex-space-between"
      >
        <router-link :to="{name: 'story', params: {story: story.id}}">Cancel</router-link>
        <button v-if="! story.fid">Share</button>
        <button v-else>Update</button>
      </form>
      <div v-else>
        <p>
          Waiting for server... <i class="fa fa-refresh fa-spin" />
        </p>
      </div>
    </div>
  </div>
  <div v-else>{{$t('app_status.loading')}}</div>
</template>

<script>
export default {
  name: 'publish-story',
  props: {
    story: Object,
  },
  data() {
    return {
      status: null,
    };
  },
  computed: {
    user() { return this.$root.user; },
  },
  methods: {
    async handlePublish() {
      this.error = null;
      this.status = 'uploading';
      try {
        await this.story.publish(true);
        this.$router.push(`/story/${this.story.id}`);
      } catch(ex) {
         this.error = ex.message;
      }
      this.status = null;
    },
  },
};
</script>
