<template>
  <BaseAutoField
    :field="field"
    :value="value"
    @update:value="$emit('update:value', $event)"
  >
    <template v-slot:direction>
      <directional-input
        :value="value[field.name]"
        :scale="field.size || 100"
        @update:value="update($event, field, $event)"
      />
    </template>

    <template v-slot:color>
      <div>
        <color-picker
          :value="value[field.name]"
          @update:value="update($event, field, $event)"
        />
      </div>
    </template>

    <!-- <template v-slot:checkbox>
      <span class="checkbox">
        <label class="stretch" :for="field.name">
          <input
            :checked="value[field.name]"
            type="checkbox"
            @change="update($event, field, $event.target.checked)"
          />
        </label>
      </span>
    </template> -->

    <template v-slot:buttons>
      <div class="row">
        <input
          :value="value[field.name]"
          :type="field.type"
          :size="field.size"
          class=""
          :name="field.name"
          :placeholder="field.placeholder"
          :min="field.min"
          :max="field.max"
          :step="field.step"
          :pattern="field.pattern"
          @change="update($event, field, $event.target.value)"
        />
        <button
          v-for="(button, index) of (field.buttons || [])"
          :id="`button_${field.name}_${index}`"
          :key="button.icon || button.text"
          type="button"
          @click="handleClick($event, field, button)"
        >
          <vi :name="button.icon" />
          {{ button.text }}
        </button>
      </div>
    </template>

    <template v-slot:aura>
      <AuraEditor
        @update:value="update($event, field, $event)"
        :value="value[field.name]"
      />
    </template>
  </BaseAutoField>
</template>

<script>
// import ScaleInput from '../editor/scale_input.vue';
import DirectionalInput from '../editor/directional_input.vue';
import StyleChooser from '../editor/style_chooser.vue';
import BaseAutoField from '@morphosis/base/components/forms/AutoField.vue';
import AuraEditor from "@/components/editor/AuraEditor.vue";

export default {
  name: 'AutoField',
  components: {
    BaseAutoField,
    DirectionalInput,
    // StyleChooser,
    AuraEditor,
  },
  props: {
    field: { type: Object, required: true },
    value: { type: Object, required: true },
    story: { type: Object, default: null },
  },
  mounted() {
    if (!this.value[this.field.name] && (this.field.default === 0 || this.field.default)) {
      this.update(this.field, this.field.default);
    }
  },
  methods: {
    update(event, field, value) {
      let errors = [];
      if (field.validators) {
        errors = field.validators.map(v => v(value)).filter(e => e);
      }

      if (errors.length === 0) {
        const values = this.copy(this.value);
        if (field.type === 'number') {
          values[field.name] = parseFloat(value);
        } else {
          values[field.name] = value;
        }
        this.$emit('update:value', values);
      }
      if (event.target) {
        event.target.setCustomValidity(errors.join(', '));
        event.target.reportValidity();
      }
    },
    handleClick(event, field, button) {
      button.action(field, this.value, '#' + event.currentTarget.id);
    },
  },
};
</script>
