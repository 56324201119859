
let canvas = document.createElement("canvas");

export default async function compress(image, opts) {
  let reader = new FileReader();
  let src = await new Promise((resolve, reject) => {
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(image);
  });

  let img = new Image();
  await new Promise((resolve, reject) => {
    img.onload = resolve;
    img.onerror = reject;
    img.src = src;
  });

  let w = null;
  w = img.width;
  let h = img.height;
  if (w > opts.maxWidthOrHeight) {
    let ratio = h / w;
    w = opts.maxWidthOrHeight;
    h = w * ratio;
  } else if (h > opts.maxWidthOrHeight) {
    let ratio = w / h;
    h = opts.maxWidthOrHeight;
    w = h * ratio;
  }

  if (
    w > 500 &&
    ["image/svg", "image/svg+xml", "image/gif"].indexOf(image.type) > -1
  ) {
    return src;
  } else {
    canvas.width = w;
    canvas.height = h;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, w, h);
    let result = canvas.toDataURL("image/webp");
    return result;
  }
}

compress.getDataUrlFromFile = function (canvas) {
  return canvas;
};
