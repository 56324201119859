<template>
  <span>
    <vi name="flag" @click="showModal = true" />
    <popover v-if="showModal" :value="true">
      <moderation-report
        :source="source"
        :content="content"
        :user="user"
        @show="showModal = false"
      />
    </popover>
  </span>
</template>

<script>
export default {
  name: 'ModerationFlag',
  props: {
    source: String,
    content: String,
    user: String,
  },
  data() {
    return {
      showModal: false,
    };
  },
}
</script>

<style scoped>
svg {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 0px 5px;
}
</style>
