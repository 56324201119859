<template>
  <div
    ref="el"
    class="large-draggable-list"
    @move="$event.stopImmediatePropagation()"
    :style="{'height': `${minHeight}px`}"
  >
    <Sortable
      :list="viewportItems"
      :options="{
        animation: 150,
        group: props.group,
        handle: '.handle',
        forceFallback: true,
      }"
      :style="{transform: `translate(0, ${top}px)`}"
      @start="dragStart"
      @update="handleChange"
      tag="div"
      item-key="id"
      class="elements"
    >
      <template #item="{ element: item, index: idx }">
        <div class="item">
          <slot :item="item" :index="offset + idx" :height="heights[idx]">
            {{ item }}
          </slot>
        </div>
      </template>
    </Sortable>
    <!-- <div class="info">{{ heights }} - {{ minHeight }}</div> -->
  </div>
</template>

<script setup lang="ts">
import copy from "@/utils/copy";
import { Sortable } from "sortablejs-vue3";
import { watch } from "vue";
import { ref, nextTick, onMounted, onUnmounted, computed, } from 'vue';
// import copy from "@/utils/functions/copy";

const emit = defineEmits(['update:items', 'scroll']);
const props = defineProps({
  items: Array,
  startCount: { default: 100 },
  startIndex: { default: 0 },
  group: { default: 'draggables' },
  scrollParentClass: { default: '.scroll' },
});
const el = ref(null);
const heights = ref([] as number[]);
const vh = ref(window.innerHeight);
const offset = ref(props.startIndex);
const limit = ref(props.startIndex + props.startCount);
const scroll = ref(0);
const isScroller = ref(false);
const top = ref(0);
const dragContext = ref({start: -1, index: 0});

let scrollElement = null;
let scrollListener = null;

const viewportItems = computed(() => {
  return props.items.slice(offset.value, limit.value + 1);
});
const minHeight = computed(() => {
  // console.log('height', JSON.stringify(heights.value));
  return heights.value.reduce((a, b) => a + b, 0);
});
const avgHeight = computed(() => {
  return minHeight.value / heights.value.length;
});

watch(() => props.items, (a, b) => {
  if (heights.value.length > props.items.length) {
    heights.value.length = props.items.length;
  }
  if (heights.value.length < props.items.length) {
    getHeights();
  }
});
watch(() => props.startIndex, () => {
  if (props.startIndex !== offset.value) {
    scrollToIndex(props.startIndex);
  }
});

// watch(offset, () => console.log('offset', offset.value));
// watch(limit, () => console.log('limit', limit.value));
watch([top, offset], () => {
  const ct = heights.value.slice(0, offset.value).reduce((a, b) => a + b, 0);
  const tb = heights.value.slice(offset.value).reduce((a, b) => a + b, 0);
  // console.log(
  //   'top',
  //   top.value,
  //   ct,
  //   tb,
  //   ct + tb - minHeight.value,
  // )
});


const observers = [];

onMounted(() => {
  scrollToIndex(props.startIndex);
  beginScrolling();
  getHeights();

  if (window.MutationObserver) {
    const mutator = new window.MutationObserver(async (el) => {
      // console.log('mutation!');
      getHeights();
    });

    // Observe one or multiple elements
    mutator.observe(
      el.value,
      {
        // attributes: true,
        childList: true,
        subtree: true
      }
    );
    observers.push(mutator);
  }
});

onUnmounted(() => {
  endScrolling();
  observers.forEach((o) => o.disconnect());
});

async function beginScrolling() {
  endScrolling();

  await nextTick();

  scrollElement = el.value.closest(props.scrollParentClass);
  if (!scrollElement) {
    scrollElement = el.value;
    isScroller.value = true;
  }
  let timeout = null;
  scrollListener = () => {
    // console.log('scroll', scrollElement.scrollTop);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (!isScroller.value) {
        el.value.scrollTop = 0;
      }
      getHeights();
    }, 1);
  };
  scrollElement.addEventListener('scroll', scrollListener);
}

function endScrolling() {
  if (scrollElement) {
    scrollElement.removeEventListener('scroll', scrollListener);
  }
}

function getHeight(index: number) : number {
  const eIndex = index - offset.value;
  // const target = el.value.children[0].children[0].children[eIndex];

  const target = el.value.children[0].children[eIndex];
  if (target) {
    return target.getBoundingClientRect().height;
  }
  return vh.value;
}

function buildHeights(index: number) : number {
  // if (heights.value.length < index) {
  //   return heights.value[index]
  // }
  if (index >= offset.value && index <= limit.value) {
    const h = getHeight(index);
    if (index >= heights.value.length) {
      heights.value.push(h);
    } else {
      heights.value[index] = h;
    }
  }
  return heights.value[index];
}


async function scrollToIndex(index) {
  limit.value = Math.min(props.items.length - 1, Math.max(0, index));
  offset.value = Math.max(0, offset.value - props.startCount);
  await nextTick();

  while (heights.value.length < props.items.length) {
    heights.value.push(vh.value);
  }

  // build heights
  let h = 0;
  let last = 0;
  for (let i = 0; i < index; i++) {
    last = buildHeights(i)
    h += last;
  }

  h = Math.max(0, h - vh.value * 0.5 + last * 1.5);
  const scrollElement = el.value.closest('.scroll');
  await nextTick();
  scrollElement.scrollTop = h;
  await nextTick();

  getHeights();
}

async function getHeights() {
  let box = el.value.getBoundingClientRect();
  scroll.value = -box.y;

  let myTop = 0;
  let range = props.startCount;
  if (heights.value.length >= props.startCount) {
    range = Math.max(20, Math.floor((vh.value * 2) / avgHeight.value));
  }

  for (let y = 0; y < props.items.length; y += 1) {
    const h = buildHeights(y) || 0;
    if (myTop + h > scroll.value - vh.value * 1.0) {
      offset.value = y;
      limit.value = y + range;
      top.value = myTop;
      break;
    }
    myTop += h;
  }

  // wait for render
  await nextTick();

  for (let y = offset.value; y < props.items.length; y += 1) {
    myTop += buildHeights(y);
    if (myTop > scroll.value + vh.value * 1.0) {
      break;
    }
  }
}

async function dragStart(e) {
  dragContext.value.start = offset.value;
  dragContext.value.index = e.oldIndex;
}

async function handleChange(e) {
  // if (e.item.closest('.draggable-list') === $el) {
    const start = dragContext.value.start + e.oldIndex;
    let end = offset.value + e.newIndex;
    const array = copy(props.items);
    const item = array.splice(start, 1)[0];
    const isMoved = dragContext.value.start !== offset.value;
    const inViewport = start >= offset.value && start < limit.value;
    if (isMoved && !inViewport) {
      end -= 1;
    }
    array.splice(end, 0, item);
    emit('update:items', array);
  // } else {
  //   // this message is from a sub element
  //   console.log('ignoring from child', this.group, e);
  // }
  dragContext.value.start = -1;
}
</script>

<style scoped>
.large-draggable-list {
  gap: 0;
  overflow: hidden;
  position: relative;
  user-select: none;
}
.scroll {
  position: relative;
}
.item {
  flex: 0 0 auto;
  gap: 0;
  user-select: unset;
}
.info {
  display: absolute;
  background: rgba(255, 255, 255, 0.2);
  z-index: 2;
  bottom: 0;
  left: 0;
  height: min-content;
  pointer-events: none;
}
</style>
