<style scoped>
  .user-tag {
    display: inline-flex;
    flex: 0 0 auto;
    font-size: .9em;
    font-weight: bold;
    margin: 0 5px;
    line-height: 1em;
  }
  .user-tag .text-muted {
    font-weight: normal;
    opacity: .6;
  }
  .user-tag img {
    max-width: 25px;
    max-height: 25px;
    margin-right: 3px;
    border-radius: calc(var(--softness) * 3);
  }
  .username {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>

<template>
  <span class="user-tag flex-align-center">
    <img v-if="user.avatar" :src="user.avatar">
    <span v-if="user.username" class="username">
      {{ user.username }}
      <!-- <span class="text-muted">(@{{ uid }})</span> -->
    </span>
    <span v-else class="username">
      @{{ uid }}
    </span>
  </span>
</template>

<script>
/** shows a little cached username/image */
export default {
  name: 'UserTag',
  props: {
    uid: { type: String, required: true },
  },
  data() {
    return {
      user: { id: this.uid },
    };
  },
  async mounted() {
    this.user = await this.http('profile', {
      id: this.uid,
    });
  },
};
</script>
