import { nanoid } from "nanoid";
import { defineStore } from "pinia";
import { reactive } from "vue";

export interface Message {
  title?: string;
  message: string;
  level?: "error" | "warning" | "info" | "debug";
  suppressor?: string;
  link?: string;
  channel?: string;
  timeout?: number;
  timeoutMax?: number;
  className?: string;
  ex?: Error;
}

export const useToasterStore = defineStore("toaster", () => {
  const data = reactive({
    alerts: [],
    maxAlerts: 25,
    counts: {},
    suppress: {},
  });

  function add(msg: Message) {
    const suppressAs = msg.suppressor || msg.message;
    // count the number of times this message has been repeated
    if (!data.counts[suppressAs]) {
      data.counts[suppressAs] = 0;
    }
    data.counts[suppressAs] += 1;

    if (msg.ex) {
      console.error("alert", msg.ex);
    }
    if (!data.suppress[suppressAs]) {
      let alert = null;
      if (msg.channel) {
        alert = data.alerts.find((a) => a.channel === msg.channel);
      }
      if (alert) {
        alert.message = msg.message;
        alert.title = msg.title;
        alert.link = msg.link;
        alert.timeout = msg.timeout || 10000;
        alert.timeoutMax = msg.timeout || 10000;
        alert.count = data.counts[msg.message];
      } else {
        data.alerts.unshift({
          id: nanoid(),
          message: msg.message,
          title: msg.title,
          className: msg.className,
          channel: msg.channel,
          level: msg.level || "warn",
          link: msg.link,
          timeout: msg.timeout || 10000,
          timeoutMax: msg.timeout || 10000,
          count: data.counts[msg.message],
        });
      }
      if (data.alerts.length > data.maxAlerts) {
        data.alerts.pop();
      }
    }
  }
  function changeSuppression(message, value) {
    data.suppress[message] = value;
  }

  return {
    alerts: data.alerts,
    add,
    changeSuppression,
  };
});
