<style scoped>
  .character-placeholder {
    color: var(--color1_text);
    padding: 5px 10px;
  }
  .character-initiate {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 5px;
    flex: 0 0 auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid;
  }
  textarea {
    width: 100%;
    height: max-content;
    background: transparent;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    overflow: hidden;
    padding: 8px;
    border-radius: 5px;
  }
  .character-box {
    /* width: 100%; */
    min-width: 100px;
  }
  .character-box input {
    font: 0.8em;
    padding: 5px;
    flex: 1 1 auto;
    width: auto;
    min-width: 25px;
    background: var(--color1);
    color: var(--color1_text);
    opacity: 0.5;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid;
  }
  .character-box input:focus {
    opacity: 1;
  }
  .character-box input::placeholder {
    color: var(--color1_text);
    opacity: 0.6;
  }
  .character-box-spacer {
    flex: 1 1 100px;
    width: 100px;
  }
  .character-image img {
    max-width: 100%;
    max-height: 100%;
    flex: 0 0 auto;
    object-fit: cover;
  }
  .character-image {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    --aura: white;
    background: radial-gradient(circle at 30px 100%, var(--aura) 0%, transparent 90px);
    border-radius: 50%;
    margin: 0 5px;
    overflow: hidden;
  }
  #app .position-right .character-box {
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
  #app .position-center .character-box {
    justify-content: center;
  }
  #app .position-center .character-box-spacer {
    order: -1;
  }
  .position-right img {
    margin-left: auto;
    transform: scaleX(-1);
  }
  .position-center .character-box img {
    margin: 0 auto;
  }
  .autogrow-textarea {
    display: block;
  }
  .padded.small {
    font-size: 14px;
    padding-top: 0px;
    margin-bottom: 2px;
  }
  .name-column {
    min-width: 25px;
  }
  select {
    width: auto;
  }
  .text {
    min-width: 0;
  }
</style>

<template>
  <div class="text" :class="`position-${position}`">
    <character-modal
      v-if="showModal"
      :story="story"
      :value="`${value.label}`"
      :character="character"
      :position="position"
      :emote="emote"
      :tag="charTag"
      @update:value="chooseEmote"
      @position="handleChangePosition"
      @show="showModal = false"
    />

    <div class="flex-column">
      <div class="flex-row flex-align-bottom">
        <div
          v-if="characters.length > 0"
          class="left row character-box"
        >
          <div
            v-if="image"
            class="character-image row static"
            @click="showModal = true"
          >
            <blob-image :blob="image" :thumb="true" />
          </div>
          <div v-else class="character-initiate row">
            <i
              class="character-placeholder fa fa-user fa-2x"
              @click="showModal = true"
            />
          </div>
          <div class="flex-column name-column">
            <input
              class="stretch"
              :value="value.label"
              :placeholder="label"
              tabindex="-1"
              @input="$emit('update', {label: $event.target.value})"
            >
            <div v-if="value.charTag && charTag" class="flex-row gap-1 padded small">
              <i class="fa fa-tag" /> {{ charTag.name }} / {{ value.emoteName }}
            </div>
            <div v-else-if="emote" class="padded small">
              {{ character.name }} / {{ emote.name }}
            </div>
          </div>
        </div>
      </div>
      <autogrow-textarea
        :modelValue="value.content"
        :style-name="style"
        :speed="value.speed"
        :story="story"
        :placeholder="$t('text_content.add-text-here')"
        ref="textarea"
        class="stretch"
        tabindex="0"
        @keydown="$emit('keydown', $event)"
        @keypress="$emit('keypress', $event)"
        @update:speed="$emit('update', {speed: $event})"
        @update:style-name="$emit('update', {style: $event})"
        @update:modelValue="$emit('update', {content: $event})"
      />
    </div>
  </div>
</template>

<script>
import emitter from 'tiny-emitter/instance';
import CharacterModal from '../editor/character_modal.vue';

export default {
  components: {
    CharacterModal,
  },
  props: {
    story: Object,
    scene: Object,
    value: Object,
    maxWordCount: { default: 100 },
  },
  data() {
    return {
      previousCharacter: null,
      showModal: null,
    };
  },
  mounted() {
    this.listener = (e) => {
      if (this.scene.id === e.scene && this.value.id === e.direction) {
        if (e.cmd === 'set-character') {
          this.showModal = true;
        }
      }
    };
    emitter.on('command', this.listener);
  },
  unmounted() {
    emitter.off('command', this.listener);
  },
  computed: {
    // hasAdvanced() { return true; },
    charTag() {
      if (this.value.charTag) {
        const tag = this.story.tags.find((t) => t.id === this.value.charTag);
        return tag;
      }
      return null;
    },
    characters() { return this.story.characters || []; },
    character() {
      const { characters } = this;
      let charId = this.charTag?.default || this.value.character;

      if (charId) {
        const char = characters.find(c => c.id == charId);
        if (char) {
          return char;
        }
      }
      return null;
    },
    position() {
      let pos = this.value.position;
      if (!pos && this.emote) {
        pos = this.emote.position;
      }
      return pos;
    },
    aura() {
      let pos = this.value.aura;
      if (!pos && this.emote) {
        pos = this.emote.aura;
      }
      return pos;
    },
    label() {
      let pos = this.value.label;
      if (!pos && this.emote) {
        pos = this.emote.label;
      }
      return pos || this.$t('layout_content.speaker');
    },
    emote() {
      if (this.character) {
        if (this.value.state) {
          return this.character.emotes.find(e => e.id == this.value.state);
        } else if (this.value.emoteName) {
          return this.character.emotes.find(e => e.name === this.value.emoteName);
        }
      }
    },
    image() {
      const { emote } = this;
      const { type } = this.value;

      if (type == 'text' && emote) {
        return emote.blob_id;
      }
    },
    style() {
      let retval = [this.character?.style, this.emote?.style, this.value.style];
      return retval.filter((a) => a).pop();
    },
    wordCount() {
      return (this.value.content || '').trim().split(' ').length;
    },
    settings() {
      return this.story.settings || {};
    },
  },
  watch: {
    character() {
      if (this.character) {
        if (!this.value.state && this.character.emotes.length > 0) {
          this.value.state = this.character.emotes[0].id;
          this.value.emoteName = this.character.emotes[0].name;
        }
      } else {
        this.value.state = null;
        this.value.emoteName = null;
      }
    },
  },
  methods: {
    handleChangePosition(value) {
      this.$emit('update', { position: this.position === value ? undefined : value });
    },
    chooseEmote(v) {
      this.$emit('update', {
        charTag: v.charTag,
        character: v.charId,
        state: v.emoteId,
        emoteName: v.emote,
      });
      this.$refs.textarea.focus();
    },
  },
};
</script>
