const descriptions = [
  'amazing',
  'bright',
  'calculating',
  'delicious',
  'demure',
  'distracted',
  'dominating',
  'epic',
  'experimental',
  'flirty',
  'glorious',
  'handsome',
  'happy',
  'hinting',
  'interjecting',
  'junior',
  'knowledgable',
  'laughing',
  'magnificent',
  'nervous',
  'oppurtunistic',
  'popular',
  'quiet',
  'righteous',
  'shy',
  'tempestuous',
  'ultimate',
  'vivacious',
  'wandering',
  'xyloid',
  'yappy',
  'zen',
];
const names = [
  'admiral',
  'buddy',
  'cat',
  'chameleon',
  'chocolate',
  'crocodile',
  'denizen',
  'earthling',
  'framer',
  'geode',
  'hero',
  'host',
  'informant',
  'joker',
  'kraken',
  'lama',
  'lampshade',
  'moon',
  'nightmare',
  'opinion',
  'plot',
  'quasar',
  'raven',
  'servant',
  'star',
  'sun',
  'technician',
  'ultimatum',
  'victorian',
  'warrior',
  'writer',
  'xenops',
  'youngling',
];

export default {
  names,
  descriptions,
};

export function sanitize(str) {
  const div = document.createElement('div');
  div.appendChild(document.createTextNode(str));
  return div.innerHTML;
}

export function choice(array) {
  const idx = Math.floor(Math.random() * array.length);
  return array[idx];
}
