export default {
  data() {
    return {
      now: Date.now(),
      counts: {
        stories: 0,
        texts: 0,
        choices: 0,
        advanced: 0,
        backgrounds: 0,
        images: 0,
        audio: 0,
      },
    };
  },
  computed: {
    shouldShow() {
      return this.$root.inAppEvent === 'aprilfools' && this.$root.eventDetails === 1
    },
    inMalpractice() {
      return /malpractice/.test(this.$route.path);
    },
    costs() {
      const timeUsed = this.now - this.$root.now.getTime();
      return [
        { label: 'Stories', value: this.counts.stories, unit: 5.99 },
        { label: 'Text Directions', value: this.counts.texts, unit: 0.09 },
        { label: 'Choice Directions', value: this.counts.choices, unit: 0.19 },
        { label: 'Advanced Directions', value: this.counts.advanced, unit: 1.99 },
        { label: 'Backgrounds', value: this.counts.backgrounds, unit: 0.59 },
        { label: 'Images', value: this.counts.images, unit: 0.69 },
        { label: 'Music & Audio', value: this.counts.audio, unit: 0.79 },
        { label: 'Time Used', value: timeUsed, unit: 0.0001 },
      ];
    },
    cost() {
      return this.costs.reduce((a, b) => a + b.value * b.unit, 0);
    },
  },
  async mounted() {
    this._interval = setInterval(() => this.now = Date.now(), 500);
    this.calcCounts();
  },
  unmounted() {
    clearInterval(this._interval);
  },
  methods: {
    async calcCounts() {
      this.counts.stories = await this.MODELS.story.count();

      const scenes = await this.MODELS.scene.list();
      this.counts.texts = 0;
      this.counts.choices = 0;
      for (const scene of scenes) {
        for (const direction of scene.directions) {
          if (direction.type === 'text') {
            this.counts.texts += 1
          } else if (direction.type === 'choice') {
            this.counts.choices += 1;
          } else if (direction.type === 'background') {
            this.counts.backgrounds += 1;
          } else if (direction.type === 'image') {
            this.counts.images += 1;
          } else if (direction.type === 'music' && direction.type === 'audio') {
            this.counts.audio += 1;
          } else {
            this.counts.advanced += 1;
          }
        }
      }
    }
  },
};
