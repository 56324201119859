<template>
  <div class="audio-meter" :class="direction">
    <div
      v-for="(block, i) of blocks"
      :class="{ block: true, active: percent > block }"
      :style="{
        'transition-delay': `${i * 20}ms`,
      }"
    >
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  value: Number,
  max: Number,
  size: { type: Number, default: 25 },
  direction: { type: String, default: 'N' },
})
const percent = computed(() => {
  return 100 * (props.value * 5 / props.max);
});
const blocks = computed(() => {
  const length = props.size;
  const array = Array.from(new Array(length), (x, i) => 100 * i / length);
  return array;
});
</script>

<style scoped>
.audio-meter {
  background: var(--bg);
  padding: var(--spacing);
  margin: var(--spacing);
  border: 2px solid var(--color1_50);
  border-radius: var(--softness);
  color: var(--color1_text);
  flex: 0 0 auto;
  display: grid;
  gap: 1px;
}
.block {
  transition: background-color 500ms;
  background: var(--color1);
  width: var(--spacing);
  height: var(--spacing);
  /* border-radius: var(--softness); */
}
.block.active {
  transition: background-color 100ms;
  background: var(--spark);
}
.audio-meter.N, .audio-meter.S {
  grid-template-rows: repeat(v-bind('props.size'), auto);
}
.audio-meter.E, .audio-meter.W {
  grid-template-columns: repeat(v-bind('props.size'), auto);
}
.audio-meter.N .block,
.audio-meter.S .block {
  width: 20px;
}
.audio-meter.E .block,
.audio-meter.W .block {
  height: 20px;
}
</style>
