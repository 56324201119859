import { useToasterStore } from "@morphosis/base/stores/toaster";
import { mapStores } from "pinia";

export default {
  computed: {
    storePlatform(test = false) {
      if (this.$root.platform === "android") {
        return window.CdvPurchase.Platform.GOOGLE_PLAY;
      } else {
        return window.CdvPurchase.Platform.APPLE_APPSTORE;
      }
    },
    ...mapStores(useToasterStore),
  },
  mounted() {
    if (window.CdvPurchase) {
      const { store, ProductType, Platform } = window.CdvPurchase;
      if (this.$root.platform === "android") {
        const consumables = ["simplesupport", "extrasupport", "supersupport"];
        const subscriptions = ["simplesub", "extrasub"];
        consumables.forEach((id) => {
          store.register({ type: ProductType.CONSUMABLE, id, platform: Platform.GOOGLE_PLAY });
        });
        subscriptions.forEach((id) => {
          store.register({
            type: ProductType.PAID_SUBSCRIPTION,
            id,
            platform: Platform.GOOGLE_PLAY,
          });
        });
      } else if (this.$root.platform === "ios") {
        const consumables = ["simplesupport1", "extrasupport1", "supersupport1"];
        const subscriptions = ["simplesub1", "extrasub1"];
        consumables.forEach((id) => {
          store.register({ type: ProductType.CONSUMABLE, id, platform: Platform.APPLE_APPSTORE });
        });
        subscriptions.forEach((id) => {
          store.register({
            type: ProductType.PAID_SUBSCRIPTION,
            id,
            platform: Platform.APPLE_APPSTORE,
          });
        });
      }

      store.when().approved(this.iapDone.bind(this));
      store.initialize();

      this.iap = store;
    }
  },
  methods: {
    async purchaseItem(code) {
      const { store, Platform } = window.CdvPurchase;

      let platform = this.storePlatform;
      const product = store.get(code, platform);
      console.log("product", code, platform, product);
      if (product) {
        // const myTransaction = store.findInLocalReceipts(product);
        const response = await product.getOffer().order();
        console.log("response", response);

        if (!response || !response.isError) {
          this.$router.push({ name: "thanks" });
        }
      }
    },
    async iapDone(p) {
      if (p.transactionId === "appstore.application") {
        return;
      }

      let productId = p.products[0].id;

      if (p.isAcknowledged) {
        if (!/sub$/.test(productId)) {
          this.addAlert({
            title: "Purchase complete",
            messsage: `Your purchase of ${productId} has been acknowledged`,
          });
        }
      } else {
        const finishing = this.fetch("iap:finishing");
        if (finishing !== p.transactionId) {
          this.put("iap:finishing", p.transactionId);
          const response = await this.http("purchase_register", {
            transaction: p,
            platform: p.platform,
          });
          console.log("purchase register response", response);
          if (!response.error) {
            p.finish();
          }
        }
      }
    },
  },
};
