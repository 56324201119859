import Socket from "./lib/websocket.js";
import debug from "debug";

const log = debug("api");

export var socket = null;

export function timer(value, timeout, raise=false) {
  return new Promise((resolve, reject) => setTimeout(
    () => (raise ? reject : resolve)(value), timeout)
  );
}

export function create(host, opts={}) {
  socket = new Socket(host, opts);
  return socket;
}

export async function sendwait(event, data, timeout = 10000) {
  if (socket.connected) {
    await Promise.race([
      socket.promise,
      timer(null, timeout, true)
    ]);
  }
  // console.log(` => ${event}:${JSON.stringify(data).slice(0, 100)}`);
  socket.emit(event, data);
  return Promise.race([
    new Promise((resolve) => {
      socket.once(event, (data) => {
        log(`<= ${event}:${JSON.stringify(data).slice(0, 100)}`);
        resolve(data);
      });
    }),
    timer(null, timeout)
  ]);
};