import { http, ws } from "./api";

export default {
  data() {
    return {
      httpLoginPromise: null,
    };
  },
  methods: {
    http(event, data = {}, method = "POST") {
      return http({ event, data, method });
    },
    ws(event, data = {}, method = "POST") {
      return ws({ event, data, method });
    },
  },
};
