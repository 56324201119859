<style scoped>
.list-item {
  position: relative;
  background: var(--color1);
  color: var(--color1_text);
  border-radius: var(--softness);
  margin: 5px;
  transition-duration: 200ms !important;
  padding: 0;
  display: flex;
}
.list-item a {
  color: var(--color1_text);
}
.list-item > * {
  flex: 1 1 auto;
  padding: 5px;
  border-radius: 5px;
}
.buttons {
  margin-top: 10px;
  margin-bottom: 10px;
}
.list-item > div {
  flex: 1 1 auto;
  padding: 3px;
  margin: 0;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  padding-right: 35px;
}
.list-item > .delete {
  position: absolute;
  margin: 0;
  top: 0;
  right: 0;
  text-align: center;
  align-self: stretch;
  /* border: 1px solid red; */
  height: 35px;
  width: 35px;
  flex: 0 0 35px;
  font-size: 20px;
}
.object-cont {
  max-width: 100%;
  min-width: 0;
}
.object-cont.no-remove {
  padding-right: 3px;
}
.object-cont > * {
  max-width: 100%;
}
</style>


<template>
  <DraggableList
    :group="group"
    :value="array"
    @update:value="handleChange"
    class="list padded"
  >
    <template #item="{ object, index: idx }">
      <div
        class="list-item row static"
        :key="object.id"
        :style="{'transition-delay': `${idx * 50}ms`}"
      >
        <div class="object-cont flex-stretch" :class="{ 'no-remove': noRemove }">
          <slot
            :object="object"
            :index="idx"
            :rindex="value.length - idx + 1"
            :input="(e) => updateRow(idx, e)"
            :inputField="(k, v) => updateField(idx, k, v)"
          >
            {{ object }}
          </slot>
        </div>
        <a v-if="!noRemove" class="delete" href="#" @click.prevent="removeObject(object)">&times;</a>
      </div>
    </template>
    <template #footer>
      <div class="static" v-if="value?.length === 0" key='empty'>
        <slot name="empty"><p>{{ $t('list_detail.empty') }}</p></slot>
      </div>
      <div class="buttons center" v-if="!noAdd" key='buttons'>
        <button class="static" @click="addObject">
          <i class="fa fa-plus" />
          <slot name="button">{{ addText || $t('list.add-button-label') }}</slot>
        </button>
      </div>
    </template>
  </DraggableList>
</template>

<script>
import DraggableList from "@morphosis/base/components/DraggableList.vue";
import { Sortable } from "sortablejs-vue3";

export default {
  name: 'list',
  props: {
    value: Array,
    group: 'cyoa-draggable-list',
    addText: { type: String },
    noAdd: { type: Boolean, default: false },
    noRemove: { type: Boolean, default: false },
    noRemovePrompt: { type: Boolean, default: false },
    removePrompt: { type: String, default: 'Remove {name}?' },
    defaultObject: { type: [Function, Object], default: () => { 'unnamed'; } },
  },
  components: {
    Sortable,
    DraggableList
},
  computed: {
    array: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('update:value', val);
      },
    },
  },
  methods: {
    async handleChange(e) {
      // const array = this.array.slice();
      // const item = array.splice(e.oldIndex, 1)[0];
      // array.splice(e.newIndex, 0, item);
      this.$emit('update:value', e);
    },
    addObject() {
      const list = this.copy(this.value);
      let object = null;
      if (this.defaultObject instanceof Function) {
        object = this.defaultObject();
      } else {
        object = this.copy(this.defaultObject);
      }
      if (!object.id) {
        const id = this.$root.id();
        object.id = id;
      }

      list.push(object);
      this.$emit('add', object);
      this.$emit("update:value", list);
    },
    updateRow(idx, row) {
      const list = this.copy(this.value);
      list[idx] = row;
      this.$emit('update', { index: idx, object: row });
      this.$emit("update:value", list);
    },
    updateField(idx, key, value) {
      const list = this.copy(this.value);
      const object = list[idx];
      object[key] = value;
      this.$emit('update', { index: idx, object });
      this.$emit("update:value", list);
    },
    removeObject(object) {
      if (this.noRemovePrompt) {
        this.$emit('remove', object);
      } else {
        let message = this.removePrompt.slice(0);
        let name = 'this';
        if (object) {
          if (object.name) {
            name = object.name;
          }
          if (object.title) {
            name = object.title;
          }
        }
        message = message.replace('{name}', name);
        if (confirm(message)) {
          const idx = this.value.findIndex(a => a.id === object.id || Number.isNaN(a.id));
          if (idx > -1) {
            const list = this.copy(this.value);
            list.splice(idx, 1);
            this.$emit('remove', object);
            this.$emit("update:value", list);
          }
        }
      }
    },
  },
};
</script>
