import sortby from 'sort-by';

import pick from '../utils/pick';


/** make the ai characters think */
export function plot(characters) {
  for (const char of characters.filter(c => !!c.action_order)) {
    const choices = [];
    for (const { name, probability } of char.action_order) {
      const performance = char.canPerform(name);
      if (performance.possible) {
        for (let x = 0; x < (probability || 1); x += 1) {
          choices.push(name);
        }
      }
    }

    if (choices.length > 0) {
      const names = pick(choices, 3); // top three actions
      for (const name of names) {
        const action = char.sheet.actions.find(a => a.name === name);
        if (!action) {
          throw new Error(`Failed to find action named ${name} [set: ${char.sheet.actions.map(a => a.name)}]`);
        }
        let targets = [];
        if (action.targets === 'me') {
          targets = [char];
        } else if (action.targets === 'friendly') {
          targets = characters.filter(c => c.team === char.team);
        } else if (action.targets === 'any' || action.targets === 'random') {
          targets = characters;
        } else {
          targets = characters.filter(c => c.team !== char.team);
        }
        if (targets.length > 0) {
          targets = pick(targets, action.targetQuantity || 1).map(t => t.id);
          char.action = { name, targets };
          break;
        }
      }
    } else {
      char.action = null;
    }
  }
}


/** performs a single round of battle */
export function round(characters) {
  for (const char of characters) {
    char.cooldown();
  }

  // roll initiative
  for (const char of characters) {
    char.init = char.initiative();
  }
  characters.sort(sortby('-init'));

  // list teams
  const teams = new Set();

  // perform actions
  for (const char of characters) {
    teams.add(char.team);
    char.results = null;
    if (char.alive() && char.action) {
      const targets = characters.filter(c => char.action.targets.indexOf(c.id) > -1);
      char.results = char.perform(char.action.name, targets);
    } else {
      char.action = null;
    }
  }

  let winners = [];
  for (const team of teams) {
    if (characters.filter(c => c.team === team).some(c => c.alive())) {
      winners.push(team);
    }
  }

  return {
    winner: winners.length === 1 ? winners[0] : null,
    finished: winners.length <= 1,
    results: characters,
  };
}
