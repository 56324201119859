export default function pick(array, num) {
  const possible = array.slice(0);
  const choices = [];
  for (let n = 0; n < num; n += 1) {
    const idx = Math.floor(Math.random() * possible.length);
    choices.push(possible.splice(idx, 1)[0]);
    if (possible.length === 0) {
      break;
    }
  }
  return choices;
}
