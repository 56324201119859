<template>
  <button
    v-if="file && file.fid"
    class="favorite-heart button icon"
    @click.prevent="handleFavorite"
  >
    <span>{{ file.favorites }}</span>
    <i v-if="app.favorites[file.fid]" class="fa fa-heart"></i>
    <i v-else class="fa fa-heart-o"></i>
  </button>
</template>

<script lang="ts" setup>
import { Story } from "@/stores/story_defs";
import { shallowRef } from "vue";

const props = defineProps<{
  file: Story;
}>();

const app = shallowRef(window.app);

async function handleFavorite() {
  app.value.toggleFileFavorite(props.file);
}
</script>

<style scoped>
#app .favorite-heart i {
  font-size: 15px;
}
#app .favorite-heart {
  flex: 0 0 auto;
  padding: 9px;
  height: fit-content;
  align-items: center;
  justify-content: center;
  display: flex;
}
</style>
