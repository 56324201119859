<template>
  <div class="pause-content">
    <label for="seconds">{{ $t('direction.seconds') }}</label>
    <input
      name="seconds"
      type="number"
      size="4"
      :value="value.ms ? value.ms / 1000 : 1"
      @input="$emit('update', { ms: $event.target.value * 1000 })"
    />
  </div>
</template>

<script>
export default {
  name: 'PauseContent',
  props: {
    story: Object,
    scene: Object,
    value: Object,
  },
}
</script>

<style scoped>
input[name='seconds'] {
  max-width: 100px;
  text-align: right;
}
</style>
