import copy from "@morphosis/base/functions/copy";
import { defineStore } from "pinia";
import { nextTick, Ref, ref, shallowRef, watch } from "vue";

import { debounce } from "@/utils/deferred";
import type { AudioSource, Character, EffectProfile, Story, Style } from "./story_defs";

export const useStoryStore = defineStore("story", () => {
  const story: Ref<Story | null> = shallowRef(null);
  const animation: Ref<Animation[]> = ref([]);
  const audio: Ref<AudioSource[]> = ref([]);
  const characters: Ref<Character[]> = ref([]);
  const effectProfiles: Ref<EffectProfile[]> = ref([]);
  const music: Ref<AudioSource[]> = ref([]);
  const styles: Ref<Style[]> = ref([]);

  const index = { effectProfiles, characters, styles, music, audio, animation };

  for (const name in index) {
    watch(index[name], () => updateProp(name), { deep: true });
  }

  async function updateProp(name: string) {
    if (story.value) {
      console.warn("story changed", name, story.value[name]);
      story.value[name] = copy(index[name].value);
      if (await debounce("store:story:save")) {
        console.warn("story saved");
        story.value.saveAndPush();
      }
    }
  }

  async function setTargetStory(newStory: Story | null) {
    if (newStory) {
      story.value = null;
      for (const name in index) {
        index[name].value = newStory[name] || [];
      }
      await nextTick();
      story.value = newStory;
    } else {
      story.value = null;
      await nextTick();
      for (const name in index) {
        index[name].value = [];
      }
    }
  }

  return {
    animation,
    audio,
    characters,
    effectProfiles,
    music,
    story,
    styles,
    setTargetStory,
  };
});
