<style scoped>
.typer {
  position: relative;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  /* overflow: hidden; */
}
.typed {
  white-space: pre-wrap;
}
.typer::-webkit-scrollbar {
  display: none;
}
.typer-inner {
  /* TODO: wasn't commented, why? */
  /* animations will sometimes overflow */
  /* overflow: hidden; */
  display: block;
  position: relative;
  z-index: 2;
  /* white-space: nowrap; */
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.allowwrap:deep(.type) {
  overflow: visible !important;
}
.allowwrap:deep(.word.long) {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-all;
}
.allowwrap:deep(.word.whitespace),
.allowwrap:deep(.word.whitespace > span) {
  display: inline;
  white-space: pre-wrap;
}
.particle {
  position: absolute;
  border-radius: 50%;
  z-index: 1;
}
.measure {
  visibility: hidden;
  touch-action: none;
  white-space: break-spaces;
  position: absolute;
}
.particles {
  top: 0px;
  left: 0px;
  position: absolute;
}
</style>

<template>
  <div class="typer" :class="{ allowwrap: allowWrap }" @click="allowFastforward ? speedup() : null">
    <div class="particles">
      <div
        class="particle"
        v-for="(particle, idx) of particles.filter((p) => p.type == 'particle')"
        :key="idx"
        :style="{
          transform: `translate(${particle.x - particle.size / 2}px, ${
            particle.y - particle.size / 2
          }px) rotate(${particle.a}rad)`,
          width: `${particle.size}px`,
          height: `${particle.size}px`,
          maskImage: particle.decal ? `url(${particle.decal})` : null,
          backgroundColor: `${particle.color}`,
          opacity: particle.opacity,
        }"
        v-show="particle.expires > now"
      >
        {{ particle.char }}
      </div>
    </div>
    <div class="measure" ref="measure" />
    <div class="typer-inner" ref="inner">
      <!-- @click.prevent.stop="handleType"> -->
      <typer-leaf
        :position="cidx"
        :finished="typed"
        :words="words"
        :variables="variables"
        :allowImages="allowImages"
      />
    </div>
  </div>
</template>

<script>
import Animation from "@/utils/animations";
import debug from "debug";
import bitdown from "./bitdown";
import TyperLeaf from "./typer_leaf.vue";
import { typerSplitLine } from "./typer_split.ts";

const log = debug("typer");

function nrand(x) {
  return (Math.random() - 0.5) * 2 * x;
}

function h2r(hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
    : null;
}

function r2h(rgb) {
  return "#" + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1);
}

function interpolateColor(color1, color2, factor) {
  let result = color1.slice();
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  }
  return result;
}

function merge(a, b) {
  for (let prop in b) {
    if (b[prop] || b[prop] === 0) {
      a[prop] = b[prop];
    }
  }

  return a;
}

export default {
  components: {
    TyperLeaf,
  },
  props: {
    text: String,
    allowImages: { type: Boolean, default: false },
    allowWrap: { type: Boolean, default: true },
    speed: { type: Number, default: 32 },
    allowFastforward: { default: true },
    animationConfig: { type: Object, required: false },
    animationDurationMultiplier: { type: Number, default: 1 },
    animations: { type: Array, required: false },
    story: { type: Object, required: false },
    delay: { type: Number, default: 0 },
    particleConfig: { type: Object },
    variables: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      version: 0,
      cidx: -1,
      typed: -1,
      words: [],
      multipliers: {
        "\n": 5,
        ".": 8,
        "?": 8,
        ",": 6,
        "-": 0,
        image: 8,
      },
      finished: false,
      particles: [],
      now: 0,
      hasNextFrame: false,
      lastIndex: 0,
      output: null,
      myWidth: 0,
      mWidth: -1,
    };
  },
  watch: {
    async text() {
      this.reset();
    },
    particleConfig: {
      handler() {
        this.reset();
      },
      deep: true,
    },
    animationConfig: {
      handler(o, n) {
        // console.log(o, n);
        if (o.enter !== n.enter || o.exit !== n.exit) {
          this.createAnimators();
        }
      },
      deep: true,
    },
    animations: {
      handler() {
        this.createAnimators();
      },
      deep: true,
    },
  },
  mounted() {
    this.myWidth = this.$refs.inner.getBoundingClientRect().width;

    // log("%ctext started", "font-weight: bold; color: darkblue", this.text);
    this.createAnimators();
    this.splitWords();
    this.animation = this.enterAnimation;
    setTimeout(this.handleType.bind(this), this.delay);

    if (this.speed <= 1) {
      this.speedup();
    }
  },
  unmounted() {
    this.resetStyles();
  },
  methods: {
    splitWords() {
      this.words = [];
      const output = bitdown(this.text, this.story, this.variables);
      // console.log("md", this.text, '\n >> \n', output);
      const words = typerSplitLine(output);

      let lineWidth = 0;
      words.forEach((w) => {
        w.width = this.measureWidth(w.content);
        lineWidth += w.width;
        if (w.content.includes("\n")) {
          lineWidth = 0;
          w.newLine = true;
        } else if (lineWidth > this.myWidth) {
          lineWidth = w.width;
          w.newLine = true;
        }
        w.long = w.width > window.innerWidth * 0.8;
      });

      this.lastIndex = this.measureWords(words);
      this.words = words;
    },
    measureWords(line, index = 0, id = 1) {
      for (const word of line) {
        word.id = id;
        id += 1;
        word.startsAt = index;
        if (word.children) {
          index = this.measureWords(word.children, index, id);
        } else {
          index += word.content.length;
        }
        word.endsAt = index;
      }
      return index;
    },
    measureWidth(text) {
      if (this.mWidth === -1) {
        this.$refs.measure.textContent = "m";
        this.mWidth = this.$refs.measure.getBoundingClientRect().width;
      }
      this.$refs.measure.textContent = "m" + text.join("");
      return this.$refs.measure.getBoundingClientRect().width - this.mWidth;
    },
    resetStyles() {
      if (this.animation) {
        this.animation.stop();
      }
      for (let w = 0; w < this.words.length; w += 1) {
        for (let c = 0; c < this.words[w].content.length; c += 1) {
          const el = this.$refs[`char_${w}_${c}`];
          if (el && el[0]) {
            el[0].style = null;
          }
        }
      }
    },
    createAnimators() {
      this.resetStyles();
      this.exitAnimation = null;
      this.enterAnimation = null;
      this.idleAnimation = null;
      if (this.animationConfig) {
        const enter = this.animations.find((a) => a.id === this.animationConfig.enter);
        const idle = this.animations.find((a) => a.id === this.animationConfig.idle);
        const exit = this.animations.find((a) => a.id === this.animationConfig.exit);
        if (enter) {
          this.enterAnimation = new Animation(
            enter.pipeline,
            enter.duration * this.animationDurationMultiplier,
          );
        }
        if (idle) {
          this.idleAnimation = new Animation(
            idle.pipeline,
            idle.duration * this.animationDurationMultiplier,
          );
        }
        if (exit) {
          this.exitAnimation = new Animation(
            exit.pipeline,
            exit.duration * this.animationDurationMultiplier,
            true,
          );
        }
      }
      // console.log('built animations', this.enterAnimation);
    },
    async clear() {
      if (this.animation) {
        await this.animation.stop();
        log("animation stopped");
      }
      this.version += 1;
      this.cidx = -1;
      this.typed = -1;
      this.finished = true;
      log("cleared");
    },
    async reset() {
      // console.log("%ctext updated", "font-weight: bold; color: darkblue",  this.text);
      this.splitWords();

      this.cidx = -1;
      this.typed = -1;
      this.version += 1;
      this.finished = false;
      this.particles.length = 0;

      // next tick to prevent animation bleed
      if (this.animation) {
        await this.animation.finished();
      }
      this.animation = this.enterAnimation;
      this.$nextTick(() => setTimeout(this.handleType.bind(this), this.delay));
    },
    getChar(index, words) {
      const word = words.find((word) => word.startsAt <= index && word.endsAt > index);
      if (!word) {
        return null;
      }
      let char = null;
      if (word.children) {
        char = this.getChar(index, word.children);
      } else {
        char = word.content[index - word.startsAt];
      }
      return char;
    },
    async handleType() {
      let cidx = this.cidx + 1;
      this.cidx = cidx;

      if (cidx < this.lastIndex) {
        //this.words.length) {
        const char = this.getChar(cidx, this.words);
        if (/\W/.test(char)) {
          // this.words.push('');
        } else {
          this.$emit("type", char);
          if (this.particleConfig) {
            this.particle(this.particleConfig);
          }
        }
        clearTimeout(this.__type);
        let multiplier = this.multipliers[char] || 1;
        this.__type = setTimeout(
          this.handleType.bind(this),
          (this.speed || 32) * multiplier * this.animationDurationMultiplier,
        );
      } else {
        if (this.animation) {
          await this.animation.finished();
        }
        this.finished = true;
        this.$emit("end");
        log("finished");
      }

      if (this.animation) {
        await this.$nextTick();
        const el = this.$el.querySelector(`#char_${cidx}`);
        if (el) {
          const version = this.version;
          this.animation.start(el).then(() => {
            // when the animation is reset, old versions would contine
            // to pollutes the next render with incorrect data
            if (this.version === version) {
              this.typed = cidx;
            }
          });
        } else {
          this.typed = cidx;
        }
      } else {
        this.typed = cidx;
      }
    },
    async speedup(e) {
      const animationFinished = this.animation ? this.animation.isFinished() : true;
      // log("text finished?", this.finished);
      // log("animation fnished?", animationFinished);
      // log("clearing?", this.cleared ? this.cleared.resolved : 'n/a');
      // log('----');
      // console.warn('hurry text');

      this.resetStyles();
      if (this.cleared && !animationFinished) {
        this.animation.stop();
        this.cleared.resolve();
        return true;
      } else if (!this.finished || !animationFinished) {
        clearTimeout(this.__type);
        if (this.animation) {
          this.animation.stop();
        }
        if (!this.finished) {
          this.typed = this.lastIndex;
          this.cidx = this.lastIndex;
          this.finished = true;
          this.$emit("end");
        }
        return true;
      } else {
        if (animationFinished) {
          this.$emit("click", e);
          return false;
        }
        return true;
      }
    },
    async particle(spec) {
      await this.$nextTick();

      if (!spec.x) {
        spec = this.copy(spec);

        await this.$nextTick();
        let parent = this.$el.getBoundingClientRect();
        let box = this.$el.querySelector(".char.cursor").getBoundingClientRect();

        // console.log('box', box, this.$el.querySelector(".word"));
        spec.x = box.x - parent.x + box.width * 0.5;
        spec.y = box.y - parent.y + box.height * 0.5;
        // spec.x = box.x - parent.x + box.width * 0.0;
        // spec.y = box.y - parent.y + box.height * 0.4;
      }

      if (spec.type == "emitter") {
        spec.probability *= 0.2;
        this.particles.push(spec);
      } else {
        spec = merge(
          {
            type: "particle",
            probability: 1.0,
            color1: "#ff8000",
            color2: "#c0c0c0",
            count: 1,
            a: 0,
            da: 0.2,
            da2: 0.1,
            dx: 0.5,
            dy: 0.5,
            dy2: 0.8,
            gravity: 4.4,
            size: 20,
            dsize: 0.7,
            duration: 0.9,
          },
          spec,
        );

        for (let x = 0; x < spec.count; x += 1) {
          if (Math.random() <= spec.probability) {
            // reuse, recycle

            let p = this.particles.find((p) => p.expires && p.expires <= Date.now());
            if (p || this.particles.length < 1500) {
              let particle = {
                type: "particle",
                a: spec.a + nrand(spec.da),
                x: spec.x,
                y: spec.y,
                da: nrand(spec.da2) / 10,
                dx: nrand(spec.dx),
                dy: nrand(spec.dy) - spec.dy2,
                ddx: 0,
                ddy: parseFloat(spec.gravity) / 100,
                size: spec.size,
                dsize: spec.dsize,
                colors: [h2r(spec.color1), h2r(spec.color2)],
                char: spec.char,
                duration: spec.duration * 1000,
                expires: spec.duration ? Date.now() + spec.duration * 1000 : 0,
                decal: spec.decal,
              };
              if (p) {
                for (let prop in particle) {
                  p[prop] = particle[prop];
                }
              } else {
                this.particles.push(particle);
              }
            }
          }
        }
      }
      if (!this.hasNextFrame) {
        this.nextFrame();
      }
    },
    nextFrame() {
      this.now = Date.now();

      // check particles
      let expired = 0;
      for (let particle of this.particles) {
        if (!particle.expires || particle.expires > this.now) {
          if (particle.type == "emitter") {
            if (Math.random() * 100 < 12) {
              let p2 = this.copy(particle);
              p2.type = "particle";
              this.particle(p2);
            }
          } else {
            let t = Math.max(0, particle.expires - this.now) / particle.duration;
            particle.a += particle.da;
            particle.x += particle.dx;
            particle.y += particle.dy;
            particle.dx += particle.ddx;
            particle.dy += particle.ddy;
            particle.size += particle.dsize;
            particle.opacity = t;
            if (particle.colors) {
              particle.color = r2h(interpolateColor(particle.colors[0], particle.colors[1], 1 - t));
            }
          }
        } else {
          expired += 1;
        }
      }

      let particle = this.particles[0];

      if (expired < this.particles.length) {
        this.hasNextFrame = true;
        window.requestAnimationFrame(this.nextFrame.bind(this));
      } else {
        this.hasNextFrame = false;
        log("particles finished");
      }
    },
  },
};
</script>
