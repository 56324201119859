<template>
  <div class="background-detail flex-column">
    <div v-if="editing" class="background-meta flex-row">
      <form @submit.prevent class="flex1 flex-row flex-wrap">
        <div class="flex-column">
          <div class="flex-column flex-align-top">
            <label for="name" class="text-left">{{ $t("backgrounds.form-field-name") }}</label>
            <input
              type="text"
              name="name"
              v-model="object.name"
              placeholder="Untitled"
              @input="doSave()"
            />
          </div>
          <div class="flex-row flex-left flex-align-center">
            <label for="repeat" class="flex-static">
              <input type="checkbox" name="repeat" v-model="object.repeat" @input="doSave()" />
              Repeat
            </label>
          </div>
        </div>
        <media-uploader
          @update:value="updateImage($event)"
          :value="object"
          :use-cloud="props.story.cloudSync"
          class="flex1"
        />
      </form>
      <div v-if="object.error" class="error" @click="removeError()">
        {{ object.error }}
      </div>
    </div>
    <div v-if="object.blob_id">
      <BlobImage :blob="object.blob_id" />
    </div>
    <!-- <div v-else-if="object.layers" class="layers">
      <div class="editor flex-row flex-static scroll-x">
        <div v-for="layer of object.layers" class="card mellow flex-static">
          <media-uploader
            @update:value="updateImage(layer)"
            :value="layer"
            :use-cloud="props.story.cloudSync"
            class="flex1"
          />
          <div>

          </div>
        </div>
        <div>
          Add layer
        </div>
      </div>
      <div v-for="layer of object.layers" class="detail-image">
        <BlobImage :blob="layer.blob_id" />
      </div>
    </div> -->
  </div>
</template>

<script setup lang="ts">
import copy from "@/utils/copy";
import { onMounted, ref, watch } from "vue";

const props = defineProps({
  story: { type: Object, required: true },
  instance: { type: Object, required: true },
  editing: { type: Boolean, default: false },
});
const emit = defineEmits(["update:instance"]);

const object = ref({ name: "loading...", layers: [] });

watch(
  props,
  () => {
    migrate(props.instance);
  },
  { deep: true },
);

onMounted(async () => {
  migrate(props.instance);
  await props.story.fixMissingMedia("backgrounds");
});

async function migrate(bg) {
  object.value = copy(props.instance);
  if (!object.value.layers) {
    object.value.layers = [{ blob_id: object.value.blob_id, depth: 0 }];
    // object.value.blob_id = null;
    // await doSave();
  }
}

async function updateImage(update) {
  for (const prop in update) {
    if (prop !== "name" || !object.value[prop]) {
      object.value[prop] = update[prop];
    }
  }
  emit("update:instance", object.value);
  await doSave();
}

function removeError() {
  object.value.error = null;
  doSave();
}

async function doSave() {
  if (object.value.id) {
    emit("update:instance", object.value);
    await props.story.saveAndPush();
  }
}
</script>

<style scoped>
.background-detail {
  padding: 5px;
  background: var(--color1_20);
  border: 2px solid var(--color2_20);
  border-radius: var(--softness);
  overflow: hidden;
  flex: 1 1 auto;
  min-height: 100px;
  height: min-content;
}
.bg > * {
  max-width: 100%;
}
img {
  background: white;
  object-fit: contain;
  flex: 1 1 20vw;
  align-self: center;
  max-width: 100%;
}
form {
  min-width: 150px;
  margin: 0 10px;
}
label {
  justify-content: flex-end;
  align-items: center;
}
.background-meta {
  flex: 1 1 auto;
}
.detail-image {
  flex: 999 999 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.detail-image > img {
  object-fit: cover;
}
.editor {
  height: 170px;
}
</style>
