<style>
.blog-background {
  background-position: center;
  background-size: cover;
}
</style>

<template>
  <div
    v-if="image"
    class="blog-background"
    @click="$emit('click', $event)"
    :style="{backgroundImage: image ? `url(${image})` : ''}"
  >
    <slot></slot>
  </div>
</template>

<script>
import Media from '../models/media';

export default {
  name: "blob-image",
  props: {
    blob: String,
    thumb: { type: Boolean, default: false },
  },
  data() {
    return {
      image: null,
    };
  },
  watch: {
    async blob() {
      await this.getImage();
    }
  },
  async mounted() {
    await this.getImage();
  },
  methods: {
    async getImage() {
      if (this.blob) {
        const instance = await Media.load(this.blob);
        if (instance) {
          if (this.thumb) {
            this.image = await instance.getThumbnail();
          } else {
            this.image = await instance.getUrl();
          }
        }
      } else {
        this.image = null;
      }
    }
  }
}
</script>