<template>
  <transition
    appear
    mode="out-in"
    v-bind:css="false"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
    @click.capture="stop"
  >
    <slot></slot>
  </transition>
</template>

<script>
import Animation from "../utils/animations";

function nextFrame() {
  return new Promise((r) => {
    requestAnimationFrame(r);
  });
}

export default {
  name: "animation",
  props: {
    id: String,
    playReverse: { type: Boolean, default: true },
    animations: Array,
  },
  data() {
    let resolve = null;
    const promise = new Promise((r) => (resolve = r));
    return {
      promise,
      resolve,
    };
  },
  watch: {
    id() {
      this.setup();
    },
    animations() {
      this.setup();
    },
  },
  mounted() {
    this.setup();
    this.resolve();
  },
  methods: {
    in() {
      if (this.forward) {
        return !this.forward.isFinished();
      }
      return false;
    },
    stop() {
      if (this.forward) {
        this.forward.stop();
      }
      if (this.reverse) {
        this.reverse.stop();
      }
    },
    setup() {
      if (this.animations) {
        this.config = this.animations.find((a) => a.id === this.id);
        if (this.config) {
          this.forward = new Animation(this.config.pipeline, this.config.duration);
          if (this.playReverse) {
            this.reverse = new Animation(this.config.pipeline, this.config.duration, true);
          }
        }
      }
    },

    // ENTERING
    beforeEnter(el) {
      el.style.display = null;
    },
    // the done callback is optional when
    // used in combination with CSS
    async enter(el, done) {
      await this.promise;
      if (this.forward) {
        await this.forward.stop();
        this.promise = this.forward.start(el);
      }
      await this.promise;
      done();
    },
    afterEnter(el) {
      this.$emit("after-enter", el);
    },

    // LEAVING
    beforeLeave(el) {},
    // the done callback is optional when
    // used in combination with CSS
    async leave(el, done) {
      if (this.reverse) {
        await this.reverse.stop();
        this.promise = this.reverse.start(el);
        await this.promise;
      }
      done();
    },
    afterLeave(el) {
      el.style.transition = null;
      el.style.opacity = 0;
      this.$emit("after-leave", el);
    },
  },
};
</script>
