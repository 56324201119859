<template>
  <div class="chooser row" :class="{choosing, }">
    <label v-if="title" @click.prevent="choosing = !choosing">
      <typer :text="title" />
    </label>

    <div @click.prevent="startChoosing" class="item display">
      <slot :object="chosen" v-if="chosen">
        {{ chosen }}
      </slot>
      <slot v-else name="empty">
        ----
      </slot>
    </div>
    <Popover v-if="choosing" :value="true" @show="choosing = $event">
      <h2>{{ title }}</h2>
      <div class="items column stretch">
        <!-- <typer :text="title" /> -->
        <div class="item empty stretch" @click="makeChoice(null)">
          <slot name="empty">
            ----
          </slot>
        </div>
        <a
          v-for="object of list" :key="object.id"
          @click.prevent="makeChoice(object.id)"
          :class="{active: chosen && object.id === chosen.id}"
          class="item stretch"
          href="#"
        >
          <slot :object="object">
            {{ object }}
          </slot>
        </a>
        <slot name="footer">
        </slot>
      </div>
    </Popover>
  </div>
</template>

<script>
import Popover from "@morphosis/base/components/Popover.vue";

export default {
  components: {
    Popover,
  },
  props: {
    title: String,
    value: null,
    story: Object,
    list: Array,
  },
  data() {
    return {
      choosing: false,
    };
  },
  computed: {
    chosen() {
      return this.list.find(o => o.id == this.value);
    },
  },
  methods: {
    async startChoosing() {
      this.choosing = true;
      await this.$nextTick();
      const el = this.$el.querySelector('.active');
      if (el) {
        el.scrollIntoView({
          block: 'center', inline: 'center', behavior: 'smooth',
        });
      }
    },
    makeChoice(v) {
      this.$emit("update:value", v);
      this.choosing = false;
    },
  },
};
</script>

<style scoped>
  label {
    flex: 0 1 100px;
  }
  .items {
    display: block;
    margin: var(--spacing);
    overflow: hidden;
    overflow-y: auto;
  }
  .item {
    background: var(--color2);
    justify-content: stretch;
    align-items: stretch;
    min-width: 10px;
    width: 100%;
    text-align: left;
    margin-bottom: var(--spacing);
  }
  .items .item {
    font-size: 1.2em;
    padding: 5px;
  }
  .display {
    padding-left: var(--spacing);
    align-items: flex-start;
    justify-content: center;
  }
</style>
