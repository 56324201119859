/* eslint-disable no-restricted-syntax */
import { nanoid } from 'nanoid';
import Manager from './base';
import copy from '../../functions/copy';


export default class FilesystemManager extends Manager {
  static get prefix() { return this.appPrefix || ''; }

  static set prefix(v) { this.appPrefix = v; }

  async getStore() {
    const label = `${this.constructor.prefix}:${this.name}`;
    if (!window.app) {
      console.error('this model requires an `app`');
      throw new Error('missing app');
    } else if (!window.app.getFilesystem) {
      console.error('this model requires an app with filesystem_mixin', window.app);
      throw new Error('missing app with filesystem_mixin');
    } else {
      return window.app.getFilesystem(label);
    }
  }

  async list(ref) {
    const store = await this.getStore();
    const retval = ref || [];
    await store.iterate((object) => {
      retval.push(object);
    });
    return retval;
  }

  async count() {
    const store = await this.getStore();
    return store.length();
  }

  async load(id) {
    const store = await this.getStore();
    return store.getItem(`${id}`);
  }

  async save(object) {
    const store = await this.getStore();
    let data = object;
    if (!object.id) {
      data = copy(object);
      data.id = nanoid();
    }

    try {
      await store.setItem(data.id, data);
    } catch(ex) {
      window.app.addAlert({
        title: 'Failed to write to with mgLocalStorage driver',
        message: `${ex.message}`,
        ex,
      });
    }
    return data.id;
  }

  async delete(id) {
    const store = await this.getStore();
    return store.removeItem(`${id}`);
  }
}
