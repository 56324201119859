export class Bouncer {
  resolve: Function | null;
  timeout: number;
  promise: Promise<boolean> | null;
  _timeout: number = -1;

  constructor(timeout = 1000) {
    this.resolve = null;
    this.timeout = timeout;
    this.promise = null;
  }

  async debounce(): Promise<boolean> {
    this.cancel();
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      clearTimeout(this._timeout);
      this._timeout = window.setTimeout(() => {
        resolve(true);
      }, this.timeout);
    });
    return this.promise;
  }

  cancel() {
    if (this.resolve) {
      clearTimeout(this._timeout);
      this.resolve(false);
      this.promise = null;
    }
  }
}

const bouncers = {};
export function debounce(name: string, timeout: number = 1000): Promise<boolean> {
  if (!bouncers[name]) {
    bouncers[name] = new Bouncer(timeout);
  }
  return bouncers[name].debounce();
}

export function timer(timeout: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, timeout));
}

export function readFileAsDataURL(file: File): Promise<string> {
  const reader = new FileReader();
  const promise = new Promise<string>((resolve) => {
    reader.onload = (e) => {
      resolve((e.target?.result || "") as string);
    };
  });
  reader.readAsDataURL(file);
  return promise;
}

export function readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
  const reader = new FileReader();
  const promise = new Promise<ArrayBuffer>((resolve) => {
    reader.onload = (e) => {
      if (e.target?.result) {
        resolve(e.target.result as ArrayBuffer);
      } else {
        resolve(new ArrayBuffer(0));
      }
    };
  });
  reader.readAsArrayBuffer(file);
  return promise;
}

export function loadImage(file: string): Promise<HTMLImageElement> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.src = file;
  });
}
