<template>
  <div key="suggestions" class="tab">
    <help-message name="choice-tags-suggestions">
      {{ $t('direction.input-suggestion-help') }}
    </help-message>
    <div class="form-row">
      <thought-array
        :value="value.suggestions || []"
        @update:value="$emit('update', { suggestions: $event })"
      />
    </div>
  </div>
</template>

<script>
import ThoughtArray from '../thought_array.vue';

export default {
  name: 'SuggestionsTab',
  components: {
    ThoughtArray,
  },
  props: {
    story: Object,
    scene: Object,
    value: Object,
  },
}
</script>
