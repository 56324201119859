<template>
  <popover
    id="searching-modal"
    @update:value="$emit('update:value', $event)"
    @show="$emit('show', $event)"
    color-theme="mellow"
    :value="true"
  >
    <a
      @click.prevent="$emit('show', false)"
      @keypress.prevent="$emit('show', false)"
      class="close-button"
    >
      &times;
    </a>
    <SearchingList
      :placeholder="placeholder || $t('story_browser.search')"
      :config="config"
      :actions="actions"
      :options="options"
      :value="value"
      @escape="$emit('show', false)"
      @action="$emit('action', $event)"
      @update:filter="$emit('update:filter', $event)"
      @update:value="$emit('update:value', $event); $emit('show', false)"
    >
      <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>
    </SearchingList>
    <slot name="footer" />
  </popover>
</template>

<script>
import SearchingList from './SearchingList.vue';

export default {
  name: 'SearchingModal',
  components: { SearchingList },
  props: {
    placeholder: { type: String, required: false },
    config: { type: Object, default() { return null; } },
    actions: { type: Array, default() { return []; } },
    options: { type: Array, required: true },
    value: { type: [Number, String], default() { return ''; } },
  },
}
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}
</style>