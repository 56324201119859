<style scoped>
  textarea {
    width: 100%;
    height: max-content;
    background: transparent;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    overflow: hidden;
    padding: 8px;
    border-radius: 5px;
  }
  .option-check {
    flex: 0 0 70px;
    opacity: 0.4;
    font-size: 1.3em;
  }
  .label {
    background: var(--spark);
    color: var(--spark_text);
    padding: calc(var(--padding)) calc(var(--padding) * 2);
    margin-right: var(--spacing);
    border-radius: calc(var(--spacing) * 3);
    font-size: 0.75em;
  }
</style>

<template>
  <div class="option">
    <div v-if="!overlayout" class="alert block">
      <i18n-t
        path="option_content.option-directions-require-overlayout"
        tag="span"
      >
        <router-link :to="{ name: 'story-settings', params: { story: story.id } }">
          {{ $t('option_content.settings') }}
        </router-link>
      </i18n-t>
    </div>
    <div class="flex-row">
      <div
        class="flex-row flex-align-center flex-center option-check"
      >
        <i v-if="min === 0 && max === 1" class="fa fa-check-square" />
        <i v-else class="fa fa-sort" />
      </div>
      <div>
        <autogrow-textarea
          :modelValue="value.label"
          :style-name="value.style"
          :speed="value.speed"
          :story="story"
          :placeholder="$t('text_content.add-text-here')"
          ref="textarea"
          class="stretch"
          tabindex="0"
          @keydown="$emit('keydown', $event)"
          @keypress="$emit('keypress', $event)"
          @update:speed="$emit('update', {speed: $event})"
          @update:style-name="$emit('update', {style: $event})"
          @update:modelValue="$emit('update', {label: $event})"
        />
        <div class="flex-row flex-left">
          <div v-if="value.min" class="label flex-static">{{ $t('option_content.min-value-min', {min: value.min}) }}</div>
          <div v-if="value.max" class="label flex-static">{{ $t('option_content.max-value-max', {max: value.max}) }}</div>
          <div v-if="value.step" class="label flex-static">{{ $t('option_content.step-value-step', {step: value.step}) }}</div>
          <div v-if="value.points" class="label flex-static block muted small">
            {{ $t('advanced.shared-points') }}
            <i>{{ value.points }}</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CharacterModal from '../editor/character_modal.vue';

export default {
  name: 'text-direction',
  components: {
    CharacterModal,
  },
  props: {
    story: Object,
    scene: Object,
    value: Object,
  },
  data() {
    return {
      showModal: null,
    };
  },
  computed: {
    overlayout() {
      return this.story.settings.overlayout;
    },
    min() {
      return this.value.min || 0;
    },
    max() {
      return this.value.max || 1;
    },
    step() {
      return this.value.step || 1;
    },
    style() {
      let retval = this.story.styles.find(s => s.id == this.value.style);
      if (!retval) {
        retval = this.story.styles.find(s => s.default);
      }
      return retval;
    },
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  },
};
</script>
