<template>
  <div class="video-content">
    <input
      :value="value.youtube"
      @input="fixCode($event)"
      :placeholder="$t('video_content.youtube-code')"
    >
    <div class="flex-row flex-center">
      <iframe
        v-if="value.youtube"
        :src="`https://www.youtube-nocookie.com/embed/${value.youtube}`"
        :title="$t('video_content.youtube-video-player')"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
    </div>
  </div>
</template>

<script>
import ChoiceLine from '../choice_line.vue';
import InputArray from '../input_array.vue';
import TagPicker from '../editor/tag_picker.vue';

export default {
  components: {
    TagPicker,
    ChoiceLine,
    InputArray,
  },
  props: {
    story: Object,
    scene: Object,
    value: Object,
  },
  data() {
    return {};
  },
  methods: {
    fixCode($event) {
      let code = $event.target.value;
      code = code.replace('https://youtu.be/', '');
      code = code.replace('https://www.youtube.com/embed/', '');
      this.update({youtube: code})
    },
    update(value) {
      this.$emit('update', value);
    },
  },
};
</script>

<style scoped>
label {
  margin-right: 5px;
}
iframe {
  width: 100%;
  max-width: 600px;
  height: 60vw;
  max-height: 400px;
}
</style>
