import { TsunamiUser } from "./user/user_defs";

export interface RemoteCall {
  event: string;
  data?: any;
  user?: TsunamiUser;
  method?: string;
  headers?: { [key: string]: string };
}

const statusTexts = {
  413: "Your upload is too large and the server rejected it",
};

export async function jsonapi(opts: RemoteCall): Promise<any> {
  let url = `${import.meta.env.VITE_APP_TSUNAMI_SERVER}${opts.event}/`;
  let fetchopts = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(opts.headers || {}),
    },
    method: opts.method || "POST",
    body: undefined as any | undefined,
  };
  let data: any = {};
  if (opts.data) {
    data = { ...opts.data };
  }
  if (opts.user?.token) {
    data.token = opts.user.token;
    data.token_user = opts.user.id;
  }
  if (fetchopts.method.toUpperCase() === "GET") {
    const params: string[] = [];
    for (let key in data) {
      params.push(`${key}=${data[key]}`);
    }
    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }
  } else if (data) {
    fetchopts.body = JSON.stringify(data);
  }
  // console.log(">>> api", url, fetchopts); //, opts.data);
  let response = await fetch(url, fetchopts);
  if (response.headers.get("Content-Type") === "application/json") {
    return response.json();
  } else if (response.status === 204) {
    return { ok: true, message: "no content" };
  } else if (response.status > 200) {
    let error = response.statusText || statusTexts[response.status];
    return { error: `${error} [${response.status}]` };
  } else {
    return { error: await response.text() };
  }
}
