<template>
  <searching-modal
    :config="{
      keys: ['title'],
      includeMatches: true,
      findAllMatches: true,
    }"
    :placeholder="$t('tutorial_popup.search-tutorials')"
    :options="tutorials"
    :value="typing"
    v-model:filter="typing"
    @show="$emit('show', $event)"
    @update:value="handleInput"
    @action="handleInput"
  >
    <template v-slot:default="{result}">
      <span
        class="block result"
      >
        <b>{{ result.item.title }}</b>
      </span>
    </template>
  </searching-modal>
</template>

<script>
import SearchingModal from './searching_modal.vue';
import GetTutorials from '../../assets/data/tutorials';

export default {
  name: 'TutorialModal',
  components: {
    SearchingModal,
  },
  props: {
    target: String,
    story: Object,
    value: String,
    type: {default: 'all'},
  },
  data() {
    let typing = this.value;
    return {
      status: null,
      results: null,
      typing,
      error: null,
      tutorials: GetTutorials().filter(t => !t.system),
    };
  },
  computed: {
    match() {
      const terms = (this.typing || '').split(/ |\//g);
      let results = this.tags.slice();
      for (const term of terms) {
        results = results.filter(r => r.category === term || r.name === term);
      }
      if (results.length > 0) {
        return results[0];
      }
    },
  },
  watch: {
    value() {
      this.typing = this.value;
    },
  },
  async mounted() {

    if (this.formula) {
      this.parseFormula();
      this.autogrow();
    }

    // auto select
    await this.$nextTick();
    const input = this.$refs.typing;
    if (input) {
      input.select();
    }
  },
  methods: {
    async handleInput(item) {
      this.$emit("update:value", item ? item.id : null);
    },
    handleChoice(item) {
      this.$emit("update:value", item.id);
      this.$emit('show', false);
    },
  },
}
</script>

<style scoped>
#app .close-button {
  display: inline-block;
  flex: 0 0 auto;
  margin: 0;
  font-size: 25px;
  height: 30px;
  width: 30px;
  padding: 0;
  place-self: flex-end;
  align-self: flex-start;
  text-align: right;
  margin: 0;
}
a {
  padding: 0;
}
.result.row {
  padding: 5px;
  margin: 3px;
}
.search {
  max-width: 100%;
}
.result {
  margin: 0;
  padding: 6px;
}
.category {
  flex: 0 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 10px;
}
.top {
  align-self: flex-start;
}
.label {
  background: var(--color2);
  color: var(--color2_text);
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
}
.title, .content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.icon {
  flex: 0 0 auto;
  padding: 5px;
  margin-right: 9px;
  font-size: 21px;
}
.missing .block {
  padding: 6px;
}
.text-header {
  margin-bottom: 5px;
}
.formula-input {
  min-width: max-content;
  min-height: max-content;
}
</style>
