/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { create } from 'jsondiffpatch';
import copy from '../../functions/copy';
// import msgpack from 'msgpack-lite';
import Manager from './localforage';

const differ = create({
  objectHash(obj) {
    // this function is used only to when objects are not equal by ref
    return obj._id || obj.id;
  },
  arrays: {
    detectMove: true,
    includeValueOnMove: true,
  },
});

export default class TsunamiManager extends Manager {
  get storageName() {
    if (this.namespace) {
      return `${this.namespace}_${this.name}`;
    }
    return this.name;
  }

  async pushContent(object) {
    const retval = copy(object);
    if (retval._server) {
      delete retval._server;
    }
    if (retval._meta) {
      delete retval._meta;
    }
    return JSON.stringify(retval);
  }

  async syncAll(Model, callback = null) {
    const clouds = await this.pullList();
    // console.log('syncing all...', clouds);
    return Promise.all(clouds.map(async (cloud) => {
      let instance = await Model.load(cloud.id);
      // console.log('cloud', Model, instance, cloud);
      if (!instance) {
        instance = new Model({ id: cloud.id });
      }
      const saved = await this.sync(instance, cloud, true);
      if (saved) {
        // console.log('saved1', instance);
        instance.update(saved);
        await instance.save();
        if (callback) {
          callback(instance, cloud);
        }
      }
      // console.log('saved2', instance, saved);
      return instance;
    }));
  }

  async sync(object, serverObject = null, lazyPull = false) {
    let output = copy(object);
    let server = serverObject || await this.pull(object.id);
    delete server._server;

    if (serverObject) {
      output._meta = serverObject;
    }

    // if (output._server) {
    //   delete output._server;
    // }

    // has this object changed?
    const originalDate = new Date((object._server || {}).updated || 1);
    const serverDate = new Date(server.updated || 1);
    const objectDate = new Date(object.updated || 1);
    const objectUpdated = objectDate > originalDate;
    const serverUpdated = serverDate > originalDate;

    // if (object.id === 'fDSjU4Wj86rRAbulWyUEG') {
    //   const odt = originalDate.getTime();
    //   console.log(
    //     'sync ->', this.name,
    //     originalDate.toISOString(),
    //     objectDate.getTime() - odt,
    //     serverDate.getTime() - odt,
    //     serverDate.getTime() - objectDate.getTime(),
    //   );
    //   console.log('syncd:', serverUpdated, objectUpdated, !!object._server);
    // }

    if (serverUpdated && objectUpdated && object._server) {
      // update with merge
      if (serverObject && lazyPull) {
        server = await this.pull(object.id);
      }
      const original = object._server;
      delete output.updated;
      delete server.updated;
      delete original.updated;

      const diff1 = differ.diff(original, output);
      const diff2 = differ.diff(original, server);
      const args = serverDate > objectDate ? [diff2, diff1] : [diff1, diff2];
      const diff = await this.mergeDiffs(...args);

      let output2 = null;
      try {
        // console.log('patching', original, server, args);
        output2 = differ.patch(original, diff);
        // console.log('diffing', output, diff, output2);
      } catch (e) {
        output2 = serverDate > objectDate ? server : object;
        console.error(e);
      }

      output = output2;
      output.updated = new Date().toISOString();
      await this.push(output);
    } else if (serverUpdated) {
      if (serverObject && lazyPull) {
        server = await this.pull(object.id);
      }

      // console.log('server updated', server);

      // update with pull
      for (const prop in server) {
        output[prop] = server[prop];
      }

      server.updated = serverDate.toISOString();
      if (server._server) {
        delete server._server;
      }
      output._server = server;
    } else if (objectUpdated) {
      // update with push
      await this.push(output);
    }
    // console.log('synced', output._server);

    return output;
  }

  async mergeDiffs(a, b, key = null) {
    console.log('diff', key, '=>', a, '|', b);
    let retval = {};
    if (a === undefined) {
      retval = b;
    } else if (b === undefined) {
      retval = a;
    } else if (a instanceof Array) {
      retval = a;
      if (a.length === 1) {
        retval = [await this.mergeKey(key, null, a[0], b[0])];
      } else if (a.length === 2) {
        retval = [a[0], await this.mergeKey(key, a[0], a[1], b[1])];
      }
    } else {
      const keys = new Set(Object.keys(a).concat(Object.keys(b)));
      if (keys.has('_t')) {
        keys.delete('_t');
        retval._t = 'a';
      }
      for (const sub of keys) {
        if (a[sub] && b[sub]) {
          // eslint-disable-next-line no-await-in-loop
          retval[sub] = await this.mergeDiffs(
            a[sub],
            b[sub],
            `${key || '$root'}.${sub}`,
          );
        } else if (a[sub]) {
          retval[sub] = a[sub];
        } else if (b[sub]) {
          retval[sub] = b[sub];
        }
      }
      // console.log('change', a, b);
    }
    // console.log('result', retval);
    return retval;
  }

  async mergeKey(key, orig, a, b) {
    const type = typeof (orig || a);
    // console.log('merge key', key, type, orig, '=>', a, '|', b);
    if (type === 'string') {
      if ((!a && !b) || (a && !b)) { return a; }
      if (b && !a) { return b; }
      return a.length >= b.length ? a : b;
    }
    return a;
  }

  async listEditors(object) {
    return window.app.http('db_editor', {
      doc: object.id.toString(),
      model: this.storageName,
      action: 'list',
    });
  }

  async addEditor(object, { user, level }) {
    return window.app.http('db_editor', {
      doc: object.id,
      model: this.storageName,
      user,
      level,
      action: 'add',
    });
  }

  async changeEditor(object, { member, user, level }) {
    return window.app.http('db_editor', {
      doc: object.id,
      model: this.storageName,
      id: member,
      user,
      level,
      action: 'change',
    });
  }

  async removeEditor(object, { member, user }) {
    return window.app.http('db_editor', {
      doc: object.id,
      model: this.storageName,
      id: member,
      user,
      action: 'delete',
    });
  }
}
