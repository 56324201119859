<template>
  <img
    :src="image"
    :width="width"
    v-if="blob && image"
    @click="$emit('click', $event)"
    @error="handleError"
    @load="loaded"
  />
  <img v-else>
    <!-- :style="{display: isLoaded ? 'unset' : 'none'}" -->
</template>

<script>
import { mapStores } from 'pinia';
import { useImageStore } from '@/stores/images';
import Media from '@/models/media';

export default {
  name: "blob-image",
  props: {
    blob: String,
    width: { type: [String, Number], default: null },
    thumb: { type: Boolean, default: false },
  },
  data() {
    return {
      image: null,
      isLoaded: false,
    };
  },
  computed: {
    ...mapStores(useImageStore),
  },
  watch: {
    async blob() {
      this.isLoaded = false;
      await this.getImage();
    }
  },
  async mounted() {
    try {
      await this.getImage();
    } catch(e) {
      console.error('error loading images', e);
    }
  },
  methods: {
    async getImage() {
      this.image = await this.imagesStore.loadImage(this.blob, this.thumb);
      // if (image) {
      //   console.timeLog(key, 'url loaded');
      //   const img = new Image();
      //   await new Promise((resolve, reject) => {
      //     img.onload = resolve;
      //     img.onerror = reject;
      //     img.src = image;
      //   });
      //   this.image = img.src;

      //   console.timeEnd(key);
      // } else {
      //   this.image = null;
      // }
    },
    async loaded() {
      this.isLoaded = true;
      this.$emit('load');
    },
    handleError(error) {
      this.$emit('error', error)
      this.imagesStore.clearOne(this.blob, this.thumb);
      this.getImage();
    },
  }
}
</script>
