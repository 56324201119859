<template>
  <div class="contestant column">
    <help-message name="audio-clips-explanation">
      {{ $t('contestant_content.enters-a-character-into-battles-put-a-battle-direction-under-all-contestants') }}
    </help-message>
    <p v-if="story.characters.length === 0" class="flex row accented wrap">
      <span class="stretch">{{ $t('contestant_content.youll-need-to-create-a-character') }}</span>
      <!-- <router-link
        class="static button"
        :to="{name: 'characters', query: {back: $route.path}, params: {story: story.id}}"
      >
        {{ $t('contestant_content.go') }}
      </router-link> -->
    </p>
    <template v-else>
      <label class="stretch">
        <span>Character:</span>
        <div v-if="story.characters.length === 0">
          {{ $t('contestant_content.create-new') }}
        </div>
        <select
          v-else
          :value="value.character" @input="$emit('update', {character: $event.target.value})"
        >
          <option
            v-for="character of story.characters"
            :key="character.id"
            :value="character.id"
          >
            {{ character.name }}
          </option>
        </select>
        <!-- <router-link
          v-if="value.character"
          :to="{name: 'character', query: {back: $route.path}, params: {story: story.id, id: value.character}}"
        >
          <i class="fa fa-pencil" />
        </router-link>
        <router-link
          v-else
          :to="{name: 'characters', query: {back: $route.path}, params: {story: story.id}}"
        >
          <i class="fa fa-plus" />
        </router-link> -->
      </label>
      <label class="stretch">
        <span>{{ $t('contestant_content.orientation') }}</span>
        <select :value="value.team" @input="$emit('update', {team: $event.target.value})">
          <option value="protagonist">{{ $t('contestant_content.protagonist') }}</option>
          <option value="antagonist">{{ $t('contestant_content.antagonist') }}</option>
        </select>
      </label>
      <label class="stretch">
        <input
          type="checkbox"
          :value="value.reset"
          @change="$emit('update', {reset: $event.target.checked})"
        >
        {{ $t('contestant_content.reset-character-stats') }}
      </label>
    </template>
  </div>
</template>

<script>
export default {
  name: 'text-direction',
  props: {
    story: Object,
    value: Object,
  },
  data() {
    return {
      teams: [
        {
          name: this.$t('battle_content.protagonists'),
          key: 'protagonists',
        },
        {
          name: this.$t('battle_content.antagonists'),
          key: 'antagonists',
        },
      ],
    };
  },
  computed: {
  },
  watch: {
    character() {
    },
  },
  methods: {
  }
};
</script>

<style scoped>
label {
  display: flex;
}
span {
  flex: 0 0 min(50%, 150px);
}
.wrap {
  display: block;
  overflow: visible;
}
.flex {
  display: flex;
}
.flex span {
  flex: 1 1 auto;
}
</style>
