<template>
  <!--

  A responsive number leaf
  --><falling-number
    v-if="tagName === 'r' && isNumber"
    :start="startValue || 0"
    :delta="(endValue || 0) - (startValue || 0)"
  /><!--

  A responsive text leaf
  --><template v-else-if="tagName === 'r'"
    ><typer-leaf
      :words="[{ startsAt: 0, endsAt: (value || '').length, content: arrayify(value) }]"
      :position="frame"
      :finished="frame"
      :allowImages="allowImages" /></template
  ><!--

  Normal typing text
  --><component v-else :is="tagName" class="word" v-bind="htmlattrs"
    ><!--

    Print Words
    --><span
      v-for="word of readyWords"
      :key="`word-${word.id}`"
      class="word"
      :class="{
        long: word.long,
        current: word.startsAt <= position && word.endsAt > position,
        nextword: word.startsAt > position,
        whitespace: word.type === 'whitespace',
        notNewLine: !word.newLine,
        newLine: word.newLine,
      }"
      ><!--

      An img leaf if allowed
      --><BlobImage
        v-if="word.tag === 'img' && allowImages"
        :blob="word.attrs.src"
        :width="word.attrs.width"
        :class="{
          cursor: position === word.startsAt,
          hidden: position < word.endsAt,
        }"
      /><!--

      An img leaf not allowed
      --><span v-if="word.tag === 'img'" /><!--

      Child nodes
      --><typer-leaf
        v-else-if="word.children"
        :position="position"
        :finished="finished"
        :words="word.children"
        :tagName="word.tag"
        :variables="variables"
        :htmlattrs="word.attrs"
        :allowImages="allowImages"
      /><!--

      Completed words
      --><template v-else-if="word.endsAt <= finished"
        ><span class="chars">{{ word.content.join("") }}</span></template
      ><!--

      Print each character
      --><template v-else
        ><span
          v-for="(c, cid) of word.content"
          :id="`char_${word.startsAt + cid}`"
          :key="`char_${word.startsAt + cid}`"
          class="char"
          :class="{
            cursor: position === word.startsAt + cid,
            hidden: position < word.startsAt + cid,
          }"
          >{{ c }}</span
        ></template
      ><!--
    --></span
    ><!--
  --></component>
</template>

<script lang="ts">
import BlobImage from "../blob_image.vue";
import FallingNumber from "../falling_number.vue";

export default {
  name: "typer-leaf",
  components: {
    FallingNumber,
    BlobImage,
  },
  props: {
    allowImages: { default: false },
    position: { type: Number },
    finished: { type: Number },
    words: { type: Array },
    tagName: { type: String, default: "span" },
    htmlattrs: { type: Object },
    variables: { type: Object },
  },
  data() {
    let value = null;
    return {
      value,
      startValue: value || 0,
      endValue: value || 0,
      frame: 0,
      maxFrames: 80,
    };
  },
  computed: {
    readyWords() {
      const nb = this.nextBreak;
      return this.words.filter((word) => {
        return word.startsAt <= nb;
      });
    },
    nextBreak() {
      const words = this.words.filter((w) => w.startsAt > this.position && w.newLine);
      if (words.length > 1) {
        return words[0].startsAt;
      }
      if (this.words.length > 0) {
        return this.words[this.words.length - 1].endsAt;
      }
      return 0;
    },
    word() {
      return this.getWord();
    },
    var() {
      if (!this.variables || !this.word) {
        return null;
      }
      return this.variables[this.word];
    },
    isNumber() {
      return typeof this.var === "number";
    },
  },
  watch: {
    var() {
      this.start();
    },
  },
  mounted() {
    this.start();
  },
  unmounted() {
    cancelAnimationFrame(this._moveValue);
  },
  methods: {
    start() {
      if (this.tagName === "r") {
        this.frame = 1;
        if (this.var || this.var === 0) {
          if (this.isNumber) {
            this.startValue = this.value || 0;
            this.endValue = this.var;
            this.value = this.var;
            this.maxFrames = Math.max(1, Math.floor(Math.abs(this.startValue - this.endValue) * 2));
          } else {
            this.value = this.var;
            this.maxFrames = this.var.length;
          }
          this.moveValue();
        } else {
          this.value = null;
        }
      } else {
        const word = this.getWord();
        if (this.variables && this.variables[word]) {
          this.value = this.variables[word];
        }
      }
    },
    getWord() {
      if (this.words.length > 0) {
        return this.words[0].content.join("");
      }
      return null;
    },
    arrayify(value) {
      return Array.from(`${value}`);
    },
    moveValue() {
      // if (this.isNumber) {
      //   const p = this.ease(this.frame / this.maxFrames, 2);
      //   // slow down frames
      //   if (p === 1 || this.frame % 4 === 0) {
      //     const diff = this.endValue - this.startValue;
      //     this.value = Math.floor(this.startValue + diff * p);
      //   }
      // }
      this.frame += 1;
      if (this.frame <= this.maxFrames) {
        this._moveValue = requestAnimationFrame(() => this.moveValue());
      }
    },
    ease(x) {
      return 1 - Math.pow(1 - x, 2);
      // return x <= 0.3 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
    },
  },
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
blockquote {
  margin: 0 5px 0 5px;
  padding: 0;
}
h1 > span,
h2 > span,
h3 > span,
h4 > span,
h5 > span,
h6 > span,
blockquote > span {
  margin: 0;
  padding: 0;
}

blockquote {
  padding-left: calc(var(--spacing));
}

s {
  text-decoration: line-through;
}

pre {
  margin-left: calc(var(--spacing) * 2);
}

img {
  display: inline-block;
  max-width: calc(min(100%, 600px));
  max-width: calc(min(70vh, 600px));
  transition: 300ms opacity;
  opacity: 1;
  line-height: 100%;
  z-index: 1;
  margin-top: -3px;
  margin-bottom: -3px;
}

.cursor {
  /* font-size: 2em; */
}
.current {
  display: inline-block;
}
.char {
  /* TODO: was commented out, why */
  /* animations require inline-block */
  display: inline-block;
  transition: none;
  /* transition: 10000ms opacity; */
  transform-origin: 50% 65%;
  white-space: pre-wrap;
  margin: 0 !important;
  padding: 0 !important;
  /* border-right: 1px solid red; */
  opacity: 1;
}
#app .hidden {
  opacity: 0;
  /* visibility: hidden; */
  /* border-right: 1px solid red; */
}
/* #app .newLine {
  border-bottom: 2px dashed purple;
} */
.word {
  /* display: inline-block; */
  white-space: nowrap;
  /* border-bottom: 1px dashed green; */
  /* border-right: 1px dashed green; */
}
.word .char,
.word .chars {
  /* white-space: nowrap; */
}
.word.long {
  white-space: nowrap;
}
.word.nextword {
  visibility: hidden;
}
.word.whitespace .char {
  /* background: cyan; */
  white-space: pre-wrap;
}
.word.whitespace .char,
.word.whitespace .chars {
  display: inline;
  white-space: wrap;
}
</style>
