import BaseDriver from './base';

export default class PlatformDriver extends BaseDriver {

  constructor(name, ext) {
    super();
    this.name = name;
    this.ext = ext;
    this.proxy = null;
  }

  get isMergable() {
    return this.proxy.isMergable;
  }

  get type() {
    return this.proxy.type;
  }

  async ready() {
    if (!this.proxy) {
      // const platform = Capacitor.getPlatform();
      // const { publicStorage } = await Filesystem.checkPermissions();
      let Driver = null;
      // if (publicStorage === 'granted' && platform !== 'web') {
      //   Driver = (await import('./CapacitorDriver')).default;
      // } else
      if (window.electron) {
        Driver = (await import('./JetpackDriver')).default;
      } else {
        Driver = (await import('./LocalForageDriver')).default;
      }
      this.proxy = new (Driver)(this.name, this.ext);
    }
    return this.proxy.ready();
  }

  async length() {
    return this.proxy.length();
  }

  async iterate(predicate) {
    return this.proxy.iterate(predicate);
  }

  async setItem(name, data) {
    await this.ready();
    return this.proxy.setItem(name, data);
  }

  async getItem(name, def) {
    return this.proxy.getItem(name, def);
  }

  async removeItem(name) {
    return this.proxy.removeItem(name);
  }
}
