<template>
  <div class="tab">
    <help-message name="choice-efects" class="left">
      {{ $t('direction.setting-tags-can-help-hide-or-show-future-directions') }}
    </help-message>
    <div class="padded">
      <b>{{ $t('direction.set-tags') }}</b>
    </div>
    <operation-array v-model:value="effects" :story="story" :extras="{
      $item: value.array,
      $value: ['option'].includes(value.type),
    }" type="effects" />
  </div>
</template>

<script>
import OperationArray from '../operation_array.vue';
import { effects } from '../../utils/tag_effects';

export default {
  name: 'RequirementsTab',
  components: { OperationArray },
  props: {
    story: Object,
    scene: Object,
    value: Object,
  },
  computed: {
    requirements: {
      get() {
        return (this.value.effects || []).filter(
          (f) => f && effects.list.indexOf(f.op) === -1
        );
      },
      set(v) {
        const myEffects = v.slice(0).concat(this.copy(this.effects));
        this.$emit('update', { effects: myEffects });
      },
    },
    effects: {
      get() {
        return (this.value.effects || []).filter(
          (f) => f && effects.list.indexOf(f.op) > -1
        );
      },
      set(v) {
        const myEffects = this.copy(this.requirements.slice(0)).concat(v);
        this.$emit('update', { effects: myEffects });
      },
    },
  }
}
</script>
