<template>
  <div class="totd" v-if="show">
    <div class="backdrop" @click.prevent="show = false" />
    <div class="modal">
      <h2>
        <i class="fa fa-lightbulb-o" />
        {{ $t('daily_tip.tutorialOfTheDay') }}
        <a class="close static" href="#" @click.prevent="show = false"> &times; </a>
      </h2>
      <div class="tutorial flex-row">
        <div
          v-for="object of tips.filter(t => t.img)"
          :key="`${object.img}`"
          class="tut"
        >
          <p v-if="object.tip">{{ object.tip }}</p>
          <img
            v-if="object.img"
            :src="`assets/tutorials/${object.img}`"
          >
          <p v-if="object.low">{{ object.low }}</p>
        </div>
      </div>
      <div class="mellow accented checkbox row space-around">
        <label class="right">
          <span class="stretch">
            {{ $t('daily_tip.showTutorials') }}
          </span>
          <input
            v-model="preference"
            type="checkbox"
            class="static"
          >
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import GenericCarousel from './generic_carousel.vue';
import tips from '../assets/data/tips.js';

export default {
  name: 'DailyTip',
  components: {
    GenericCarousel,
  },
  data() {
    const preference = !this.$root.disableTuts && this.fetch('show-totd', true);
    const today = this.fetchSession('shown-totd', true);
    this.putSession('shown-totd', false);
    return {
      show: preference && today,
      preference,
      index: Math.floor(tips.length * Math.random()),
      tips,
    };
  },
  watch: {
    preference() {
      this.put('show-totd', this.preference);
    },
  },
  methods: {
    openModal() {
      this.show = true;
    },
  },
};
</script>

<style scoped>
.totd {
  position: fixed;
  display: grid;
  top: 0; left: 0; right: 0; bottom: 0;
  z-index: 100;
}
.backdrop,
.modal {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}
.backdrop {
  background: var(--bg);
  opacity: .9;
}
.modal {
  z-index: 2;
  width: 300px;
  min-height: 400px;
  max-height: 100vh;
  background: var(--color1);
  box-shadow: 0 2px 4px var(--bg);
  margin: auto;
  border-radius: 10px;
  padding: 10px;
  position: relative;
}
.modal .tut {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.modal h2 {
  font-size: 18px;
  text-align: center;
  justify-content: center;
  display: block;
  flex: 1 1 100%;
  margin: 0;
  padding: 0;
}
.modal p {
  padding: 10px 0;
  margin: 0;
}
.modal img {
  flex: 1 1 100px;
  max-width: 100%;
  object-fit: contain;
}
.checkbox {
  padding: 10px;
  opacity: 0.8;
}
.tutorials {
  flex: 1 1 auto;
  flex-direction: row;
  overflow: scroll;
  scroll-snap-type: x mandatory;
  scroll-snap-stop: always;
}
.tut {
  scroll-snap-align: center;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
}
</style>
