<style scoped>
.autogrow-textarea {
}
</style>

<template>
  <div class="flex-column">
    <autogrow-textarea
      :placeholder="$t('input_content.input-prompt')"
      :modelValue="value.label"
      :style-name="value.style"
      :story="story"
      :no-speed="true"
      @update:style-name="$emit('update', {style: $event})"
      @update:modelValue="$emit('update', {label: $event})"
    />
    <tag-picker
      :story="story"
      :value="value.content"
      :placeholder="$t('input_content.store-value-in')"
      :excludes="['array']"
      @update:value="$emit('update', {content: $event})"
    />
    <div v-if="storyType" class="flex-row flex-align-center">
      <label class="flex-stretch">
        {{ $t('input_content.item-attribute') }}
        <select
          :value="value.attr"
          @input="$emit('update', {attr: $event.target.value})"
        >
          <option> {{ $t('input_content.choose') }} </option>
          <option
            v-for="attr of storyType.attrs"
            :key="attr.id"
            :value="attr.name"
          >
            {{ attr.label || attr.name }}
          </option>
        </select>
      </label>
      <label>
        {{ $t('input_content.max-points') }}
        <input
          :placeholder="$t('input_content.unlimited')"
          :value="value.points"
          @input="$emit('update', {points: $event.target.value})"
        >
      </label>
    </div>
    <p class="small" v-if="tag">
      {{ $t('input_content.tag-input-notice', {format: tagFormat}) }}
    </p>
  </div>
</template>

<script>
import TagPicker from "../editor/tag_picker.vue";

export default {
  name: "InputContent",
  components: {
    TagPicker,
  },
  props: {
    story: Object,
    value: Object,
  },
  computed: {
    actions() {
      return this.story.tags.filter(tag => tag.type == 'action');
    },
    variables() {
      return this.story.tags.filter(tag => tag.type == 'tag');
    },
    tag() {
      return this.story.tags.find(tag => tag.id === this.value.content);
    },
    tagFormat() {
      const format = (this.tag ? (this.tag.format || 'numeric') : null) || 'text';
      return this.$t(`tags.format-types-${format}`);
    },
    isArray() { return this.tag && this.tag.type === 'array'; },
    storyType() {
      if (this.tag && this.tag.referencing) {
        return this.story.types.find((type) => type.id === this.tag.referencing);
      }
      return null;
    },
  },
}
</script>

<style scoped>
.small {
  font-size: .8em;
  opacity: 0.8;
  padding: 5px;
  margin: 0;
}
.tag-picker {
  width: 100%;
}
</style>
