import nudged from "nudged";

export interface Coord {
  x: number;
  y: number;
}
export interface Transform {
  x: number;
  y: number;
  scale: number;
}

export default class Explore {
  width: number;
  height: number;
  move: Transform;
  offset: Coord;
  start: { [key: string]: Coord };
  end: { [key: string]: Coord };
  position: Transform;
  screen: Coord;
  minScale: number = 1;
  maxScale: number = 1;

  constructor({ width, height, scale } = { width: 100, height: 100, scale: 1 }, screen = null) {
    this.width = width;
    this.height = height;
    this.start = {};
    this.end = {};
    this.offset = { x: 0, y: 0 };
    this.changeContainer(screen, scale);
    // TODO: allow tesselate
  }

  get x(): number {
    return this.position.x + this.move.x;
  }
  get y(): number {
    return this.position.y + this.move.y;
  }
  get scale(): number {
    return this.position?.scale * this.move?.scale || 1;
  }
  get w(): number {
    return this.width * this.scale - this.screen.x;
  }
  get h(): number {
    return this.height * this.scale - this.screen.y;
  }

  set x(v: number) {
    this.position.x = v - this.move.x;
  }
  set y(v: number) {
    this.position.y = v - this.move.y;
  }
  set scale(v: number) {
    this.position.scale = v / this.move.scale;
  }

  changeContainer(screen, scale = null) {
    if (scale === null) {
      scale = this.scale || 0;
    }
    if (screen) {
      this.screen = screen;
    } else {
      this.screen = { x: window.innerWidth, y: window.innerHeight };
    }
    this.minScale = Math.max(this.screen.x / this.width, this.screen.y / this.height);
    this.maxScale = Math.max(this.minScale, scale || 1) + 2;
    this.move = { x: 0, y: 0, scale: 1 };

    scale = Math.min(this.maxScale, Math.max(this.minScale, scale || 0));
    this.position = {
      x: this.screen.x / 2 - this.width * scale * 0.5,
      y: this.screen.y / 2 - this.height * scale * 0.5,
      scale,
    };
  }

  handle(event) {
    if (event.type === "pointercancel") {
      console.log("pointercancel was sent, you need to set `touch-actions: none`");
      return;
    }

    // TODO: mouse wheel
    let x = event.x - this.position.x - this.offset.x;
    let y = event.y - this.position.y - this.offset.y;

    if (event.type === "wheel") {
      const d = Math.max(
        this.minScale / this.position.scale,
        Math.min(this.maxScale / this.position.scale, 1 - event.deltaY * 0.0005),
      );
      this.start["wheel1"] = { x: x + 1, y: y };
      this.start["wheel2"] = { x: x - 1, y: y };
      this.end["wheel1"] = { x: x + 1 * d, y: y };
      this.end["wheel2"] = { x: x - 1 * d, y: y };
    } else {
      if (event.type === "pointerdown" && !this.start[event.pointerId]) {
        this.start[event.pointerId] = { x, y };
        event.preventDefault();
      }
      if (this.start[event.pointerId]) {
        this.end[event.pointerId] = { x, y };
      }
    }

    if (Object.keys(this.start).length > 0) {
      const keys = Object.keys(this.start);
      const move = nudged.estimate({
        estimator: "TS",
        domain: keys.map((k) => this.start[k]),
        range: keys.map((k) => this.end[k]),
      });

      const mv = nudged.transform.getTranslation(move);
      const scale = nudged.transform.getScale(move);
      const s = Math.round(this.position.scale * scale * 10) / 10;

      if (s >= this.minScale && s <= this.maxScale) {
        this.move.scale = scale;
        this.move.x = Math.max(-this.w - this.position.x, Math.min(0 - this.position.x, mv.x));
        this.move.y = Math.max(-this.h - this.position.y, Math.min(0 - this.position.y, mv.y));
      }

      if (["pointerup", "wheel"].includes(event.type)) {
        this.position.scale = this.scale;
        this.position.x = this.x;
        this.position.y = this.y;
        this.move = { x: 0, y: 0, scale: 1 };
        this.start = {};
        this.end = {};
      }
    }
  }
}
