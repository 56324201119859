<template>
  <div class="padded">
    <insert-direction
      :story="story"
      :scene="scene"
      :index="index"
      @create="$emit('create', $event)" />
  </div>
</template>

<script>
import InsertDirection from '../insert_direction.vue'

export default {
  name: 'InsertTab',
  components: {
    InsertDirection,
  },
  props: {
    story: Object,
    scene: Object,
    value: Object,
    index: Number,
  },
}
</script>
