export function fetchSession(name: string, def) {
  try {
    const result = JSON.parse(sessionStorage[name]);
    if (result === undefined) {
      return def;
    }
    return result;
  } catch (ex) {
    // log("error fetching", name, localStorage[name])
    return def;
  }
}

export function put(name: string, data) {
  try {
    localStorage[name] = JSON.stringify(data);
  } catch (ex) {
    this.$root.addAlert({
      title: "Failed to save data",
      message: `${ex.message}`,
      ex,
    });
  }
}

export function fetch(name: string, def: any) {
  try {
    const result = JSON.parse(localStorage[name]);
    if (result === undefined) {
      return def;
    }
    return result;
  } catch (ex) {
    // log("error fetching", name, localStorage[name])
    return def;
  }
}
