<template>
  <div class="flex-column" v-if="book.story">
    <div class="relative flex-row align-center">
      <i class="fa fa-film" />
      <Select
        v-bind="$attrs"
        v-model="value"
        :options="book.animation"
        :invalid="errors.length > 0"
        showClear
        optionLabel="name"
        optionValue="id"
        class="p-3-l"
      >
      </Select>
    </div>
    <div>
      <RouterLink
        :to="{ name: 'animation', params: { story: book.story.id, back: route.path } }"
        class="font-size-3 p-0-y p-1-t"
      >
        Animations...
      </RouterLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStoryStore } from "@/stores/story";
import Select from "primevue/select";
import { useField } from "vee-validate";
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const book = useStoryStore();

const props = withDefaults(
  defineProps<{
    name: string;
    showTitle?: boolean;
    accept?: string;
  }>(),
  { accept: "image" },
);

const { value, errors } = useField(props.name);

const instance = computed({
  get: () => book.animation.find((a) => a.id === value.value),
  set: (v: Animation) => (value.value = v.id),
});
</script>

<style scoped>
i {
  position: absolute;
  z-index: 2;
  margin-left: 0.7em;
}
</style>
