<style scoped>
.tutorial-message {
  position: absolute;
  vertical-align: bottom;
  z-index: 1;
  color: white;
  font-size: 25px;
  padding: 25px;
  margin: 5px;
  min-height: 110px;
  text-align: center;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.8);
  border: 5px solid rgba(0, 0, 0, 0.9);
}
.tutorial-button {
  position: absolute;
  border: 2px dashed black;
  border-radius: 50%;
  box-shadow: 0 0 0 9999px rgba(100, 100, 100, 0.6);
}
.tutorial {
  padding: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 100;
}
#app .tutorial p {
  color: white;
}
</style>

<template>
  <transition name="fade" class="tutorial">
    <div
      v-if="tuts.current"
      class="tutorial"
      @click="tuts.next()"
    >
      <p
        class="tutorial-message"
        :style="{ transform: `translate(0px, ${msgY}px` }"
        v-html="tuts.current.message"
      />
      <div
        class="tutorial-button"
        :style="{
          width: `${tuts.current.size || size}px`,
          height: `${tuts.current.size || size}px`,
          top: `${tuts.current.point.y - size / 2}px`,
          left: `${tuts.current.point.x - size / 2}px`,
        }"
      ></div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { useTutorialStore } from '@/stores/tutorials';
import { computed } from 'vue';

const size = 100;
const tuts = useTutorialStore();

const msgY = computed(() => {
  if (tuts.current) {
    if (tuts.current.point.y > window.innerHeight / 2) {
      return tuts.current.point.y - 120 - size / 2;
    } else {
      return tuts.current.point.y + 20 + size / 2;
    }
  }
})
</script>
