import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";
import debug from "debug";
import JSZip from "jszip";
import localForage from "localforage";
import { nanoid } from "nanoid";
import { findTag, textStyle } from "./utils/tags";
// import Typer from './components/typer.vue';
import BackButton from "@morphosis/base/components/BackButton.vue";
import ColorPicker from "@morphosis/base/components/ColorPicker.vue";
import MgList from "@morphosis/base/components/List.vue";
import Popover from "@morphosis/base/components/Popover.vue";
import UserTag from "@morphosis/base/components/UserTag.vue";
import VectorImage from "@morphosis/base/components/VectorImage.vue";
import shortid from "shortid";
import Animation from "./components/animation.vue";
import AnimationGroup from "./components/animation_group.vue";
import BlobAudio from "./components/blob_audio.vue";
import BlobBackground from "./components/blob_background.vue";
import BlobImage from "./components/blob_image.vue";
import ConfirmButton from "./components/confirm_button.vue";
import CopyButton from "./components/copy_button.vue";
import TipOfTheDay from "./components/daily_tip.vue";
import AnimationChooser from "./components/editor/animation_chooser.vue";
import AutogrowTextarea from "./components/editor/autogrow_text.vue";
import ListChooser from "./components/editor/list_chooser.vue";
import FavoriteHeart from "./components/favorite_heart.vue";
import AppHeader from "./components/header.vue";
import HelpMessage from "./components/help_message.vue";
import InputArray from "./components/input_array.vue";
import List from "./components/list.vue";
import ListDetail from "./components/list_detail.vue";
import Sidebar from "./components/sidebar.vue";
import SidebarDetail from "./components/sidebar_detail.vue";
import TabSwitch from "./components/tab_switch.vue";
import TransitionQueue from "./components/transition_queue.vue";
import Typer from "./components/typer/typer_tree.vue";
import WatchButton from "./components/watch_button.vue";
// import AutoField from "./components/forms/auto_field.vue";
import AutoForm from "./components/forms/auto_form.vue";
// import AutoForm from '@morphosis/base/components/forms/AutoForm.vue';
import BugReport from "./components/communication/bug_report.vue";
import ModerationFlag from "./components/communication/moderation_flag.vue";
import ModerationReport from "./components/communication/moderation_report.vue";
import FormulaPopup from "./components/editor/formula_popup.vue";
import MediaUploader from "./components/editor/media_uploader.vue";
import TagLabel from "./components/editor/tag_label.vue";
import Media from "./models/media";
import Scene from "./models/scene";
import Story from "./models/story";

// import fonts
import { useToasterStore } from "@morphosis/base/stores/toaster";
import { Ref } from "vue";
import FiraCode from "./assets/fonts/fira-code/woff/FiraCode-Regular.woff";
import Literate from "./assets/fonts/libera/literatabook-webfont.ttf";
import PlasmaDrip from "./assets/fonts/plasdrip-webfont.woff";
import { FullStory, Permalink } from "./stores/story_defs";
import { basename } from "./utils/basename";
import { fixCDNPermalink } from "./utils/cdn";
import { cleanImage } from "./utils/images";
import { sceneGetText } from "./utils/scene_utils";
import { fetch, fetchSession, put } from "./utils/storage";
import { collectMedia } from "./utils/story/storyMedia";

const filenames = localForage.createInstance({ name: "filenames" });
const junk = localForage.createInstance({ name: "junk" });

export const CURRENT_VERSION = 3;
export const SAMPLE_TEXT = [
  {
    id: 10,
    text: "**Responsive <t>Sample</t>**,\n Health: **@health@**\n status: <r>status</r>",
    background: "assets/Backgrounds/green.jpg",
  },
  {
    id: 20,
    text: "Sample,\n    **forest** *background ~~strike~~*",
    background: "assets/Backgrounds/green.jpg",
  },
  {
    id: 30,
    text: "Pancetta esse adipisicing, tenderloin ipsum brisket ham.",
    background: "assets/Backgrounds/dark.jpg",
  },
  {
    id: 40,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    background: "assets/Backgrounds/light.jpg",
  },
  {
    id: 50,
    text: "Что это за звук?",
    background: "assets/Backgrounds/light.jpg",
  },
  {
    id: 60,
    text: "私はベドに行きます",
    background: "assets/Backgrounds/dark.jpg",
  },
  {
    id: 70,
    text: "日本語日本語日本語日本語日本語日本語日本語日本語日本語日本語日本語",
    background: "assets/Backgrounds/green.jpg",
  },
  { id: 80, text: "😀 😃 😄" },
];
export const FONTS = [
  { id: "", name: "Monospace", font: "monospace" },
  { id: "", name: "Serif", font: "serif" },
  { id: "", name: "Sans-serif", font: "sans-serif" },
  { id: "", name: "Cursive", font: "cursive" },

  {
    id: "",
    name: "Literate",
    font: "literata_bookregular",
    default: true,
    url: Literate,
  },
  { id: "", name: "Plasma Drip", font: "plasma_drip_brkregular", url: PlasmaDrip },
  { id: "", name: "Griffen", font: "griffinregular" },
  { id: "", name: "Architect", font: "architects_daughterregular" },
  { id: "", name: "Kingthings", font: "kingthings_foundationregular" },
  { id: "", name: "Rothenburg", font: "rothenburg_decorativenormal" },
  // {id: '', name: "Tangerine", font: "tangerineregular"},
  { id: "", name: "Yataghan", font: "yataghanregular" },
  { id: "", name: "Homemade Apple", font: "homemade_appleregular" },
  { id: "", name: "Calligraffiti", font: "calligraffitiregular" },
  { id: "", name: "Desyrel", font: "desyrelregular" },
  { id: "", name: "Konstytucyja", font: "konstytucyjamedium" },
  { id: "", name: "Fira Code", font: "Fira Code", url: FiraCode },
  { id: "", name: "Silkscreen Pixel", font: "silkscreennormal" },
  { id: "", name: "Silkscreen Pixel Bold", font: "silkscreenbold" },
];
for (const font of FONTS) {
  font.id = font.font;
}
export const SIZES = [
  { id: "", name: "small", size: "11.2px" },
  { id: "", name: "normal", size: "16px", default: true },
  { id: "", name: "large", size: "19.2px" },
  { id: "", name: "yelling", size: "24px" },
  { id: "", name: "title", size: "32px" },
];
for (const size of SIZES) {
  size.id = size.size;
}

export const TYPE_SPEEDS = [
  { name: "Instant", fa: "fighter-jet", id: -1 },
  { name: "Lightning", fa: "bolt", id: 9 },
  { name: "Normal", fa: "", id: 32 },
  { name: "Slow", fa: "snail", id: 100 },
  { name: "Slowest", fa: "slow", id: 200 },
];

export const CHOICE_TEMPLATE = {
  qr: false,
  timer: null,
  label: "",
  scene: "",
  requires: [],
  gives: [],
  takes: [],
  excludes: [],
};

const cachedURLs = {};

export const log = debug("app");

export class Bouncer {
  constructor(timeout = 1000) {
    this.resolve = null;
    this.timeout = timeout;
    this.promise = null;
  }

  debounce() {
    this.cancel();
    this.promise = new Promise((r) => {
      this.resolve = r;
      clearTimeout(this._timeout);
      this._timeout = setTimeout(() => {
        r(true);
      }, this.timeout);
    });
    return this.promise;
  }

  cancel() {
    if (this.resolve) {
      clearTimeout(this._timeout);
      this.resolve(false);
      this.promise = null;
    }
  }
}

export default {
  components: {
    Animation,
    AnimationChooser,
    AnimationGroup,
    AppHeader,
    // AutoField,
    AutoForm,
    AutogrowTextarea,
    BackButton,
    BlobAudio,
    BlobBackground,
    BlobImage,
    BugReport,
    ColorPicker,
    ConfirmButton,
    CopyButton,
    FavoriteHeart,
    FormulaPopup,
    HelpMessage,
    InputArray,
    List,
    ListChooser,
    ListDetail,
    MediaUploader,
    MgList,
    ModerationFlag,
    ModerationReport,
    Popover,
    Sidebar,
    SidebarDetail,
    TabSwitch,
    TagLabel,
    TipOfTheDay,
    TransitionQueue,
    Typer,
    UserTag,
    VectorImage,
    vi: VectorImage,
    WatchButton,
  },
  data() {
    return {
      open: false,
      storyBouncer: new Bouncer(1000),
      server: this.$root.$socketOpts?.fallback.replace("/api/", "/"),
      genres: [],
      languages: [],
      CURRENT_VERSION,
      MODELS: {
        [Scene.objectName]: Scene,
        [Story.objectName]: Story,
        [Media.objectName]: Media,
        // [Theme.objectName]: Theme,
      },
    };
  },
  mounted() {
    this.genres = [
      this.$t("genres.adventure"),
      this.$t("genres.dating"),
      this.$t("genres.escape"),
      this.$t("genres.experimental"),
      this.$t("genres.fantasy"),
      this.$t("genres.horror"),
      this.$t("genres.modern"),
      this.$t("genres.mystery"),
      this.$t("genres.other"),
      this.$t("genres.psychological-horror"),
      this.$t("genres.sci-fi"),
      this.$t("genres.simulation"),
      this.$t("genres.survival"),
      this.$t("genres.visual-novella"),
      this.$t("genres.zombie"),
    ];
    this.languages = [
      this.$t("languages.english"),
      this.$t("languages.french"),
      this.$t("languages.german"),
      this.$t("languages.hungarian"),
      this.$t("languages.indonesian"),
      this.$t("languages.italian"),
      this.$t("languages.korean"),
      this.$t("languages.portuguese"),
      this.$t("languages.russian"),
      this.$t("languages.serbian"),
      this.$t("languages.spanish"),
      this.$t("languages.other"),
    ];
  },
  computed: {
    $stories() {
      return this.$root._$stories;
    },
    $reading() {
      return this.$root._$reading;
    },
    $images() {
      return this.$root._$images;
    },
    $socket() {
      return this.$root.websocketStore.socket;
    },
  },
  methods: {
    log(...mgs) {
      // @ts-ignore
      log(...mgs);
    },
    copy(a) {
      return JSON.parse(JSON.stringify(a));
    },
    generateId(array) {
      return array.filter((a) => a.id).reduce((a, b) => Math.max(a, parseInt(b.id, 10)), 0) + 1;
    },
    hashCode(s) {
      return s.split("").reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0);
    },
    putSession(name, data) {
      sessionStorage[name] = JSON.stringify(data);
    },
    capFirst(str) {
      return str.charAt(0).toUpperCase() + str.substr(1, str.length).toLowerCase();
    },
    fetch,
    fetchSession,
    put,
    async write(name, data) {
      try {
        await localForage.setItem(name, data);
      } catch (ex) {
        useToasterStore().add({
          title: "Failed to write data",
          message: `${ex.message}`,
          ex,
        });
      }
    },
    async read(name, def) {
      return (await localForage.getItem(name)) || def;
    },
    writeCache(name, data) {
      return this.write(name, data);
    },
    readCache(name, def) {
      return this.read(name, def);
    },
    basename,
    sceneGetText,
    cleanImage,
    async api(url, data = null, method = "GET") {
      const base = "https://prototype.darkchocolate.dev/api/";
      // let base = "http://localhost:8000/api/";
      const opts = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method,
      };

      if (this.$root.oldUser.token) {
        opts.headers.Authorization = `token ${this.$root.oldUser.token}`;
      }

      let query = "";
      if (data) {
        if (method.toLowerCase() != "get") {
          opts.body = data ? JSON.stringify(data) : "";
        } else {
          for (const key in data) {
            if (query != "") {
              query += "&";
            }
            query += `${key}=${encodeURIComponent(data[key])}`;
          }
          if (query) {
            query = `?${query}`;
          }
        }
      }
      const response = await window.fetch(`${base}${url}/${query}`, opts);
      return await response.json();
    },
    async setFilename(id, filename = null) {
      await filenames.setItem(id, filename);
    },
    async loadFullStory(id, db = null, redirect = true): Promise<FullStory> {
      await this.storyBouncer.promise;

      // TODO: this is commented, but why did we want to clear urls anyway?
      // await Media.clearCachedUrls();

      if (!db) {
        db = this.$stories;
        console.warn("using stories db");
      }

      // const filename = await filenames.getItem(id);
      // TODO: load from file if not in indexeddb

      let story = await db.getItem(id);
      if (!story) {
        const instance = await Story.load(id);
        if (instance) {
          console.warn("save to json");
          instance.saveJson(true);
        }
      }

      story = await db.getItem(id);
      // if no story exists check the object db
      // and save it to the story db if needed
      if (!story) {
        const instance = await Story.load(id);
        if (instance) {
          await instance.saveJson(true);
          story = await db.getItem(id);
        }
      }

      if (!story) {
        if (redirect) {
          console.error("No story!", id, story);
          useToasterStore().add({ message: "Could not find story" });
          this.$router.go(-1);
          // this.$router.push({ name: 'home' });
        }
      } else {
        await this.migrateStory(story, db);
      }
      return story;
    },

    /** loads story into the database */
    async createStoryDocuments(storyInit) {
      const story = this.copy(storyInit);
      const scenes = story.scenes || [];
      story.scenes = scenes.filter((s) => s).map((s) => s.id);

      const saving = [];
      const inst = new Story(story);
      for (const scene of scenes) {
        saving.push(new Scene(scene).save());
      }
      // saving story last so all scenes will be available
      await Promise.all(saving);
      await inst.save(true);

      return story;
    },
    async saveStoryDocument(id) {
      const story = new Story();
      await story.load(id);
      await story.saveJson();
    },
    async saveStoryDebounced(story) {
      if (await this.storyBouncer.debounce()) {
        await this.saveStory(story);
      }
    },
    async saveStory(story, db) {
      this.storyBouncer.cancel();
      story = story || this.story;
      if (story) {
        if (!story.uid && this.$root.user) {
          story.uid = this.$root.user.id;
        }
        story.updated = Date.now();

        // TODO: remove
        delete story._db;
        if (!db) {
          db = this.$stories;
        }
        try {
          await db.setItem(story.id, story);
        } catch (err) {
          console.error("failed to save story", err);
          this.findBadKey(story);
        }

        // TODO: save to file
        // const filename = await filenames.getItem(story.id);
      } else {
        throw new Error("Invalid story");
      }
    },
    async findBadKey(object, keys = "") {
      if (object && typeof object === "object") {
        try {
          await junk.setItem("test", object);
        } catch (e) {
          console.warn(">", keys, object);
          for (const key in object) {
            if (object[key] instanceof Array) {
              let i = 0;
              for (const item of object[key]) {
                this.findBadKey(item, `${keys} > ${key}[${i}]`);
                i += 1;
              }
            } else {
              this.findBadKey(object[key], `${keys} > ${key}`);
            }
          }
        }
      }
    },
    async mergeStories(response) {
      for (const original of response) {
        delete original._db;

        const match = await this.$stories.iterate((s) => {
          if ((original.fid && s.id == original.fid) || s.id == original.id) {
            return s;
          }
        });

        if (match) {
          if (original.updated < match.updated) {
            for (const prop in original) {
              match[prop] = original[prop];
            }
            await this.saveStory(match);
            await this.createStoryDocuments(match);
          }
        } else if (original.title) {
          original.uid = this.$root.user.id;
          await this.saveStory(original);
          await this.createStoryDocuments(original);
        }
      }
    },
    async migrateStory(story, db) {
      // log('migrating story', story.id, story.version);
      let changed = false;
      if (!story.id) {
        log("story is missing id");
        story.id = this.$root.id();
      }

      // check for missing data
      const missing = {
        version: 0,
        backgrounds: [],
        images: [],
        types: [{ id: "character", name: "character", attrs: [] }],
        blueprints: [],
        characters: [],
        media: [],
        attrs: {},
        styles: [],
        audio: [],
        music: [],
      };
      for (const prop in missing) {
        if (!story[prop]) {
          story[prop] = missing[prop];
        }
      }

      // fix misspelling of genre
      if (story.attrs.genera) {
        story.attrs.genre = story.attrs.genera;
        delete story.attrs.genera;
      }

      // missing animation
      if (!story.animation || story.animation.length === 0) {
        story.animation = [
          {
            id: "fade",
            name: "fade",
            duration: 200,
            pipeline: [
              { id: "1", track: 0, type: "opacity", duration: 0, opacity: 0 },
              { id: "2", track: 0, type: "empty", duration: 1 },
            ],
          },
        ];
      }

      // missing settings
      if (!story.settings || typeof story.settings !== "object") {
        story.settings = {};
      }

      // missing color labels
      if (!story.settings.colorLabels) {
        story.settings.colorLabels = {};
        const colorLabels = [
          "white",
          "black",
          "red",
          "orange",
          "yellow",
          "green",
          "blue",
          "violet",
        ];
        for (const color of colorLabels) {
          if (!story.settings.colorLabels[color]) {
            story.settings.colorLabels[color] = "";
          }
        }
        changed = true;
      }

      // TODO: convert particles to animations
      // for (const style of story.styles.filter(s => s.particles)) {
      //   log(style);
      // }

      if (story.version === 0) {
        for (const char of story.characters) {
          if (char.aura) {
            char.auraConfig = { color: char.aura };
            // delete char.auraConfig;
          }
          if (!char.attributes) {
            char.attributes = [];
          }
          if (!char.actions) {
            char.actions = [];
          }
          for (const emote of char.emotes) {
            if (!emote.position) {
              emote.position = "";
            }
            if (emote.aura) {
              emote.auraConfig = { color: emote.aura };
              // delete emote.aura;
            }
          }
          for (const attr of char.attributes) {
            if (attr.undefined && !attr.formula) {
              attr.formula = attr.undefined;
              delete attr.undefined;
            }
          }
        }

        //  convert tags to objects
        if (story.tags && story.tags.length > 0) {
          const tags = [];
          for (const tag of story.tags) {
            if (!tag.name) {
              tags.push({
                id: shortid(),
                name: tag,
              });
            } else {
              if (!tag.id) {
                tag.id = shortid();
              }
              tags.push(tag);
            }
          }
          story.tags = tags;
          changed = true;
        }

        // change to tag id
        for (const scene of story.scenes.filter((s) => s)) {
          for (const direction of scene.directions) {
            for (const effect of direction.effects || []) {
              if (!effect.id) {
                effect.id = shortid();
              }
              const tag = story.tags.find((t) => t.name == effect.tag);
              if (tag) {
                effect.tag = tag.id;
              }
            }
          }
        }

        // convert images to blobs
        for (const bg of story.backgrounds) {
          if (bg.url) {
            try {
              const media = await Media.importBlob(bg.url);
              bg.blob_id = media.id;
              delete bg.url;
              changed = true;
            } catch (ex) {
              console.error(ex);
            }
          }
        }
        for (const char of story.characters) {
          for (const emote of char.emotes) {
            if (emote.url) {
              try {
                const media = await Media.importBlob(emote.url);
                emote.blob_id = media.id;
                delete emote.url;
                changed = true;
              } catch (ex) {
                console.error(ex);
              }
            }
          }
        }
        for (const object of story.audio) {
          if (object.content) {
            try {
              const media = await Media.importBlob(object.content);
              object.blob_id = media.id;
              delete object.content;
              changed = true;
            } catch (ex) {
              console.error(ex);
            }
          }
        }
        for (const object of story.music) {
          if (object.content) {
            try {
              const media = await Media.importBlob(object.content);
              object.blob_id = media.id;
              delete object.content;
              changed = true;
            } catch (ex) {
              console.error(ex);
            }
          }
        }

        for (const scene of story.scenes.filter((s) => s)) {
          // directions
          if (!scene.directions) {
            scene.directions = [];
          }
          if (scene.text) {
            changed = true;
            scene.directions.push({ id: 1, type: "text", content: scene.text });
            delete scene.text;
          }
          if (scene.choices) {
            changed = true;
            for (const choice of scene.choices) {
              const id = scene.directions
                .filter((d) => d.id)
                .reduce((a, d) => Math.max(a, d.id + 1), 1);
              choice.id = id;
              choice.type = "choice";
              scene.directions.push(choice);
            }
            delete scene.choices;
          }

          // previous bugs in the sorting caused some directions to become undefined
          scene.directions = scene.directions.filter((d) => d);

          for (const d of scene.directions.filter((d) => !d.id)) {
            d.id = scene.directions.filter((x) => x.id).reduce((a, b) => Math.max(a, b.id + 1), 1);
          }
          delete scene.choices;

          for (const d of scene.directions) {
            // transform gives, requires, takes, excludes
            if (d.gives || d.requires || d.takes || d.excludes) {
              if (!d.effects) {
                d.effects = [];
              }

              for (const tag of d.requires || []) {
                d.effects.push({ tag, op: "#" });
              }
              delete d.requires;

              for (const tag of d.excludes || []) {
                d.effects.push({ tag, op: "!" });
              }
              delete d.excludes;

              for (const tag of d.gives || []) {
                d.effects.push({ tag, op: ":" });
              }
              delete d.gives;

              for (const tag of d.takes || []) {
                d.effects.push({ tag, op: "#" });
                d.effects.push({ tag, op: ":", value: 0 });
              }
              delete d.takes;

              changed = true;
            }
          }
        }
      }

      // strong start scene
      // if any acts are present force the first to be the start scene
      if (story.version < 2) {
        let start = story.scenes[0];
        const acts = story.scenes.filter((s) => s.act);
        const starts = story.scenes.filter((s) => s.start);
        if (acts.length > 0) {
          start = acts[0];
        } else if (starts.length > 0) {
          start = starts[0];
        }
        // clear starts
        starts.forEach((s) => (s.start = undefined));
        start.start = true;
        changed = true;
      }

      if (story.version < 3) {
        // give unique scene ids
        for (const scene of story.scenes) {
          if (typeof scene.id !== "string" || scene.id.length < 6) {
            const oldid = scene.id;
            scene.id = nanoid();
            log("fixing scene id", oldid, scene.id);
            changed = true;
            for (const s2 of story.scenes) {
              for (const d of s2.directions.filter((d) => d.scene === oldid)) {
                d.scene = scene.id;
              }
            }
          }
        }
      }

      if (changed) {
        log("migrated story,", story.version, "saving...");
        story.version = CURRENT_VERSION;
        this.saveStory(story, db);
      }
    },
    async importStory(
      blob: Blob,
      privateMedia = false,
      percent?: Ref<number>,
    ): Promise<{ story: Story; handlers: Promise<any>[] }> {
      const zip = new JSZip();
      await zip.loadAsync(blob);

      let retval = null;
      const files = [];

      zip.forEach(async (path, file) => {
        files.push([path, file]);
      });

      let images: Promise<any>[] = [];
      for (const [path, file] of files) {
        if (/__MACOSX/.test(path)) {
          // console.log('skipping macos');
        } else if (/.json$/.test(path)) {
          retval = JSON.parse(await file.async("string"));
        } else {
          const name = path.split(".")[0];
          images.push(
            file.async("blob").then((value) => {
              value.name = path;
              return Media.importBlob(value, name, privateMedia);
            }),
          );
        }
      }

      // breakdown permalinks so we can get a progress bar on those
      const fetched = await this.fetchPermalinks(retval, privateMedia, percent);
      images = [...images, ...fetched.handlers];

      await Promise.all(images);

      // don't cloudsync by default from imported stories
      retval.cloudSync = false;

      return retval;
    },
    async fetchPermalinks(
      story: FullStory,
      privateMedia = false,
      percent?: Ref<number>,
    ): Promise<{
      handlers: Promise<any>[];
      images: {}[];
    }> {
      log("--- downloading permalinks ---");
      const images: Permalink[] = [];
      const existing = {};

      for (const m of await Media.list()) {
        existing[m.id] = true;
      }

      for (const permalink of story.mediaManifest ?? []) {
        console.log(permalink.from, permalink.id, permalink.url);
        if (!existing[permalink.id]) {
          existing[permalink.id] = true;
          images.push(permalink);
        }
      }

      for (const type of ["backgrounds", "images", "audio", "music"]) {
        for (const asset of story[type] || []) {
          if (asset.permalink && !existing[asset.blob_id]) {
            existing[asset.blob_id] = true;
            images.push({
              id: asset.blob_id,
              url: asset.permalink,
              name: asset.name,
              type: "image/png",
            });
          }
        }
      }
      for (const char of story.characters || []) {
        for (const emote of (char.emotes || []).filter((e) => e.permalink)) {
          if (!existing[emote.blob_id]) {
            existing[emote.blob_id] = true;
            images.push({
              id: emote.blob_id,
              url: emote.permalink,
              name: emote.name,
              type: "image/png",
            });
          }
        }
      }
      const handlers = [];
      let completed = 0;
      const count = images.length;
      async function next() {
        const retval: Media[] = [];
        while (images.length > 0) {
          const permalink = images.shift();
          try {
            const media = await Media.importBlob(permalink.url, permalink.id, privateMedia);
            media.name = permalink.name || media.name;
            media.type = permalink.type || media.type;
            media.permalink = permalink.url;
            retval.push(media);
            // return media;
          } catch (error) {
            console.error("error importing permalink", permalink.url, error);
          }
          if (percent) {
            completed += 1;
            percent.value = completed / count;
          }
        }
        return retval;
      }
      for (let x = 0; x < 4; x += 1) {
        handlers.push(next());
      }
      return { handlers, images };
    },
    async downloadPermalinks(story: FullStory, privateMedia = false) {
      const permalinks = await this.fetchPermalinks(story, privateMedia);
      return Promise.all(permalinks.handlers);
    },
    async exportZip(storyId: string, excludeTypes: string[] = []): Promise<JSZip> {
      const story = await this.loadFullStory(storyId);
      delete story._db;
      delete story._server;
      delete story._meta;
      const zip = new JSZip();
      const permalinks: Permalink[] = [];
      const medias: Record<string, Media> = {};
      const blobs: Record<string, Blob> = {};
      const promises: Promise<void>[] = [];

      // TODO: count the bytes and limit the max size with an error
      const files = await collectMedia(story);

      // wait for all files
      for (const file of files) {
        if (!excludeTypes.includes(file.type)) {
          promises.push(
            (async () => {
              const media = await Media.load(file.id);
              medias[file.id] = media;
              if (media) {
                blobs[file.id] = await media.getBlob();
              }
            })(),
          );
        }
      }
      await Promise.all(promises);

      for (const file of files) {
        if (!excludeTypes.includes(file.type)) {
          const media = medias[file.id];
          if (media) {
            if (media.permalink) {
              permalinks.push({
                id: file.id,
                name: media.name,
                type: media.type,
                from: file.type,
                url: media.permalink,
              });
            } else {
              const ext = media.type.split("/")[1];
              zip.file(`${file.id}.${ext}`, blobs[file.id]);
              log("zipping", media, ext);
            }
          } else if (media) {
            console.error(file.type, "value is not a blob");
          }
        }
      }

      // add last so permalinks are included from patches above
      story.mediaManifest = permalinks;
      zip.file("story.json", JSON.stringify(story, null, 4));

      // TODO: move this
      if (story.update) {
        story.update({ lastExport: new Date().toISOString() });
        story.save();
      } else {
        console.error("Your story object is missing, but the export finished successfully");
      }
      return zip;
    },
    async exportStory(storyId: string, excludeTypes: string[] = []) {
      const zip = await this.exportZip(storyId, excludeTypes);
      return await zip.generateAsync({ type: "blob" });
    },
    async downloadStory(id: string, progress?: Ref<number>): Promise<FullStory> {
      const download = await this.ws("file_fetch", { id });
      if (download) {
        if (/^data:application\/zip/.test(download.content)) {
          const blob = await window.fetch(download.content);
          download.content = await this.importStory(await blob.blob(), true, progress);
        }
        download.content.fid = id;
        download.content.id = id;
        // download.content.timestamp = this.story.timestamp;
        await this.saveStory(download.content, this.$reading);
        return await this.loadFullStory(id, this.$reading, false);
      } else {
        throw new Error("Failed to download");
      }
    },
    async downloadFile(data: Blob | object | string, filename = "data.json", type = "text/plain") {
      if (data instanceof Blob) {
      } else {
        data = JSON.stringify(data, null, 4);
      }
      if (this.$root.flags.capacitor) {
        const file = await Filesystem.writeFile({
          path: filename,
          data,
          directory: Directory.Cache,
          encoding: /^text\//.test(type) ? Encoding.UTF8 : null,
          recursive: true,
        });
        console.log("file", filename, file);
        try {
          await Share.share({
            title: filename,
            text: filename,
            url: file.uri,
            dialogTitle: "Download",
          });
        } catch (e) {}
      } else {
        let dataurl = `data:${type};charset=utf-8,${encodeURIComponent(data)}`;
        if (data instanceof Blob) {
          dataurl = await URL.createObjectURL(data);
        }
        const link = document.createElement("a");
        link.download = filename;
        link.href = dataurl;
        link.click();
      }
    },
    /**
     * @param {Array<Blob>} files
     * @param {String} description - Explanation for share
     */
    async downloadFiles(fileList: Blob[], description = "Download Files") {
      if (this.$root.flags.capacitor) {
        const files = await Promise.all(
          fileList
            .filter((a) => a)
            .map(async (file) => {
              let filename = file.name || "file";
              if (file.type) {
                filename += `.${file.type ? file.type.split("/")[1] : ""}`;
              }
              const dataString = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = (e) => {
                  resolve(e.target.result);
                };
                reader.readAsDataURL(file);
              });
              return Filesystem.writeFile({
                path: filename,
                data: dataString,
                directory: Directory.Cache,
              });
            }),
        );
        await Share.share({
          text: description,
          dialogTitle: "Download",
          files: files.map((file) => file.uri),
        });
      } else {
        for (const blob of fileList) {
          console.log(blob);
          let filename = blob.name || "file";
          filename += `.${blob.type ? blob.type.split("/")[1] : "blob"}`;
          let dataurl = await URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.download = filename;
          link.href = dataurl;
          link.click();
        }
      }
    },
    fixCDNPermalink,
    async toggleFavorite(file) {
      await this.$root.toggleFileFavorite(file);
    },
    textStyle,
    findTag,
  },
};
