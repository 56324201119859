import { useImageStore } from "@/stores/images";
import PlatformDriver from "@morphosis/base/utils/file/PlatformDriver";
import compress from "../compress";
import FilesystemModel from "./filesystem_model";

function cacheBusted(originalUrl) {
  let url = originalUrl;
  if (!/^data:/.test(url)) {
    if (/\?/.test(url)) {
      url += "&";
    } else {
      url += "?";
    }
    url += `dt=${Date.now()}`;
  }
  return url;
}

export default class Media extends FilesystemModel {
  static get objectName() {
    return "media";
  }

  static get defaultObject() {
    return {
      id: null,
      name: "",
      type: "image/png",
      permalink: null,
      cloud: false,
      cloudSafe: false,
      width: null,
      height: null,
      updated: new Date().toISOString(),
      isPrivate: null,
      size: null,

      // @deprecated
      thumb: null,
    };
  }

  static get blobs() {
    if (!Media._blobs) {
      Media._blobs = new PlatformDriver("mediablobs");
    }
    return Media._blobs;
  }

  static get thumbnails() {
    if (!Media._thumbnails) {
      Media._thumbnails = new PlatformDriver("mediathumbnails");
    }
    return Media._thumbnails;
  }

  static get canvases() {
    if (!Media._canvases) {
      Media._canvases = new PlatformDriver("mediacanvases");
    }
    return Media._canvases;
  }

  static async importBlob(blob, id = null, isPrivate = false) {
    const blob_id = id || blob.blob_id;

    if (typeof blob === "string") {
      blob = await (await fetch(cacheBusted(blob))).blob();
    }

    // get existing media if available
    let media = null;
    if (blob_id) {
      media = await Media.load(blob_id);
    }
    if (!media) {
      const ext = blob.type ? blob.type.split("/")[1] : "";
      media = new Media({
        id: blob_id,
        name: blob_id + ext,
      });

      if (!media.id) {
        await media.save();
      }
    }

    // save new blob and check if it is an image
    return media.importBlob(blob, isPrivate);
  }

  static async clearCachedUrls() {
    console.error(" --> clearCachedUrls");
    for (const key in Media.cachedUrls) {
      URL.revokeObjectURL(Media.cachedUrls[key]);
      delete Media.cachedUrls[key];
    }
    const store = useImageStore();
    await store.clear();
  }

  static async ready() {
    await Promise.all([Media.canvases.ready(), Media.blobs.ready(), Media.thumbnails.ready()]);
  }

  get canvas() {
    return Media.canvases.getItem(this.id);
  }

  set canvas(value) {
    this.setCanvas(value);
  }

  async setCanvas(value) {
    this.updated = new Date().toISOString();
    return Media.canvases.setItem(this.id, value);
  }

  get isImage() {
    return /^image/.test(this.type);
  }

  get isAudio() {
    return /^audio/.test(this.type);
  }

  toString() {
    return this.name;
  }

  async thumbnailImage(blob = null, isPrivate = false) {
    const tkey = `t.${this.id}`;
    if (Media.cachedUrls[tkey]) {
      await URL.revokeObjectURL(Media.cachedUrls[tkey]);
      delete Media.cachedUrls[tkey];
    }
    if (blob) {
      let url = null;
      if (typeof blob === "string") {
        blob = await (await fetch(cacheBusted(blob))).blob();
      }
      if (blob.size === 0) {
        throw new Error("blob is empty");
      }
      try {
        url = await compress(blob, {
          maxWidthOrHeight: 200,
          useWebWorker: app.platform !== "ios",
        });
      } catch (e) {
        throw e;
      }

      const tblob = await (await fetch(url)).blob();
      if (!isPrivate) {
        await Media.thumbnails.setItem(this.id, tblob);
      }
      return tblob;
    } else if (this.id) {
      this.thumb = null;
      await Media.thumbnails.removeItem(this.id);
      return null;
    }
  }

  async getBlob() {
    if (this.id) {
      let blob = await Media.blobs.getItem(this.id);
      // if (!blob && this.data.content) {
      //   const content = this.data.content;
      //   this.data.content = null;
      //   await this.importBlob(content);
      //   blob = await Media.blobs.getItem(this.id);
      // }
      return blob;
    }
  }

  async getUrl(existing = null) {
    if (this.id && !Media.cachedUrls[this.id]) {
      let blob = existing || (await this.getBlob());
      if (blob) {
        if (typeof blob === "string") {
          blob = await (await fetch(cacheBusted(blob))).blob();
          console.log("blob is a string, fixing", this.id, blob);
        }
        Media.blobs.setItem(this.id, blob);
        Media.cachedUrls[this.id] = URL.createObjectURL(blob);
      }
    }
    return Media.cachedUrls[this.id];
  }

  async getDataUrl() {
    const blob = await this.getBlob();
    if (blob) {
      const url = new Promise((resolve, reject) => {
        var a = new FileReader();
        a.onload = (e) => resolve(e.target.result);
        a.onerror = reject;
        a.readAsDataURL(blob);
      });
      return url;
    }
    return "";
  }

  async getThumbnail(blob = null) {
    const tkey = `t.${this.id}`;

    // get new blob if needed
    if (!blob && this.thumb) {
      blob = await (await fetch(this.thumb)).blob();
    }

    // this property is deprecated
    if (this.thumb) {
      this.thumb = null;
      await this.save();
    }

    // revoke old thumbnail
    if (blob) {
      if (Media.cachedUrls[tkey]) {
        await URL.revokeObjectURL(Media.cachedUrls[tkey]);
        delete Media.cachedUrls[tkey];
      }
    }

    if (blob || !Media.cachedUrls[tkey]) {
      // if (!blob) {
      //   blob = await Media.thumbnails.getItem(this.id);
      // } else {

      // if no thumbnail was given and this media is an image...
      if (!blob && this.isImage) {
        blob = await this.getBlob();
      }

      // then thumbnail it
      if (blob) {
        blob = await this.thumbnailImage(blob);
      }
      // }
      if (blob) {
        Media.cachedUrls[tkey] = URL.createObjectURL(blob);
      }
    }
    return Media.cachedUrls[tkey];
  }

  async cleanImage(file) {
    if (["image/svg", "image/svg+xml", "image/gif"].includes(file.type)) {
      return { result: file, error: null };
    }
    try {
      file = await compress(file, {
        // maxWidthOrHeight: 2048,
        maxWidthOrHeight: 3840,
        useWebWorker: app.platform !== "ios",
      });
      const str = await compress.getDataUrlFromFile(file);
      return { result: str, error: null };
    } catch (ex) {
      return { result: null, error: ex };
    }
  }

  async importBlob(blob, isPrivate = false) {
    if (typeof blob === "string") {
      blob = await (await fetch(cacheBusted(blob))).blob();
    }

    // need to get an id
    if (!this.id) {
      await this.save();
    }

    if (!isPrivate) {
      await Media.blobs.setItem(this.id, blob);
    }
    if (Media.cachedUrls[this.id]) {
      URL.revokeObjectURL(Media.cachedUrls[this.id]);
      delete Media.cachedUrls[this.id];
      Media.cachedUrls[this.id] = null;
    }

    const url = await this.getUrl(blob);
    let image = null;
    try {
      image = await new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = () => reject(new Error("Invalid image"));
        image.src = url;
      });
    } catch (err) {
      // not an image
    }
    if (blob.name) {
      this.name = blob.name;
    }
    if (blob.type && blob.type !== "application/octet-stream") {
      this.type = blob.type;
    }

    if (image) {
      this.width = image.width;
      this.height = image.height;
      await this.thumbnailImage(blob, isPrivate);
    } else {
      await this.thumbnailImage(null, isPrivate);
    }

    this.size = blob.size;
    this.isPrivate = isPrivate;
    this.updated = new Date().toISOString();
    await this.save();
    return this;
  }

  async save() {
    if (this.data.content) {
      console.log(" --> save", this.id);
      const content = this.data.content;
      this.data.content = null;
      await this.importBlob(content);
    }
    if (this.id && !this.size) {
      this.size = (await this.getBlob()).size;
    }
    if (this.id && this.data.canvas) {
      await this.setCanvas(this.data.canvas);
      delete this.data.canvas;
    }

    return super.save();
  }

  async push() {
    console.warn("pushing");
    const content = await this.getDataUrl();
    if (content) {
      console.log("push", this.name, this.type);
      const response = await Media.objects.push(
        {
          id: this.id,
          client: localStorage.clientid,
        },
        {
          content,
          model: "media",
          public: true,
          updated: this.updated,
          name: this.name,
          content_type: this.type,
        },
      );
      let shouldSave = false;
      if (response.error && (/moderation/gi.test(response.error) || response.code === "nsfw")) {
        this.permalink = "";
        this.cloud = false;
        this.cloudSafe = false;
        shouldSave = true;
      } else if (response.url && this.permalink !== response.url) {
        this.permalink = response.url;
        shouldSave = true;
      }
      if (shouldSave) {
        await this.save();
      }
      return response;
    }
  }

  async pushDelete() {
    return Media.objects.pushDelete(this.id);
  }

  async pull() {
    console.warn("pulling");
    const response = await window.app.http("db_pull", {
      id: this.id,
      model: this.constructor.objectName,
    });

    console.log("pull response", response);

    if (response.ok) {
      const record = response.record;
      const permalink = response.record.permalink || response.record.url;
      if (record.name) {
        this.name = record.name;
      }
      if (record.content_type) {
        this.type = record.content_type;
      }
      if (permalink) {
        this.permalink = permalink;
        this.cloud = true;
        await this.importBlob(permalink);

        // overwrite the update change from importing a blob
        this.updated = response.record.updated;
      }
      this.save();
    } else {
      await this.push();
    }
    return null;
  }

  async sync(server, lazyPull = false) {
    const serverDate = new Date(server.updated || 1);
    const objectDate = new Date(this.updated || 1);

    if (serverDate.getTime() > objectDate.getTime()) {
      console.log("media sync pull", this.id, serverDate);
      const url = server.permalink || server.content;
      if (url) {
        console.log("server in pull", server);
        if (server.name) {
          this.name = server.name;
        }
        if (server.type) {
          this.type = server.type;
        }
        this.permalink = url;
        this.cloud = true;
        await this.importBlob(url);
        this.updated = server.updated;
        await this.save();
      } else {
        await this.pull();
      }
      return true;
    } else if (serverDate.getTime() < objectDate.getTime()) {
      console.warn(
        "media sync push",
        this.id,
        this.cloud,
        serverDate.toISOString(),
        objectDate.toISOString(),
      );
      await this.push();
    }
    return false;
  }

  async dispose() {
    console.log("--> disposed", this.id);
    if (Media.cachedUrls[this.id]) {
      URL.revokeObjectURL(Media.cachedUrls[this.id]);
      delete Media.cachedUrls[this.id];
    }
    const tkey = `t.${this.id}`;
    if (Media.cachedUrls[tkey]) {
      URL.revokeObjectURL(Media.cachedUrls[tkey]);
      delete Media.cachedUrls[tkey];
    }
  }

  async delete() {
    await Media.blobs.removeItem(this.id);
    try {
      await Media.canvases.removeItem(this.id);
    } catch (ex) {}
    try {
      await Media.thumbnails.removeItem(this.id);
    } catch (ex) {}

    console.log("--> deleted");
    // Media.clearCachedUrls();
    return super.delete();
  }
}

Media.cachedUrls = {};
