<style scoped>
  .sidebar-detail {
    display: grid;
    grid-template-columns: 1fr;
    flex: 1 1 auto;
    align-self: stretch;
    justify-self: stretch;
    min-height: 1px;
    overflow: hidden;
    position: relative;
  }
  .sidebar-detail.keep-sidebar-open {
    grid-template-columns: 1fr 2fr;
  }
  .sidebar-detail.keep-sidebar-open .list-column {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
  .sidebar-detail.keep-sidebar-open .detail {
    grid-row: 1 / 1;
    grid-column: 2 / 2;
  }
  .sidebar-detail .list-column {
    max-width: 480px;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
    z-index: 10;
  }
  .sidebar-detail .detail {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
  .list-column {
    background: var(--color2);
    color: var(--color2_text);
    flex: 0 1 auto;
    align-self: stretch;
    justify-self: stretch;
    display: flex;
    flex-direction: column;
  }
  .buttons {
    flex: 0 0 auto;
    padding: 10px;
    background: var(--color1);
    color: var(--color1_text);
  }
  @media (max-width: 480px) {
    .sidebar-detail {
      display: grid;
      grid-template-columns: 1fr;
      align-self: stretch;
      justify-self: stretch;
      flex: 1 1 auto;
    }
    .sidebar-detail .list-column,
    .sidebar-detail .detail {
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }
    .sidebar-detail .list-column {
      z-index: 10;
    }
    .detail.empty {
      display: none;
    }
  }
  .sidebar-detail .list-item {
    align-items: stretch;
    color: var(--color2_text);
    flex: 0 0 auto;
    padding: 15px;
    border-bottom: 1px solid var(--color3);
  }
  .sidebar-detail .list-item:last-child {
    border-bottom: none;
  }
  .sidebar-detail .detail {
    align-self: stretch;
    justify-self: stretch;
    max-height: calc(100vh - 60px);
  }
  .backdrop {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 9;
  }
  .sidebar a {
    color: var(--color3_text);
  }
</style>

<template>
  <div class="sidebar-detail no-scroll" :class="{'keep-sidebar-open': keepSidebarOpen}">
    <transition name="fade">
      <div
        v-if="modelValue"
        key="backdrop"
        @click="$emit('update:modelValue', false)"
        class="backdrop"
      />
    </transition>
    <transition name="transform">
      <div
        v-if="modelValue || tabletSized"
        key="sidebar"
        class="list-column mellow no-scroll"
      >
        <slot name="sidebar">
          - sidebar -
        </slot>
      </div>
    </transition>

    <div class="detail scroll">
      <slot>
        - detail -
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidebarDetail',
  props: {
    modelValue: { type: Boolean, default: true },
    keepSidebarOpen: { type: Boolean, default: true },
  },
  data() {
    return {
      tabletSized: this.keepSidebarOpen && document.documentElement.clientWidth > 480,
    };
  },
  mounted() {
    this.resizeListener = () => {
      this.tabletSized = this.keepSidebarOpen && document.documentElement.clientWidth > 480;
    };
    window.addEventListener('resize', this.resizeListener);
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeListener);
  },
};
</script>
