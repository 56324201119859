<template>
  <div key="thoughts" class="tab">
    <help-message name="choice-tags-thoughts">
      {{ $t('direction.thoughts-help') }}
    </help-message>
    <div class="form-row">
      <thought-array
        :value="value.thoughts || []"
        @update:value="handleUpdate"
      />
    </div>
  </div>
</template>

<script>
import ThoughtArray from '../thought_array.vue';

export default {
  name: 'ThoughtsTab',
  components: {
    ThoughtArray,
  },
  props: {
    story: Object,
    scene: Object,
    value: Object,
  },
  methods: {
    handleUpdate(e) {
      this.$emit('update', { thoughts: e });
    },
  }
}
</script>
