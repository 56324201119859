import { runSimpleFormula } from './tags';
import { nanoid } from 'nanoid';


export default class Blueprint {
  constructor(meta, config, data = {}) {
    this.$meta = meta;
    this.$config = config;
    // TODO: might need to copy this
    this.data = data;
    if (!this.data.id) {
      this.data.id = nanoid();
    }
    if (!this.data.name) {
      this.data.name = config.name;
    }
    for (const attr of (this.$meta.attrs) || []) {
      if (data[attr.name] === undefined) { // don't forget, zero is falsy
        const value = (this.$config.values || []).find(v => v.name === attr.name);
        if (value) {
          this.data[value.name] = runSimpleFormula(`${value.formula}`) || null;
        } else {
          if (attr.type === 'number') {
            this.data[attr.name] = parseFloat(attr.default || 0);
          } else if (attr.type === 'text') {
            this.data[attr.name] = attr.default || '';
          } else if (attr.type === 'boolean') {
            this.data[attr.name] = !!(attr.default || false);
          } else {
            this.data[attr.name] = attr.default || null;
          }
        }
      }
      Object.defineProperty(this, attr.name, {
        get: () => this.getValue(attr.name),
        set: (v) => { this.setValue(attr.name, v); },
      });
    }
  }

  get id() {
    return this.data.id;
  }

  get name() {
    return this.data.name;
  }

  getValue(n) {
    if (this.data[n] || this.data[n] === 0) {
      return this.data[n];
    }
    else if (this.$config[n]) {
      return runSimpleFormula(this.$config[n].formula, this.data)
    }
  }

  setValue(n, v) {
    this.data[n] = v;
  }

  toJSON() {
    const retval = {
      $meta: [this.$meta.id, this.$config.id],
    };
    Object.keys(this.data).forEach((key) => {
      retval[key] = this.data[key];
    })
    return retval;
  }

  static parse(story, value) {
    if (!(value instanceof Blueprint) && value.$meta) {
      let [meta, config] = value.$meta;

      meta = story.allTypes.find((type) => type.id === meta);
      config = story.blueprints.find((bp) => bp.id === config);
      if (!config && meta.name === '$player') {
        config = { id: '$player', name: '$player' };
      }

      if (meta && config) {
        delete value.$meta;
        return new Blueprint(meta, config, value);
      }
    }
    return value;
  }
}
