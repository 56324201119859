<template>
  <div class="padded">
    <b>{{ $t('choice_content.multiplayer') }}</b>
    <div class="auto-form">
      <div class="form-row">
        <label>{{ $t('choice_content.change-group') }}</label>
        <div class="flex-column">
          <div class="flex-row">
            <input
              type="text"
              name="change-group"
              :value="value.split"
              @input="$emit('update', { split: $event.target.value })">
            <button @click.prevent="$refs.formula.show()" class="accented">
              <vi name="formula" />
            </button>
          </div>
          <formula-popup
            ref="formula"
            :story="story"
            :value="value.split"
            @input="$emit('update', { split: $event })" />
          <div class="help">
            {{ $t('choice_content.split-explanation') }}
          </div>
        </div>
      </div>
      <div class="form-row">
        <label>{{ $t('multiplayer.wait-for') }}</label>
        <div>
          <input-array
            :value="value.waitFor || []"
            :placeholder="$t('multiplayer.type-a-group-to-wait-for')"
            @update:value="$emit('update', { waitFor: $event })" />
          <div class="help">
            {{ $t('multiplayer.if-set-these-groups-must-be-present-at-this-exact-direction-before-the-story-can-continue') }}
          </div>
        </div>
      </div>
      <div class="row">
        <label>{{ $t('multiplayer.player-count') }}</label>
        <div>
          <input
            type="number"
            :value="value.playerCount"
            @update:value="$emit('update', { playerCount: $event.target.value })"
            :min="1"
            :max="4"
            placeholder="1">
          <help-message name="multiplayer-redirect-split">
            {{ $t('multiplayer.if-a-group-is-set-a-limited-number-of-players-will-be-split-away-from-the-current-party') }}
          </help-message>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TagPicker from '../editor/tag_picker.vue'

export default {
  name: 'AdvancedTab',
  components: {
    TagPicker,
  },
  props: {
    story: Object,
    scene: Object,
    value: Object,
  },
}
</script>
