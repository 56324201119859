<style scoped>
.achievement-content {
  padding-bottom: 5px;
}
.selected.image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.selected.image img {
  max-height: 250px;
  max-width: 250px;
}
.responsive-side {
  align-self: flex-start;
  padding: 5px 10px;
}
.formrow {
  padding: 5px 0px;
  flex-direction: row;
  align-items: center;
}
.formrow label,
.formrow .controls {
  font-size: 17px;
}
.formrow label {
  flex: 0 0 40%;
  justify-content: flex-end;
  padding: 0 10px;
  text-align: right;
}
.formrow .controls {
  flex: 0 0 60%;
  justify-content: flex-start;
}
</style>

<template>
  <div class="achievement-content">
    <help-message name="achievement-explanation">
      {{ $t('achievement_content.achievements-track-user-progress-and-can-show-shiny-popups') }}
    </help-message>
    <div class="row wrap">
      <div class="responsive-main">
        <autogrow-textarea
          :modelValue="value.title"
          :style-name="value.titleStyle"
          :speed="value.titleSpeed"
          :story="story"
          :placeholder="$t('achievement_content.add-title-text')"
          ref="textarea"
          class="stretch"
          @update:speed="$emit('update', {titleSpeed: $event})"
          @update:style-name="$emit('update', {titleStyle: $event})"
          @update:modelValue="$emit('update', {title: $event})"
          :maxlength="50"
        />
        <autogrow-textarea
          :modelValue="value.content"
          :style-name="value.style"
          :speed="value.speed"
          :story="story"
          :placeholder="$t('text_content.add-text-here')"
          ref="textarea"
          class="stretch"
          @update:speed="$emit('update', {speed: $event})"
          @update:style-name="$emit('update', {style: $event})"
          @update:modelValue="$emit('update', {content: $event})"
        />
      </div>
      <div class="column responsive-side">
        <div class="formrow">
          <label>
            {{ $t('achievement_content.animation') }}:
          </label>
          <div class="controls">
            <animation-chooser
              :label="$t('achievement_content.choose')"
              :story="story"
              :value="value.animationId"
              @input="$emit('update', {animationId: $event})"
            />
          </div>
        </div>
        <div class="formrow">
          <label for="code">{{ $t('achievement_content.code') }}:</label>
          <div class="controls">
            <help-message name="achievement-code">
              {{ $t('achievement_content.adding-a-code-will-save-reader-statistics') }}
            </help-message>
            <input id="code" :value="value.code" @input="$emit('update', {code: $event.target.value})" maxlength="10">
          </div>
        </div>
        <div class="formrow">
          <label for="hidden">{{ $t('achievement_content.hidden') }}:</label>
          <div class="controls">
            <help-message name="achievement-hidden">
              {{ $t('achievement_content.achievement-will-not-be-revealed-on-story-detail-page') }}
            </help-message>
            <input id="hidden" :checked="value.hidden" @input="$emit('update', {hidden: $event.target.checked})" type="checkbox">
          </div>
        </div>
        <div class="formrow">
          <label for="silent">{{ $t('achievement_content.silent') }}:</label>
          <div class="controls">
            <help-message name="achievement-silent">
              {{ $t('achievement_content.readers-will-not-see-a-popup') }}
            </help-message>
            <input id="silent" :checked="value.silent" @input="$emit('update', {silent: $event.target.checked})" type="checkbox">
          </div>
        </div>
        <!-- <div class="formrow">
          <label for="modal">
            :
          </label>
          <div class="controls">
            <input name="modal" type="checkbox" :checked="value.modal" />
          </div>
        </div> -->
        <!-- <select>
          <option v-for="option of options" :key="option.value" :value="option.value">
            {{ option.label }}
          </option>
        </select> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AchievementContent",
  props: {
    story: Object,
    value: Object,
  },
  data() {
    return {
      options: [
        {label: this.$t('achievement_content.small'), value: 'small'},
        {label: this.$t('achievement_content.large'), value: 'large'},
      ],
      selecting: !this.value.content,
    };
  },
  computed: {
    image() {
      return this.images.find(a => a.id == this.value.content);
    },
    images() {
      return this.story.images;
    }
  },
}
</script>
