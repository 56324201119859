<template>
  <ObjectList
    v-model:list="objectList"
    @update:id="emit('update:id', $event)"
    @update:action="emit('update:action', $event)"
    :id="props.id"
    :action="props.action"
    :size="200"
    :showHighlight="props.showHighlight"
  >
    <template #highlight="{ object }">
      <div class="flex-row flex-center">
        <div class="header-detail">
          <BackgroundDetail :story="story" :instance="object" />
        </div>
      </div>
    </template>
    <template #item="{ object }">
      <div v-if="object.action" class="action flex-row">
        <vi v-if="object.icon" :name="object.icon" />
        <i>{{ object.name }}</i>
      </div>
      <div v-else class="bg">
        <blob-image
          :thumb="true"
          :blob="object.blob_id"
          v-if="object.blob_id"
        />
        <div class="name flex-static">
          {{ object.name }}
        </div>
      </div>
    </template>
    <template #edit="{ object }">
      <BackgroundDetail
        @update:instance="handleSave(object, $event)"
        :story="story"
        :instance="object"
        :editing="true"
      />
    </template>
  </ObjectList>
</template>

<script setup lang="ts">
import ObjectList from '@/components/editor/admin/ObjectList.vue';
import Story from '@/models/story';

import BackgroundDetail from '../detail/BackgroundDetail.vue';
import { createSaveHandler } from '../utils';

const props = defineProps<{
  story: Story;
  id?: string;
  action?: string;
  showHighlight?: boolean;
}>();
const emit = defineEmits(["update:story", 'update:id', 'update:action', 'action']);
const { objectList, handleSave } = createSaveHandler(
  props.story, 'backgrounds', emit
);
</script>

<style scoped>
.header-detail {
  max-height: 200px;
  max-width: 600px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  position: relative;
}
.header-detail .options {
  position: absolute;
  top: 0.25em;
  right: 0.25em;
}
.bg {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  overflow: hidden;
  max-width: 500px;
  position: relative;
}
.bg img {
  background-color: white;
  flex: 1 1 auto;
  object-fit: cover;
  background-position: center;
  max-height: 100%;
}
.action {
  display: flex;
  grid-column: 1/3;
  height: 100%;
  padding: 5px;
}

.name {
  font-size: 0.9em;
  opacity: 0.7;
  background: white;
  color: black;
  padding: 5px;
  position: absolute;
  text-align: left;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
