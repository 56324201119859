import { Parser } from 'expr-eval';
import shortid from 'shortid';

const parser = new Parser();
parser.functions.array = (len) => new Array(len);


export default class Character {
  constructor(sheet, base = null) {
    this.id = shortid();
    this.base = base || {};
    this.sheet = sheet;
    this.team = null; // battle team
    this.results = null; // battle results
    this.reset();
  }

  reset() {
    this.setInitiative();
    this.modifiers = [];
    this.heat = {};
    this.actionFormulas = {};
    this.effectFormulas = {};
    this.requireFormulas = {};
    this.sheet.actions = this.sheet.actions ? this.sheet.actions : [];
    this.sheet.attributes = this.sheet.attributes ? this.sheet.attributes : [];
    this.sheet.actions.forEach(a => {
      try {
        this.actionFormulas[a.name] = a.formula ? parser.parse(a.formula.toLowerCase()) : null;
        this.requireFormulas[a.name] = a.requires ? parser.parse(a.requires.toLowerCase()) : null;
        this.effectFormulas[a.name] = a.effectFormula ? parser.parse(a.effectFormula.toLowerCase()) : null;
        this.heat[a.name] = 0;
      } catch (ex) {
        console.error(ex);
        for (const dict of [this.actionFormulas, this.requireFormulas, this.effectFormulas, this.heat]) {
          if (dict[a.name]) {
            dict[a.name] = undefined;
          }
        }
      }
    });
    this.calcBaseValues();
  }

  alive() {
    for (const vital of this.sheet.attributes.filter(a => a.vital)) {
      const label = (vital.abbrev || vital.name).toLowerCase();
      if (this.value(label) <= 0) {
        return false;
      }
    }
    return true;
  }

  attr(name) {
    return this.sheet.attributes.find(a => {
      return a.name.toLowerCase() === name.toLowerCase() ||
        (a.abbrev || '').toLowerCase() === name.toLowerCase();

    });
  }

  getAttrLabel(name) {
    const attr = this.attr(name);
    let retval = name;
    if (attr) {
      retval = attr.abbrev || attr.name;
    }
    return retval.toLowerCase();
  }

  mods(name, mode = 'out') {
    return this.modifiers.filter(m => (
      (
        !name ||
        m.attr.toLowerCase() === name.toLowerCase()
      )
      && (!mode || m.mode === mode)
    ));
  }

  addMod(mod) {
    this.modifiers.push({
      attr: mod.attr,
      mode: mod.mode,
      turns: mod.turns,
      f: typeof mod.formula === 'string' ? parser.parse(mod.formula.toLowerCase()) : mod.formula,
    });
  }

  calcBaseValues() {
    const retval = {};
    for (const attr of this.sheet.attributes.filter(a => !a.formula)) {
      const label = attr.abbrev || attr.name;
      let base = 0;
      if (this.base[label]) {
        base = this.base[label];
      } else {
        base = parseInt(attr.default) || 0;
      }

      if (attr.abbrev) {
        retval[attr.abbrev.toLowerCase()] = base;
      } else {
        retval[attr.name.toLowerCase()] = base;
      }
    }
    this.baseValues = retval;
    return retval;
  }

  baseValue(name) {
    const label = this.getAttrLabel(name);
    return this.baseValues[label] || 0;
  }

  value(name) {
    const attr = this.attr(name);
    let value = null;
    if (attr && attr.formula) {
      try {
        const formula = parser.parse(attr.formula.toLowerCase());
        const args = this.getFormulaArgs(formula);
        value = Math.floor(formula.evaluate(args)) + this.baseValue(name);
      } catch(ex) {
        value = 0;
      }
    } else {
      value = this.baseValue(name);
    }
    return this.modifyValue(name, 'out', value);
  }

  modifyValue(name, mode, value) {
    let val = value;
    for (const mod of this.mods(name, mode)) {
      this.baseValues.$value = val;
      const delta = Math.floor(mod.f.evaluate(this.getFormulaArgs(mod.f)));
      if (mod.f.variables().indexOf('$value') > -1) {
        val = delta;
      } else {
        val += delta;
      }
    }
    return val;
  }

  modify(name, delta) {
    if (delta !== 0) {
      let d = this.modifyValue(name, 'in', Math.abs(delta));

      // effects can't be reversed
      if (delta > 0) {
        d = Math.max(0, d);
      } else {
        d = -Math.max(0, d);
      }

      const attr = this.attr(name);
      const label = this.getAttrLabel(name);
      let value = (this.baseValues[label] || 0) + d;
      if (attr) {
        if (attr.min || attr.min === 0) {
          value = Math.max(attr.min, value);
        }
        if (attr.max || attr.max === 0) {
          value = Math.min(attr.max, value);
        }
      }
      this.baseValues[label] = value;
      return d;
    }
    return 0;
  }

  setValue(name, value) {
    const attr = this.attr(name);
    if (attr) {
      const label = this.getAttrLabel(name);
      if (attr.min || attr.min === 0) {
        value = Math.max(attr.min, value);
      }
      if (attr.max || attr.max === 0) {
        value = Math.min(attr.max, value);
      }
      this.baseValues[label] = value;
      return value;
    }
  }

  setValues(values) {
    for (const key in values) {
      if (!/\$/.test(key)) {
        this.setValue(key, values[key]);
      }
    }
  }

  getFormulaArgs(f) {
    const args = {};
    for (const v of f.variables()) {
      if (/^\$rand(\d+)/.test(v)) {
        const match = /^\$rand(\d+)/.exec(v);
        // eslint-disable-next-line no-param-reassign
        args[v] = Math.floor(Math.random() * (parseInt(match[1], 10) + 1));
      } else {
        args[v] = this.value(this.getAttrLabel(v));
      }
    }
    return args;
  }

  canPerform(name, targets=null, world=null) {
    const action = this.sheet.actions.find(a => a.name === name);

    if (!action) {
      return {possible: false, reason: 'Action unavailable'};
    }

    if (this.sheet.actions.filter(a => a.name === name).length > 1) {
      return {possible: false, reason: `Writer didn't make action names unique`};
    }
    if (!action.effectAttr) {
      return {possible: false, reason: `Writer didn't set "Effect Attribute"`};
    }
    if (!action.effectFormula) {
      return {possible: false, reason: `Writer didn't set "Effect Formula"`};
    }
    if (action.cost && action.costAttr) {
      const cost = this.modifyValue(action.costAttr, 'in', action.cost);
      if (cost > this.value(action.costAttr)) {
        return {possible: false, reason: `requires ${cost} ${action.costAttr}`};
      }
    }

    if (action.cooldown) {
      if (this.heat[action.name]) {
        return {possible: false, reason: `cooldown for ${this.heat[action.name]} turns`};
      }
    }

    return {possible: true};
  }

  perform(name, targets) {
    const action = this.sheet.actions.find(a => a.name === name);

    if (action.cost && action.costAttr) {
      this.modify(action.costAttr, -action.cost);
    }
    if (action.cooldown) {
      this.heat[action.name] += parseFloat(action.cooldown);
    }

    const test = this.actionFormulas[name];
    const args = test && this.getFormulaArgs(test);
    const success = test && test.evaluate(args);
    if (test === null || (typeof(success) === 'boolean' && success === true) || (success > 0)) {
      const attrLabel = this.getAttrLabel(action.effectAttr);
      const modifiers = {};
      for (const target of targets) {
        const f = this.effectFormulas[name];
        const before = target.value(attrLabel);

        // 'buff', 'bless', 'poison'
        if (action.effect === 'bless' || action.effect === 'poison') {
          const modifier = {
            attr: attrLabel,
            mode: 'round',
            formula: f,
            before,
            turns: action.effectTurns,
            name: target.sheet.name,
          };
          target.addMod(modifier);
          const after = target.value(attrLabel);
          modifier.effectiveAmount = after - before;
          modifiers[target.id] = modifier;
        } else if (action.effect === 'debuff' || action.effect === 'buff') {
          const modifier = {
            attr: attrLabel,
            mode: 'out',
            formula: f,
            before,
            turns: action.effectTurns,
            name: target.sheet.name,
          };
          target.addMod(modifier);
          const after = target.value(attrLabel);
          modifier.effectiveAmount = after - before;
          modifiers[target.id] = modifier;
        } else {
          let q = 1;
          try {
            const eArgs = this.getFormulaArgs(f);
            q = Math.floor(f.evaluate(eArgs));
          } catch (ex) {
            console.error('evaluate error', ex, f, args);
            q = 0;
          }

          const amount = target.modify(attrLabel, q);
          const after = target.value(attrLabel);
          modifiers[target.id] = {
            attr: attrLabel,
            amount,
            before,
            after,
            effectiveAmount: after - before,
            name: target.sheet.name,
          };
        }
      }
      return { success: true, modifiers };
    }
    return { success: false };
  }

  cooldown() {
    // apply blessings poisons
    const mods = this.modifiers.filter(m => ['round'].indexOf(m.mode) > -1);
    for (const mod of mods) {
      const v = Math.floor(mod.f.evaluate(this.baseValues));
      this.modify(mod.attr, v);
    }
    // countdown turns
    for (const mod of this.modifiers.filter(m => m.turns > 0)) {
      mod.turns -= 1;
    }
    // cooldown actions
    for (const action in this.heat) {
      if (this.heat[action] > 0) {
        this.heat[action] -= 1;
      }
    }
    // remove expired modifiers
    this.modifiers = this.modifiers.filter(
      m => m.turns === undefined || m.turns === null || m.turns > 0,
    );
  }

  initiative() {
    return Math.floor(this.initiativeFormula.evaluate(
      this.getFormulaArgs(this.initiativeFormula)//, this.baseValues),
    ));
  }

  setInitiative(formula = null) {
    this.initiativeFormula = parser.parse((formula || this.sheet.initiative || '1').toLowerCase());
  }

  toObject() {
    // TODO: get name from character
    const retval = {name: this.sheet.name};
    for (const attr of this.sheet.attributes) {
      const label = this.getAttrLabel(attr.name)
      retval[label] = this.value(label);
    }
    // TODO: get inventory?
    return retval;
  }

  toString() {
    return '$internal';
  }
}
