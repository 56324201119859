import debug from 'debug';
import Campfire from "./campfire";
import copy from "./copy";
import { runFormula } from './tag_effects';
import { insertTags, shouldShowDirection, shuffle } from "./tags";

const log = debug('read');

export class Directive {
  constructor(reader, data) {
    this.reader = reader;
    this.data = copy(data);
  }

  movePlayersToGroup(players, split) {
    const groups = new Set();
    const tags = copy(this.reader.tags);
    players.forEach((player) => {
      tags.$player = player;
      let groupname = runFormula(this.reader.story, split, tags);
      if (groupname === undefined) {
        groupname = split;
      }
      const group = this.reader.players.getPartyIdByName(groupname);
      player.party = group;
      groups.add(group);
    });
    Array.from(groups).forEach((group, index) => {
      this.reader.players.getParty(group).savepoint = {
        microcosms: copy(this.reader.microcosms),
        choices: [],
        scene: this.reader.scene.id,
        idx: this.reader.directionIndex,
      };
      if (index === 0) {
        this.reader.activateParty(groups[0]);
      }
    });
    // this.reader.proceed();
    this.reader.notify('players', this.reader.players.players);

    return groups;
  }

  splitGroups() {
    const currentParty = this.reader.players.partyId;
    let players = shuffle(
      this.reader.players.players.filter(p => p.party === currentParty)
    );
    const switching = players.slice(0, this.data.playerCount || players.length);
    const groups = this.movePlayersToGroup(switching, this.data.split);

    // back to main party
    if (players.filter(p => p.party === currentParty).length > 0) {
      this.reader.activateParty(currentParty);
      this.reader.recordChoice(this.data);
      this.reader.proceed();
    }

    Array.from(groups).forEach((group, index) => {
      this.reader.activateParty(group);
      this.reader.recordChoice(this.data);
      if (this.data.scene) {
        this.reader.changeScene(this.data.scene);
        this.reader.players.getParty(group).savepoint = {
          choices: [],
          microcosms: copy(this.reader.microcosms),
          scene: this.reader.scene.id,
          idx: this.reader.directionIndex,
        };
      } else {
        this.reader.proceed();
      }
    });
  }

  fetchArray(name) {
    let tag = this.reader.story.tags.find((t) => t.id === name);
    if (/^\$players/.test(name)) {
      tag = { name: '$players', type: 'array', referencing: '$player'};
    }
    if (tag && tag.type === 'array') {
      return {
        tag,
        array: this.reader.tags[name] || [],
      };
    }
    return {
      tag,
      array: [],
    };
  }

  generateArrayText(array, text, each) {
    const texts = {}
    const tags = copy(this.reader.tags);
    for (const item of array) {
      tags.$item = item.data;
      const result = insertTags(text, this.reader.story, tags);
      if (each) {
        each(item, result);
      }
      texts[item.id] = result;
    }
    return texts;
  }

  adjustArrayEffects(prefix, array, effects, each) {
    array.forEach((item, index) => {
      const replacement = copy(effects);
      replacement.forEach((effect) => {
        let tag = `${prefix}.${item.id}`;
        if (effect.op === '<t>' || effect.op === '<f>') {
          tag = `${prefix}[${index}]`;
        }
        if (effect.tag) {
          effect.tag = effect.tag.replace(/\$item/gi, tag);
        }
        if (typeof (effect.value) === 'string') {
          effect.value = effect.value.replace(/\$item/gi, tag);
        }
        if (typeof (effect.other) === 'string') {
          effect.other = effect.other.replace(/\$item/gi, tag);
        }
      });
      each(item, replacement);
    });
  }

  /** read this directive and return true if it redirects to another scene */
  apply() {}
  startsBlock() { return false; }
  endsBlock() { return false; }
  allowStacking() { return false; }
  willRedirect() { return false; }
  shouldRecordEffects() { return true; }
  get stackingType() { return this.data.type; }
}

export class TextDirective extends Directive {
  apply() {
    this.reader.changeText(this.data);
  }

  startsBlock() { return !this.data.auto_advance; }
  // endsBlock() { return !this.data.auto_advance; }
  allowStacking() { return this.data.auto_advance; }
}

export class ImageDirective extends Directive {
  apply() {
    this.reader.setImage(this.data);
  }

  // startsBlock() { return true; }
  startsBlock() {
    return !this.data.auto_advance;
  }
  allowStacking() {
    return this.data.auto_advance;
  }
}

export class SceneChangingDirective extends Directive {
  applyMicrocosm() {
    const microcosm = this.data.microcosm;
    const scene = this.reader.story.scenes.filter(a => a).find(s => s.id === this.data.scene);
    if (microcosm || (scene && scene.microcosm)) {
      this.reader.microcosms.push({
        scene: this.reader.scene.id,
        idx: this.reader.directionIndex,
      });
    }
  }

  waitForAll() {
    if (this.data.waitFor) {
      // for (const partyName of this.data.waitFor) {
      //   const party = this.reader.players.getPartyIdByName(partyName);
      //   this.reader.players.waitFor(party);
      // }

      const firename = `join-${this.reader.scene.id}-${this.data.id}`;
      const partyIds = this.data.waitFor.map(
        a => this.reader.players.getPartyIdByName(a),
      ).filter(
        a => this.reader.players.players.filter(p => p.party === a).length > 0,
      );

      if (partyIds.length > 1) {
        const fire = Campfire.register(firename, partyIds);
        const retval = fire.arrival(this.reader.players.partyId);
        this.reader.notify('waiting', {
          scene: this.reader.scene.id,
          direction: this.data.id,
          needs: fire.needs,
          has: Array.from(fire.arrived),
        });
        if (retval) {
          const players = this.reader.players.players.filter(p => partyIds.indexOf(p.party) > -1);
          this.movePlayersToGroup(players, this.data.split);
          return false;
        }
      }
      return true;
    }
  }

  // willRedirect() { return true; }
  willRedirect() { return this.data.scene; }
}


export class ChoiceDirective extends SceneChangingDirective {
  apply() {
    return this.applyChoice();
  }

  applyChoice() {
    // this.applyMicrocosm();
    if (this.data.array) {
      const {tag, array} = this.fetchArray(this.data.array);
      if (array) {
        this.generateArrayText(array, this.data.label, (item, text) => {
          const choice = copy(this.data);
          choice.type = 'choice';
          choice.id = item.id;
          choice.label = text;
          this.reader.addChoice(choice);
        });
      } else {
        const choice = {
          id: -1,
          label: 'Sorry, the author had a vote setup here, but there are no options to choose'
        }
        console.log('adding broken choice', choice);
        this.reader.addChoice(choice);
      }
    } else {
      const choice = copy(this.data);
      choice.label = insertTags(this.data.label, this.reader.story, this.reader.tags);
      if (choice.thoughts) {
        for (let x = 0; x < choice.thoughts.length; x++) {
          choice.thoughts[x] = insertTags(
            choice.thoughts[x],
            this.reader.story,
            this.reader.tags
          );
        }
      }
      this.reader.addChoice(choice);
    }
  }

  endsBlock() { return true; }
  allowStacking() { return true; }
  shouldRecordEffects() { return false; }
}


export class RedirectDirective extends SceneChangingDirective {
  apply() {
    if (this.data.scene) {
      this.applyMicrocosm();
    }

    if (this.data.split) {
      this.splitGroups();
      return true;
    } else if (this.data.scene) {
      this.reader.recordChoice(this.data);
      this.reader.changeScene(this.data.scene);
      return true;
    }
  }
}


export class IsolateDirective extends SceneChangingDirective {
  apply() {
    this.waitForAll();
    this.applyMicrocosm();

    const choice = copy(this.data);
    // choice.scene = null;
    // choice.redirect = this.data.scene;
    choice.label = insertTags(this.data.label, this.reader.story, this.reader.tags);
    this.reader.addChoice(choice);
  }

  endsBlock() { return true; }
  allowStacking() { return true; }
}


export class InputDirective extends Directive {
  apply() {
    const data = copy(this.data);
    const {array} = this.fetchArray(data.content);
    if (data.attr && array) {
      data.array = [];
      this.generateArrayText(array, data.label, (item, text) => {
        data.array.push({
          id: item.id,
          start: item[data.attr] || 0,
          label: text,
        });
      });
      if (typeof data.points === 'string') {
        data.points = runFormula(this.reader.story, data.points, this.reader.tags);
      }
    } else if (data.label) {
      data.label = insertTags(data.label, this.reader.story, this.reader.tags);
    }
    this.reader.notify('input', data);
  }

  startsBlock() { return !!this.data.label; }
  endsBlock() { return true; }
}

export class PauseDirective extends Directive {
  apply() { this.reader.startPause(this.data.ms); }
  endsBlock() { return true; }
  get stackingType() { return 'choice'; }
}

export class TimerDirective extends Directive {
  apply() {
    this.reader.startTimer(this.data.seconds * 1000, this.data.scene);
  }

  endsBlock() { return true; }
  allowStacking() { return true; }
  get stackingType() { return 'choice'; }
}

export class SetDirective extends Directive {
  apply() {
    if (this.data.type === 'background') {
      this.reader.changeBackground(this.data);
    } else if (this.data.type === 'audio') {
      this.reader.changeClip(this.data);
    } else if (this.data.type === 'music') {
      this.reader.changeMusic(this.data);
    } else if (this.data.type === 'lighting') {
      this.reader.changeLighting(this.data.light);
    } else if (this.data.type === 'video') {
      this.reader.changeVideo(this.data);
    }
  }
}

export class EmptyDirective extends Directive {
  apply() {
    if (this.data.array) {
      const {tag, array} = this.fetchArray(this.data.array);
      this.adjustArrayEffects(this.data.array, array, this.data.effects, (item, effects) => {
        if (shouldShowDirection(this.reader.story, { effects }, this.reader.tags)) {
          this.reader.recordChoice({effects});
        }
      });
    }

    if (this.data.split) {
      this.splitGroups();
      return true;
    }
  }
}

export class ReturnDirective extends Directive {
  apply() {
    if (this.reader.microcosms.length > 0) {
      this.reader.finish(); // will pop the most recent return and continue
      return true;
    } else {
      const choices = this.reader.player.choices.slice(0);
      choices.reverse();
      for (const mod of choices) {
        if (mod.scene && mod.from && mod.type !== 'return') {
          this.reader.changeScene(mod.from.scene, mod.from.idx);
          return true;
        }
      }
    }
  }

  willRedirect() { return true; }
  // needs Break() { return true; }
}

export class ContestantDirective extends Directive {
  apply() {
    this.reader.notify('contestants', this.data);
  }
}

export class BattleDirective extends Directive {
  apply() {
    this.reader.notify('battle', this.data);
  }

  startsBlock() { return true; }
}

export class GroupDirective extends Directive {
  findDirection() {
    let options = [];
    let directionType = null;


    // let index = this.reader.directionIndex + 1;
    let index = this.reader.scene.directions.findIndex(d => d.id === this.data.id) + 1;
    for (; index < this.reader.scene.directions.length; index += 1) {
      const direction = this.reader.scene.directions[index];
      if (direction.type === 'group' || directionType != null && direction.type !== directionType) {
        break;
      }
      directionType = direction.type;
      if (shouldShowDirection(this.reader.story, direction, this.reader.tags)) {
        options.push(direction);
      }
    }

    // if tags matched remove non-tagged options
    if (options.filter(o => o.weight === -1).length !== options.length) {
      options = options.filter(o => o.weight !== -1);
    }

    // add probability weights
    const total = options.reduce((a, b) => a + (b.weight === undefined ? 1 : parseInt(b.weight, 10)), 0);
    const lots = new Array(total);
    let idx = 0;
    for (const option of options) {
      let num = parseInt(option.weight === undefined ? 1 : option.weight);
      if (num > 0) {
        // of 10 lots for possible fractions
        lots.fill(option.id, idx, idx + num);
        idx += num;
      }
    }

    // select next scene
    const lotIndex = Math.random() * lots.length;
    const lotId = lots[Math.floor(lotIndex)];
    const next = options.find(o => o.id === lotId);

    return { data: next, index: index - 1 };
  }

  get direction() {
    if (this._direction === undefined) {
      const {data, index} = this.findDirection();
      if (data) {
        this._direction = new (directives[data.type])(this.reader, data);
        this._index = index;
      }
    }
    return this._direction;
  }

  apply() {
    if (this.direction) {
      this.reader.directionIndex = this._index;
      return this.direction.apply();
    }
  }

  startsBlock() {
    if (this.direction) {
      return this.direction.startsBlock();
    }
    return false;
  }

  endsBlock() {
    if (this.direction) {
      return this.direction.endsBlock();
    }
    return false;
  }

  allowStacking() {
    if (this.direction) {
      return this.direction.allowStacking();
    }
    return false;
  }

  willRedirect() {
    if (this.direction) {
      return this.direction.willRedirect();
    }
    return false;
  }

  get stackingType() {
    if (this.direction) {
      return this.direction.stackingType;
    }
    return 'group';
  }
}

export class AchievementDirective extends Directive {
  apply() {
    // this.reader.changeText(this.data);
    this.reader.notify('achievement', this.data);
  }

  startsBlock() {
    return this.data.silent !== true;
  }
}

export class EventDirective extends Directive {
  apply() {
    // this.reader.changeText(this.data);
    this.reader.notify('event', this.data);
    // this.reader.notify(`event:${this.data.name}`, this.data.content);
    this.reader.lockUntilEvent(this.data.name);
  }

  startsBlock() {
    return this.data.silent !== true;
  }
}


export class OptionDirective extends Directive {
  apply() {
    const data = copy(this.data);
    data.label = insertTags(data.label, this.reader.story, this.reader.tags);
    data.content = insertTags(
      data.content,
      this.reader.story,
      this.reader.tags
    );
    this.reader.notify("option", data);
  }
  startsBlock() { return false; }
  endsBlock() { return true; }
  allowStacking() { return true; }
  shouldRecordEffects() { return false; }
}

export class VideoDirective extends SetDirective {
  startsBlock() { return true; }
  endsBlock() { return true; }
  allowStacking() { return false; }
  shouldRecordEffects() { return false; }
}

export class LayoutDirective extends Directive {
  apply() {
    this.reader.notify('settings', this.data);
  }
}

export class ClearDirective extends Directive {
  apply() {
    this.reader.notify('settings', { execute: 'clear' });
  }
}

export var directives = {
  'choice-timer': TimerDirective,
  achievement: AchievementDirective,
  audio: SetDirective,
  background: SetDirective,
  battle: BattleDirective,
  clear: ClearDirective,
  choice: ChoiceDirective,
  contestant: ContestantDirective,
  empty: EmptyDirective,
  event: EventDirective,
  group: GroupDirective,
  image: ImageDirective,
  input: InputDirective,
  lighting: SetDirective,
  music: SetDirective,
  notes: EmptyDirective,
  option: OptionDirective,
  pause: PauseDirective,
  redirect: RedirectDirective,
  return: ReturnDirective,
  text: TextDirective,
  video: VideoDirective,
  layout: LayoutDirective,
};
