import { all } from "@vee-validate/rules";
import { defineRule } from "vee-validate";
import VvBasicUpload from "./vvfields/VvBasicUpload.vue";
import VvCheckbox from "./vvfields/VvCheckbox.vue";
import VvInputNumber from "./vvfields/VvInputNumber.vue";
import VvInputText from "./vvfields/VvInputText.vue";
import VvRange from "./vvfields/VvRange.vue";
import VvSelect from "./vvfields/VvSelect.vue";

Object.entries(all).forEach(([name, rule]) => {
  defineRule(name, rule);
});

export interface VvField {
  name: string;
  type: string;
  label?: string;
  help?: string;
  validation?: string;
  attrs?: Record<string, any>;
  default?: any;
}

export const registry: Record<string, { comp: any; attrs?: {} }> = {};

export function registerVvType(type: string, component: any, attrs?: Record<string, any>) {
  registry[type] = {
    comp: component,
    attrs: attrs ? attrs : {},
  };
}

registerVvType("text", VvInputText);
registerVvType("number", VvInputNumber);
registerVvType("file", VvBasicUpload);
registerVvType("range", VvRange);
registerVvType("checkbox", VvCheckbox);
registerVvType("select", VvSelect);
