import { useToasterStore } from "@morphosis/base/stores/toaster";
import { Parser, type Expression } from "expr-eval";
import copy from "./copy";

export function extendParser(parser: Parser): Parser {
  parser.functions.array = (len) => new Array(len).fill(0);
  parser.functions.rand = (x) => Math.floor(Math.random() * parseInt(x));
  parser.functions.slice = (x, a, b) => x.slice(parseInt(a), parseInt(b));
  parser.functions.subset = (array, key, value) => (array[key] = value);
  return parser;
}

export function getUnknownVariables(text: string, expression: Expression): string[] {
  const retval = expression.variables({ withMembers: false });
  return retval.filter((v) => {
    return (
      !new RegExp(`^${v} *=`, "m").test(text) &&
      !new RegExp(`^${v}\(.*?\) *=`, "m").test(text) &&
      !new RegExp(`${v}[,)].*?=`, "mi").test(text)
    );
  });
}

export function runFormula(story, formulaText, tags) {
  try {
    const parser = new Parser({
      operators: {
        assignment: true,
      },
    });
    extendParser(parser);
    // console.log('formula text:\n', formulaText);
    let formula: Expression = parser.parse(formulaText);
    const variables = formula.variables();
    const unknownVariables = getUnknownVariables(formulaText, formula);

    // find variables
    const args = {};
    for (const v of variables) {
      // random
      if (/^\$RAND(\d+)/.test(v)) {
        const match = /^\$RAND(\d+)/.exec(v);
        args[v] = Math.floor(Math.random() * (parseInt(match[1]) + 1));

        // variable insertion
      } else if (!/^\$/.test(v)) {
        const variable = story.tags.find((t) => t.name == v);
        if (variable) {
          if (variable.type === "ref") {
            let result = tags[variable.id];
            if (typeof result === "string") {
              result = StoryTag.traverse(tags, result.split("."));
            }
            args[v] = result;
          } else if (variable.type === "text") {
            args[v] = tags[variable.id] || "";
          } else {
            args[v] = tags[variable.id] || 0;
          }
        } else if (unknownVariables.includes(v)) {
          // useToasterStore().add({
          //   title: "Formula Error",
          //   message: `Using unknown variable "${v}"`,
          //   level: "warning",
          //   suppressor: "unknown variable",
          // });
          args[v] = 0;
        }
      } else {
        args[v] = tags[v];
      }
    }

    // console.log("formula", formulaText, args);
    const resultValue = formula.evaluate(args);
    // console.log("formula result", resultValue);

    // if action results are specified
    // reduce formula result to one of the given results
    // if (action.results && action.results.length > 0) {
    //   let newValue = null;
    //   for (const result of action.results) {
    //     if (result.target <= resultValue) {
    //       newValue = result.label;
    //       break;
    //     }
    //   }
    //   resultValue = newValue;
    // }
    return resultValue;
  } catch (ex) {
    console.error("formula error", ex);

    const toaster = useToasterStore();
    toaster.add({
      title: "Formula Error",
      message: `Error: ${ex}`,
      level: "warning",
    });
    // throw ex;
    return null;
  }
}

export class StoryTag {
  constructor(story, tags, basis, tag, trait) {
    (this.story = story), (this.tags = tags);
    this.basis = basis;
    this._tag = tag;
    this.trait = trait;
    this.setup();
  }

  setup() {}
  get tag() {
    return this._tag;
  }
  get isTag() {
    return !this.tag.type || this.tag.type === "tag";
  }
  get isText() {
    return this.isTag && this.tag.format === "text";
  }
  get isNumber() {
    return this.tag.type === "number" || (this.isTag && this.tag.format !== "text");
  }
  get display() {
    return `${this.name}.${this.trait}`;
  }
  get value() {
    let val = this.basis[this.trait];
    if (this._tag && this._tag.type === "tag" && this._tag.format !== "text") {
      return val || 0;
    }
    return val || "";
  }
  set value(v) {
    this.basis[this.trait] = v;
  }

  erase() {
    delete this.basis[this.trait];
  }

  static traverse(tags, bits) {
    let object = tags;
    for (const bit of bits) {
      if (object instanceof Array) {
        let nextb = null;
        if (typeof bit === "number") {
          nextb = object[bit];
        } else {
          nextb = object.find((element) => element.id === bit);
        }
        if (!nextb) {
          console.log(
            "key not found",
            bit,
            object.map((e) => e.id),
          );
          throw Error("key not found");
        }
        object = nextb;
      } else {
        object = object[bit];
      }
    }
    return object;
  }

  static generate(story, tags, reference) {
    const bits = reference.split(".");
    bits.forEach((bit, i) => {
      const groups = /^(.+)\[(\d+)\]$/.exec(bit);
      if (groups) {
        bits[i] = groups[1];
        bits.splice(i + 1, 0, parseInt(groups[2]));
      }
    });
    const basis = this.traverse(tags, bits.slice(0, bits.length - 1));
    const name = bits[bits.length - 1];
    const trait = /\./.test(reference) ? name : null;
    let tag = (story.allTags || []).find((t) => t.id === bits[0]);
    let klass = StoryTag;
    if (!tag) {
      console.warn(
        "missing tag",
        bits[0],
        (story.allTags || []).map((t) => t.id),
      );
      tag = {
        name: "unknown-type",
        type: typeof basis[trait] === "string" ? "string" : "number",
      };
    } else if (tag.type === "action") {
      klass = StoryActionTag;
    } else if (["ref", "array"].includes(tag.type) && bits.length > 1) {
      const { meta, attr } = StoryTag.dereference(story, {
        typeId: tag.referencing,
        trait,
      });
      tag = {
        id: tag.id,
        element: bits[1],
        type: attr ? attr.type : tag.type,
        attr: attr,
        meta: meta,
      };
    }
    return new klass(story, tags, basis, tag, name);
  }

  static dereference(story, { typeId, blueprintId, trait = null }) {
    let bp = null;
    let id = typeId;

    if (blueprintId) {
      bp = story.blueprints.find((b) => b.id === blueprintId);
      id = bp.meta;
    }

    const meta = (story.allTypes || []).find((type) => type.id === id);
    // const meta = story.types.find((type) => type.id === id);
    if (!meta) {
      console.warn("failed to find type", typeId, blueprintId, trait);
    }
    return {
      meta,
      blueprint: bp,
      attr: trait ? meta && meta.attrs.find((attr) => attr.name === trait) : null,
    };
  }

  static formula(story, tags, text) {
    return runFormula(story, text, tags);
  }
}

class StoryReferenceTag extends StoryTag {
  setup() {
    super.setup();

    // TODO: check if this is a trait or direct reference
    let value = this.basis;
    const valueType = typeof value;
    this._tag = copy(this._tag);
    this.direct = false;
    if (valueType !== "string") {
      value = StoryTag.traverse(this.basis, [this.trait]);
      this.direct = true;
    } else {
      value += "." + this.trait;
      if (this.tag.referencing) {
        const { meta, attr } = StoryTag.dereference(this.story, {
          typeId: this.tag.referencing,
          trait: this.trait,
        });
        if (attr.type) {
          this.tag.type = attr.type;
        }
      }
    }

    // if (value) {
    //   this.other = StoryTag.generate(this.story, this.tags, value);
    // }
    this._value = value;
  }

  get value() {
    // if (this.other) {
    //   return this.other.value;
    // }
    return this.value;
  }

  set value(v) {
    if (this.direct) {
      super.value = v;
    }
    // else if (this.other) {
    //   this.other.value = v;
    // }
  }
}

class StoryActionTag extends StoryTag {
  get value() {
    return runFormula(this.story, this.tag.formula, this.tags);
  }
  set value(v) {
    console.error("Cannot set the value of an action");
  }
}

export function defaultOne(value, precision = 0) {
  if (value === null || value === undefined) {
    return 1;
  }

  const places = 10 ** precision;
  const val = parseInt(value * places) / places;
  if (val === 0) {
    return 0;
  }
  return val || 1;
}

class TagEffect {
  eval(story, tags, effect, funcName, ...args) {
    this.story = story;
    this.tags = tags;
    this.tag = this.find(effect.tag);
    this.tag.value = this[funcName](effect, ...args);
  }

  test(story, tags, effect, funcName, ...args) {
    this.story = story;
    this.tags = tags;
    this.tag = this.find(effect.tag);
    return this[funcName](effect, ...args);
  }

  find(name) {
    return StoryTag.generate(this.story, this.tags, name);
  }

  default(v) {
    if (this.tag.isNumber) {
      return defaultOne(v, this.tag.tag.precision || 0);
    }
    return v;
  }

  // text functions
  concatList(a, b) {
    return [a, b].filter((a) => a).join(", ");
  }
  concat(effect, ref = false) {
    const value = ref ? this.find(effect.value).value : this.default(effect.value);
    return this.tag.value + value;
  }
  spliceList(a, b) {
    const temp = a
      .split(",")
      .map((v) => v.trim())
      .filter((v) => v);
    if (b) {
      const idx = temp.findIndex((t) => t.toLowerCase() === b.toLowerCase());
      if (idx > -1) {
        temp.splice(idx, 1);
      }
    }
    return temp.join(", ");
  }

  set(effect, ref = false) {
    return ref ? this.find(effect.value).value : this.default(effect.value);
  }

  // math
  add(effect, ref = false, sub = false) {
    const value = ref ? this.find(effect.value).value : this.default(effect.value);
    if (this.tag.isText) {
      if (sub) {
        return this.spliceList(this.tag.value, value);
      }
      return this.concatList(this.tag.value, value);
    }
    return (this.tag.value || 0) + (sub ? -1 : 1) * this.default(value);
  }

  formula(effect) {
    return runFormula(this.story, effect.value, this.tags);
  }

  array_shift(effect) {
    const other = this.find(effect.other);
    other.value = this.tag.value.shift();
    return this.tag.value;
  }

  array_push(effect) {
    const other = this.find(effect.other);
    this.tag.value.push(other.value);
    other.value = null;
    return this.tag.value;
  }

  array_transfer(effect) {
    const other = this.find(effect.other);
    other.value.forEach((e) => {
      this.tag.value.push(e);
    });
    other.value.length = 0;
    return this.tag.value;
  }

  erase(effect) {
    this.tag.erase();
  }

  // effects
  "+"(s, ts, e) {
    return this.eval(s, ts, e, "add");
  }
  "-"(s, ts, e) {
    return this.eval(s, ts, e, "add", false, true);
  }
  ":"(s, ts, e) {
    return this.eval(s, ts, e, "set");
  }
  "."(s, ts, e) {
    return this.eval(s, ts, e, "concat");
  }
  x(s, ts, e) {
    return this.eval(s, ts, e, "erase");
  }
  "<+>"(s, ts, e) {
    return this.eval(s, ts, e, "add", true);
  }
  "<->"(s, ts, e) {
    return this.eval(s, ts, e, "add", true, true);
  }
  "<:>"(s, ts, e) {
    return this.eval(s, ts, e, "set", true);
  }
  "<.>"(s, ts, e) {
    return this.eval(s, ts, e, "concat", true);
  }
  "<f>"(s, ts, e) {
    return this.eval(s, ts, e, "formula");
  }
  "#>"(s, ts, e) {
    return this.eval(s, ts, e, "array_shift");
  }
  "#<"(s, ts, e) {
    return this.eval(s, ts, e, "array_push");
  }
  "#:"(s, ts, e) {
    return this.eval(s, ts, e, "array_transfer");
  }

  get list() {
    return ["x", ":", "+", "-", ".", "<:>", "<+>", "<->", "<.>", "<f>", "#>", "#<", "#:", "<c>"];
  }
}

export var effects = new TagEffect();

export function findTag(story, reference, tags = null) {
  let trait = null;

  if (/\./.test(reference)) {
    [reference, trait] = reference.split(".");
  }

  let tag = (story.tags || []).find((t) => t.id === reference);
  if (/^\$players/.test(reference)) {
    tag = { type: "array", referencing: "$player" };
  }

  if (tag) {
    if (tag.type === "player" || tag.type === "character") {
      const char = (story.characters || []).find((char) => char.id == tag.character);
      if (char && trait) {
        const attr = char.attributes.find((a) => a.abbrev === trait || a.name === trait);
        tag = {
          type: "character",
          icon: "user",
          name: tag.name,
          display: `${tag.name}.${attr.name}`,
          character: char.id,
          attr,
          get value() {
            return tags[reference][trait];
          },
          set value(v) {
            tags[reference][trait] = v;
          },
        };
      } else {
        tag = {
          type: "character",
          icon: "user",
          name: tag.name,
          format: "text",
          character: char ? char.id : null,
          get value() {
            return tags[reference];
          },
          set value(v) {
            tags[reference][trait] = v;
          },
        };
      }
    } else if (tag.type === "ref") {
      tag = {
        id: tag.id,
        name: tag.name,
        format: tag.format,
        trait: trait,
        type: tag.type,
        formula: tag.formula,
        precision: tag.precision,
        get value() {
          return tags[tag.id][trait];
        },
        set value(v) {
          if (trait) {
            tags[tag.id].data[trait] = v;
          } else {
            tags[tag.id] = v;
          }
        },
        get reference() {
          return tags[tag.id];
        },
        set reference(v) {
          tags[tag.id] = tags[v];
        },
      };
    } else {
      tag = {
        id: tag.id,
        name: tag.name,
        format: tag.format,
        trait: trait,
        type: tag.type,
        formula: tag.formula,
        precision: tag.precision,
        get value() {
          return tags ? tags[tag.id] : undefined;
        },
        set value(v) {
          tags[tag.id] = v;
        },
      };
    }
  }
  return tag;
}

export function generateEffectsWithSubstitutions(effects, player = null, array = null) {
  effects.forEach((effect) => {
    if (player) {
      const replacement = "$players." + player;
      if (effect.tag) {
        effect.tag = effect.tag.replace(/\$player\b/, replacement);
      }
      if (typeof effect.value === "string") {
        effect.value = effect.value.replace(/\$player\b/, replacement);
      }
      if (typeof effect.other === "string") {
        effect.other = effect.other.replace(/\$player\b/, replacement);
      }
    }
    if (array) {
      if (effect.tag) {
        effect.tag = effect.tag.replace("$item", array);
      }
      if (typeof effect.value === "string") {
        effect.value = effect.value.replace("$item", array);
      }
      if (typeof effect.other === "string") {
        effect.other = effect.other.replace("$item", array);
      }
    }
  });
  return effects;
}
