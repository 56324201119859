<template>
  <!-- color change -->
  <!-- @update:value="$emit('update:value', $event)" -->
  <popover
    :value="value"
    :target="target"
    color-theme="spark"
  >
    <div class="scroll left responsive-max">
      <b>{{ $t('scene_color_popup.scene-color') }}</b>
      <div class="grid">
        <div
          v-for="(value, color) in story.settings.colorLabels" :key="color"
          class="flex-row"
        >
          <div
            class="dot" :class="color"
            @click="$emit('color', color)"
          />
          <input
            v-model="story.settings.colorLabels[color]"
            @update:value="story.saveAndPush()"
            :placeholder="color"
          />
        </div>
      </div>
      <div v-if="value" class="remove center" @click="$emit('color', null)">
        &times; {{ $t('scene_color_popup.no-color') }}
      </div>
    </div>
  </popover>
</template>

<script>
export default {
  emits: ['color'],
  props: {
    value: Boolean,
    target: String,
    story: Object,
  },
  async mounted() {

    if (!this.story.settings) {
      this.story.settings = {};
    }

    // missing color labels
    const colorLabels = [
      'white', 'black',
      'red', 'orange', 'yellow',
      'green', 'blue', 'violet'
    ];
    if (!this.story.settings.colorLabels) {
      this.story.settings.colorLabels = {};
    }
    let changed = false;
    for (const color of colorLabels) {
      if (this.story.settings.colorLabels[color] === undefined) {
        this.story.settings.colorLabels[color] = '';
        changed = true;
      }
    }
    for (const label in this.story.settings.colorLabels) {
      if (!colorLabels.includes(label)) {
        delete this.story.settings.colorLabels[label];
        changed = true;
      }
    }
    if (changed) {
      this.story.saveAndPush();
    }
  }
}
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
}
input {
  width: 120px;
}
.remove {
  padding: 15px;
  padding-bottom: 5px;
}
</style>
