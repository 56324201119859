import Media from "@/models/media";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useImageStore = defineStore("images", () => {
  const thumbnails = ref<{ [key: string]: string }>({});
  const images = ref<{ [key: string]: string }>({});
  const queue = ref<{ [key: string]: Promise<string> }>({});

  function loadImage(
    blobId: string,
    thumbnail: boolean = true
  ): Promise<string> {
    const key = `${blobId}-${thumbnail}`;
    if (!queue.value[key]) {
      queue.value[key] = processQueue(blobId, thumbnail);
    }
    return queue.value[key];
  }

  async function processQueue(
    blobId: string,
    thumbnail: boolean = true,
  ): Promise<string> {
    let image: string | null = null;
    if (thumbnail) {
      image = thumbnails.value[blobId];
    } else {
      image = images.value[blobId];
    }

    if (!image) {
      const key = `getting image: ${blobId}`;

      // console.time(key);
      if (blobId) {
        const instance = await Media.load(blobId);
        // console.timeLog(key, "media loaded");
        if (instance) {
          if (thumbnail) {
            image = await instance.getThumbnail();
            thumbnails.value[blobId] = image;
          } else {
            image = await instance.getUrl();
            images.value[blobId] = image;
          }
        }
      } else {
        image = null;
      }
      if (image) {
        // console.timeLog(key, "url loaded");
        const img = new Image();
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
          img.src = image;
        });
      }
      // console.log("loaded image", blobId, image);
      // console.timeEnd(key);
    }
    queue.value[`${blobId}-${thumbnail}`] = null;
    return image;
  }

  function clearOne(blobId: string, thumbnail: boolean = true) {
    queue.value[`${blobId}-${thumbnail}`] = undefined;
    images.value[blobId] = undefined;
    thumbnails.value[blobId] = undefined;
  }

  function clear() {
    thumbnails.value = {};
    images.value = {};
  }

  return { thumbnails, images, loadImage, clear, clearOne };
});
