<template>
  <div
    :id="`direction-${value.id}`"
    :data-id="value.id"
    :class="{
      [`direction-${value.type}`]: true,
      tabActive: !!tab,
      deleted: deleted,
      grouped: isGrouped,
    }"
    class="direction item"
    @transitionend="transitionHasEnded"
  >
    <div class="header row">
      <slot></slot>
      <span class="handle">
        <i class="fa fa-arrows-v" />
        {{ translatedType }}
        <span class="small">| {{ index + 1 }}</span>
      </span>
      <!-- <span class="handle" v-else>
        <a href="#" v-if="index > 0" @click.prevent="$emit('move', -1)">
          <i class="fa fa-arrow-up" />
        </a>
        <i v-else class="fa fa-arrow-up fa-disabled" />
        {{ value.type }}
        <a href="#" v-if="index < count - 1" @click.prevent="$emit('move', +1)">
          <i class="fa fa-arrow-down" />
        </a>
        <i v-else class="fa fa-arrow-down fa-disabled" />
      </span> -->

      <span class="spacer"></span>

      <button
        tabindex="-1"
        class="add-above icon"
        :class="{ active: editing && tab == 'add' }"
        @click.prevent="handleChangeEditTab('add', $event)"
      >
        <i class="fa fa-plus" :class="{ 'fa-rotate-90': editing && tab == 'add' }" />
      </button>

      <confirm-button tabindex="-1" @confirm="handleDelete(value)">
        <template v-slot:confirm>Delete?</template>
      </confirm-button>
    </div>

    <div ref="content" start-from-initial>
      <transition-group name="circle" mode="out-in" class="direction-content" tag="div">
        <component
          key="content"
          :is="getContentClass()"
          :story="story"
          :scene="scene"
          :value="value"
          :editing="editing"
          @update="update($event)"
          class="main-content"
        />

        <component
          v-if="editing && tabs[tab]"
          :key="tab"
          :is="tabs[tab]"
          :story="story"
          :scene="scene"
          :value="value"
          :index="index"
          @create="
            $emit('createAbove', $event);
            $emit('editing', null);
          "
          @update="update($event)"
        />
      </transition-group>
    </div>
    <!-- </height-transition> -->

    <div v-if="value.type !== 'notes'" :class="{ open: editing && tab }" class="editing-options">
      <div class="lower-buttons buttons">
        <span v-if="isGrouped" @click="$refs[`weight:${value.id}`].show()" class="attr">
          <i class="fa fa-cube" />
          <span>{{ weight }}</span>
          <popover :ref="`weight:${value.id}`">
            <div class="flex-row flex-align-center">
              <i class="fa fa-step-forward padded" @click="update({ weight: (weight + 1) % 4 })" />
              <label for="weight">Weight:</label>
              <input
                name="weight"
                type="number"
                size="3"
                min="0"
                max="100"
                :value="value.weight"
                @change="update({ weight: $event.target.value })"
              />
            </div>
          </popover>
        </span>

        <span
          @click="handleChangeEditTab('requirements', $event)"
          :class="{ active: editing && tab == 'requirements' }"
          class="attr"
        >
          <i class="fa fa-filter" />
          <span v-if="requirements.length">
            {{ requirements.length }}
          </span>
        </span>

        <span
          @click="handleChangeEditTab('effects', $event)"
          :class="{ active: editing && tab == 'effects' }"
          class="attr"
        >
          <i class="fa fa-tags" />
          <span v-if="effects.length">
            {{ effects.length }}
          </span>
        </span>

        <span
          v-if="showTabs.thoughts"
          @click="handleChangeEditTab('thoughts', $event)"
          :class="{ active: editing && tab === 'thoughts' }"
          class="attr"
        >
          <i class="fa fa-comment-o" />
          <span v-if="value.thoughts && value.thoughts.length > 0">
            {{ value.thoughts.length }}
          </span>
        </span>

        <span
          v-if="showTabs.suggestions"
          @click="handleChangeEditTab('suggestions', $event)"
          :class="{ active: editing && tab === 'suggestions' }"
          class="attr"
        >
          <i class="fa fa-commenting-o" />
          <span v-if="value.suggestions && value.suggestions.length > 0">
            {{ value.suggestions.length }}
          </span>
        </span>

        <span
          v-if="showTabs.multiplayer"
          @click="handleChangeEditTab('multiplayer', $event)"
          :class="{ active: tab === 'multiplayer' }"
          class="attr multiplayer-tab"
        >
          <i class="fa fa-group" />
          <span class="tag" v-if="value.split && value.join"> * </span>
        </span>

        <span
          v-if="showTabs.audioRecording"
          @click="handleChangeEditTab('audioRecording', $event)"
          :class="{ active: tab === 'audioRecording' }"
          class="attr audioRecording-tab"
        >
          <i class="fa fa-microphone" />
          <span class="tag" v-if="value.audio"> * </span>
        </span>

        <span
          v-if="value.layout && value.layout != 'bottom'"
          @click="handleChangeEditTab('advanced', $event)"
          :class="{ active: editing && tab == 'advanced' }"
          class="attr"
        >
          <vi :name="layoutDirection" />
        </span>
        <span
          v-if="showTabs.advanced"
          @click="handleChangeEditTab('advanced', $event)"
          :class="{ active: editing && tab == 'advanced' }"
          class="attr"
        >
          <i class="fa fa-cog" />
        </span>
      </div>
      <div class="spacer" />
      <div class="scroll flex-row flex-static">
        <div
          v-for="group of value.waitFor"
          :key="group"
          class="tag flex-row flex-align-center flex-space-between"
        >
          <vi name="merge" />
          <span class="margin-left">{{ group }}</span>
        </div>
        <div v-if="value.split" class="tag flex-row flex-align-center flex-space-between">
          <vi name="split" class="flex-static" /><span class="margin-left">{{ value.split }}</span>
        </div>
        <span v-if="arrayTag" class="tag flex-row flex-align-center">
          <vi name="array" class="flex-static" /><code>{{ arrayTag.name }}</code> &mdash;&gt;
          <code><em>$item</em></code>
        </span>
        <div v-if="value.microcosm" class="tag flex-row flex-align-center flex-space-between">
          <i class="fa fa-undo" /><span class="margin-left">{{ $t("direction.return") }}</span>
        </div>
        <tag-effect
          v-for="(row, idx) of (value.effects || []).filter((a) => a)"
          :key="`${idx}-${row.op}-${row.tag}-${row.value}`"
          :story="story"
          :value="row"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TagEffect from "./editor/tag_effect.vue";
import InsertDirection from "./insert_direction.vue";

import AchievementContent from "./directions/achievement_content.vue";
import ActionContent from "./directions/action_content.vue";
import AudioContent from "./directions/audio_content.vue";
import BackgroundContent from "./directions/background_content.vue";
import BattleContent from "./directions/battle_content.vue";
import ChoiceContent from "./directions/choice_content.vue";
import ChoiceTimerContent from "./directions/choice_timer_content.vue";
import ClearContent from "./directions/clear_content.vue";
import ContestantContent from "./directions/contestant_content.vue";
import EmptyContent from "./directions/empty_content.vue";
import EventContent from "./directions/event_content.vue";
import GroupContent from "./directions/group_content.vue";
import ImageContent from "./directions/image_content.vue";
import InputContent from "./directions/input_content.vue";
import InvalidContent from "./directions/invalid_content.vue";
import LayoutContent from "./directions/layout_content.vue";
import LightingContent from "./directions/lighting_content.vue";
import MusicContent from "./directions/music_content.vue";
import NotesContent from "./directions/notes_content.vue";
import OptionContent from "./directions/option_content.vue";
import PauseContent from "./directions/pause_content.vue";
import RedirectContent from "./directions/redirect_content.vue";
import ReturnContent from "./directions/return_directive.vue";
import TextContent from "./directions/text_content.vue";
import VideoContent from "./directions/video_content.vue";

import { markRaw } from "vue";
import AdvancedTab from "./direction_tabs/advanced.vue";
import AudioRecordingTab from "./direction_tabs/audioRecording.vue";
import EffectsTab from "./direction_tabs/effects.vue";
import InsertTab from "./direction_tabs/insert.vue";
import MultiplayerTab from "./direction_tabs/multiplayer.vue";
import RequirementsTab from "./direction_tabs/requirements.vue";
import SuggestionsTab from "./direction_tabs/suggestions.vue";
import ThoughtsTab from "./direction_tabs/thoughts.vue";

const CONTENT_CLASSES = {
  "choice-timer": ChoiceTimerContent,
  achievement: AchievementContent,
  action: ActionContent,
  audio: AudioContent,
  background: BackgroundContent,
  battle: BattleContent,
  choice: ChoiceContent,
  clear: ClearContent,
  contestant: ContestantContent,
  empty: EmptyContent,
  event: EventContent,
  group: GroupContent,
  image: ImageContent,
  input: InputContent,
  lighting: LightingContent,
  layout: LayoutContent,
  music: MusicContent,
  notes: NotesContent,
  option: OptionContent,
  pause: PauseContent,
  redirect: RedirectContent,
  return: ReturnContent,
  text: TextContent,
  video: VideoContent,
};

export default {
  name: "direction",
  components: {
    TagEffect,
    InsertDirection,
  },
  props: {
    value: Object,
    index: Number,
    count: Number,
    story: Object,
    scene: Object,
    editing: { default: false },
    isGrouped: { default: false },
    scroll: Number,
  },
  data() {
    return {
      expandOptions: false,
      status: null,
      tab: null,
      focused: false,
      deleted: false,
      tabs: markRaw({
        requirements: RequirementsTab,
        effects: EffectsTab,
        thoughts: ThoughtsTab,
        suggestions: SuggestionsTab,
        multiplayer: MultiplayerTab,
        advanced: AdvancedTab,
        add: InsertTab,
        audioRecording: AudioRecordingTab,
      }),
      translatedType: {
        "choice-timer": this.$t("direction.choice-timer"),
        achievement: this.$t("direction.achievement"),
        action: this.$t("directionType.action"),
        audio: this.$t("directionType.audio"),
        background: this.$t("style.form-field-background"),
        battle: this.$t("directionType.battle"),
        clear: this.$t("directionType.clear"),
        choice: this.$t("directionType.choice"),
        contestant: this.$t("directionType.contestant"),
        empty: this.$t("directionType.empty"),
        event: this.$t("directionType.event"),
        group: this.$t("directionType.group"),
        image: this.$t("directionType.image"),
        input: this.$t("directionType.input"),
        lighting: this.$t("directionType.lighting"),
        layout: this.$t("directionType.layout"),
        music: this.$t("directionType.music"),
        notes: this.$t("directionType.notes"),
        option: this.$t("directionType.option"),
        pause: this.$t("directionType.pause"),
        redirect: this.$t("directionType.redirect"),
        return: this.$t("directionType.return"),
        text: this.$t("directionType.text"),
        timer: this.$t("directionType.timer"),
        video: this.$t("directionType.video"),
      }[this.value.type],
      editLevel: null,
    };
  },
  computed: {
    useOverlayout() {
      return this.settings.overlayout || this.$root.overlayout;
    },
    isMultiplayer() {
      return this.story.attrs.multiplayer;
    },
    showTabs() {
      const advanced = [
        "choice",
        "empty",
        "input",
        "text",
        "option",
        "image",
        "background",
        "achievement",
        "redirect",
      ].includes(this.value.type);
      return {
        requirements: true,
        effects: true,
        thoughts: this.value.type === "choice",
        suggestions: this.value.type === "input",
        multiplayer:
          ["empty", "choice", "redirect"].indexOf(this.value.type) > -1 && this.isMultiplayer,
        advanced,
        audioRecording:
          app.platform === "web" && this.useOverlayout && ["text"].indexOf(this.value.type) > -1,
      };
    },
    layoutDirection() {
      return {
        bottom: "gamedown",
        left: "gameleft",
        top: "gameup",
        right: "gameright",
        over: "gameall",
        under: "gamenone",
      }[this.value.layout || "bottom"];
    },
    arrayTag() {
      if (this.value.array) {
        const tag = this.story.tags.find((t) => t.id === this.value.array);
        return tag || { name: this.value.array };
      }
      return null;
    },
    weight() {
      return this.value.weight === 0 ? 0 : parseInt(this.value.weight, 10) || 1;
    },
    settings() {
      return this.story.settings || {};
    },
    style() {
      let retval = this.story.styles.find((s) => s.id === this.value.style);
      if (!retval && this.value.type === "choice") {
        retval = this.story.styles.find((s) => s.default_choice);
      }
      if (!retval) {
        retval = this.story.styles.find((s) => s.default);
      }
      return retval;
    },
    effectOps() {
      return [":", "+", "-", ".", "<.>", "<:>", "<+>", "<->", "<f>", "x", "#<", "#>"];
    },
    effects: {
      get() {
        return (this.value.effects || []).filter((f) => f && this.effectOps.indexOf(f.op) > -1);
      },
      set(v) {
        const effects = this.requirements.slice(0).concat(v);
        this.update({ effects });
      },
    },
    requirements: {
      get() {
        return (this.value.effects || []).filter((f) => f && this.effectOps.indexOf(f.op) === -1);
      },
      set(v) {
        const effects = v.slice(0).concat(this.effects);
        this.update({ effects });
      },
    },
  },
  watch: {
    editing() {
      this.tab = null;
    },
  },
  methods: {
    getContentClass() {
      return CONTENT_CLASSES[this.value.type] || InvalidContent;
    },
    async handleChangeEditTab(tab, e) {
      let cstart = `circle(0 at center)`;
      if (e) {
        const box = this.$refs.content.getBoundingClientRect();
        const x = e.x - box.x;
        const y = e.y - box.y;
        // const w = box.width;
        // const h = box.height;
        cstart = `circle(0 at ${x}px ${y}px)`;
      }
      this.$refs.content.style.setProperty("--cstart", cstart);

      if (this.tab != tab) {
        if (this.tab) {
          this.tab = null;
          await new Promise((r) => setTimeout(r, 250));
        }

        this.$emit("editing", this.value.id);
        await this.$nextTick();
        this.tab = tab;
      } else {
        this.$emit("editing", !this.editing ? this.value.id : null);
        await this.$nextTick();
        this.tab = null;
      }
      // if (this.tab) {
      //   await this.$nextTick();
      //   const el = this.$el.editing.querySelector('select,input,[tabindex="0"],button');
      //   if (el) {
      //     el.focus();
      //   }
      // }
    },
    update(props) {
      const object = this.copy(this.value);
      for (const prop in props) {
        object[prop] = props[prop];
      }
      this.$emit("update:value", object);
    },
    async handleDelete(value) {
      this.deleted = true;
      // setTimeout((r) => (this.deleted = false), 1000);
    },
    transitionHasEnded() {
      if (this.deleted) {
        this.$emit("remove", this.value.id);
        this.$root.emitInternal("story:update", {
          action: "direction-delete",
          story: this.story.id,
          scene: this.scene.id,
          direction: this.value,
          index: this.index,
        });
      }
    },
    cycleColumn() {
      const choices = ["left", "right", "center"];
      const index = (choices.indexOf(this.value.col) + 1) % choices.length;
      this.update({ col: choices[index] });
    },
    cycleRow() {
      const choices = ["top", "middle", "bottom"];
      const index = (choices.indexOf(this.value.row) + 1) % choices.length;
      this.update({ row: choices[index] });
    },
  },
};
</script>

<style scoped>
.direction {
  position: relative;
  background: var(--color1);
  background: radial-gradient(200% 100% at 50% 0%, var(--color1), var(--color2));
  color: var(--color1_text);
  margin: 5px;
  border: 3px solid var(--color2);
  border-radius: var(--softness);
  flex: 0 0 auto;
  text-align: left;
  font-size: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: margin-left 200ms, transform 120ms, opacity 120ms, max-height 150ms;
}
.direction.deleted {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  border: 0;
  padding: 0;
  margin: 0;
  transform: scaley(0);
}

.direction-option {
  background: var(--color3);
  background: radial-gradient(200% 100% at 50% 0%, var(--color3_60), var(--color3_10));
  color: var(--color3_text);
}

.direction-action,
.direction-choice,
.direction-return,
.direction-redirect {
  background: var(--color3);
  background: radial-gradient(200% 100% at 50% 0%, var(--color3), var(--color2));
  color: var(--color3_text);
}
/* .direction-action .editing-options,
.direction-choice .editing-options,
.direction-return .editing-options,
.direction-redirect .editing-options {
  background: var(--color2);
  background: linear-gradient(var(--color2), var(--color2));
  color: var(--color2_text);
} */

.direction-return {
  margin-right: 25%;
}

.direction a {
  color: var(--spark);
}
.header {
  padding: 3px 10px 5px 10px;
  color: var(--text);
  font-size: 17px;
  margin-bottom: 2px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
}
.header button {
  padding: 3px 5px 2px 5px;
  margin: 0px -5px 0 15px;
}
.header span {
  flex: 0 0 110px;
}

.header .handle {
  flex: 0 0 auto;
  color: var(--color2_text);
  background: var(--color2);
  padding: 6px;
  margin-top: -1px;
  margin-left: -7px;
  border-radius: 6px;
  cursor: pointer;
}
.header a {
  padding: 0px 7px;
}
/* input {
  font-size: 18px;
}
input[type='checkbox'] {
  max-width: 25px;
  flex: 0 0 auto;
}
select {
  font-size: 18px;
} */

.editing-buttons > * {
  width: 40px;
  height: 50px;
}

.editing-options {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-start;
  /* background: var(--color2); */
  /* color: var(--color2_text); */
  text-align: right;
  font-size: 20px;
  padding: 0;
  border-radius: var(--softness);
  overflow: hidden;
  margin: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}
.editing-options.open {
  border-radius: var(--softness) var(--softness) 0 0;
}
.editing-options .scroll {
  justify-content: flex-start;
  flex: 0 1 auto;
}

.height-transition {
  padding: 0;
}
.direction-content {
  display: grid;
  /* overflow: hidden; */
  /* --cstart: circle(0 at center); */
}
.direction-content > * {
  grid-row: 1/1;
  grid-column: 1/1;
  z-index: 2;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: flex-start;
  display: block;
  border-radius: 0 0 var(--softness) var(--softness);
  /* border-radius: 0 0 calc(var(--softness) - 3px) calc(var(--softness) - 3px); */
  /* margin: var(--spacing); */
  margin-top: calc(var(--spacing) * -1);
  padding: 5px;
  padding-top: 50px;
  padding-bottom: 42px;
  max-width: 100%;
  min-width: 0;
  --animation-time: 350ms;
  color: var(--color3_text);
  background: var(--color3);
}
.direction-content .main-content {
  color: var(--color1_text);
  background: transparent;
}

.circle-enter-active {
  animation: circle var(--animation-time);
  z-index: 3;
}
.circle-leave-active:last-child {
  animation: circle var(--animation-time) reverse;
  z-index: 2;
}
.circle-leave-active {
  animation: nothing var(--animation-time) reverse;
}
@keyframes circle {
  0% {
    clip-path: var(--cstart);
    background: var(--color2);
    opacity: 0.7;
  }
  100% {
    clip-path: circle(100% at center);
    background: var(--color3);
    opacity: 1;
  }
}
@keyframes nothing {
  100% {
  }
}

.tag {
  flex: 0 0 auto;
  font-size: 14px;
  text-align: center;
  margin: 0 3px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 8px;
  background: var(--color3);
  color: var(--color3_text);
  padding: 3px 10px;
  font-weight: bold;
}
.tag b {
  color: var(--spark);
}
.fa {
  transition: transform, 150ms;
  color: currentColor;
}
.muted {
  font-weight: normal;
  text-align: right;
  font-size: 0.75em;
}
.add-direction {
  padding: 5px;
}
.choice-line {
  margin-bottom: 5px;
}
.fa-disabled {
  padding: 0 7px;
  opacity: 0.2;
}
.row {
  max-width: 100%;
}
.row i,
.row b {
  flex: 0 0 auto;
}
.header .add-above {
  font-size: 0.75em;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.grouped {
  margin-left: 20px;
}

.buttons {
  display: flex;
  flex-direction: row;
  height: 35px;
  margin-top: -1px;
  padding-left: 5px;
}
.buttons .attr {
  min-width: 40px;
  padding: 0px 6px;
  margin-right: 3px;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
}
.tabActive .buttons i {
  color: var(--color3_text);
}

.buttons i,
.buttons svg {
  z-index: 2;
}
.buttons .attr span {
  display: inline-block;
  font-size: 80%;
  opacity: 0.8;
  margin-left: 0px;
  min-width: 1.4em;
  text-align: center;
  background: var(--color3);
  color: var(--color3_text);
  border-radius: 8px;
  padding: 1px 1px;
}
#app .active {
  background: var(--spark);
  color: var(--spark_text);
  border-radius: var(--softness);
}
#app .active i,
#app .active vi {
  color: var(--spark_text);
}
#app .active span {
  opacity: 0.6;
  background: var(--spark_text);
  color: var(--spark);
}
</style>
