<template>
  <div v-if="view === 'list'">
    <SearchingList
      v-model:filter="filter"
      @update:value="handleSelect"
      :actions="actions"
      :options="list"
      :config="config"
      :placeholder="highlight ? highlight[props.searchField] : undefined"
      :columns="`repeat(auto-fit, minmax(${props.size}px, 1fr))`"
    >
      <template #pre-list>
        <div v-if="!filter && highlight && props.showHighlight" class="card accented">
          <div class="options flex-row flex-space-between flex-align-center">
            <b>{{ highlight[searchField] }}</b>
            <div class="flex-stretch" />
            <div class="flex flex-row flex-align-center flex-static">
              <a @click.prevent="view = 'edit'">
                <vi name="pencil" />
              </a>
              <button @click.prevent="view = 'select'">Select</button>
            </div>
          </div>
          <slot name="highlight" :object="highlight"> {{ props.id }}: {{ highlight }} </slot>
        </div>
      </template>
      <template #default="{ object }">
        <div
          v-if="object.action && !object.id"
          class="flex-row flex-center flex-align-top padded action"
          @click="object.perform"
          :data-id="object.action"
        >
          <i :class="`fa fa-${object.icon}`" />
          <div>{{ object.name }}</div>
        </div>
        <slot v-else name="item" :object="object"> -- {{ object }} </slot>
      </template>
    </SearchingList>
  </div>
  <div v-else-if="view === 'edit' || view === 'create'" @update:value="handleSave">
    <div class="options flex-row flex-space-between flex-align-center">
      <a @click.prevent="handleBack" data-id="back">
        <vi name="arrowleft" />
      </a>
      <b>{{ highlight[searchField] || "Untitled" }}</b>
      <a v-if="highlight.id" @click.prevent="view = 'delete'" data-id="delete">
        <vi name="trash" />
      </a>
      <span v-else />
    </div>
    <slot name="edit" :object="highlight">
      Define a `edit` template:<br />
      {{ highlight }}
    </slot>
  </div>
  <div v-else-if="view === 'delete'">
    <slot name="delete" :object="highlight">
      <h3>Delete `{{ highlight[props.searchField] }}`?</h3>
      <slot name="highlight" :object="highlight"> </slot>
      <div class="flex-row flex-space-between">
        <button @click.prevent="handleDelete">Yes</button>
        <a @click.prevent="view = 'edit'">Cancel</a>
      </div>
    </slot>
  </div>
  <div v-else>confused? [{{ action }}]</div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";

import copy from "@morphosis/base/functions/copy";

import SearchingList from "../SearchingList.vue";

const emit = defineEmits(["update:id", "update:action", "update:list"]);
const props = defineProps({
  id: String,
  action: { type: String, default: "list" },
  list: Array,
  size: { type: Number, default: 200 },
  searchField: { type: String, default: "name" },
  showHighlight: { type: Boolean, default: true },
  newObject: {
    default() {
      return { name: "" };
    },
  },
});
const highlight = ref(null);
const filter = ref("");
const actions = computed(() => {
  const retval = [];
  retval.push({
    action: "create",
    name: filter.value ? `Create ${filter.value}` : "Create New",
    icon: "plus",
    span: "1/-1",
    perform: handleCreate,
  });
  if (props.showHighlight && highlight.value) {
    retval.push({
      action: "clear",
      icon: "eraser",
      name: "Clear Selection",
      span: "1/-1",
      perform: handleClear,
    });
  }
  // TODO: find story object variables
  return retval;
});
const config = computed(() => {
  return {
    keys: [props.searchField],
    includeMatches: true,
    findAllMatches: true,
  };
});
const view = computed({
  get: () => {
    let retval = "list";
    if (highlight.value) {
      retval = props.action || "list";
    }
    return retval;
  },
  set: (v) => {
    emit("update:action", v);
    if (v === "list" && !props.showHighlight) {
      emit("update:id", null);
    }
  },
});

watch(
  props,
  () => {
    if (view.value !== "create") {
      highlight.value = props.list.find((o) => o.id === props.id);
    }
  },
  { deep: true },
);

onMounted(() => {
  if (props.id) {
    highlight.value = props.list.find((o) => o.id === props.id);
  }
});

function handleSelect(e) {
  emit("update:id", e.id);

  if (!props.showHighlight) {
    view.value = "edit";
  }
  filter.value = "";
  highlight.value = e;
}

function handleCreate(e) {
  emit("update:id", null);
  view.value = "create";
  // router.push({ query: { back: route.query.back, view: "edit" } });
  filter.value = "";
  highlight.value = { [props.searchField]: filter.value };
}

function handleSave(e) {
  emit("update:id", e.id);
}

function handleDelete(e) {
  const list = copy(props.list);
  const index = list.findIndex((o) => o.id === highlight.value.id);
  if (index > -1) {
    list.splice(index, 1);
    emit("update:list", list);
    handleClear();
  }
}

function handleClear() {
  highlight.value = null;
  emit("update:id", null);
}

function handleBack(e) {
  view.value = "list";
  if (!highlight.value.id) {
    highlight.value = null;
  }
}
</script>

<style scoped>
.options b {
  margin-left: var(--spacing);
}
#app .options a {
  margin: 0;
  padding: var(--spacing);
}
.action i {
  display: inline-block;
  margin-right: 0.5em;
}
</style>
