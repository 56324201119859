<template>
  <div class="mod-report group">
    <b>{{ $t('moderation_report.report-content') }}</b>
    <div v-if="status">{{ status }}...</div>
    <form v-else @submit.prevent="submitModerationReport">
      <div class="source"><i>{{ source }}</i></div>
      <p>{{ content }}</p>
      <div>
        <label for="report">
          {{ $t('moderation_report.what-is-objectionable-about-this-content') }}
        </label>
        <textarea
          v-model="report"
          :placeholder="$t('moderation_report.describe-the-problem-here')"
          required
          rows="2"
        />
      </div>
      <div>
        <label for="email">
          {{ $t('moderation_report.email-address') }}
        </label>
        <input required name="email" type="email" :placeholder="$t('moderation_report.your-email-address')" v-model="email">
      </div>
      <div class="flex-row flex-right">
        <button>{{ $t('feedback.submit') }}</button>
        <a @click.prevent="$emit('show', false)" href="#">{{ $t('story_reader.cancel') }}</a>
      </div>
      <hr>
      <div v-if="user && user !== $root.user.id">
        <div class="flex-column">
          <label for="block">
            {{ $t('moderation_report.block-user') }}
          </label>
          <p class="help">
            {{ $t('moderation_report.blocking-explained') }}
          </p>
        </div>
        <div class="flex-row flex-right">
          <button
            v-if="!isBlocked"
            @click.prevent="blockUser()"
            type="button"
          >
            {{ $t('moderation_report.block-this-user') }}
          </button>
          <button v-else disabled>{{ $t('moderation_report.blocked') }}</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { Base64 } from 'js-base64';

export default {
  name: 'ModerationReport',
  props: {
    source: { type: String },
    content: { type: String },
    user: { type: String },
    extra: { type: Object, default: null }
  },
  data() {
    return {
      status: null,
      blockStatus: null,
      report: '',
      email: '',
    };
  },
  computed: {
    isBlocked() { return this.$root.blockedUsers.indexOf(this.user) > -1; },
  },
  methods: {
    async submitModerationReport() {
      // let story = this.story;

      // if (this.storyId && !story) {
      //   story = await this.loadFullStory(this.storyId);
      // }

      this.status = 'Sending...';
      const report = `
        user: ${this.$root.user.username} -- ${this.$root.user.id}<br>
        email: ${this.email}<br>
        version: ${this.$root.version}<br>
        platform: ${this.$root.platform}<br>
        source: ${this.source}<br>
        content: ${this.content}<br>
        feedback: ${this.report}<br>
        extras: ${JSON.stringify(this.extra)}
      `.trim();
      try {
        await this.ws('support_message', {
          subject: this.$t('moderation_report.cyoa-moderation-report'),
          message: report,
          attachments: [
            // {
            //   type: 'application/json',
            //   name: 'story_backup.json',
            //   data: Base64.encode(JSON.stringify(story)),
            // },
          ],
        });
      } catch (ex) {
        console.error(ex);
      }
      alert(this.$t('moderation_report.thank-you-we-will-review-your-submission'));
      this.bstatus = '';
      this.report = '';
      this.$emit('show', false);
    },
    blockUser() {
      this.blockStatus = 'blocked';
      this.$root.addBlockedUser(this.user)
      alert(this.$t('moderation_report.this-user-is-now-blocked-you-can-change-this-in-the-settings'));
    },
  },
};
</script>

<style scoped>
.source {
  font-size: 11px;
}
.mod-report textarea {
  font-size: 20px;
}
.mod-report label {
  white-space: normal;
  display: block;
}
</style>