import { Deferred } from "@morphosis/base/utils/deferred";

const DEFAULT_TIMEOUT = 100;

export default class DeferredList {
  constructor() {
    this.queue = [];
    this.running = false;
  }

  add(func) {
    const promise = new Deferred();
    this.queue.push({ promise, func });
    this.run();
    return promise;
  }

  async run() {
    if (!this.running) {
      this.running = true;
      while (this.queue.length > 0) {
        const item = this.queue.shift();
        try {
          // eslint-disable-next-line no-await-in-loop
          await Promise.race([item.promise.promise, item.func(item.promise)]);
          item.promise.resolve();
        } catch (ex) {
          console.log("error", ex);
          item.promise.reject(ex);
        }
      }
      this.running = false;
    }
  }
}

export class Bouncer {
  resolve: Function | null;
  timeout: number;
  promise: Promise<boolean> | null;
  _timeout: number = -1;

  constructor(timeout = DEFAULT_TIMEOUT) {
    this.resolve = null;
    this.timeout = import.meta.env.VITEST ? 1 : timeout;
    this.promise = null;
  }

  async debounce(): Promise<boolean> {
    this.cancel();
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      clearTimeout(this._timeout);
      this._timeout = window.setTimeout(() => {
        resolve(true);
      }, this.timeout);
    });
    return this.promise;
  }

  cancel() {
    if (this.resolve) {
      clearTimeout(this._timeout);
      this.resolve(false);
      this.promise = null;
    }
  }
}

const bouncers: Record<string, Bouncer> = {};
export function debounce(name: string, timeout: number = DEFAULT_TIMEOUT): Promise<boolean> {
  if (!bouncers[name]) {
    bouncers[name] = new Bouncer(timeout);
  }
  return bouncers[name].debounce();
}
