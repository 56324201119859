import { createRouter, createWebHashHistory } from "vue-router";

// import Home from "./views/home.vue";
import { routes } from "vue-router/auto-routes";
import Backgrounds from "./components/editor/storyObjects/views/backgrounds.vue";
import QRPrint from "./views/qr_print.vue";
import QR from "./views/qr_view.vue";
import Scene from "./views/scene.vue";
import RemoveEffect from "./views/scene/choice_remove.vue";
import Shared from "./views/shared.vue";
import Story from "./views/story.vue";
import StoryCreate from "./views/story_create.vue";
import StoryDelete from "./views/story_delete.vue";
import PublishStory from "./views/story_publish.vue";
import ShareStory from "./views/story_share.vue";
// import AiTest from "./views/ai/ai_test.vue";

function typedProps(props) {
  return (route) => {
    const retval = {};
    for (const prop in route.params) {
      if (props.indexOf(prop) > -1) {
        retval[prop] = parseInt(route.params[prop], 10);
      } else {
        retval[prop] = route.params[prop];
      }
    }
    return retval;
  };
}

function setRouteProps(routes) {
  for (const route of routes) {
    route.props = true;
    console.log("route", route.path, route.name);
    if (route.children) {
      setRouteProps(route.children);
    }
  }
}
setRouteProps(routes);

// @ts-ignore
export default createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/account/",
      name: "account",
      component: () => import("@morphosis/base/views/Account.vue"),
    },
    {
      path: "/admin/",
      name: "admin",
      component: () => import("./views/admin.vue"),
    },
    {
      path: "/filesystem/",
      name: "filesystem",
      component: () => import("./views/filesystem.vue"),
    },
    {
      path: "/cloud/",
      name: "cloud",
      component: () => import("./views/cloud_list.vue"),
    },
    {
      path: "/donate/",
      name: "donate",
      component: () => import("./views/donate.vue"),
    },
    {
      path: "/joke-packages/",
      name: "joke-packages",
      component: () => import("./views/joke_packages.vue"),
    },
    {
      path: "/market/browser/",
      name: "market",
      component: () => import("./views/story_browser.vue"),
    },
    {
      path: "/market/browser/tokens/",
      name: "tokens",
      component: () => import("./views/token_info.vue"),
    },
    {
      path: "/market/browser/:id/reader/read",
      name: "market-read",
      component: () => import("./views/story_reader.vue"),
    },
    {
      path: "/market/browser/:id/detail",
      name: "market-detail",
      component: () => import("./views/story_detail.vue"),
    },
    {
      path: "/market/browser/:id/check",
      name: "market-check",
      component: () => import("./views/story_check.vue"),
      props: true,
    },
    {
      path: "/comments/:id/",
      name: "comments",
      component: () => import("./views/comments.vue"),
    },

    { path: "/story/create/", name: "story_create", component: StoryCreate },
    {
      path: "/story/import/",
      name: "import-backup",
      component: () => import("./views/import_backup.vue"),
    },
    // {
    //   path: '/story/',
    //   name: 'home',
    //   component: () => import('./views/home.vue'),
    // },
    {
      path: "/story/:story/",
      component: Story,
      children: [
        {
          path: "",
          name: "story",
          component: () => import("./views/story/dashboard.vue"),
        },
        {
          path: "detail",
          name: "story-detail",
          component: () => import("./views/story/detail.vue"),
        },
        {
          path: "scene/:id?",
          name: "scene",
          component: Scene,
          props: true,
        },
        {
          path: "acts/:actId?",
          name: "acts",
          component: () => import("./views/story/acts.vue"),
        },
        {
          path: "search/",
          name: "scene-search",
          component: () => import("./views/story/scene_search.vue"),
        },
        { path: "share/", name: "share", component: ShareStory },
        {
          path: "export/",
          name: "export-backup",
          component: () => import("./views/export_backup.vue"),
        },
        {
          path: "render/",
          name: "export-render",
          component: () => import("./views/export_render.vue"),
        },
        {
          path: "compare/",
          name: "compare-backup",
          component: () => import("./views/story/MergeStory.vue"),
        },
        { path: "publish/", name: "publish", component: PublishStory },
        {
          path: "unpublish/",
          name: "unpublish",
          component: () => import("./views/story_unpublish.vue"),
        },
        { path: "print-qrs/", name: "print-qrs", component: QRPrint },
        {
          path: "settings/",
          name: "story-settings",
          component: () => import("./views/story_settings.vue"),
        },
        { path: "delete/", name: "delete", component: StoryDelete },
        {
          path: "export-apk/",
          name: "export-apk",
          component: () => import("./views/export_apk.vue"),
        },
        {
          path: "change-start/",
          name: "change-start",
          component: () => import("./views/scene/change_start.vue"),
        },
        {
          path: "read/",
          name: "read",
          component: () => import("./views/story_reader.vue"),
        },
        {
          path: "mp-test/",
          name: "mp-test",
          component: () => import("./views/story/mp_tester.vue"),
        },
        {
          path: "mp-test/:gameId/:pnum/",
          name: "mp-test-player",
          component: () => import("./views/story/mp_tester.vue"),
        },
        {
          path: "styles/:action?/:id?",
          name: "styles",
          component: () => import("./components/editor/storyObjects/views/styles.vue"),
        },
        // {
        //   path: "styles/:id/:action",
        //   name: "style",
        //   component: () =>
        //     import("./components/editor/storyObjects/detail/StyleDetail.vue"),
        // },

        {
          path: "backgrounds/create/",
          name: "backgrounds-create",
          component: Backgrounds,
          meta: { action: "create" },
        },
        {
          path: "backgrounds/:id?/:action?",
          name: "background",
          component: Backgrounds,
        },
        {
          path: "backgrounds/",
          name: "backgrounds",
          component: Backgrounds,
        },
        {
          path: "images/",
          name: "images",
          component: () => import("./components/editor/storyObjects/views/images.vue"),
        },
        // {
        //   path: "music/",
        //   name: "music",
        //   component: () => import("./views/music.vue"),
        // },
        {
          path: "audio-clips/",
          name: "audio",
          component: () => import("./views/audio_clips.vue"),
        },
        {
          path: "blueprints/",
          name: "blueprints",
          component: () => import("./views/blueprints.vue"),
        },
        {
          path: "blueprints/:typeName/",
          name: "blueprints-type",
          component: () => import("./views/blueprints.vue"),
        },
        {
          path: "blueprints/:typeName/:blueprintName/",
          name: "blueprints-detail",
          component: () => import("./views/blueprints.vue"),
        },
        {
          path: "blueprints/:typeName/:blueprintName/:attrName",
          name: "blueprints-attr",
          component: () => import("./views/blueprints.vue"),
        },
        {
          path: "characters/:id?/:action?/",
          name: "characters",
          component: () => import("./components/editor/storyObjects/views/characters.vue"),
        },
        {
          path: "characters/:id/:action/",
          name: "character",
          component: () => import("./components/editor/storyObjects/views/characters.vue"),
        },
        {
          path: "characters/:character/action/:id/",
          name: "character-action",
          component: () => import("./views/action.vue"),
        },
        {
          path: "characters/:character/attribute/:id/",
          name: "character-attribute",
          component: () => import("./views/attribute.vue"),
        },
        {
          path: "tags/:id?",
          name: "tags",
          component: () => import("./views/tags/list.vue"),
        },
        {
          path: "animation/:id?",
          name: "animation",
          component: () => import("./views/animation/index.vue"),
        },
        {
          path: "scene/:id/remove/",
          name: "remove_choice",
          component: RemoveEffect,
        },
        {
          path: "verify/",
          name: "verify",
          component: () => import("./views/verify_files.vue"),
        },
        {
          path: "review/",
          name: "direction-review",
          component: () => import("./views/DirectionReview.vue"),
        },
      ],
    },
    {
      path: "/gallery/",
      name: "gallery",
      component: () => import("./views/drawings/gallery.vue"),
      props: true,
    },
    {
      path: "/gallery/create/",
      name: "gallery-create",
      component: () => import("./views/drawings/create.vue"),
      props: true,
    },
    {
      path: "/gallery/:id/",
      name: "gallery-item",
      component: () => import("./views/drawings/gallery_item.vue"),
      props: true,
    },
    // { path: '/gallery/:id/draw/', name: 'drawing', component: () => import('./views/drawings/draw.vue'), props: true },

    {
      path: "/game/host/",
      name: "story-host",
      component: () => import("./views/story/host.vue"),
    },
    {
      path: "/game/join/",
      name: "story-join",
      component: () => import("./views/story/join_game.vue"),
      props: true,
    },
    {
      path: "/game/session/:gameId/",
      name: "story-session",
      component: () => import("./views/story/client.vue"),
      props: true,
    },

    // { path: '/experimental/mini-games/', name: 'mini-games', component: () => import('./views/mini/MiniGameList.vue')},
    // { path: '/experimental/mini-games/:id', name: 'mini-game', component: () => import('./views/mini/MiniGameEdit.vue')},

    {
      path: "/malpractice/invoice/",
      name: "malpractice-invoice",
      component: () => import("./components/seasonal/malpractice_invoice.vue"),
    },
    { path: "/shared/:id/", name: "shared", component: Shared },
    {
      path: "/app/settings/:tab?",
      name: "settings",
      component: () => import("./views/settings.vue"),
    },
    {
      path: "/app/benchmark/",
      name: "benchmark",
      component: () => import("./views/benchmark.vue"),
    },
    {
      path: "/app/help/",
      name: "help",
      component: () => import("./views/help.vue"),
    },
    {
      path: "/app/translate/",
      name: "translate",
      component: () => import("./views/translate.vue"),
    },
    {
      path: "/app/thanks/",
      name: "thanks",
      component: () => import("./views/thanks.vue"),
    },
    // { path: "/qr-scan/", name: "scan", component() { return import("./views/qr_scan.vue"); }},
    { path: "/qr/:text/", name: "qr", component: QR },
    {
      path: "/utils/expr-console/",
      name: "expr-console",
      component: () => import("./views/utils/expr_console.vue"),
    },
    {
      path: "/utils/parties/",
      name: "parties",
      component: () => import("@morphosis/base/views/Parties.vue"),
    },

    // TODO:
    // { path: "/ai-test/", name: "ai-test", component: AiTest},
    {
      path: "/privacy-policy/",
      name: "privacy-policy",
      component: () => import("@morphosis/base/views/PrivacyPolicy.vue"),
    },
    {
      path: "/test/",
      name: "test",
      component: () => import("./views/test.vue"),
    },
    {
      path: "/",
      name: "onboard",
      component: () => import("./views/onboard.vue"),
    },
    { path: "", redirect: "/story/" },
    // { path: '*', redirect: '/story/' },
    ...routes,
  ],
});
