<template>
  <div class="left relative">
    <div class="flex-row">
      <slot name="header" :select="selectItem" />
    </div>
    <Searcher
      :placeholder="placeholder || $t('story_browser.search')"
      :config="config"
      :actions="actions"
      :options="options"
      :filter="filter"
      v-model:selected="selected"
      v-model:results="results"
      @update:value="emit('update:value', $event)"
      @update:filter="emit('update:filter', $event)"
      @update:selected="selected = $event"
      @escape="$emit('escape', $event)"
    >
      <template v-slot:pre-search="scope">
        <slot name="pre-search" v-bind="scope" />
      </template>
    </Searcher>

    <slot name="pre-list" />

    <TransitionGroup v-if="results" name="grid" class="search" appear tag="div">
      <a
        v-for="(result, idx) of results.slice(0, max)"
        :key="result.item.id || 'empty'"
        :class="{ selected: selected === idx }"
        :style="{ 'grid-column': result.item.span || 'span 1' }"
        class="flex-row result item flex-left flex-align-center flex-stretch"
        @click.prevent="selectItem(result.item)"
        @keypress.prevent="selectItem(result.item)"
        @pointerover="selected = idx"
        @pointerout="selected = -1"
        tabindex="0"
      >
        <slot name="default" :result="result" :object="result.item" :selectItem="selectItem">
          {{ result.item }}
        </slot>
      </a>
      <div v-if="options?.length > max" class="more">
        <a @click.prevent="max += 50">Show more...</a>
      </div>
    </TransitionGroup>
    <slot v-else name="empty">
      <span>¯\_(ツ)_/¯</span>
    </slot>
    <slot v-if="options?.length === 0" name="none">
      <em>{{ $t("searching_modal.this-place-is-empty") }}</em>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import Searcher from "./searcher.vue";

const props = defineProps({
  placeholder: { type: String, required: false },
  columns: { type: String, default: "auto" },
  config: {
    type: Object,
    default() {
      return null;
    },
  },
  actions: {
    type: Array,
    default() {
      return [];
    },
  },
  options: { type: Array, required: true },
  value: {
    type: [Number, String],
    default() {
      return "";
    },
  },
});

const emit = defineEmits(["action", "update:value"]);
const status = ref(null);
const results = ref([]);
const max = ref(50);
const selected = ref(-1);
const filter = ref(props.value);

watch(
  () => props.value,
  () => {
    filter.value = props.value;
  },
);

function selectItem(item) {
  if (item.action) {
    emit("action", item.action);
  } else {
    emit("update:value", item);
  }
}
</script>

<style scoped>
a {
  padding: 0;
}
.search {
  max-width: 100%;
  display: grid;
  grid-template-columns: v-bind(columns);
  justify-content: stretch;
  align-items: stretch;
}
.selected {
  background: var(--spark);
  color: var(--spark_text);
  border-radius: 5px;
}
.selected * {
  color: var(--spark_text);
}
.more {
  grid-column: 1/-1;
  padding: var(--spacing);
  opacity: 0.7;
}
.item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 2px;
  font-size: 1.2em;
  margin: 2px;
}
input {
  margin: 0;
}
</style>
