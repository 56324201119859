<template>
  <div
    class="alert-list"
    @pointerover="handleHover(true)"
    @pointerout="handleHover(false)"
  >
    <transition-group class="alerts" name="fade-quick" tag="div">
      <div
        v-for="alert of alerts" :key="`alert-${alert.id}`"
        :class="alert.className"
        class="alert"
      >
        <div class="flex-row">
          <div class="flex-column">
            <div v-if="alert.title" class="alert-title">
              <router-link v-if="alert.link" to="">{{ alert.title }}</router-link>
              <template v-else>{{ alert.title }}</template>
            </div>
            <div class="alert-message flex-row flex-align-center">
              <router-link v-if="alert.link" to="">{{ alert.message }}</router-link>
              <template v-else>{{ alert.message }}</template>
            </div>
            <div v-if="alert.timeoutMax > 0 && alert.count > 1" class="suppress">
              <label>
                <input
                  type="checkbox"
                  @change="$root.changeSuppression(alert.message, $event.target.checked)"
                >
                Suppress these messages
              </label>
            </div>
          </div>
          <div
            v-if="alert.timeoutMax <= 0"
            @click="handleClose(alert)"
            class="spinner flex-static flex-align-top"
          >
            <i class="fa fa-cog fa-spin" />
          </div>
          <div
            v-if="alert.timeoutMax > 0"
            @click="handleClose(alert)"
            class="close flex-static flex-align-top"
          >
            <i class="fa fa-close" />
          </div>
        </div>
        <div
          v-if="alert.timeoutMax > 0"
          class="alert-timer"
          :style="{width: `${alert.timeout / alert.timeoutMax * 100}%`}"
        />
      </div>
      <div v-if="alertCount > 2" key="clear-all">
        <button @click.prevent="handleClear">
          <i class="fa fa-trash" />
          Clear all
        </button>
      </div>
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useToasterStore } from '../stores/toaster';

const toaster = useToasterStore();

const hover = ref(false);
const lastFrame = ref(0);
const timerOn = ref(false);

const alerts = computed(() => toaster.alerts.filter((a) => a.timeoutMax <= 0 || a.timeout > 0));
const alertCount = computed(() => alerts.value.length);


watch(alerts, () => {
  startTimer();
});

onMounted(() => {
  startTimer();
});
function handleClear() {
  for (const alert of toaster.alerts) {
    alert.timeout = 0;
    alert.timeoutMax = 1;
  }
};

function handleHover(status: boolean) {
  hover.value = status;
};

function handleClose(alert: any) {
  alert.timeout = 0;
  alert.timeoutMax = 1;
};

function startTimer() {
  if (!timerOn.value) {
    timerOn.value = true;
    runTimer();
  }
};

function runTimer() {
  if (alerts.value.length > 0) {
    lastFrame.value = Date.now();
    requestAnimationFrame(() => {
      if (!hover.value) {
        alerts.value.forEach((a: any) => {
          if (a.timeoutMax > 0) {
            a.timeout -= Math.min(Date.now() - lastFrame.value, 30);
            a.timeout = Math.max(a.timeout, 0);
          }
        });
      }
      runTimer();
    });
  } else {
    timerOn.value = false;
  }
};
</script>

<style scoped>
.alert-list {
  position: fixed;
  left: 5px;
  bottom: 5px;
  width: 90vw;
  max-width: 300px;
  text-align: left;
  z-index: 4;
}
.alerts {
  display: grid;
}
.alert {
  grid-column: 1 / 1;
  grid-row: span 1 / span 1;
  position: relative;
  overflow: hidden;
  margin: 0;
  margin-bottom: var(--spacing);
}
.alert.mini {
  padding: calc(var(--spacing) * 1.5);
  font-size: 0.8em;
}
.alert-title {
  font-style: italic;
  font-size: .8em;
}
.alert-message {
  font-size: 1.05em;
}
.alert-timer {
  position: absolute;
  bottom: 0;
  left: 0;
  background: var(--spark);
  height: 2px;
  box-shadow: 0 0 2px var(--spark);
}
.suppress {
  transform: scale(0.8);
  opacity: 0.8;
  margin-top: 10px;
  text-align: right;
  margin-right: 15px;
}
.spinner {
  font-size: 1.9em;
  margin-right: var(--spacing);
  padding: 0;
  margin: 0;
}
.spinner i {
  padding: 0;
  margin: 0;
}
.close {
  position: static;
  /* margin: 10px 15px 0 0; */
  cursor: pointer;
  padding: 0;
  margin: 0;
}
</style>
