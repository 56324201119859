export const routes = [
  {
    path: '/setup',
    /* internal name: '/setup' */
    /* no component */
    children: [
      {
        path: 'test/basic',
        name: '/setup/test.basic',
        component: () => import('/home/leitjohn/Projects/cyoa/src/pages/setup/test.basic.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/story',
    /* internal name: '/story' */
    /* no component */
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('/home/leitjohn/Projects/cyoa/src/pages/story/index.vue'),
        /* no children */
      },
      {
        path: ':story/music/:action?/:selected?',
        name: 'music',
        component: () => import('/home/leitjohn/Projects/cyoa/src/pages/story/[story].music.[[action]].[[selected]].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('/home/leitjohn/Projects/cyoa/src/pages/users.vue'),
    /* no children */
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

