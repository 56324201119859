<style scoped>
  .author {
    padding: 10px;
  }
  .author a,
  .links {
    margin: 0;
    padding: 2px;
  }
  .links {
    margin: auto;
    padding: 5px;
    background: var(--spark);
  }
  .bio {
    margin: 5px;
  }
  .bio.short {
    display: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .author-line {
    font-style: italic;
    font-size: 110%;
    opacity: 0.7;
  }
</style>

<template>
  <div class="author static" v-if="author">
    <div class="author-line flex-row flex-center flex-align-center" v-if="showUser">
      <span>{{ $t('author_plug.written-by') }}</span> <user-tag :uid="author.id" />
    </div>
    <div class="author-line" v-else>
      <router-link to="/account">{{ author.username }}</router-link>
    </div>
    <div class="bio" v-if="showUser && author.bio">{{ author.bio }}</div>
    <div class="bio short" v-else-if="author.bio">{{ author.bio }}</div>
    <ul class="links static row center" v-if="author && author.links && author.links.length > 0">
      <li v-for="link of author.links" :key="link.type">
        <a :href="link.url" target="_system">
          <span v-if="link.type == 'ko-fi'">ko-fi</span>
          <svg v-else-if="link.type == 'flattr'" style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="var(--spark_text)" d="M21,12A9,9 0 0,1 12,21H4.5L9.74,15.76L11.16,17.17L9.33,19H12A7,7 0 0,0 19,12V7L21,5V12M3,12A9,9 0 0,1 12,3H19.5L14.26,8.24L12.84,6.83L14.67,5H12A7,7 0 0,0 5,12V17L3,19V12Z" />
          </svg>
          <svg v-else-if="link.type == 'liberapay'" style="width:24px;height:24px" viewBox="0 0 80 80">
            <g transform="translate(-81.76206,-211.304)">
              <path fill="var(--spark_text)" d="m104.28 271.1c-3.571 0-6.373-.466-8.41-1.396-2.037-.93-3.495-2.199-4.375-3.809-.88-1.609-1.308-3.457-1.282-5.544.025-2.086.313-4.311.868-6.675l9.579-40.05 11.69-1.81-10.484 43.44c-.202.905-.314 1.735-.339 2.489-.026.754.113 1.421.415 1.999.302.579.817 1.044 1.546 1.395.729.353 1.747.579 3.055.679l-2.263 9.278" />
              <path fill="var(--spark_text)" d="m146.52 246.14c0 3.671-.604 7.03-1.811 10.07-1.207 3.043-2.879 5.669-5.01 7.881-2.138 2.213-4.702 3.935-7.693 5.167-2.992 1.231-6.248 1.848-9.767 1.848-1.71 0-3.42-.151-5.129-.453l-3.394 13.651h-11.162l12.52-52.19c2.01-.603 4.311-1.143 6.901-1.622 2.589-.477 5.393-.716 8.41-.716 2.815 0 5.242.428 7.278 1.282 2.037.855 3.708 2.024 5.02 3.507 1.307 1.484 2.274 3.219 2.904 5.205.627 1.987.942 4.11.942 6.373m-27.378 15.461c.854.202 1.91.302 3.167.302 1.961 0 3.746-.364 5.355-1.094 1.609-.728 2.979-1.747 4.111-3.055 1.131-1.307 2.01-2.877 2.64-4.714.628-1.835.943-3.858.943-6.071 0-2.161-.479-3.998-1.433-5.506-.956-1.508-2.615-2.263-4.978-2.263-1.61 0-3.118.151-4.525.453l-5.28 21.948" />
            </g>
          </svg>
          <span v-else-if="link.type == 'discord'" style="color: var(--spark_text)">
            <vector-image name="discord" />
          </span>
          <template v-else>
            <i class="fa" style="color: var(--spark_text)" :class="`fa-${link.type}`" />
          </template>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "author-plug",
  props: {
    author: Object,
    showUser: {type: Boolean, default: false}
  },
}
</script>