
export function nextFrame() {
  return new Promise(r => {
    requestAnimationFrame(r);
  });
}

export function timer(t) {
  return new Promise(r => {
    setTimeout(() => {
      r();
    }, t);
  });
}
