<template>
  <div class="flex-column" v-if="book.story">
    <div class="relative flex-row align-center">
      <i class="text-icon">
        <span>T</span>
        <span>t</span>
      </i>
      <Select
        v-bind="$attrs"
        v-model="value"
        :options="book.styles"
        :invalid="errors.length > 0"
        showClear
        optionLabel="name"
        optionValue="id"
        class="p-3-l"
      >
        <template #value="{ placeholder, value }">
          <div v-if="instance" class="style p-2-x p-1-y" :style="textStyle(instance)">
            {{ instance.name || "Untitled" }}
          </div>
          <div v-else>
            {{ placeholder || "----" }}
          </div>
        </template>
        <template #option="slotProps">
          <div class="style p-2-x p-1-y" :style="textStyle(slotProps.option)">
            {{ slotProps.option.name }}
          </div>
        </template>
      </Select>
    </div>
    <div>
      <RouterLink
        :to="{ name: 'styles', params: { story: book.story.id, back: route.path } }"
        class="font-size-3 p-0-y p-1-t"
      >
        Styles...
      </RouterLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStoryStore } from "@/stores/story";
import { textStyle } from "@/utils/tags";
import Select from "primevue/select";
import { useField } from "vee-validate";
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const book = useStoryStore();

const props = withDefaults(
  defineProps<{
    name: string;
    showTitle?: boolean;
    accept?: string;
  }>(),
  { accept: "image" },
);

const { value, errors } = useField(props.name);

const instance = computed({
  get: () => book.styles.find((a) => a.id === value.value),
  set: (v: Animation) => (value.value = v.id),
});
</script>

<style scoped>
i.text-icon {
  position: absolute;
  z-index: 2;
  margin-left: 0.7em;
}
.text-icon span {
  display: inline-block;
}
.text-icon span:first-child {
  opacity: 0.75;
  translate: -1px -1px;
}
.text-icon span:last-child {
  translate: -2px 1px;
  opacity: 0.5;
}
#app :deep(.style) {
  background: linear-gradient(red, grey, black, white);
}
</style>
