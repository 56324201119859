<style scoped>

</style>

<template>
  <div class="story-create">
    <app-header v-model:modelValue="open" :show-menu="false">
      <router-link :to="{name: 'home'}"><i class="fa fa-arrow-left" /></router-link>
      {{$t('home.create-story')}}
    </app-header>
    <div class="scroll responsive-max">
      <story-form v-model:value="story" @submit="handleCreateStory" />
    </div>
  </div>
</template>

<script>
import StoryForm from '../components/story_form.vue';

export default {
  name: 'story-create',
  components: {
    StoryForm,
  },
  data() {
    return {
      error: null,
      status: null,
      author: null,
      story: {
        title: '',
        short_description: '',
        description: '',
        cover: null,
        attrs: {
          language: this.fetch('story-browser-language', ''),
          genre: '',
        },
        settings: {
          overlayout: true,
        }
      },
    };
  },
  computed: {
    user() { return this.$root.user; },
  },
  methods: {
    handleBack() {
      if (
        (!this.title && !this.description)
        || confirm(this.$t('story_create.do-you-wish-to-abandon-this-novel'))
      ) {
        this.$router.push('../..');
      }
    },
    async handleCreateStory() {
      this.status = 'creating';
      const story = new this.MODELS.story({
        uid: this.user ? this.user.id : null,
        version: this.CURRENT_VERSION,
        created: Date.now(),
        updated: Date.now(),
        scenes: [],
        tags: [],
        ...this.story
      });
      await story.save();
      this.$router.push(`/story/${story.id}/`);
      this.status = null;
    },
  },
};
</script>
