<template>
  <span class="tag-label flex-left" v-if="object">
    <!-- <vi v-if="object.icon" :name="object.icon" />
    <vi v-else name="tag" /> -->
    <span v-if="object.category" class="flex-shrink">{{ object.category }}/</span>
    <b>{{ object.display || object.name }}</b>
    <span v-if="object.trait" class="trait">.{{ object.trait }}</span>
    <span class="flex-stretch" />
  </span>
  <span v-else>{{ reference }}</span>
</template>

<script>
export default {
  name: 'TagLabel',
  props: {
    story: {type: Object, required: true},
    reference: {type: String, required: false},
    tag: {type: Object, required: false},
  },
  computed: {
    object() {
      return this.tag || this.findTag(this.story, this.reference, {});
    },
  },
}
</script>

<style scoped>
.tag-label {
  display: inline-flex;
  min-width: 10px;
  overflow: hidden;
}
b {
  flex: 1 1 auto;
}
svg {
  max-height: 20px;
  max-width: 20px;
}
</style>