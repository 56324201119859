<template>
  <div class="padded">
    <CYOARecordingStudio
      :story="story"
      :character="character"
      :characterName="characterName"
      :text="value.content"
      :value="value.audio"
      :frozen="value.frozenAudio"
      :cloud="story.cloudSync"
      :effectProfileId="value.ep"
      @update:effectProfileId="$emit('update', { ep: $event })"
      @update:value="$emit('update', { audio: $event })"
    />

    <div v-if="characterName" class="character-name">
      <b>{{ characterName }}</b>
    </div>
    <code>{{ value.content }}</code>
  </div>
</template>

<script>
import CYOARecordingStudio from "../audio/CYOARecordingStudio.vue";
export default {
  components: {
    CYOARecordingStudio,
  },
  props: {
    story: Object,
    scene: Object,
    value: Object,
    index: Number,
  },
  computed: {
    characters() {
      return this.story.characters || [];
    },
    character() {
      const { characters } = this;
      if (this.value.character) {
        const char = characters.find((c) => c.id == this.value.character);
        if (char) {
          return char;
        }
      }
      return null;
    },
    characterName() {
      return this.value.label || this.character?.name;
    },
  },
};
</script>

<style scoped>
.character-name {
  margin: 10px 0 10px 5px;
}
code {
  display: block;
  padding: var(--spacing);
  background: var(--bg);
  color: var(--text);
}
</style>
