const tips = [
  {
    category: 'Writing',
    tip: 'Start by writing your whole story with one choice per scene',
    low: 'Then it will be easier to add in other story arcs',
  },
  {
    category: 'Keyboard',
    tip: `If you have a physical keyboard you can press CTRL + P to open the command palette`,
    low: '',
  },
  {
    category: 'Scene Editor',
    tip: 'Text blocks will type out text for the reader',
    img: 'text.gif',
    low: 'They will wait for the user to continue',
    steps: [
      { action: 'patch', args: [{
        scenes: [
          {id: '1', directions: [
            {id: '1', type: 'text', content: ''},
            {id: '2', type: 'text', content: '_'}
          ], start: true},
        ]
      }] },
      { action: 'navigate', args: ['/story/test/scene/1/'] },
      { action: 'goto', args: ['textarea'] },
      { action: 'type', args: ['This text will appear in the story. '] },
      { action: 'click', args: ['.read-story-link'] },
    ],
  },
  {
    category: 'Scene Editor',
    tip: 'Give the reader control by adding choice directions',
    img: 'choices.gif',
    steps: [
      { action: 'patch', args: [{
        scenes: [
          {id: '1', directions: [
            {id: '1', type: 'text', content: 'Give the reader control by adding choice directions. '},
          ], start: true},
        ]
      }] },
      { action: 'navigate', args: ['/story/test/scene/1/'] },

      { action: 'click', args: ['.direction-type-choice'] },
      { action: 'goto', args: ['.direction:nth-child(2) textarea'] },
      { action: 'type', args: ['Yes! Choices are good!'] },

      { action: 'click', args: ['.direction-type-choice'] },
      { action: 'goto', args: ['.direction:nth-child(3) textarea'] },
      { action: 'type', args: ['No! I hate choices!'] },

      { action: 'click', args: ['.read-story-link'] },
    ],
  },
  {
    category: 'Scene Editor',
    tip: 'Redirect the user to continue to another scene without a prompt',
    img: 'redirects.gif',
    steps: [
      { action: 'patch', args: [{
        scenes: [
          {id: '1', directions: [
            {id: '1', type: 'text', content: 'Redirects move to another scene without a choice. '},
          ], start: true},
        ]
      }] },
      { action: 'navigate', args: ['/story/test/scene/1/'] },

      { action: 'click', args: ['.direction-type-redirect'] },
      { action: 'click', args: ['.choice-line'] },
      { action: 'click', args: ['.create-link'] },
      { action: 'pause', args: [1300] },
      { action: 'goto', args: ['textarea'] },
      { action: 'type', args: ['You are at the next scene! '] },

      { action: 'click', args: ['.read-story-link'] },
      { action: 'pause', args: [2000] }, // while text types
      { action: 'click', args: ['.next a'] },
    ],
  },
  {
    category: 'Scene Editor',
    tip: 'Create a new scenes by tapping the (+) icon on a choice direction',
    img: 'create_scene.gif',
    steps: [
      { action: 'patch', args: [{
        scenes: [
          {id: '1', directions: [
            {id: '1', type: 'text', content: 'You need to go to the market!'},
          ], start: true},
        ]
      }] },
      { action: 'navigate', args: ['/story/test/scene/1/'] },

      { action: 'click', args: ['.direction-type-choice'] },
      { action: 'goto', args: ['.direction:nth-child(2) textarea'] },
      { action: 'type', args: ['Go to the market'] },
      { action: 'click', args: ['.choice-line'] },
      { action: 'click', args: ['.create-link'] },
      { action: 'pause', args: [1300] },
      { action: 'goto', args: ['textarea'] },
      { action: 'type', args: ['You have arrived at the market! '] },

      { action: 'click', args: ['.read-story-link'] },
      { action: 'pause', args: [2100] }, // while text types
      { action: 'click', args: ['.choice a'] },
    ],
  },
  {
    category: 'Scene Editor',
    tip: 'Link to existing scenes by selecting from the scene list',
    img: 'link_scene.gif',
    steps: [
      { action: 'patch', args: [{
        scenes: [
          {id: '1', directions: [{type: 'text', content: 'Link to another scene'}, {type: 'redirect'}], start: true},
          {id: '2', directions: [{type: 'text', title: 'next', content: 'Welcome to the next scene'}]},
        ]
      }] },
      { action: 'navigate', args: ['/story/test/scene/1/'] },
      { action: 'click', args: ['.choice-line'] },
      { action: 'click', args: ['.scene-link'] },
      { action: 'click', args: ['.linked-scene a'] },
    ],
  },
  {
    category: 'Scene Editor',
    tip: 'Insert directions by tapping the (+) icon',
    img: 'inserting_directions.gif',
    low: 'The new direction will appear above',
    steps: [
      { action: 'navigate', args: ['/story/test/scene/test1/'] },
      { action: 'goto', args: ['textarea'] },
      { action: 'type', args: ['Insert a direction above this one with the (+) button. '] },
      { action: 'click', args: ['.add-above'] },
      { action: 'click', args: ['.direction-type-text'] },
      { action: 'goto', args: ['textarea'] },
      { action: 'type', args: ['Ta Da!'] },
    ],
  },
  {
    category: 'Scene Editor',
    tip: 'Delete directions by tapping the (x) icon, and confirming',
    img: 'delete_direction.gif',
    steps: [
      { action: 'navigate', args: ['/story/test/scene/test1/'] },
      { action: 'goto', args: ['textarea'] },
      { action: 'type', args: ['Delete this by tapping the (x) icon. '] },
      { action: 'click', args: ['.confirm-button'] },
      { action: 'click', args: ['.confirm-button'] },
    ],
  },
  {
    category: 'Scene Editor',
    tip: 'Reorder scene directions by dragging their handles',
    img: 'drag_n_drop_directions.gif',
  },
  {
    category: 'Scene Editor',
    tip: 'Test read your story by tapping the eye at the top of the scene editor',
    img: 'tap_eye_to_read.gif',
    low: 'It will resume from wherever you were last reading',
    steps: [
      { action: 'patch', args: [{
          scenes: [{
            id: '1',
            directions: [
              {type: 'text', content: 'There is not enough light to see, but you can hear something behind you...'},
              {type: 'redirect'}
            ],
            start: true
          }],
        }],
      },
      { action: 'navigate', args: ['/story/test/scene/1/'] },
      { action: 'click', args: ['.read-story-link'] },
    ],
  },
  {
    category: 'Scene Editor',
    tip: 'Tags can be used to hide or show directions',
    img: 'tag_control.gif',
    // steps: [
    //   { action: 'patch', args: [{
    //       scenes: [{
    //         id: 1,
    //         directions: [
    //           {type: 'text', content: 'There is not enough light to see, but you can hear something behind you...'},
    //           {type: 'redirect'}
    //         ],
    //         start: true
    //       }],
    //     }],
    //   },
    //   { action: 'navigate', args: ['/story/test/scene'] },
    //   { action: 'click', args: ['.direction .fa-tags'] },
    //   { action: 'click', args: ['.operation-array button'] },
    //   { action: 'click', args: ['.read-story-link'] },
    // ],
  },
  // {
  //   category: 'Scene Editor',
  //   tip: 'There are two types of tags',
  //   low: 'Effects and Requirements',
  // },
  {
    category: 'Scene Editor',
    tip: 'Directions can be used to give tags',
    img: 'give_tag.gif',
  },
  {
    category: 'Scene Editor',
    tip: 'Use `input` directions to get text from the user',
    img: 'input.gif',
    low: 'What they type will be stored in the tag you choose',
    steps: [
      { action: 'patch', args: [{
        tags: [{
          id: 'name', name: 'name', format: 'text', type: 'tag'
        }],
        scenes: [
          {id: '1', directions: [
            {id: '1', type: 'input', content: '', label: ''},
            {id: '2', type: 'text', content: 'Hello {{ name }}!'},
            {id: '3', type: 'text', content: '.'}
          ], start: true},
        ]
      }] },
      { action: 'navigate', args: ['/story/test/scene/1/'] },
      { action: 'goto', args: ['textarea'] },
      { action: 'type', args: ['What is your name? '] },
      { action: 'click', args: ['.tag-picker'] },
      { action: 'click', args: ['.modal .result'] },
      { action: 'click', args: ['.read-story-link'] },
      { action: 'goto', args: ['input'] },
      { action: 'type', args: ['Batman'] },
      { action: 'click', args: ['.text-content button'] },
    ],
  },
  {
    category: 'Scene Editor',
    tip: 'You can insert the value of a tag into text',
    img: 'tag_interpolation.gif',
    low: 'Tap the gear, and then `Insert Tag`',
    steps: [
      { action: 'patch', args: [{
        tags: [{
          id: 'name', name: 'name', format: 'text', type: 'tag'
        }],
        scenes: [
          {id: '1', directions: [
            {id: '1', type: 'text', content: '', effects: [{tag: 'name', op: ':', value: 'Batman'}]},
            {id: '2', type: 'text', content: '_'}
          ], start: true},
        ]
      }] },
      { action: 'navigate', args: ['/story/test/scene/1/'] },
      { action: 'goto', args: ['textarea'] },
      { action: 'type', args: ['I am {{ name }}'] },
      { action: 'click', args: ['.read-story-link'] },
    ],
  },
  {
    category: 'Scene Editor',
    tip: 'You can randomize directions with a group',
    img: 'group_usage.gif',
    low: 'one direction will be shown randomly from a group',
    steps: [
      { action: 'patch', args: [{
        scenes: [
          {id: '1', directions: [
            {id: '1', type: 'group'},
            {id: '2', type: 'text', content: 'A'},
            {id: '3', type: 'text', content: 'B'},
            {id: '4', type: 'text', weight: 100, content: 'C'}
          ], start: true},
        ]
      }] },
      { action: 'navigate', args: ['/story/test/scene/1/'] },
      { action: 'click', args: ['.read-story-link'] },
    ],
  },
  {
    category: 'Scene Editor',
    tip: 'You can influence randomization in a group',
    img: 'group_weight.gif',
    low: 'weight increases the chances of selecting that direction',
    steps: [
      { action: 'patch', args: [{
        scenes: [
          {id: '1', directions: [
            {id: '1', type: 'group'},
            {id: '2', type: 'text', content: 'A'},
            {id: '3', type: 'text', content: 'B'},
            {id: '4', type: 'text', content: 'C'}
          ], start: true},
        ]
      }] },
      { action: 'navigate', args: ['/story/test/scene/1/'] },
      { action: 'click', args: ['.direction:nth-child(2) .weight-changer'] },
      { action: 'click', args: ['.direction:nth-child(3) .weight-changer'] },
      { action: 'click', args: ['.read-story-link'] },
    ],
  },
  {
    category: 'Scene Editor',
    tip: 'Group randomization respects tags',
    img: 'group_tags.gif',
    low: 'A random selection will match tags',
    steps: [
      { action: 'patch', args: [{
        scenes: [
          {id: '1', directions: [
            {id: '1', type: 'group'},
            {id: '2', type: 'text', content: 'No requirements', weight: 0},
            {id: '3', type: 'text', weight: 100, content: 'Requires Batman', effects: [{tag: 'name', op: '=', value: 'Batman'}]},
          ], start: true},
        ]
      }] },
      { action: 'navigate', args: ['/story/test/scene/1/'] },
      { action: 'goto', args: ['.direction:nth-child(2) .weight-changer'] },
      { action: 'click', args: ['.read-story-link'] },
    ],
  },
  {
    category: 'Scene Editor',
    tip: 'You can add a randomization fallback in a group (if-else)',
    img: 'group_zero.gif',
    low: 'Zero weight directions are only used when no other directions match',
    steps: [
      { action: 'patch', args: [{
        scenes: [
          {id: '1', directions: [
            {id: '1', type: 'group'},
            {id: '2', type: 'text', content: 'Who are you?', weight: 0},
            {id: '3', type: 'text', weight: 100, content: 'You are Batman', effects: [{tag: 'name', op: '=', value: 'Batman'}]},
            {id: '4', type: 'text', weight: 100, content: 'You are Robin', effects: [{tag: 'name', op: '=', value: 'Robin'}]},
          ], start: true},
        ]
      }] },
      { action: 'navigate', args: ['/story/test/scene/1/'] },
      { action: 'goto', args: ['.direction:nth-child(2) .weight-changer'] },
      { action: 'click', args: ['.read-story-link'] },
    ],
  },
  {
    category: 'Scene Editor',
    tip: 'More tutorials to come soon...',
    img: 'more.gif',
  },
  // {
  //   category: 'Scene Editor',
  //   tip: '',
  //   img: '',
  // },
];

let id = 0;
tips.forEach(t => t.id = ++id);

export default tips;
