<style scoped>
  .comments {
    padding-top: 10px;
    padding-bottom: 10px;
    white-space: normal;
  }
  .comments,
  .comments textarea {
    font-size: 18px;
  }
  .comment-list {
    border-radius: 0;
    margin: 0;
    padding: 10px;
  }
  .comment .content {
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
    word-break: break-word;
    display: block;
  }
  textarea {
    font-size: 18px;
    height: 200px;
  }
  .meta {
    margin-top: 25px;
    flex: 0 0 auto;
    font-size: .8em;
    align-items: flex-end;
    justify-content: flex-end;
    opacity: 0.75;
  }
  .meta-date {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .remove-comment {
    padding: 0px 15px;
  }
</style>

<template>
  <div class="comments static" v-if="story">
    <ul v-if="comments.length > 0" class="comment-list">
      <li v-for="comment in filteredComments.slice(-100, comments.length)" :key="comment.id" class="comment column">
        <div class="meta row center">
          <div class="meta-date">{{ new Date(comment.timestamp * 1000).toLocaleString() }}</div>
          <user-tag :uid="comment.uid" />
          <a
            v-if="comment.uid === $root.user.id || $root.isEditor"
            class="static remove-comment"
            @click="removeComment(comment)"
          >
            &times;
          </a>
          <moderation-flag
            v-else
            :source="`Story/${story}/Comments/${comment.id}@${comment.uid}`"
            :content="comment.content"
            :user="comment.uid"
          />
        </div>
        <div class="content row">{{ comment.content.trim() }}</div>
      </li>
    </ul>
    <div class="row padded space-around">
      <button @click.prevent="fetchComments"><i class="fa fa-refresh" /> </button>
      <button v-if="status == null" @click="status = 'typing';" class="button">
        {{ $t('comments.share-your-thoughts') }}
      </button>
    </div>
    <form class="form" @submit.prevent="saveComment">
      <textarea
        v-if="status == 'typing'"
        v-model="content"
        :placeholder="$t('comments.type-your-comment-here')"
        required
      />
      <button v-if="status == 'typing'" class="button">
        <i class="fa fa-upload" />
        {{ $t('comments.submit-comment') }}
      </button>
      <button v-if="status == 'typing'" class="button" @click.prevent="status = null">
        &times; {{ $t('story_reader.cancel') }}
      </button>
    </form>
  </div>
</template>

<script>
import sortBy from 'sort-by';

export default {
  name: 'comments',
  props: {
    story: String,
    remoteApi: Function,
  },
  data() {
    return {
      status: null,
      content: '',
      comments: [],
      page: 1,
    };
  },
  computed: {
    filteredComments() {
      return this.comments.filter(c => {
        return this.$root.blockedUsers.indexOf(c.uid) === -1;
      });
    },
  },
  async mounted() {
    await this.fetchComments();
  },
  methods: {
    async fetchComments(page=1) {
      if (this.story) {
        const response = await this.remoteApi('public_comments', {
          fid: this.story,
          page,
        });
        if (!response.error) {
          this.comments = response.list;
          this.comments.sort(sortBy('timestamp'));
        }
      }
    },
    async removeComment(comment) {
      if (confirm(this.$t('comments.remove-this-comment'))) {
        await this.remoteApi('public_comment_remove', {
          fid: this.story,
          id: comment.id,
        });
        await this.fetchComments();
        this.status = null;
      }
    },
    async saveComment() {
      if (this.status === 'typing') {
        await this.remoteApi('public_comment', {
          fid: this.story,
          content: this.content,
        });
        this.content = '';
        await this.fetchComments();
        this.status = null;
      } else {
        this.status = 'typing';
      }
    },
  },
};
</script>
