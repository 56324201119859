<template>
  <div class="invalid-content">
    <div class="error">
      This block is not recognized as valid content.<br>
      Here is the raw content of this direction:
    </div>
    <pre>{{ value }}</pre>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
  },
};
</script>

<style scoped>
.invalid-content pre {
  background: pink;
  color: darkred;
  padding: 5px;
}
</style>
