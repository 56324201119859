<style scoped>
  .fa {
    color: var(--spark);
  }
  .formula {
    margin-top: 5px;
  }
  .row select {
    margin-left: 15px;
    margin-top: 5px;
    font-size: .95em;
  }
</style>

<template>
  <div>
    <autogrow-textarea
      :placeholder="$t('action_content.type-action-text-here')"
      :modelValue="value.label"
      :style-name="value.style"
      :story="story"
      :no-speed="true"
      @update:style-name="$emit('update', {style: $event})"
      @update:modelValue="$emit('update', {label: $event})"
    />
    <div class="row">
      <select :value="value.content" @input="$emit('update', {content: $event.target.value})">
        <option v-for="tag of actions" :key="tag.id" :value="tag.id">
          {{ tag.category ? `${tag.category}/${tag.name}` : tag.name }}
        </option>
      </select>
      <!-- <i class="fa fa-arrow-right" />
      <select
        placholder="Store result in tag"
        :value="value.resultTag"
        @input="$emit('update', {resultTag: $event.target.value})"
      >
        <option value="">Store action result</option>
        <option v-for="tag of variables" :key="tag.id" :value="tag.id">
          {{ tag.category ? `${tag.category}/${tag.name}` : tag.name }}
        </option>
      </select> -->
      <router-link v-if="value.content" :to="{name: 'tags', params: {id: value.content, story: story.id}}">
        <i class="fa fa-pencil" />
      </router-link>
      <router-link v-else :to="{name: 'tags', params: {story: story.id}}">
        <i class="fa fa-pencil" />
      </router-link>
    </div>
  </div>
</template>

<script>
import ChoiceLine from "../choice_line.vue";

export default {
  name: "audio-content",
  components: {
    ChoiceLine,
  },
  props: {
    story: Object,
    value: Object,
  },
  computed: {
    actions() {
      return this.story.tags.filter(tag => tag.type == 'action');
    },
    variables() {
      return this.story.tags.filter(tag => tag.type == 'tag');
    },
  },
}
</script>