<template>
  <div class="falling-numbers" @click="hurry">
    <span class="number">
      <span class="number-text">{{ number }}</span>
      <div
        v-for="particle of particles"
        :key="particle.id"
        :style="{
          transform: `translate(${particle.x/22}em, ${particle.y/22}em) rotate(${particle.a}rad)`,
          opacity: particle.opacity,
        }"
        class="particle"
        :class="{positive: delta > 0, negative: delta < 0}"
      >
        {{ particle.text }}
      </div>
    </span>
    <transition name="quickfade">
      <span v-if="remaining" :class="{positive: delta > 0, negative: delta < 0}">
        {{ delta > 0 ? '+' : '-' }}{{ Math.floor(Math.abs(remaining)) }}
      </span>
    </transition>
  </div>
</template>

<script>
function nextFrame() {
  return new Promise(r => {
    requestAnimationFrame(r);
  });
}
function timer(t) {
  return new Promise(r => {
    setTimeout(() => {
      r();
    }, t);
  });
}
function nrand(x = 1.0) {
  return (Math.random() - 0.5) * 2.0 * x;
}
export default {
  name: 'FallingNumbers',
  props: {
    start: { type: Number, required: true },
    delta: { type: Number, required: true },
  },
  data() {
    return {
      number: Math.floor(this.start),
      remaining: this.delta,
      particles: [],
      life: 600,
      hurried: false,
    };
  },
  watch: {
    start() { this.resetFall(); },
    delta() { this.resetFall(); },
  },
  async mounted() {
    this.resetFall();
  },
  unmounted() {
    this.hurried = true;
    this.particles.length = 0;
    window.cancelAnimationFrame(this.nextFrame);
    clearTimeout(this.timeout);
  },
  methods: {
    async hurry() {
      this.hurried = true;
    },
    async resetFall() {
      if (!this.timeout) {
        this.timeout = 1;
        this.hurried = true;
        await this.previous;
        this.hurried = false;
        this.previous = this.fall();
      }
    },
    async fall() {
      this.number = Math.floor(this.start);
      this.remaining = this.delta;
      this.timeout = timer(600);
      await this.timeout;
      const steps = Math.ceil(Math.min(15, Math.abs(this.delta)));
      let last = Date.now();
      let lastNumber = this.number;
      for (let step = 1; step <= steps; step += 1) {
        const p = this.ease(step / steps);
        this.number = Math.floor(this.start + this.delta * p);
        this.remaining = Math.abs(Math.floor(this.delta * (1 - p)));

        if ((p >= 1 || Date.now() - last > 60) && Math.abs(this.number - lastNumber) > 0) {
          let number = this.number - lastNumber;
          if (number > 0) {
            number = '+' + number;
          }
          last = Date.now();
          const angle = 0.0 + Math.cos((Date.now() + nrand(0.4) * 2) / 50) * 0.3;
          const speed = nrand(1) + 5;
          this.particles.push({
            id: last,
            text: number,
            // text: `${this.number}`,
            expires: Date.now() + this.life,
            x: 0,
            y: 0,
            a: angle,
            dx: 0 + Math.cos(angle) * speed, // + nrand(1.5),
            dy: 0 + Math.sin(angle) * speed, // + nrand(2),
            da: nrand(0.00),
            opacity: 1,
          });
          lastNumber = this.number;
        }

        this.timeout = timer(10 + Math.random() * 20);
        await this.timeout;
        await nextFrame();
        this.startAnimation();
        if (this.hurried) {
          this.number = this.start + this.delta;
          this.remaining = 0;
          break;
        }
      }
      this.timeout = null;
    },
    ease(x) {
      return 1 - Math.pow(1 - x, 2);
      // return x <= 0.3 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
    },
    startAnimation() {
      if (! this.nextFrame) {
        this.animate();
      }
    },
    async animate() {
      for (let p of this.particles) {
        const life = (p.expires - Date.now());
        if (life < 100) {
          p.opacity = life / 100;
        }
        if (p.opacity <= 0) {
          this.particles.splice(this.particles.indexOf(p), 1);
        } else {
          p.x += p.dx;
          p.y += p.dy;
          p.a += p.da;
          p.dy = p.dy * 0.9;
          p.dx = p.dx * 0.9;
          p.da = p.da * 0.9;
          // p.dy += 9.81 / 60;
        }
      }
      if (this.particles.length > 0) {
        this.nextFrame = window.requestAnimationFrame(this.animate.bind(this));
      } else {
        this.nextFrame = null;
      }
    },
  },
}
</script>

<style scoped>
.falling-numbers {
  display: inline-block;
  white-space: nowrap;
}
.negative {
  color: darkred;
}
.positive {
  color: darkgreen;
}
.number-text {
  display: inline-block;
  position: relative;
  z-index: 1;
}
.number {
  display: inline-block;
  position: relative;
}
.particle {
  z-index: 2;
  top: 0;
  left: 0em;
  font-size: 0.8em;
  position: absolute;
}
</style>