<template>
  <popover
    id="scene-search"
    :value="true"
    @update:value="$emit('update:value', $event)"
    @show="$emit('show', $event)"
    @keydown="handleKeypress"
    color-theme="mellow"
  >
    <div v-if="status">
      <div class="result action">
        <div class="row">
          <div class="icon"><i class="fa fa-plus-circle fa-spin" /></div>
          {{$t('scene_search_popup.creating-new-scene')}}
        </div>
      </div>
    </div>
    <div v-else ref="results" class="left relative">
      <div class="search-form padded fixed flex-row flex-align-stretch">
        <input
          class="filter-input"
          :placeholder="$t('scene_search_popup.type-to-search-scenes')"
          ref="typing"
          v-model="filter"
          @keydown="handleKeypress"
        >
        <a
          @click.prevent="$emit('show', false)"
          @keypress.prevent="$emit('show', false)"
          class="close-button"
        >
          &times;
        </a>
      </div>
      <div class="result action">
        <div class="row">
          <div class="icon"><i class="fa fa-plus-circle" /></div>
          <a
            class="create-link title stretch"
            @click.prevent="handleCreate"
            @keypress.prevent="handleCreate"
            tabindex="0"
          >
            {{ filter ? $t('scene_search_popup.create-new-scene-with-title', {title: filter}) : $t('scene_search_popup.create-new-scene') }}
          </a>
        </div>
      </div>
      <div class="result action" v-if="currentScene">
        <div class="row">
          <div class="icon"><i class="fa fa-unlink" /></div>
          <a
            class="unlink-link title stretch"
            @click.prevent="$emit('show', false); $emit('update:value', null)"
            @keypress.prevent="$emit('show', false); $emit('update:value', null)"
            tabindex="0"
          >
            {{$t('scene_search_popup.unlink-currentscenedtitle', {title: currentScenedTitle})}}
          </a>
        </div>
      </div>
      <div class="search left top" v-if="results">
        <div class="result" v-for="result of results.slice(0, max)" :key="result.item.id">
          <div class="row">
            <div class="dot static" :class="result.item.color" />
            <a
              class="scene-link title stretch"
              @click.prevent="$emit('show', false); $emit('update:value', result.item.id)"
              @keypress.prevent="$emit('show', false); $emit('update:value', result.item.id)"
              tabindex="0"
            >
              {{ result.item.title || sceneGetText(result.item).slice(0, 75) }}
            </a>
          </div>
        </div>
        <div class="row" v-if="max < results.length">
          <div class="result padded center">
            <a
              @click.prevent="max += 15"
              tabindex="0"
            >
              {{$t('scene_search_popup.load-more-scenes')}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </popover>
</template>

<script>
import Fuse from 'fuse.js';

const options = {
  keys: ['title', 'directions.title', 'directions.content', 'directions.label', 'color'],
  includeMatches: true,
  findAllMatches: true,
}

export default {
  name: 'SceneSearchPopup',
  props: {
    value: {type: [Number, String]},
    story: Object,
  },
  data() {
    return {
      status: null,
      filter: null,
      pending: null,
      results: null,
      max: 15,
      scenes: [],
    };
  },
  computed: {
    linked() { return this.scenes.find(s => s.id === this.value); },
    linkedTitle() {
      if (this.linked) {
        return this.linked.title || this.sceneGetText(this.linked);
      }
    },
    currentScene() { return this.scenes.find(s => s.id === this.value); },
    currentScenedTitle() {
      if (this.currentScene) {
        return this.currentScene.title || this.sceneGetText(this.currentScene);
      }
    },
  },
  watch: {
    async value() {
      this.filter = null;
      this.status = null;
      if (this.value) {
        this.loadScenes();
      }
    },
    filter() {
      this.max = 15;
      this.loadScenes();
    },
  },
  async mounted() {
    this.scenes = await this.story.rel_scenes.list();
    this.loadFuse();
    await this.$nextTick();
    const input = this.$refs.typing;
    if (input) {
      input.focus();
      input.select();
    }
  },
  methods: {
    async handleCreate() {
      const title = this.filter;
      this.status = 'creating...';

      // create new scene
      const scene = await this.story.createScene({title,});
      this.$emit('show', false);
      this.$emit("update:value", scene.id);
      await new Promise(r => setTimeout(r, 100));
      await this.$nextTick();

      // go to new scene
      this.$router.push({
        name: 'scene', params: { story: this.story.id, id: scene.id },
      });
    },
    loadFuse() {
      this.fuse = new Fuse(this.scenes, options);
      this.loadScenes();
    },
    loadScenes() {
      if (this.fuse && this.filter) {
        this.results = this.fuse.search(this.filter);
      } else {
        this.results = this.scenes.map(scene => {
          return {item: scene};
        }).reverse();
      }
    },
    handleKeypress(e) {
      if (e.key === 'Enter') {
        const el = this.$refs.results.querySelector('.result a');
        if (el) {
          el.click();
        }
      } else if (e.key === 'Escape') {
        this.$emit('show', false);
      }
    },
  },
}
</script>

<style scoped>
a {
  padding: 0;
}
.search {
  max-width: 100%;
}
.result {
  margin: 0;
}
.result a {
  padding: 2px;
  font-weight: bold;
}
.top {
  align-self: flex-start;
}
.label {
  background: var(--color2);
  color: var(--color2_text);
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
}
.title, .content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.icon {
  flex: 0 0 auto;
  padding: 5px;
  margin-right: 9px;
  font-size: 21px;
}
.action {
  padding: 5px;
  opacity: 0.8;
}
.search-form {
  align-items: center;
}
#app .search-form .close-button {
  display: inline-block;
  flex: 0 0 auto;
  margin: 0;
  font-size: 25px;
  height: 30px;
  width: 30px;
  padding: 0;
  place-self: flex-end;
  align-self: flex-start;
  text-align: right;
  margin: 0;
}
input {
  margin: 0;
}
</style>
