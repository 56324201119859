<template>
  <div class="image-content">
    <help-message name="image-clips-explanation">
      {{
        $t(
          "image_content.images-are-shown-in-the-foreground-the-story-will-wait-for-the-user-to-click-the-image"
        )
      }}
    </help-message>
    <div
      v-if="image"
      @click.prevent="imagesPopup.show()"
      class="flex-center flex-align-center"
    >
      <blob-image v-if="image" :blob="image.blob_id" :thumb="true" />
    </div>
    <div v-else class="change">
      <button @click.prevent="imagesPopup.show()">
        {{ $t("image_content.change-image") }}
      </button>
    </div>
    <Popover ref="imagesPopup">
      <ImageList
        v-model:action="action"
        @update:id="emit('update', { content: $event })"
        :story="props.story"
        :id="props.value.content"
        :show-highlight="true"
      />
    </Popover>
  </div>
</template>

<script setup lang="ts">
import Popover from "@morphosis/base/components/Popover.vue";
import ImageList from "@/components/editor/storyObjects/lists/ImageList.vue";
import { ref, computed, watch } from "vue";

const props = defineProps({
  story: Object,
  value: Object,
});

const imagesPopup = ref(null);

const emit = defineEmits(["update"]);
const action = ref('list');
const image = computed(() =>
  props.story.images.find((a) => a.id == props.value.content)
);
watch(() => {
  if (action.value === 'select') {
    imagesPopup.value.hide();
    action.value = 'list';
  }
});
</script>

<style scoped>
.selected {
  display: flex;
  align-items: center;
  justify-content: center;
}
img {
  max-height: 250px;
  max-width: 250px;
}
</style>
