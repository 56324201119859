<template>
  <div class="redirect-content">
    <help-message name="redirect-direction">
      {{ $t('direction.redirect-help') }}
    </help-message>
    <choice-line
      :story="story"
      :scene="scene"
      :value="value"
      @change="update({scene: $event})"
    />
  </div>
</template>

<script>
import ChoiceLine from '../choice_line.vue';

export default {
  name: '',
  props: {
    story: Object,
    scene: Object,
    value: Object,
    advanced: Boolean,
  },
  data() {
    return {
    };
  },
  computed: {
    hasAdvanced() { return true; },
  },
  components: {
    ChoiceLine,
  },
  methods: {
    update(value) {
      this.$emit('update', value);
    },
  },
}
</script>

<style scoped>
.redirect-content {
  padding-bottom: 5px;
}
</style>
