import { fetch, put } from "@/utils/storage";
import { defineStore } from "pinia";
import { reactive, watch } from "vue";

export const useOptionStore = defineStore("options", () => {
  const options = reactive({
    showWordCount: fetch("show-word-count", false) as boolean,
  });
  const keys = [
    { name: "showWordCount", key: "show-word-count", default: false },
  ];
  for (const key of keys) {
    watch(
      () => options[key.name],
      () => {
        put(key.key, options[key.name]);
      },
    );
  }
  return {
    ...options,
  };
});
