import debug from "debug";

const log = debug("game");


export default {
  data() {
    return {
      gameAutoRejoin: false,
      gameId: null,
      players: {},
      state: {},
      shared: {},
    };
  },
  computed: {
    // players: {
    //   get() { return this.$store.state.players; },
    //   set(v) { this.$store.commit("players", v); }
    // },
    // state: {
    //   get() { return this.$store.state.state; },
    //   set(v) { this.$store.commit("state", v); }
    // },
    // shared: {
    //   get() { return this.$store.state.shared; },
    //   set(v) { this.$store.commit("shared", v); }
    // },
    blockedPlayers() {
      return this.shared.blocked || [];
    },
    playerIds() {
      return Object.keys(this.state).filter((p) => this.blockedPlayers.indexOf(p) === -1);
    },
    mystate() { return (this.state || {})[this.user.id] || {}; },
    allSteps() { return Object.values(this.state).map(p => p._step); },
    allReady() {
      let readyPlayers = this.allSteps.filter(v => v == this.shared._step).length;
      return this.playerIds.length > 0 && readyPlayers == this.playerIds.length;
    },
    iamHosting() {
      if (this.gameId && this.user) {
        return this.user.id == this.shared.owner;
      }
      return false;
    },
    iamBlocked() {
      if (!this.$root.user) {
        return false;
      }
      return this.blockedPlayers.indexOf(this.$root.user.id) > -1;
    },
  },
  watch: {
    gameId() {
      localStorage.gameId = this.gameId || "";
    },
    "shared._step"(v) {
      if (v && this._waitingForStep[v]) {
        this._waitingForStep[v].resolve();
      }
    },
    allReady(v) {
      if (v && this.shared._step && this._waitingForAll[this.shared._step]) {
        this._waitingForAll[this.shared._step].resolve();
      }
    },
  },
  created() {
    this.gameSynced = new Promise(r => this._gameSynced = r);
  },
  mounted() {
    this._waitingForAll = {};
    this._waitingForStep = {};

    this.$socket.on("connect", async () => {
      log("waiting for timeout");
      await new Promise(r => setTimeout(r, 1));
      log("waiting for login 2", this.loggedIn);
      let user = await this.loggedIn;
      const gameId = this.gameId || localStorage.gameId;
      if (user && gameId && this.gameAutoRejoin) {
        log("rejoining game");
        this.playerJoinGame(gameId);
      } else {
        log("no game (or user?), not joining", user, this.user, this.gameId);
      }
    });

    this.$socket.on("game", async (data) => {
      log("game update", data);
      if (data.event) {
        this.handleGameEvent(data.event, data.data)
      } else {
        for (let key of ["players", "state", "shared"]) {
          if (data[key]) {
            this[key] = data[key];
          }
        }
        if (!this.shared.owner && this.playerIds[0] == this.user.id) {
          await this.$sendwait("game_shared", {owner: this.user.id});
        }
      }
      this.$nextTick(this._gameSynced);
    });

    if (this.gameId) {
      this.$socket.emit("game", {});
    }
  },
  async unmounted() {
    this.$socket.off("game");
  },
  methods: {

    async handleGameEvent(event, data) {
      console.warn("unknown event", event, data);
    },

    async playerSendEvent(event, data) {
      return await this.$sendwait("game_event", {event, data});
    },

    /** Waits for all players to arrive at this state
     * Intended to be used only by the host's game logic */
    async serverWaitForStep(name) {
      let response = null;

      response = await this.$sendwait("game_shared", {_step: name});

      // TODO: validate response

      await new Promise((resolve, reject) => {
        if (this._waitingForAll[name]) {
          this._waitingForAll[name].reject();
        }
        this._waitingForAll[name] = {resolve, reject};
      });
    },

    /** start hosting a game */
    async serverHostGame() {
      let response = await this.$sendwait("game_create", {owner: this.user.id});

      // TODO: validate response
      if (! response) {
        throw new Error('Server returned empty');
      }

      this.gameId = response.id;
      response = await this.$sendwait("game_shared", {owner: this.user.id});
      response = await this.$sendwait("game_update", {username: this.user.username, connected: true});

      // TODO: validate response
      return this.gameId;
    },

    /** boot a player from the game */
    async blockPlayer(id) {
      if (this.iamHosting) {
        const blocked = this.blockedPlayers.slice();
        blocked.push(id);
        return this.$sendwait("game_shared", { blocked });
      }
    },

    /** changes a players state to this value */
    async playerChangeStep(name, data) {
      if (! data) {
        data = {};
      }
      data._step = name;
      return await this.$sendwait("game_update", data)
    },

    /** waits for the server to go to the next step */
    async playerWaitForStep(name) {
      await new Promise((resolve, reject) => {
        this._waitingForStep[name] = {resolve, reject};
      });
    },

    /** join an existing game by code */
    async playerJoinGame(code) {
      await this.$root.loggedIn;

      if (!this.$root.serverLoggedIn) {
        await this.$socket.ensureConnected();
        await this.$nextTick();
        await this.$root.loggedIn;
      }
      let response = await this.$sendwait("game_join", { id: code });
      // TODO: validate response

      response = await this.$sendwait("game_update", { username: this.user.username, connected: true });

      // TODO: validate repsonse
      if (response && response.error) {
        throw new Error(response.error);
      }

      this.gameId = code;
    },

    /** join an existing game by code */
    async playerLeaveGame(code) {
      log("leave game", code)
      await this.$sendwait("game_update", {username: this.user.username, connected: false});
      let response = await this.$sendwait("game_leave", {id: code});
      // TODO: validate repsonse
      this.gameId = null;
    },

    /** ask for updated game data */
    gameDataReload() {
      this.$socket.emit("game", {});
    },

    gameReset() {
      // this.gameId = undefined;
      // delete localStorage.gameId;
      this.shared = {};
      this.players = {};
      this.state = {};
    }
  }

};