<template>
  <div class="draggable-list" @move="$event.stopImmediatePropagation()">
    <slot name="header"></slot>
    <Sortable
      :list="array"
      :options="{
        animation: 150,
        group,
        handle: '.handle',
        forceFallback: true,
      }"
      @update="handleChange"
      tag="div"
      item-key="id"
    >
      <!-- @change.capture
      @choose.capture
      @unchoose.capture
      @start.capture
      @end.capture
      @add.capture
      @sort.capture
      @remove.capture
      @filter.capture
      @move.capture.stop
      @clone.capture -->
      <template #item="{ element: object, index: idx }">
        <div class="item">
          <slot name="item" :object="object" :index="idx"></slot>
        </div>
      </template>
    </Sortable>
    <slot name="footer"></slot>
  </div>
</template>

<script>
import { Sortable } from "sortablejs-vue3";
import copy from "../functions/copy";

export default {
  props: {
    value: Array,
    group: { default: 'draggables' },
  },
  components: {
    Sortable,
  },
  computed: {
    array: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('update:value', val);
      },
    },
  },
  methods: {
    handleStart(e) {
      this.$emit('start', e);
    },
    handleEnd(e) {
      this.$emit('end', e);
    },
    async handleChange(e) {
      if (e.item.closest('.draggable-list') == this.$el) {
        const array = copy(this.array);
        const item = array.splice(e.oldIndex, 1)[0];
        array.splice(e.newIndex, 0, item);
        this.$emit('update:value', array);
      } else {
        // this message is from a sub element
        console.log('ignoring from child', this.group, e);
      }
    },
  },
};
</script>

<style scoped>
</style>
