import expr from "expr-eval";
import safe from "safe-regex";
import sortby from "sort-by";
import Twig from "twig";

import { StoryTag, effects, findTag, runFormula } from "./tag_effects";

export { findTag, runFormula };

export function shuffle(array) {
  const list = array.map((i) => ({ item: i, sort: Math.random() }));
  list.sort(sortby("sort"));
  return list.map((l) => l.item);
}

Twig.extend(function (Twig) {
  Twig.exports.extendTag({
    // unique name for tag type
    type: "player",
    // regex match for tag (flag white-space anything)
    regex: /^ ?player +(.+) ?$/,
    // this is a standalone tag and doesn't require a following tag
    next: [],
    open: true,

    // runs on matched tokens when the template is loaded. (once per template)
    compile: function (token) {
      var prop = token.match[1];
      token.stack = {
        name: "prop",
        value: Twig.expression.compile.apply(this, [
          {
            type: Twig.expression.type.expression,
            value: prop,
          },
        ]).stack,
      };
      delete token.match;
      return token;
    },

    // Runs when the template is rendered
    parse: function (token, context, chain) {
      // parse the tokens into a value with the render context
      const name = token.stack.name;
      const value = Twig.expression.parse.apply(this, [
        token.stack.value,
        context,
      ]);
      context[name] = value;
      return {
        chain: false,
        output: "${player." + value + "}",
      };
    },
  });
  Twig.exports.extendTag({
    // unique name for tag type
    type: "settag",
    // regex match for tag (flag white-space anything)
    regex: /^settag\s+"([^"]+)"\s+(.+)$/,
    // this is a standalone tag and doesn't require a following tag
    next: [],
    open: true,

    // runs on matched tokens when the template is loaded. (once per template)
    compile: function (token) {
      var name = token.match[1];
      var value = token.match[2];

      // Compile the expression. (turns the string into tokens)
      token.stack = {
        name,
        value: Twig.expression.compile.apply(this, [
          {
            type: Twig.expression.type.expression,
            value: value,
          },
        ]).stack,
      };

      delete token.match;
      return token;
    },

    // Runs when the template is rendered
    parse: function (token, context, chain) {
      // parse the tokens into a value with the render context
      const name = token.stack.name;
      const value = Twig.expression.parse.apply(this, [
        token.stack.value,
        context,
      ]);
      document.dispatchEvent(
        new CustomEvent("tag", { detail: { name, value } })
      );
      context[name] = value;
      return {
        chain: false,
        output: "",
      };
    },
  });
});

const fontAdjust = {
  griffinregular: 1.2,
  konstytucyjamedium: 1.7,
  architects_daughterregular: 0.9,
  calligraffitiregular: 1.2,
};

export function textStyle(style, extras) {
  const retval = {
    color: "black",
    background: "white",
    fontFamily: "literata_bookregular",
    fontSize: "1em",
    textShadow: "0 0 5px white",
    boxShadow: "",
    border: "",
    align: "left",
  };
  if (extras) {
    Object.keys(extras).forEach((key) => {
      if (extras[key]) {
        retval[key] = extras[key];
      }
    });
  }
  if (style) {
    if (style.foreground) {
      retval.color = style.foreground;
    }
    if (style.gradient) {
      switch (style.gradient) {
        case "left":
          retval.background = `linear-gradient(to left, ${style.background}, ${style.background2})`;
          break;
        case "right":
          retval.background = `linear-gradient(to right, ${style.background}, ${style.background2})`;
          break;
        case "top":
          retval.background = `linear-gradient(to top, ${style.background}, ${style.background2})`;
          break;
        case "bottom":
          retval.background = `linear-gradient(to bottom, ${style.background}, ${style.background2})`;
          break;
        default:
        case "bloom":
          retval.background = `radial-gradient(ellipse at 65% 65%, ${style.background}, ${style.background2})`;
          break;
      }
    } else if (style.background) {
      retval.background = style.background;
    }
    if (retval.background) {
      retval["--style-bg"] = retval.background;
      retval["background"] = "var(--style-bg)";
    }
    if (style.size) {
      const adjust = fontAdjust[style.font] || 1;
      retval.fontSize = `calc(${style.size} * ${adjust})`;
    }
    if (style.shadow) {
      retval.textShadow = `0 0 ${style.shadowLength || "5px"} ${
        style.shadow || "white"
      }`;
    }
    if (style.font) {
      retval.fontFamily = style.font;
    }
    if (style.border) {
      retval["--border-width"] = style.border;
      retval["--border-color"] = style.borderColor;
      retval.border = `var(--border-width, 0) solid var(--border-color, transparent)`;
    }
    if (style.borderShadow) {
      retval.boxShadow = `0 0 ${style.borderShadow} 1px ${style.borderShadowColor}`;
    }
    if (style.align) {
      retval["text-align"] = style.align;
    }
  }
  return retval;
}

export function copy(o) {
  if (o) {
    return JSON.parse(JSON.stringify(o));
  }
  return o;
}

export function insertTags(textPrimordial, story, tags, startContext = null) {
  const context = startContext || {};
  let textModified = textPrimordial || "";
  for (const tag in tags) {
    const conf = story.tags.find((t) => t.id === tag);
    if (/^\$/.test(tag)) {
      const safeName = tag.replace("$", "__");
      const regContent = tag.slice(1);
      textModified = textModified.replace(
        new RegExp(`\\$${regContent}\\b`),
        safeName
      );
      context[safeName] = tags[tag];
    } else if (conf) {
      let safeName = conf.name.trim().replace(/[^a-z^0-9^_]/gi, "_");
      if (/^[0-9]/.test(safeName)) {
        safeName = "_" + safeName;
      }
      try {
        textModified = textModified.replace(
          new RegExp(`\\b${conf.name}\\b`),
          safeName
        );
      } catch (ex) {
        console.warn("failed to regex this", conf.name);
      }

      // adding reactive variables
      try {
        const regex = new RegExp(`@${conf.name}@`, "g");
        textModified = textModified.replace(regex, `@${conf.id}@`);
      } catch (ex) {
        console.warn("failed to regex this", conf.name);
      }

      if (conf.type === "ref") {
        let result = tags[tag];
        if (typeof result === "string") {
          result = StoryTag.traverse(tags, result.split("."));
        }
        context[safeName] = result;
      } else {
        context[safeName] = tags[tag];
      }
      // } else {
      //   console.log('missing tag', tag);
    }
  }

  try {
    // console.log(`Insert tags: "${textPrimordial}"`);
    // console.log(`Insert modified template: "${textModified}"`);
    // console.log(`Tags:`, tags);
    // console.log(`Context:`, context);
    const t = Twig.twig({
      data: textModified,
      strict_variables: false,
      rethrow: true,
    });
    const result = t.render(context);
    // console.log(`Result: "${result}"`);
    return result;
  } catch (ex) {
    let message = ex.message;
    const parts = message.split("near");
    if (parts.length > 1) {
      const near = ex.message.split("near")[1];
      message = `Failed to render text: near ${near}`;
    }
    window.app.addAlert({
      title: "Text Parse Error",
      level: "error",
      message,
    });
    return `$#$${message}$#$`;
  }
}

export function runSimpleFormula(formulaText, tags = {}) {
  try {
    const parser = new expr.Parser({
      operators: {
        assignment: true,
      },
    });
    parser.functions.array = (len) => new Array(len).fill(0);

    let formula = parser.parse(formulaText);

    // find variables
    const args = copy(tags);
    for (const v of formula.variables()) {
      // random
      if (/^\$RAND(\d+)/.test(v)) {
        const match = /^\$RAND(\d+)/.exec(v);
        args[v] = Math.floor(Math.random() * (parseInt(match[1]) + 1));
      }
    }

    // console.log('result start', formulaText, args);
    const resultValue = formula.evaluate(args);

    return resultValue;
  } catch (ex) {
    console.error("formula error", ex);
    return null;
  }
}

export function applyEffect(story, effect, tags) {
  if (effects[effect.op]) {
    // let tag = findTag(story, effect.tag, tags);
    const tag = StoryTag.generate(story, tags, effect.tag);
    if (!tag) {
      console.log("tag not found", effect.tag, effect);
      return;
    }
    effects[effect.op](story, tags, effect);
  }
}

export function shouldShowDirection(story, direction, tags) {
  // never show notes
  if (direction.type === "notes") {
    return false;
  }

  // show array empties always
  if (direction.type === "empty" && direction.array) {
    return true;
  }

  const effectList = (direction.effects || []).filter(
    (r) =>
      (r.op === "<t>" || (r.op && r.tag)) && effects.list.indexOf(r.op) === -1
  );
  for (const effect of effectList) {
    const { value } = effect;
    const nvalue = parseInt(
      effect.value === null || effect.value === undefined ? 1 : effect.value,
      10
    );
    const storytag = effect.tag
      ? StoryTag.generate(story, tags, effect.tag)
      : null;
    const tag = storytag ? storytag.value : 1;
    const ntag = parseInt(tag, 10) || 0;
    let other = null;
    switch (effect.op) {
      case "#":
        // console.log("has tag?", storytag._tag.name, !!tag, tag, typeof tag);
        if (!tag) {
          return false;
        }
        break;
      case "!":
        // console.log("not tag?", !(!!tag), tag);
        if (tag) {
          return false;
        }
        break;
      case ">":
        // console.log("greater than tag?", tag, !(ntag < nvalue), ntag, nvalue);
        // returns false, so reversed sign
        if (ntag < nvalue) {
          return false;
        }
        break;
      case "<":
        // console.log("less than tag?", !(tag > value), tag, nvalue);
        // returns false, so reversed sign
        if (ntag > nvalue) {
          return false;
        }
        break;
      case "=":
        // console.log("equals tag?", !(tag != value), tag, nvalue);
        if (ntag != nvalue) {
          return false;
        }
        break;
      case "!=":
        // console.log("not tag?", !(!!tag), tag);
        if (`${tag}`.toLowerCase() === `${value}`.toLowerCase()) {
          return false;
        }
        break;
      case "==":
        if (`${tag}`.toLowerCase() != `${value}`.toLowerCase()) {
          return false;
        }
        break;
      case "[v]":
        // console.log("contains?", !new RegExp(value, 'gi').test(tag), tag, value, safe(value));
        if (safe(value) && !new RegExp(value, "gi").test(tag || "")) {
          return false;
        }
        break;
      case "[^]":
        // console.log("doesn't contain?", new RegExp(value, 'gi').test(tag), tag, value, safe(value));
        if (safe(value) && new RegExp(value, "gi").test(tag || "")) {
          return false;
        }
        break;
      case "<=>":
        other = StoryTag.generate(story, tags, effect.value);
        // console.log("values match?", !(tag != value), tag, value);
        if (tag != other.value) {
          return false;
        }
        break;
      case "<!>":
        other = StoryTag.generate(story, tags, effect.value);
        // console.log("values don't match?", !(tag != other.value), effect.value, tag, other.value, tags);
        if (tag == other.value) {
          return false;
        }
        break;
      case "<>>":
        other = StoryTag.generate(story, tags, effect.value);
        if (tag < other.value) {
          return false;
        }
        break;
      case "<<>":
        other = StoryTag.generate(story, tags, effect.value);
        if (tag > other.value) {
          return false;
        }
        break;
      case "<t>":
        // testing formula
        const result = runFormula(story, value, tags);
        // console.log('<t>', value, '=>', result);
        if (result === false || result <= 0) {
          return false;
        }
        break;
      case "[ ]":
        if (tag && tag.length > 0) {
          return false;
        }
        break;
      case "[>]":
        if (tag && tag.length <= nvalue) {
          return false;
        }
        break;
      case "[*]":
        if (tag && tag.length === 0) {
          return false;
        }
        break;
      default:
    }
  }
  return true;
}

export function directionWillModify(direction) {
  const deffects = direction.effects || [];
  for (const effect of deffects.filter((r) => r.op && r.tag)) {
    if (effects.list.indexOf(effect.op) > -1) {
      return true;
    }
  }
  return false;
}
