<style scoped>
</style>


<template>
  <div class="image-content">
    <help-message name="grouped-directions">
      {{ $t('group_content.this-will-bundle-the-following-directions-of-the-same-type-and-randomly-show-one-of-them') }}
    </help-message>
    <help-message name="grouped-directions-probabilties">
      <i class="fa fa-cube" />
      {{ $t('group_content.tap-on-the-cube') }}
    </help-message>
    <help-message name="grouped-directions-zero-omission">
      {{ $t('group_content.directions-with-a-zero-value-probability') }} <i class="fa fa-cube" />
      {{ $t('group_content.will-only-be-used-if-no-other-direction-matches') }}
    </help-message>
  </div>
</template>

<script>
export default {
  name: "GroupContent",
}
</script>
