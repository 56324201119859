<template>
  <searching-modal
    :config="{
      keys: ['name'],
      includeMatches: true,
      findAllMatches: true,
    }"
    :options="options"
    @show="$emit('show', $event)"
    @update:value="$emit('update:value', $event)"
    @action="$emit('action', $event)"
  >
    <template v-slot:header>
      <div class="flex-column">
        <div class="flex-row flex-center" v-if="type === 'music'">
          <b>{{ $t('audio_modal.select-music') }}</b>
        </div>
        <div class="flex-row flex-center" v-else-if="type === 'audio'">
          <b>{{ $t('audio_modal.select-audio-clip') }}</b>
        </div>
        <div class="accented card padded">
          <tab-switch
            v-model:value="tab"
            :tabs="{upload: 'Upload', url: 'URL'}"
          />
          <div class="flex-column flex-align-top" v-if="tab === 'upload'">
            <input type="file" class="flex-static" ref="upload" :accept="`audio/*`" @change="uploadClip" />
            <label class="flex-align-center">
              <i class="fa fa-cloud" /> <span class="padded">{{ $t('media_uploader.send-to-cloud') }}</span>
              <input type="checkbox" v-model="cloud" />
            </label>
          </div>

          <div class="padded" v-else-if="tab === 'url'">
            <input
              type="url"
              :value="permalink"
              :placeholder="$t('audio_modal.url-permalink')"
              @change="importAsset"
            />
          </div>
        </div>
      </div>
      <div v-if="match">
        <div class="accented padded">
          <div class="name">
            {{ match.name }}
          </div>
        </div>
      </div>
    </template>
    <template v-slot:pre-search="">
    </template>
    <template v-slot:default="{result}">
      <div class="name">
        {{ result.item.name }}
      </div>
      <div class="flex-stretch" />
      <a v-if="result.item.id" @click.capture.prevent="handleDelete(result.item)">
        &times;
      </a>
      <a @click.prevent v-else>&mdash;</a>
    </template>
    <template v-slot:footer>
    </template>
  </searching-modal>
</template>

<script>
import SearchingModal from './searching_modal.vue';
import { nanoid } from "nanoid";

export default {
  name: 'AudioModal',
  components: {
    SearchingModal,
  },
  props: {
    story: Object,
    audio: Object,
    type: { type: String, default: 'music' },
  },
  data() {
    return {
      permalink: '',
      tab: '',
      cloud: this.story.cloudSync,
    };
  },
  computed: {
    options() {
      let retval = [
        {
          id: null,
          name: this.$t('music_content.silence'),
          blob_id: null,
        },
      ];
      for (const object of this.story[this.type]) {
        retval.push({
          id: object.id,
          name: object.name,
          blob_id: object.blob_id,
        });
      }
      if (this.audio) {
        const index = retval.findIndex(o => o.id === this.audio.content);
        if (index > -1) {
          const top = retval.splice(index, 1);
          retval = top.concat(retval);
        }
      }
      return retval;
    },
    match() {
      return this.audio && this.story[this.type].find(o => o.id === this.audio.content);
    },
  },
  methods: {
    handleUpload(e) {
      const content = {
        id: nanoid(),
        blob_id: e.blob_id,
        permalink: e.permalink,
        name: e.name,
      }
      this.story[this.type].push(content);
      // this.story.saveAndPush();
      this.$emit('update', {content: content.id})
    },
    handleDelete(object) {
      const idx = this.story[this.type].findIndex(m => m.id === object.id);
      if (idx > -1 && confirm(this.$t('app.delete-this-object-name', { name: object.name }))) {
        this.story[this.type].splice(idx, 1);
      }
    },
    async uploadClip(e) {
      let file = e.target.files[0];
      await this.story.uploadFile(this.type, file);
      this.tab = null;
    },
    async importAsset(evt) {
      let url = this.fixCDNPermalink(evt.target.value);
      await this.story.proxyFile(this.type, url);
    },
  }
}
</script>

<style scoped>
.accented {
  border-radius: 15px;
}
.grid2 {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-content: flex-start;
}
.grid2 > * {
  padding: 10px;
}
.name {
}
</style>