<style scoped>
  h3 {
    padding: 0;
    margin: 10px;
    margin-bottom: 5px;
    font-weight: bold;
  }
  .scroll a {
    padding: 0;
  }
  #app p {
    text-align: left;
  }
  #app li {
    padding: 10px;
    margin: 0;
    display: list-item;
    text-align: left;
    border-bottom: 1px solid var(--color3);
  }
</style>

<template>
  <div v-if="story">
    <div class="no-scroll">
      <app-header :show-menu="false">
        <router-link :to="{name: 'story', params: {story: story.id}}"><i class="fa fa-arrow-left" /></router-link>
        <p>Publish `{{ story.title }}`? <span v-if="story.fid">(<i>{{ story.fid }}</i>)</span></p>
      </app-header>
      <div class="scroll">
        <div v-if="!user.email" class="accented padded static">
          <p>
            We recommend that you register an email on your user account before publishing.
          </p>
          <ul>
            <li>
              Only this account can update or remove this story,
              adding an email makes it possible to <b>recover your account</b>
            </li>
            <li>
              You can include an endcard with links to your donation or
              social media sites by adding them to your account too
            </li>
          </ul>
          <div class="row">
            <button @click="$router.push('/account')" class="static">
              Update User Profile
            </button>
            <div class="spacer" />
          </div>
        </div>

        <div class="mellow padded">
          <h3>Some Rules Before Publishing:</h3>
          <ul>
            <li v-for="(row, idx) of requirements" :key="idx" class="rule-item">
              <input disabled :checked="row.checked" type="checkbox">
              <router-link v-if="row.link" :to="row.link">{{ row.content }}</router-link>
              <span v-else>{{ row.content }}</span>
            </li>
            <li>
              <div v-if="!story.fid">Publishing will allow others to read and review your story.</div>
              If your story has content that is not suitable for children, include a warning at the beginning of the story or description (or both).<br>
              Please <b>do not publish</b> stories without utility to readers, <b>publish when you have real content to share</b>.<br>
              Useless content will be removed. Specifically this includes stories that are:
                trolling,
                written for an overly specific audience,
                malicious,
                tests or
                nonsense
                <br><br>
              Stories must be less than 20mb to upload.<br><br>
              Media depicting explicit nudity or real-world violence is not allowed.<br>
              Do not solicit personal information from readers<br>
              <hr>
              <input type="checkbox" v-model="termsAccepted"> My story adheres to these terms
            </li>
          </ul>
          <h3>Some Advice before publishing:</h3>
          <ul>
            <li>
              <router-link :to="{name: 'export-backup', params: {story}}"><b>Save your own backup!</b></router-link>
              Stories will be deleted after about 30 days of inactivity (downloads count as activity)</li>
            <!-- <li v-if="!$root.user.email || !/@/.test($root.user.email)">
              <router-link :to="{name: 'account'}"><b>Add an email to your user account</b></router-link>.
              Your user account is required to update this story. You can recover your account with an email</li> -->
            <li>A cover image makes a big difference, but make sure you have rights to the image</li>
            <li>Have friends and family review your story and redraft until it's perfect</li>
          </ul>
        </div>

        <div v-if="!meetsRequirements" class="accented static padded">
          Please fix the above requirements before attempting to publish
        </div>
        <div v-else class="accented static">
          <form @submit.prevent="handlePublish" v-if="user && !status">
            <div v-if="error" class="error">{{ error }}</div>
            <div class="row">
              <button v-if="! story.fid">Publish</button>
              <button v-else>Update</button>
              <div class="spacer" />
              <router-link :to="{name: 'story', params: {story: story.id}}">Cancel</router-link>
            </div>
          </form>
          <div v-else-if="status === 'published'">
            <p><b>Your story has been published!</b></p>
            <p>
              Please consider making a donation to help keep our servers running.
              You'll get some perks too.
            </p>
            <div><router-link :to="{ name: 'donate' }" class="button">Donate</router-link></div>
          </div>
          <div v-else>
            <p>
              Waiting for server... <i class="fa fa-refresh fa-spin" />
            </p>
          </div>
          <ul v-if="history.length > 0">
            <li
              v-for="(row, idx) of history.slice().reverse()" :key="`row-${idx}`"
              :class="`msg-${row.level}`"
            >
              - {{ row.message }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div v-else>{{$t('app_status.loading')}}</div>
</template>

<script>
export default {
  name: 'StoryPublish',
  props: {
    story: Object,
  },
  data() {
    return {
      error: null,
      history: [],
      status: null,
      wordCount: 0,
      termsAccepted: this.fetch('publish-terms-accepted', false),
    };
  },
  watch: {
    termsAccepted() {
      this.put('publish-terms-accepted', this.termsAccepted);
    },
  },
  async mounted() {
    this.$socket.on('post:status', async (d) => {
      this.addToLog(d.message);
      if (d.id && !this.story.fid) {
        this.story.fid = d.id;
        await this.story.saveAndPush()
      }
    });
    this.wordCount = await this.story.countSceneWords();
  },
  unmounted() {
    this.$socket.off('post:status');
  },
  computed: {
    user() { return this.$root.user; },
    requirements() {
      const title = this.story.title.trim().length;
      const description = this.story.short_description || '';
      const shortDescription = this.story.short_description || '';
      return [
        {
          checked: title <= 128 && title >= 4,
          content: 'Title must be between 4 and 128 characters',
          link: { name: 'story-detail', params: { story: this.story }}
        },
        {
          checked: shortDescription.length >= 15 && shortDescription.length <= 80,
          content: 'Short description must be between 15 and 80 characters',
          link: { name: 'story-detail', params: { story: this.story }}
        },
        {
          checked: description.length >= 15 && description.length <= 1000,
          content: 'Description must be between 15 and 1000 characters',
          link: { name: 'story-detail', params: { story: this.story }}
        },
        {
          checked: this.wordCount > 150,
          content: `Story must have a minimum of 150 words (You have ${this.wordCount} words)`,
          link: { name: 'scene', params: { story: this.story }}
        },
      ];
    },
    meetsRequirements() {
      return this.termsAccepted && this.requirements.map(r => r.checked).every(r => r);
    },
  },
  methods: {
    addToLog(msg, level='info') {
      this.history.push({ message: msg, level });
    },
    async handlePublish() {
      this.history = [];
      this.error = null;
      this.status = 'uploading';
      this.addToLog('compressing story');
      await this.story.saveJson(true);

      try {
        this.addToLog('uploading to server');
        await this.story.publish(false);
        this.status = 'published';
        this.addToLog('upload is complete!', 'success');
      } catch (ex) {
        this.addToLog(`${ex}`, 'error');
        console.error(ex);
        this.error = ex;
        this.status = null;
      }
    },
  },
};
</script>

<style>
.rule-item a {
  white-space: normal;
  display: inline;
}
  li a {
    display: inline;
    font-size: 100%;
  }
  .msg-error {
    font-weight: bold;
  }
  .msg-success {
    background: var(--spark);
    color: var(--spark_text);
  }
</style>
