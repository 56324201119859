<style scoped>
  .input-array {
    width: 100%;
    flex: 1 1 auto;
  }
  .input-array > div {
    display: flex;
    flex-direction: row;
  }
  .input-array a {
    font-size: 25px;
    cursor: pointer;
  }
  .new-entry * {
    font-size: .9em;
    opacity: .7;
  }
  textarea {
    flex: 1 1 auto;
  }
</style>


<template>
  <div class="input-array">
    <div v-for="(v, i) of data" :key="i">
      <AutogrowText
        v-model:modelValue="data[i]"
        @update:modelValue="update(data)"
        :class="{last: i == data.length - 1}"
      />
      <a @click="handleRemove(i)">&times;</a>
    </div>
    <div class="new-entry">
      <a>+</a>
      <input
        v-model="newEntry"
        :placeholder="$t('thought_array.type-here-to-add-a-thought')"
        key="new"
        type="text"
      >
    </div>
  </div>
</template>

<script>
import AutogrowText from './editor/autogrow_text.vue';

export default {
  name: "input-array",
  components: {
    AutogrowText,
  },
  props: {
    value: Array
  },
  data() {
    return {
      data: this.copy(this.value || []),
      newEntry: null
    };
  },
  watch: {
    value: {
      handler() {
        this.data = this.copy(this.value);
      },
      deep: true,
    },
    data() {
      // this.updateEntries();
    },
    async newEntry() {
      if (this.newEntry) {
        let entries = this.data;
        entries.push(this.newEntry);
        this.update(entries);
        await this.$nextTick();

        this.$el.querySelector(".last textarea").focus();
        this.newEntry = null;
      }
    },
  },
  methods: {
    handleRemove(i) {
      this.data.splice(i, 1);
      this.update(this.data);
    },
    update(e) {
      this.$emit("update:value", this.copy(e));
    }
  }
}
</script>
