import { Delta, create as patchCreate } from "jsondiffpatch";
import localforage from "localforage";
import { nanoid } from "nanoid";
import { defineStore } from "pinia";
import { type Ref, ref, watchEffect, WatchSource } from "vue";
import copy from "../functions/copy";
import { http } from "../utils/api";
import { createStorageAdapter, Model } from "./create_storage";
import { useToasterStore } from "./toaster";

export interface ServerRecord<T extends Model> {
  key: string;
  updated: string;
  public?: boolean;
  model?: string;
  level?: string;
  byte_size?: number;
  object?: T;
  editors?: string[];
  content?: string;
  url?: string;
}

export function createStore<T extends Model>(
  name: string,
  options: {
    endpoint?: string;
    prefix?: string;
    migrate?: (a: Partial<T>) => T;
  } = {}
) {
  const prefix = options.prefix ? options.prefix + ":" : "";
  return defineStore(prefix + name, () => {
    const toast = useToasterStore();

    const mirror = localforage.createInstance({ name: prefix + name + ":server" });
    const { store, list, updated, ready } = createStorageAdapter<T>(name, {
      prefix: options.prefix,
      migrate: options.migrate,
    });
    const migrate = options.migrate || ((a: Partial<T>) => a as T);
    const endpoint = options.endpoint ?? name;

    const differ = patchCreate({
      objectHash(obj: T) {
        return obj.id;
      },
      arrays: {
        detectMove: true,
        includeValueOnMove: true,
      },
    });

    async function getIds(): Promise<{ [key: string]: T }> {
      const ids: { [key: string]: T } = {};
      await list.value.forEach((item: T) => (ids[item.id] = item));
      return ids;
    }

    async function getServerIds(): Promise<{ [key: string]: ServerRecord<T> }> {
      const ids: { [key: string]: ServerRecord<T> } = {};
      await mirror.iterate((item: ServerRecord<T>) => {
        ids[item.key] = item;
      });
      return ids;
    }

    async function pushAll(): Promise<ServerRecord<T>[]> {
      return Promise.all(list.value.map((item) => push(item)));
    }

    async function push(object: T): Promise<ServerRecord<T>> {
      if (!object.id) {
        object.id = nanoid();
      }

      const server: ServerRecord<T> = await mirror.getItem(object.id);
      if (server?.level === "viewer") {
        console.warn("you cannot push as a viewer");
      }

      // if (typeof (object.updated) === 'number') {
      //   object.updated = new Date(object.updated);
      // }
      // object.updated = new Date().toISOString();

      try {
        const response = await http({
          event: "db_push",
          data: {
            id: object.id,
            model: endpoint,
            updated: object.updated,
            client: localStorage.clientid,
            content: JSON.stringify(object),
          },
        });
        if (response.error) {
          console.error("server error", object, response);
          if (response.code === "nsfw") {
            toast.add({
              title: "Server save failed moderation",
              message: response.error,
              level: "error",
            });
          } else {
            toast.add({
              title: "Server save failed",
              message: response.error,
              level: "error",
            });
          }
        }
        const record: ServerRecord<T> = {
          key: object.id,
          updated: object.updated || "",
          object: copy(object),
        };
        await mirror.setItem(object.id, record);

        return response;
      } catch (ex) {
        console.error("Failed to push", ex);
      }
      toast.add({
        title: "Sync problem",
        message: `Failed to push to server (${endpoint}:${object.id})`,
        level: "error",
      });
      throw new Error("failed to push");
    }

    async function pullHeader(id: string): Promise<ServerRecord<T>> {
      try {
        const response = await http({
          event: "db_pull",
          data: {
            id: id,
            model: endpoint,
          },
        });
        // console.log("pull", id, response);
        if (response.ok) {
          const record: ServerRecord<T> = response.record;
          record.object = JSON.parse(record.content);
          return record;
        } else {
          throw new Error(response.error);
        }
      } catch (ex) {
        console.error("Failed to push", ex);
      }
      toast.add({
        title: "Pull failed",
        message: `Failed to pull data form server`,
        level: "error",
      });
      throw new Error("failed to pull");
    }

    async function pull(id: string): Promise<ServerRecord<T>> {
      const record: ServerRecord<T> = await pullHeader(id);
      // console.log("pull", id, response);
      const match = list.value.find((item) => item.id === record.key);
      if (match) {
        for (const key in record.object) {
          match[key] = record.object[key];
        }
      } else {
        // console.log("pull pushing object", record.object);
        list.value.push(record.object);
      }
      await mirror.setItem(record.key, record);
      return record;
    }

    async function destroy(id: string): Promise<boolean> {
      try {
        const response = await http({
          event: "db_destroy",
          data: {
            id: id,
            model: endpoint,
          },
        });
        await mirror.removeItem(id);
        return response.ok;
      } catch (ex) {
        console.log("error", ex);
        console.error("Failed to push", ex);
      }
      console.log("failed to destroy");
      toast.add({
        title: "Destroy failed",
        message: `Failed to destroy data from server`,
        level: "error",
      });
      throw new Error("failed to destroy");
    }

    async function pullAllHeaders(): Promise<ServerRecord<T>[]> {
      try {
        // console.warn("pull", endpoint);
        const response = await http({
          event: "db_list",
          data: {
            model: endpoint,
          },
        });
        return response.records;
      } catch (ex) {
        console.error("Failed to push", ex);
      }
      toast.add({
        title: "Pull failed",
        message: `Failed to pull data form server`,
        level: "error",
      });
      throw new Error("failed to pull");
    }

    async function sync() {
      const ids = await getIds();
      // console.log("syncing all...", clouds.length);

      // push deleted
      const serverIds = await getServerIds();
      await Promise.all(
        Object.keys(serverIds)
          .filter((id) => !ids[id])
          .map(async (id) => {
            console.log(" x> ", id, ids[id]);
            delete serverIds[id];
            await mirror.removeItem(id);
            return destroy(id);
          })
      );

      const clouds = await pullAllHeaders();
      await Promise.all(
        clouds.map(async (cloud) => {
          let instance = ids[cloud.key];
          if (instance) {
            const saved = await syncObject(instance, cloud, true);
            if (saved) {
              const index = list.value.findIndex((item) => item.id === cloud.key);
              list.value[index] = saved;
            }
            delete ids[cloud.key];
            // await mirror.setItem(cloud.key, cloud);
          } else {
            console.log(" <= ", cloud.key);
            const server = await pull(cloud.key);
            instance = server.object;
          }
          if (serverIds[cloud.key]) {
            delete serverIds[cloud.key];
          }
          return instance;
        })
      );

      // push unsaved
      await Promise.all(
        Object.keys(ids)
          .filter((id) => !serverIds[id])
          .map(async (id) => {
            console.log(" => ", id);
            await push(ids[id]);
            // await mirror.setItem(id, {
            //   key: id,
            //   updated: new Date().toISOString(),
            //   object: ids[id],
            // } as ServerRecord<T>);
            delete ids[id];
          })
      );

      // delete untouched entities
      await Promise.all(
        Object.keys(ids).map((key) => {
          const index = list.value.findIndex((item) => item.id === key);
          if (index > -1) {
            // console.log(" <x ", key);
            list.value.splice(index, 1);
            // } else {
            // console.log(" !! ", key, list.value);
          }
        })
      );

      // remove deleted entries
      await Promise.all(
        Object.keys(serverIds).map((key) => {
          // console.log(" xx ", key);
          return mirror.removeItem(key);
        })
      );
    }

    async function syncObject(object: T, serverObject: ServerRecord<T>, lazyPull = false) {
      let output: T = copy(object);
      let server: ServerRecord<T> = serverObject;
      const original: ServerRecord<T> | undefined = await mirror.getItem(object.id);

      // has this object changed?
      const originalDate = new Date(original?.updated || 1);
      const serverDate = new Date(server.updated || 1);
      const objectDate = new Date(object.updated || 1);
      const objectUpdated = objectDate > originalDate;
      const serverUpdated = serverDate > originalDate;

      // console.log(" ** needs merge?", originalDate, serverDate, objectDate);
      if (serverUpdated && objectUpdated && original) {
        // update with merge

        // TODO: this pull will destroy the current object
        if (!serverObject.object && lazyPull) {
          server = await pullHeader(object.id);
        }

        // console.log(" -- sync object...");
        // console.log(" --- original", original.object);
        // console.log(" --- server", server.object);
        // console.log(" --- object", object);

        const latestDate = new Date(
          Math.max(
            ...[output.updated, server.updated, original.updated]
              .filter((a) => a)
              .map((a) => new Date(a).getTime())
          ) || 0
        ).toISOString();

        const diff1 = differ.diff(original.object, output);
        const diff2 = differ.diff(original.object, server.object);

        let diff: Delta | undefined;
        if (serverDate > objectDate) {
          diff = await mergeDiffs(diff2, diff1);
        } else {
          diff = await mergeDiffs(diff1, diff2);
        }

        let output2: T = output;
        try {
          // console.log("patching", original.object, server.object, output, diff);
          output2 = differ.patch(original.object, diff);
          // console.log("diffing", output2);
        } catch (e) {
          output2 = serverDate > objectDate ? server.object : object;
          console.error(e);
        }

        output = output2;
        output.updated = latestDate;
        await push(output);
      } else if (serverUpdated) {
        if (serverObject && lazyPull) {
          server = await pull(object.id);
        }

        // console.log('server updated', server);

        // update with pull
        for (const prop in server.object) {
          output[prop] = server.object[prop];
        }
      } else if (objectUpdated) {
        // update with push
        await push(output);
      }
      // console.log('synced', output._server);

      return output;
    }

    async function mergeDiffs(a: Delta, b: Delta, key?: string): Promise<Delta> {
      // console.log("diff", key, "=>", a, "|", b);
      let retval: Delta = {};
      if (a === undefined) {
        retval = b;
      } else if (b === undefined) {
        retval = a;
      } else if (a instanceof Array) {
        retval = a;
        if (a.length === 1) {
          retval = [await mergeKey(key, null, a[0], b[0])];
        } else if (a.length === 2) {
          retval = [a[0], await mergeKey(key, a[0], a[1], b[1])];
        }
      } else {
        const keys = new Set(Object.keys(a).concat(Object.keys(b)));
        if (keys.has("_t")) {
          keys.delete("_t");
          retval._t = "a";
        }
        for (const sub of keys) {
          if (a[sub] && b[sub]) {
            // eslint-disable-next-line no-await-in-loop
            retval[sub] = await mergeDiffs(a[sub], b[sub], `${key || "$root"}.${sub}`);
          } else if (a[sub]) {
            retval[sub] = a[sub];
          } else if (b[sub]) {
            retval[sub] = b[sub];
          }
        }
        // console.log('change', a, b);
      }
      // console.log('result', retval);
      return retval;
    }

    function mergeKey(key: string, orig: any, a: any, b: any): any {
      const type = typeof (orig || a);
      // console.log("merge key", key, type, orig, "=>", a, "|", b);
      if (type === "string") {
        if ((!a && !b) || (a && !b)) {
          return a;
        }
        if (b && !a) {
          return b;
        }
        return a.length >= b.length ? a : b;
      }
      return a;
    }

    async function serverList() {
      const retval = [];
      await mirror.iterate((item: ServerRecord<T>) => {
        retval.push(item);
      });
      return retval;
    }

    function item(idSource: WatchSource<string>): Ref<T | undefined> {
      const value = ref<T | undefined>();
      watchEffect(() => {
        const id = typeof idSource === "function" ? idSource() : idSource.value;
        value.value = list.value.find((i) => i.id === id);
      });
      return value;
    }

    function get(id: string) {
      return {
        get: () => list.value.find((i) => i.id === id) || initialize({}),
        set: (v: T) => {
          const index = list.value.findIndex((i) => i.id === id);
          if (index !== -1) {
            list.value[index] = v;
          }
        },
      };
    }

    function initialize(a: Partial<T>) {
      if (!a.id) {
        a.id = nanoid();
      }
      if (!a.updated) {
        a.updated = new Date().toISOString();
      }
      a = migrate(a);
      return a as T;
    }

    async function create(a: Partial<T> = {}, temp = false) {
      const b = await initialize(a);
      if (!temp) {
        list.value.push(b);
      }
      return b;
    }

    async function remove(a: T): Promise<boolean> {
      const index = list.value.findIndex((i) => i.id === a.id);
      if (index !== -1) {
        list.value.splice(index, 1);
        return true;
      }
      return false;
    }

    return {
      prefix,
      updated,
      store,
      list,
      ready,
      get,
      item,
      create,
      initialize,
      remove,
      push,
      pushAll,
      pull,
      destroy,
      sync,
      serverList,
    };
  });
}
