<template>
  <div class="bug-report group">
    <b>{{ $t('story_reader.bug-report') }}</b>
    <div v-if="status">{{ status }}...</div>
    <form v-else @submit.prevent="submitBugReport">
      <textarea v-model="report"
                required
                :placeholder="$t('bug_report.please-describe-the-problem')"
                rows="5" />
      <div>
        <label for="email">
          {{ $t('story_reader.optional-developers-can-contact-me-here') }}
        </label>
        <input
          v-model="email"
          :placeholder="$t('bug_report.contact-email-address')"
          name="email" type="email"
        >
      </div>
      <div class="row">
        <button>{{ $t('feedback.submit') }}</button>
        <a @click.prevent="$emit('show', false)" href="#">{{ $t('story_reader.cancel') }}</a>
      </div>
      <p>{{ $t('story_reader.the-bug-report-will-include-a-copy-of-this-story') }}</p>
    </form>
  </div>
</template>

<script>
import { Base64 } from 'js-base64';


export default {
  name: 'BugReport',
  props: {
    story: { type: Object, required: false },
    storyId: { type: [String, Number], required: false },
    extra: { type: Object, default: null }
  },
  data() {
    return {
      status: null,
      report: '',
      email: this.$root.user.email,
    };
  },
  methods: {
    async submitBugReport() {
      let story = this.story;

      if (this.storyId && !story) {
        try {
          story = await this.loadFullStory(this.storyId);
        } catch (ex) {
          // ok i guess
        }
      }

      this.status = 'Sending...';
      const report = `
        user: ${this.$root.user.username} -- ${this.$root.user.id}<br>
        email: ${this.email}<br>
        version: ${this.$root.version}<br>
        platform: ${this.$root.platform}<br>
        feedback: ${this.report}<br>
        extras: ${JSON.stringify(this.extra)}
      `.trim();
      try {
        await this.ws('support_message', {
          subject: 'CYOA Bug Report',
          message: report,
          attachments: [
            {
              type: 'application/json',
              name: 'story_backup.json',
              data: Base64.encode(JSON.stringify(story)),
            },
          ],
        });
      } catch (ex) {
        console.error(ex);
      }
      alert(this.$t('story_reader.bug-submitted'));
      this.bstatus = '';
      this.report = '';
      this.$emit('show', false);
    },
  },
};
</script>

<style scoped>
.bug-report textarea {
  font-size: 20px;
}
.bug-report label {
  white-space: normal;
  display: block;
}
</style>