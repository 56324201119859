<style scoped>
.carousel-list {
  /* transform: translate3d(-100%, 0, 0); */
  display: flex;
  flex-direction: row;
  position: relative;
  touch-action: none;
  overflow: hidden;
}
.inner {
  display: flex;
  flex: 0 0 300%;
  flex-direction: row;
}
.inner.one {
  flex: 0 0 100%;
  width: 100%;
}
.inner > div {
  flex: 0 0 33.33334%;
  margin: 0;
  padding: 0;
}
.inner.one > div {
  flex: 0 0 100%;
}
.count {
  position: absolute;
  bottom: 5px;
  left: 5px;
  padding: 12px;
  font-size: 17px;
  color: white;
  background: black;
  opacity: 0.7;
  z-index: 10;
  border-radius: 5px;
}
.count.next {
  left: auto;
  right: 5px;
}
</style>

<template>
  <div class="carousel-list" v-if="list && list.length > 0">
    <div class="count next" v-if="legend && list.length > 1" @click="handleNext">
      <i class="fa fa-arrow-right" />
    </div>
    <div class="count" v-if="legend && list.length > 1">
      {{ index + 1 }} / {{ list.length }}
    </div>
    <div
      v-if="list.length > 1"
      :style="{ transform: `translate3d(${panning + panningNext - w}px, 0, 0)` }"
      class="inner"
    >
      <!-- v-touch:drag="handlePan" -->
      <div v-if="prev" :key="prev.id"><slot v-bind="{object: prev, prefix: 'prev'}" /></div>
      <div v-else></div>
      <div :key="current.id"><slot v-bind="{object: current, prefix: 'current'}" /></div>
      <div v-if="next" :key="next.id"><slot v-bind="{object: next, prefix: 'next'}" /></div>
    </div>
    <div class="inner one" v-else>
      <div><slot :object="current" :prefix="'solo'" /></div>
    </div>
  </div>
</template>

<script>

function nextFrame() {
  return new Promise(r => requestAnimationFrame(r));
}

export default {
  props: {
    list: Array,
    value: {default: 0},
    legend: {type: Boolean, default: true},
    handle: String,
  },
  data() {
    return {
      index: this.value,
      panning: 0,
      panningNext: 0,
      panningTarget: null,
      status: null,
      w: 0,
    }
  },
  computed: {
    prev() {
      if (this.index > 0) {
        return this.list[this.safeIndex(this.index - 1)];
      }
    },
    current() { return this.list[this.safeIndex(this.index)]; },
    next() {
      if (this.list.length - 1 > this.index) {
        return this.list[this.safeIndex(this.index + 1)];
      }
      return null;
    },
  },
  watch: {
    value() {
      this.index = this.value;
    },
    async list() {
      await this.$nextTick();
      if (this.$el.nodeName != '#comment') {
        let box = this.$el.getBoundingClientRect();
        this.w = box.width;
      }
    }
  },
  async mounted() {
    await this.$nextTick();
    if (this.$el.nodeName != '#comment') {
      let box = this.$el.getBoundingClientRect();
      this.w = box.width;
    }
  },
  methods: {
    safeIndex(idx) {
      if (idx >= 0) {
        return idx % this.list.length;
      } else {
        return this.list.length + idx;
      }
    },
    handleNext() {
      this.panTo(-this.w, -1);
    },
    handlePan(e) {
      if (!this.handle || e.target.closest(this.handle)) {
        this.status = null;
        if (e.isFinal) {
          this.panning += this.panningNext;
          this.panningNext = 0;
          if (Math.abs(this.panning) > this.w * 0.3) {
            let move = this.panning / Math.abs(this.panning);
            this.panTo(this.w * move, move);
          } else {
            this.panTo(0);
          }
        } else {
          this.panningNext = parseInt(e.deltaX);
        }
      }
    },
    async panTo(x, move=0) {
      let max = 1;
      this.status = "panning";
      this.panningTarget = parseInt(x);
      let duration = 150;
      let start = Date.now();
      let diff = this.panningTarget - this.panning;
      let panStart = this.panning || 0;
      let t = 0;

      while (this.status && t < 1) {
        this.panning = panStart + diff * t;
        await nextFrame();
        t = (Date.now() - start) / duration;
      }

      if (this.status) {
        this.panning = this.panningTarget;
        if (move) {
          this.index = this.safeIndex(this.index - move);
          await this.$nextTick();
          this.$emit("switch", this.index);
          this.panning = 0;
        }
        this.status = null;
      }
    }
  }
}
</script>
