<template>
  <div>
    <app-header :showMenu="false">
      <router-link :to="{name: 'home'}">
        <i class="fa fa-arrow-left" />
      </router-link>
      Fetching Story...
    </app-header>
    <p>
      <i class="fa fa-refresh fa-spin" /> Redirecting to story detail...
    </p>
  </div>
</template>

<script>
export default {
  name: 'shared',
  computed: {
    id() { return this.$route.params.id; },
  },
  async created() {
    this.$router.replace({ name: 'market-detail', params: { id: this.id } });
  },
};
</script>
