<style scoped>
#app button.confirm-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
  padding: 0;
  font-size: 23px;
  min-width: 35px;
  min-height: 35px;
  border-radius: 35px;
  background: var(--color2);
  color: var(--color2_text);
  white-space: nowrap;
}
#app button.confirm-button.confirming {
  font-size: 15px;
  padding-left: 10px;
}
#app a.confirm-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
}
#app a.confirm-button.confirming {
  font-size: 15px;
  padding-left: 10px;
  border-radius: 35px;
  background: var(--spark);
  color: var(--spark_text);
}
#app a.confirm-button.confirming .cancel {
  font-size: 15px;
  border-radius: 35px;
  background: var(--color3);
  color: var(--color3_text);
}
#app span, #app i {
  flex: 1 1 auto;
}
#app i.cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 0 0 auto;
  margin-left: 5px;
  padding-bottom: 5px;
  font-size: 23px;
  border-radius: 35px;
  min-height: 35px;
  min-width: 35px;
  background: var(--color1);
  color: var(--color1_text);
}
</style>

<template>
  <component :is="tag"
             key="button"
             class="confirm-button"
             :class="{confirming: confirming}"
             @click="handleClick"
             @blur="confirming = false">
    <span class="text">
      <slot v-if="!confirming"> &times; </slot>
      <slot v-else name="confirm">{{ $t('confirm_button.confirm') }}</slot>
    </span>
    <transition name="slidedown">
      <i v-if="confirming"
         class="cancel">
        <i class="fa fa-ban" />
      </i>
    </transition>
  </component>
</template>

<script>
export default {
  name: "confirm-button",
  props: {
    tag: {type: String, default: "button"},
    skip: {type: Boolean, default: false},
  },
  data() {
    return {
      confirming: false,
    }
  },
  methods: {
    handleClick(e) {
      e.preventDefault();
      if (e.target.closest(".text")) {
        if (!this.confirming && !this.skip) {
          this.confirming = true;
        } else {
          this.$emit("confirm", e);
          this.confirming = false;
        }
      } else {
        this.confirming = false;
      }
    }
  }
}
</script>