<template>
  <div v-if="lazy && !loaded" class="audio icon button">
    <vi
      name="unpackage"
      @click="
        loaded = true;
        getURL();
      "
    />
  </div>
  <audio
    v-else
    :autoplay="autoplay"
    :controls="controls"
    :autobuffer="autobuffer"
    :loop="loop"
    :oncanplaythrough="oncanplaythrough"
    :src="source"
    v-show="blob && source"
    :tabindex="tabindex"
    @click="$emit('click', $event)"
    @load="$emit('load', $event)"
    @canplaythrough="$emit('canplaythrough', $event)"
    @error="handleError"
  />
</template>

<script>
import VectorImage from "@morphosis/base/components/VectorImage.vue";
import Media from "../models/media";

export default {
  name: "BlobAudio",
  components: {
    vi: VectorImage,
  },
  props: {
    blob: String,
    loop: Boolean,
    controls: Boolean,
    autobuffer: Boolean,
    autoplay: Boolean,
    oncanplaythrough: Function,
    tabindex: String,
    lazy: { type: Boolean, default: false },
    volume: { type: Number, default: 1 },
    currentTime: { type: Number, default: 0 },
  },
  data() {
    return {
      source: null,
      instance: null,
      loaded: !this.lazy,
    };
  },
  watch: {
    async blob() {
      await this.getURL();
    },
    async currentTime() {
      this.$el.currentTime = this.currentTime;
    },
    volume() {
      this.$el.volume = this.volume;
    },
  },
  async mounted() {
    await this.getURL();
  },
  beforeUnmount() {
    if (this.$el.pause) {
      this.$el.currentTime = 0;
      this.$el.src = "";
      this.$el.pause();
    }
  },
  methods: {
    async getURL() {
      if (!this.lazy || this.loaded) {
        if (this.blob) {
          this.instance = await Media.load(this.blob);
          if (this.instance) {
            this.source = await this.instance.getUrl();
          }
        } else {
          this.instance = null;
          this.source = "";
        }
      }
    },
    pause() {
      this.$el.pause();
      this.$el.currentTime = 0;
    },
    play() {
      this.$el.play();
    },
    async handleError() {
      // TODO: reload this blob instead of // await Media.clearCachedUrls();
      this.source = await this.getURL();
    },
  },
};
</script>

<style scoped>
.audio {
  width: 35px;
  height: 35px;
  max-width: 35px;
  max-height: 35px;
  display: inline-flex;
}
</style>
