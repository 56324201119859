export function fixCDNPermalink(url: string): string {
  if (/www.dropbox/.test(url)) {
    url = url.replace("www.dropbox.com", "dl.dropbox.com");
  }
  if (/github.com/.test(url)) {
    const parts = url.split("/");
    const user = parts[3];
    const repo = parts[4];
    const path = parts.slice(7).join("/");
    url = `https://cdn.jsdelivr.net/gh/${user}/${repo}/${path}`;
  }
  return url;
}
