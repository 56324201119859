<style scoped>
</style>

<template>
  <div class="layout" key="layout">
    <div class="text-center muted">Previous directions will be cleared for the reader</div>
  </div>
</template>

<script>
export default {
  props: {
    story: Object,
    scene: Object,
    value: Object,
  },
};
</script>
