<template>
  <div>
    <div
      @click="$refs.modal.show()"
      :style="textStyle(style)"
      class="this-style text-center padded"
    >
      {{ style?.name || " - none - " }}
    </div>
    <popover ref="modal" includeCloseButton>
      <StyleList
        :story="story"
        :showHighlight="true"
        v-model:id="styleId"
        v-model:action="action"
        @update:id="handleId"
      />
    </popover>
  </div>
</template>

<script>
import StyleList from "@/components/editor/storyObjects/lists/StyleList.vue";
import Searcher from "../../components/editor/searcher.vue";
import Story from "../../models/story";

export default {
  props: {
    value: { type: String },
    story: { type: Story },
  },
  components: {
    Searcher,
    StyleList,
  },
  data() {
    return {
      action: "list",
    };
  },
  watch: {
    action() {
      if (this.action === "select") {
        this.$refs.modal.hide();
        this.action = "list";
      }
    },
  },
  computed: {
    styleId: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("update:value", v);
      },
    },
    style() {
      return this.story.styles.find((s) => s.id === this.value);
    },
  },
  methods: {
    handleId(e) {
      this.styleId = e;
    },
  },
};
</script>

<style scoped>
.this-style {
  max-width: 250px;
  cursor: pointer;
  border-radius: var(--softness);
}
</style>
