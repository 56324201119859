/* eslint-disable guard-for-in */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-syntax */
import BaseField from '../fields/base';

export default class Relation extends BaseField {

  constructor(model, fieldname=null) {
    super();
    this.model = model;
    this.fieldname = fieldname;
    this._cache = null;
  }

  get length() {
    return this.object.data[this.field].length;
  }

  get field() {
    return this.fieldname || this.key;
  }

  getValue() {
    return this;
  }

  clearCache() {
    this._cache = null;
  }

  async addToCache(object) {
    if (!this._cache) {
      await this.list();
    }
    if (!this._cache.find((o) => o.id === object.id)) {
      this._cache.push(object);
    }
  }

  async list() {
    if (!this._cache) {
      const ids = this.object.data[this.field];
      // console.log('list relation', this.model.constructor.name);
      this._cache = await Promise.all(ids.map(s => this.model.load(s)));
      this._cache = this._cache.filter(a => a);
      // console.log('ids', ids, this._cache);
      if (ids.length !== this._cache.length) {
        const remove = new Set(ids);
        this._cache.map((c) => c.id).forEach((c) => remove.delete(c));
        console.warn('missing objects', remove);
        // Array.from(remove).forEach((r) => {
        //   const idx = ids.indexOf(r);
        //   ids.splice(idx, 1);
        // });
        // this.object.data[this.field] = ids;
        // this.object.save();
      }
    }
    return this._cache;
  }

  async add(object) {
    if (this.object.data[this.field].indexOf(object.id) === -1) {
      this.object.data[this.field].push(object.id);
      await this.object.save();
      this.addToCache(object);
      return true;
    }
    return false;
  }

  async create(props) {
    const sub = new (this.model)(props);
    await sub.save();

    // add to parent
    this.object.data[this.field].push(sub.id);
    await this.object.save();

    // add to current cache
    if (this._cache) {
      this._cache.push(sub);
    }

    return sub;
  }

  async delete(instance) {
    if (this._cache) {
      const index = this._cache.findIndex(c => c.id === instance.id);
      if (index > -1) {
        this._cache.splice(index, 1);
      }
    }

    const index2 = this.object.data[this.field].findIndex((c) => c === instance.id);
    if (index2 > -1) {
      this.object.data[this.field].splice(index2, 1);
      await this.object.save();
    }
    console.log('delete', instance.id, this.object.data[this.field]);
    return instance.delete();
  }
}
