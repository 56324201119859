<template>
  <div class="return-content">
    <help-message name="return-direction">
      {{ $t('direction.return-help') }}
    </help-message>
    <!-- TODO: show a warning when nothing redirects here -->
  </div>
</template>

<script>
export default {
  name: 'ReturnContent',
  props: {
    story: Object,
    scene: Object,
    value: Object,
  },
}
</script>

<style scoped>
</style>
