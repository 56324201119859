import {
  PerspectiveCamera,
} from 'three';

// eslint-disable-next-line no-unused-vars
export function adjustCamera(camera, width, height) {
  const fitwidth = (width) / camera.aspect;
  const fieldOfViewInRadians = camera.fov * (Math.PI / 180.0);
  camera.position.z = (0.5 * fitwidth) / Math.tan(0.5 * fieldOfViewInRadians);
}

export default function createCanvasCamera(width, height) {
  const camera = new PerspectiveCamera(
    75, width / height, 0.1, 10000,
  );
  adjustCamera(camera, width, height);
  return camera;
}
