<template>
  <div v-if="action === 'create' || action === 'edit'">
    <div v-if="objectForm" class="flex-static">
      <div class="flex-row flex-left align-center m-1-t">
        <a href="#" @click.prevent="action = 'list'"> <i class="fa fa-chevron-left" /> Cancel </a>
        <div class="flex-stretch" />
        <Button v-if="action === 'edit'" @click="handleDelete" class="icon">
          <i class="pi pi-trash" />
        </Button>
      </div>
      <VvDynamicForm
        v-if="action === 'create' || instance"
        v-model="instance"
        :schema="objectForm"
        @submit="handleSubmit"
      />
      <div v-else>Loading...</div>
    </div>
    <div v-else>Sorry, no form was configured to create this object</div>
  </div>
  <div v-else>
    <div class="flex-static flex-row align-center p-1-l p-2-r gap-2">
      <div v-if="searchFields.length > 0" class="flex-row flex-static align-center gap-1">
        <Searcher
          :config="fuseConfig"
          :options="props.modelValue"
          v-model:selected="instance"
          v-model:results="results"
          v-model:value="searchText"
        />
        <!-- @escape="$emit('escape', $event)" -->
      </div>
      <div class="flex-row gap-1">
        <span v-if="searchFields.length > 0 && results">{{ results.length }} / </span>
        <span>{{ props.modelValue.length }}</span>
      </div>
      <div class="flex-stretch" />
      <Button
        class="icon"
        @click="
          selected = undefined;
          action = 'create';
        "
      >
        <i class="fa fa-plus" />
      </Button>
    </div>
    <div class="table flex-static">
      <div class="header row">
        <div v-for="header of headers" class="cell text-left">
          {{ header }}
        </div>
        <div class="cell"></div>
      </div>
      <div v-if="props.modelValue.length === 0" class="row">
        <div class="cell" :class="`colspan${headers.length + 1}`">
          <i>Nothing here yet</i>
        </div>
      </div>
      <div v-for="row of cells" class="row">
        <div v-for="cell of row.cells" class="cell text-left">
          <Blob_image
            v-if="cell.modifier === 'image' || cell.modifier === 'media'"
            :blob="cell.value"
            :thumb="true"
            width="60"
          />
          <span v-if="cell.modifier === 'audio'">
            <Blob_audio :blob="cell.value" :controls="true" :lazy="true" />
          </span>
          <span v-else>{{ cell.value }}</span>
        </div>
        <div class="cell flex-row">
          <Button
            @click="
              action = 'edit';
              selected = row.item.id;
            "
            class="icon"
          >
            <i class="fa fa-pencil" />
          </Button>
          <Button v-if="props.pick" @click="emits('pick', row.item)" class="icon">
            <i class="fa fa-chevron-right" />
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends {id: string}">
import copy from "@/utils/copy";
import { FormKitSchemaDefinition } from "@formkit/core";
import { nanoid } from "nanoid";
// import InputText from "primevue/inputtext";
import Blob_audio from "@/components/blob_audio.vue";
import Blob_image from "@/components/blob_image.vue";
import { debounce } from "@/utils/deferred";
import VvDynamicForm from "@morphosis/base/components/forms/VvDynamicForm.vue";
import { FuseResult } from "fuse.js";
import Button from "primevue/button";
import { computed, onMounted, ref, watch } from "vue";
import Searcher from "../searcher.vue";

export interface AdminConfig {
  searchFields?: string[];
  listDisplay?: string[];
  form: FormKitSchemaDefinition;
  fields?: Record<string, (a: T) => string | Promise<string>>[];
}

const props = defineProps<{
  modelValue: any[];
  config?: AdminConfig;
  action?: string;
  selected?: string;
  pick?: boolean;
}>();
const emits = defineEmits(["pick", "update:modelValue", "update:action", "update:selected"]);

const action = ref(props.action);
const selected = ref(props.selected);
const searchText = ref("");
const instance = ref<T>();
const results = ref<FuseResult<T>[]>();
const fuseConfig = computed(() => {
  return {
    keys: searchFields.value,
    includeMatches: true,
    findAllMatches: true,
  };
});

const searchFields = computed(() => {
  return props.config?.searchFields || [];
});

const objectForm = computed(() => {
  return props.config?.form;
});

const headers = computed(() => {
  return listDisplay.value.map((field) => {
    return field.split("|")[0].replace(/[\-_]/g, " ");
  });
});

const headerCount = computed(() => {
  return headers.value.length;
});

const listDisplay = computed(() => {
  return props.config?.listDisplay || [];
});

const cells = ref<{ item: T; cells: { value: string; modifier: string }[] }[]>([]);

watch([results, listDisplay], async () => {
  if (await debounce("admin-list", 10)) {
    const retval = [];
    if (results.value) {
      for (const row of results.value) {
        retval.push({
          item: row.item,
          cells: await Promise.all(
            listDisplay.value.map((display) => {
              return getCell(row, display);
            }),
          ),
        });
      }
    }
    cells.value = retval;
  }
});

watch([selected, () => props.modelValue], () => {
  if (selected.value) {
    instance.value = props.modelValue.find((i) => i.id === selected.value);
  } else {
    instance.value = undefined;
  }
});

watch([instance], () => {
  if (instance.value) {
    selected.value = instance.value.id;
  } else {
    selected.value = undefined;
  }
});

onMounted(async () => {
  await window.app.ready;
  if (selected.value) {
    instance.value = props.modelValue.find((i) => i.id === selected.value);
  }
});

async function getCell(object, field) {
  let name = field;
  let modifier = "";
  if (/\|/.test(field)) {
    [name, modifier] = field.split("|");
  }
  let value = object.item[name];
  if (props.config.fields && props.config.fields[name]) {
    value = await props.config.fields[name](object.item);
  }
  return {
    value,
    modifier,
  };
}

watch(action, () => {
  console.log("action", action.value);
  emits("update:action", action.value);
});
watch(selected, () => emits("update:selected", selected.value));

function toString(object: T) {
  return object.toString();
}

function handleSubmit(e) {
  console.log("submit", e);
  const list = copy(props.modelValue);
  const newInst: Partial<T> = {};
  let id = selected.value;
  for (const key in copy(e)) {
    if (/^__/.test(key)) {
      console.log(key, e[key]);
    } else {
      newInst[key] = e[key];
    }
  }
  if (instance.value) {
    const index = props.modelValue.findIndex((i) => i.id === instance.value.id);
    list[index] = {
      ...list[index],
      ...newInst,
    };
  } else {
    id = nanoid();
    newInst.id = id;
    list.push(newInst);
  }
  action.value = "";
  emits("update:modelValue", list);
  emits("update:action", "");
  emits("update:selected", id);
}

function handleDelete() {
  const list = copy(props.modelValue);
  const index = list.findIndex((i) => i.id === props.selected);
  if (index > -1) {
    instance.value = undefined;
    list.splice(index, 1);
    action.value = "";
    emits("update:modelValue", list);
    emits("update:action", "");
    emits("update:selected", "");
  }
}
</script>

<style scoped>
.table {
  /* gap: 1em; */
  margin: 1em;
  display: grid;
  grid-template-columns: repeat(v-bind("headerCount"), 1fr) auto;
}
.row {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: span calc(v-bind("headerCount") + 1);
  background: var(--color1);
  color: var(--color1_text);
}
.row:nth-child(even) {
  background: var(--color1_10);
  color: var(--color1_text_10);
}
.cell {
  padding: 1em;
}
.header .cell {
  text-transform: uppercase;
}
</style>
