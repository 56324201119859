<template>
  <list-chooser
    :value="value"
    @update:value="handleChoice"
    :list="story.animation"
  >
    <template v-slot:empty>
      <span class="block">
        <i class="fa fa-ban" />
        {{ label || $t('achievement_content.animation') }}
      </span>
    </template>
    <template v-slot="{object}">
      <span class="flex-row flex-left flex-align-center padded flex-stretch">
        <span><i class="fa fa-film" /> {{ object.name }}</span>
      </span>
    </template>
    <template v-slot:footer>
      <router-link :to="{name: 'animation', params: {story: story.id}}">
        <i class="fa fa-pencil" /> {{ $t('story.animations') }}
      </router-link>
    </template>
  </list-chooser>
</template>

<script>
export default {
  name: 'animation-chooser',
  props: {
    value: String,
    story: Object,
    label: {type: String, default: null},
  },
  methods: {
    handleChoice(e) {
      this.$emit("update:value", e);
    }
  },
}
</script>

<style scoped>
#app .button {
  padding: 0px;
}
</style>
