<style scoped>
input[type="color"] {
  visibility: hidden;
}
.color-picker {
  color: black;
  /* max-width: 50px; */
  margin: 0 5px 5px 0px;
  overflow: visible;
}
.color {
  height: 50px;
  width: 50px;
  border: 2px solid white;
  box-shadow: 0 0 1px 1px black;
  background: black;
  padding: 2px;
  margin: 0px;
  flex: 0 0 auto;
  margin-right: 5px;
}
.empty {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAiUlEQVQoU0VQCw5AIQjCy3qoLssbH/daKyNEcTBYEFkzGBDkPN8YQSDprxWEAUAdXq95iIhTsAalxUso8SgjIkpUCSVE9enlUIe2Syswq/2I6NjMtH+9tJWCSqbVfddUPdSxQHIpu1VqsbxU2sDIFPYH8veXvllmnHsDECUTzWBiJIcktu5bhfgACnpKFhZQ1nIAAAAASUVORK5CYII=) !important;
}
</style>

<template>
  <div class="input-group color-picker column">
    <div
      @click="handleClick"
      :class="{ empty: !value }"
      :style="{ background: value }"
      class="color"
    />
    <color-dialog
      v-model:color="color"
      v-model:visible="visible"
      v-model:opacity="opacity"
      :includeNull="includeNull"
      :includeOpacity="includeOpacity"
    />
  </div>
</template>

<script>
import ColorDialog from "./ColorDialog.vue";

function pad(n, width = 2, z = "0") {
  n += "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export default {
  name: "ColorPicker",
  components: {
    ColorDialog,
  },
  props: {
    value: String,
    default: String,
    includeOpacity: { default: true },
    includeNull: { default: true },
  },
  data() {
    return {
      color: this.value,
      opacity: 1,
      right: false,
      visible: false,
    };
  },
  watch: {
    color() {
      this.update(this.color);
    },
    value() {
      this.splitOpacity(this.value);
    },
  },
  mounted() {
    if (!this.value) {
      if (!this.includeNull) {
        this.update(this.default);
        this.splitOpacity(this.default);
      }
    } else {
      this.splitOpacity(this.value);
    }
  },
  methods: {
    splitOpacity(rgba) {
      if (/rgba/.test(rgba)) {
        const parts = rgba.substring(rgba.indexOf("(") + 1, rgba.length - 1).split(",");
        const r = parseInt(parts[0], 10) || 0;
        const g = parseInt(parts[1], 10) || 0;
        const b = parseInt(parts[2], 10) || 0;
        let a = parseFloat(parts[3]);
        if (!a && a !== 0) {
          a = 1;
        }
        this.color = `#${pad(r.toString(16))}${pad(g.toString(16))}${pad(b.toString(16))}`;
        this.opacity = a;
      }
    },
    adjustHexOpacity(color, opacity) {
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    },
    updateColor() {
      let { color } = this;
      if (this.includeOpacity) {
        color = this.adjustHexOpacity(this.color, this.opacity);
      }
      this.update(color);
    },
    removeColor() {
      this.update(null);
    },
    update(value) {
      this.$emit("update:value", value);
    },
    handleClick() {
      this.visible = true;
    },
  },
};
</script>
