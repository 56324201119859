import BaseField from "./base";

export default class ImageField extends BaseField {

  initialize(object, key) {
    super.initialize(object, key);

    const url = object.data[key];
    // eslint-disable-next-line no-param-reassign
    object.data[key] = null;
    if (url) {
      this.ready = this.cachedUrl(url);
    } else {
      this.ready = true;
    }
  }

  getValue() {
    return this.url;
  }

  setValue(v) {
    this.cachedUrl(v);
  }

  getCacheKey() {
    return `${this.object.constructor.name}-${this.object.data.id}-${this.key}`;
  }

  async getBlobURL(url) {
    if (this.url) {
      URL.revokeObjectURL(this.url);
      console.log('revoked blob url', this.url);
    }
    this.url = URL.createObjectURL(await (await fetch(url)).blob());
    console.log('got blob url', this.url);
    return this.url;
  }

  async cachedUrl(url = null) {
    const cacheKey = this.getCacheKey();
    if (url) {
      ImageField._imageCache[cacheKey] = await this.getBlobURL(url);
    }
    return ImageField._imageCache[cacheKey];
  }

  async serialize() {
    return this.fetch();
  }

  async dispose() {
    URL.revokeObjectURL(this.url);
    console.log('revoked blob url2', this.url);
  }
}

ImageField._imageCache = {};
