<style scoped>
  img {
    border-radius: 10px;
  }
</style>

<template>
  <div class="image-content">
    <help-message name="lighting-explanation">
      Lighting can make background, character and images harder to see.
      Use to affect the mood of your story
    </help-message>
    <div class="form">
      <div class="flex row">
        <div class="block"><i class="fa fa-lightbulb-o" /> Light: {{ value.light }}</div>
        <div class="static">
          <!-- <img src="/assets/tutorials/more.gif" :style="{'max-height': '50px', opacity: value.light}"> -->
        </div>
      </div>
      <input
        type="range"
        :value="value.light"
        :max="1"
        :step="0.01"
        :min="0"
        class="stretch"
        @input="$emit('update', {light: $event.target.value})"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "LightingContent",
  props: {
    story: Object,
    value: Object,
  },
  data() {
    return {
      selecting: !this.value.content,
    };
  },
  computed: {
    image() {
      return this.images.find(a => a.id == this.value.content);
    },
    images() {
      return this.story.images;
    }
  },
}
</script>
