<template>
  <transition appear name="emotefade">
    <div
      ref="aura"
      class="image-aura"
      :class="{
        [`position-${position}`]: true,
      }"
      :style="{
        '--aura': props.value.color || 'white',
        '--auraShine': props.value.shine || 'transparent',
        '--auraShineSpeed': props.value.speed || '0s',
        '--auraQty': props.value.quantity || 12,
        '--auraSize': props.value.size || 10,
        '--auraPx': widthPx,
        '--layoutPosition': props.position,
      }"
    >
    </div>
  </transition>
</template>

<script setup lang="ts">
import { type Aura } from '@/stores/story_defs';
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  value: Aura,
  position?: string,
  size?: number,
}>(), {
  position: 'left',
  size: 300,
});
const origin = computed(() => {
  if (props.position === 'right') {
    return '50% 50%';
  }
  if (props.position === 'center') {
    return '0 50%';
  }
  return '50% 50%';
});
const widthPx = computed(() => {
  return `${props.size}px`;
});
const halfPx = computed(() => {
  return `${props.size / 2}px`;
});
const qPx = computed(() => {
  return `${props.size / 4}px`;
});
</script>

<style scoped>

.image-aura {
  position: absolute;
  left: 0;
  top: 0;
  /* calc(-0.25 * v-bind('qPx')); */
  /* top: v-bind('qPx'); */
  z-index: 0;
  width: v-bind('widthPx');
  height: v-bind('widthPx');
  transition-delay: 200ms;
  --sliceSize: calc(360deg / var(--auraQty, 360));
  --sliceP: var(--auraSize, 5) / 100;
  background: radial-gradient(
    circle at v-bind('halfPx') v-bind('halfPx'),
    var(--aura, white) 0px,
    transparent v-bind('halfPx')
  ), repeating-conic-gradient(
    from 0deg at v-bind('halfPx') v-bind('halfPx'),
    var(--auraShine, transparent) 0deg calc(var(--sliceSize) * var(--sliceP)),
    transparent
      calc(var(--sliceSize) * var(--sliceP))
      var(--sliceSize)
  );
  mask-image: radial-gradient(
    circle at center,
    white 0,
    white calc(var(--auraPx) * 0.3),
    transparent calc(var(--auraPx) * 0.5)
  );
  animation-name: rotating;
  animation-duration: var(--auraShineSpeed, 0);
  transform-origin: v-bind(origin);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes rotating {
  0% { rotate: 0; }
  50% { rotate: 180deg; }
  100% { rotate: 360deg; }
}
.position-center.image-aura {
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0px);
}
.position-right.image-aura {
  left: unset;
  /* right: -60px; */
}

@media (min-width: 1000px) {
  .position-left.image-aura {
    /* --aura: white; */
    /* left: 0; */
  }
  /* .position-center.image-aura {
    left: 50%;
    right: 50%;
    transform: translate(-50%, 0px);
  } */
  .position-right.image-aura {
    left: unset;
    /* right: 0px; */
  }
}
</style>