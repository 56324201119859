import BackButton from "./components/BackButton.vue";
import Popover from "./components/Popover.vue";

export default {
  install(Vue) {
    Vue.directive("popover", {
      bind(el, binding) {
        const name = binding.arg || binding.value;
        if (binding.modifiers.close) {
          el.addEventListener("click", (e) => {
            e.preventDefault();
            window.$popovers[name].hide();
          });
        } else if (binding.modifiers.rightclick) {
          el.addEventListener("contextmenu", (e) => {
            e.preventDefault();
            window.$popovers[name].show(el, binding.modifiers);
          });
        } else {
          el.addEventListener("click", (e) => {
            e.preventDefault();
            window.$popovers[name].show(el, binding.modifiers);
          });
        }
      },
    });

    Vue.mixin({
      components: {
        BackButton,
        Popover,
      },
    });
  },
};
