<template>
  <span>
    <slot
      :name="field.type || 'default'"
      :field="field"
      :value="value"
      :update="update"
    >
      <select
        v-if="(!field.type && options) || field.type === 'select'"
        :required="field.required === undefined ? true : field.required"
        :readonly="field.readonly"
        :tabindex="field.tabindex"
        :class="field.css_classes || 'form-control'"
        @input="update(field, $event.target.value)"
      >
        <option
          v-if="field.placeholder !== false"
          selected
          disabled
          :value="null"
        >
          {{ field.placeholder || '----' }}
        </option>
        <option
          v-for="option of options"
          :key="option.value"
          :selected="option.value == value[field.name]"
          :value="option.value"
        >
          {{ option.name || option.label }}
        </option>
      </select>
      <div
        v-else-if="field.type === 'checkbox-list'"
        class="checkbox-list"
        :class="`${field.name}-checkbox-list`"
      >
        <label
          v-for="option of options"
          :key="option.value"
        >
          <input
            type="checkbox"
            :value="option.value"
            :readonly="field.readonly"
            :checked="
              value[field.name] && (
                ((value[field.name] instanceof Array) && value[field.name].indexOf(option.value) > -1) ||
                value[field.name][option.value]
              )
            "
            @change="update(field, $event.target.checked, option.value)"
          >
          {{ option.name || option.label }}
        </label>
      </div>
      <div
        v-else-if="field.type === 'range'"
      >
        <input
          @input="update(field, capped($event.target.value))"
          :value="value[field.name]"
          :type="field.type || 'text'"
          :size="field.size"
          :name="field.name"
          :placeholder="field.placeholder"
          :readonly="field.readonly"
          :tabindex="field.tabindex"
          :min="field.min"
          :max="field.max"
          :step="field.step"
          :required="field.required === undefined ? true : field.required"
          :class="field.css_classes || 'form-control'"
          :list="`values_${field.name}`"
        >
        <datalist
          v-if="(field.min === 0 || field.min) && (field.max === 0 || field.max)"
          :id="`values_${field.name}`"
        >
          <option :value="field.min" :label="field.min.toFixed(1)"></option>
          <option :value="(field.min + field.max)/2" :label="((field.min + field.max)/2).toFixed(1)"></option>
          <option :value="field.max" :label="field.max.toFixed(1)"></option>
        </datalist>
      </div>
      <textarea
        v-else-if="field.type === 'textarea'"
        :value="value[field.name]"
        :rows="field.rows || 3"
        :cols="field.cols || 23"
        :name="field.name"
        :placeholder="field.placeholder"
        :readonly="field.readonly"
        :tabindex="field.tabindex"
        :required="field.required === undefined ? true : field.required"
        :class="field.css_classes || 'form-control'"
        @input="update(field, $event.target.value)"
      />
      <input
        v-else-if="field.type === 'file'"
        type="file"
        :ref="`file-${field.name}`"
        :name="field.name"
        :placeholder="field.placeholder"
        :accept="field.accept"
        :tabindex="field.tabindex"
        :required="field.required === undefined ? true : field.required"
        :class="field.css_classes || 'form-control'"
        @input="updateFile(field, $event.target.value)"
      >
      <input
        v-else
        :value="value[field.name]"
        :type="field.type || 'text'"
        :size="field.size"
        :name="field.name"
        :placeholder="field.placeholder"
        :readonly="field.readonly"
        :tabindex="field.tabindex"
        :min="field.min"
        :max="field.max"
        :step="field.step"
        :pattern="field.pattern"
        :maxlength="field.maxlength"
        :required="field.required === undefined ? true : field.required"
        :class="field.css_classes || 'form-control'"
        @input="update(field, capped($event.target.value))"
      >
    </slot>
  </span>
</template>

<script>
import copy from '../../functions/copy';

export default {
  name: 'AutoField',
  props: {
    field: { type: Object, required: true },
    value: { type: Object, required: true },
  },
  computed: {
    options() {
      if (this.field.options || this.field.choices) {
        let retval = this.field.options || this.field.choices;
        if (retval > 0 && typeof retval[0] !== 'object') {
          retval = retval.map((key) => ({ name: key, value: key }));
        }
        return retval;
      }
      return null;
    },
  },
  mounted() {
    if (this.value[this.field.name] === undefined && this.field.default) {
      this.update(this.field, this.field.default);
    }
  },
  methods: {
    update(field, rawValue, key = null) {
      // let errors = [];
      // if (field.validators) {
      //   errors = field.validators.map(v => v(value)).filter(e => e);
      // }

      // if (errors.length === 0) {
      const values = copy(this.value);
      let value = rawValue;
      if (field.type === 'number') {
        value = parseFloat(value);
      }
      if (key) {
        if (values[field.name] instanceof Array) {
          const index = values[field.name].indexOf(key);
          if (value) {
            if (index === -1) {
              values[field.name].push(key);
            }
          } else if (index > -1) {
            values[field.name].splice(index, 1);
          }
        } else {
          values[field.name][key] = value;
        }
      } else {
        values[field.name] = value;
      }
      this.$emit('update:value', values);
      // }
    },
    async updateFile(field) {
      const input = this.$refs[`file-${field.name}`];
      if (this.category === 'image') {
        this.$emit('update:value', await this.cleanImage());
      } else {
        const result = await new Promise((r) => {
          const reader = new FileReader();
          reader.onload = async () => r(reader.result);
          reader.readAsArrayBuffer(input.files[0]);
        });
        this.$emit('update:value', { [field.name]: new Blob([result], { type: input.files[0].type }) });
      }
    },
    capped(value) {
      if (this.field.type === 'number') {
        if (this.field.max && value > this.field.max) {
          return this.field.max;
        }
        if (this.field.min && value < this.field.min) {
          return this.field.min;
        }
      }
      return value;
    },
  },
};
</script>

<style scoped>
textarea {
  margin-bottom: 4px;
}
.checkbox-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
datalist {
  margin: -9px 5px 0 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  opacity: 0.75;
}
datalist option {
  position: relative;
}
datalist option:before {
  content: ' ';
  position: absolute;
  width: 5px;
  height: 9px;
  background: var(--color2);
  clip-path: polygon(0 0, 100% 0, 50% 100%);
  top: -5px;
  left: 11px;
}
</style>
