<style scoped>
select {
  font-size: 20px;
}
select :disabled {
  color: rgba(0, 0, 0, 0.5);
}
.fa-pencil {
  color: var(--spark);
}
.padded {
  padding: 15px;
}
.tag-picker {
  display: inline-flex;
  flex-direction: row;
  vertical-align: middle;
  align-items: center;
  white-space: nowrap;

  background: var(--color3_text);
  color: var(--color3);

  border-radius: 10px;
  border: 3px solid var(--spark);
  margin: 0 0 5px 0;
  padding: 9px;
  font-size: 1.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
svg, i {
  margin: 0 4px;
}
</style>

<template>
  <div
    class="tag-picker"
    @click="showModal = true"
    @keydown.enter.space.prevent="showModal = true"
    :id="`tag-selector-${name}`"
    tabindex="0"
  >
    <div class="error" v-if="error">{{ error }}</div>
    <template v-if="/^\$/.test(value)">
      <vi name="array" class="flex-static" />
      {{ value }}
      <i class="fa fa-caret-down static" />
    </template>
    <template v-else-if="selected">
      <!-- <i class="fa fa-hand-pointer-o" />&nbsp; -->
      <vi v-if="selected.icon" :name="selected.icon" class="flex-static" />
      <vi v-else name="tag" class="flex-static"/>
      <tag-label :story="story" :reference="value" :tag="selected" />
      <i class="fa fa-caret-down static" />
    </template>
    <template v-else>
      <span class="left stretch">{{ placeholder || $t('tag_picker.no-tag-selected') }}</span>
      <i class="fa fa-caret-down" />
    </template>
    <tag-modal
      v-if="story && showModal"
      :story="story"
      :value="value"
      :type="type"
      :excludes="excludes"
      :extras="extras"
      :mutating="mutating"
      @update:value="handleInput"
      @show="handleClose"
      @create="handleTag"
    />
  </div>
</template>

<script>
import TagModal from './tag_popup.vue';

export default {
  name: 'TagPicker',
  components: {
    TagModal,
  },
  props: {
    placeholder: { type: String, required: false },
    name: { type: String, required: false },
    mutating: { type: Boolean, default: true },
    type: { type: String, default: 'all' },
    excludes: { type: Array, default() { return []; } },
    value: { type: String, require: true },
    story: { type: Object, required: true },
    extras: { type: Object, default: null },
    showEdit: { type: Boolean, default: true },
  },
  data() {
    return {
      showModal: false,
      error: null,
    };
  },
  computed: {
    selected() {
      return this.findTag(this.story, this.value);
    },
  },
  methods: {
    label(tag) {
      return tag.category ?
        `${tag.category} / ${tag.name}` :
        `${tag.name}`
    },
    async handleTag(e) {
      this.error = null;
      const name = e.trim();
      if (/^\$/.test(this.e)) {
        this.error = this.$t('formula_popup.tags-are-not-allowed-to-start-with');
      } else if (this.story.tags.find(t => t.name === name)) {
        this.error = this.$t('tags.duplicate-tag-warning', { name });
      } else {
        const id = this.$root.id();
        this.story.tags.push({ name, id, type: "tag" });
        this.$emit("update:value", id);
        await this.story.saveAndPush();
      }
    },
    handleInput(e) {
      this.error = null;
      this.$emit("update:value", e);
    },
    customLabel(opt) {
      if (!opt.type || opt.type == 'tag') {
        return `${opt.name} (${opt.format || this.$t('tags.format-types-numeric')})`;
      } else if (opt.type == 'action') {
        return `${opt.name} (${opt.formula.slice(0, 10)})`;
      }
      return `${opt.name} (${opt.type})`;
    },
    handleClose(e) {
      this.showModal = e;
      if (!this.showModal) {
        this.$el.focus();
      }
    },
  },
};
</script>
