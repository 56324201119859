<script>
import MusicContent from './music_content.vue';

export default {
  name: "audio-content",
  extends: MusicContent,
  props: {
    story: Object,
    value: Object,
    type: { type: String, default() { return 'audio'; } },
  },
}
</script>
