import sortBy from "sort-by";

export default class PlayerManager {

  constructor(settings) {
    this.clear();
    this.settings = settings;
    this.waiting = {};
  }

  get length() {
    return this.players.length;
  }

  get party() {
    const retval = this.parties.find(p => p.id === this.partyId);

    if (retval) {
      return retval;
    }

    // console.warn('party is not valid', this.partyId);
    return {id: -1};
  }

  get partyCount() {
    return this.parties.length;
  }

  get choices() {
    // console.log('choices => picks', this.party);
    return this.party.picks;
  }

  clear() {
    this.players = [];
    this.partyId = 0;
    this.parties = [{ id: 0, name: 'main', picks: [], savepoint: null }];
    this.min = {};
    this.max = {};
  }

  partySize(playerId) {
    const player = this.player(playerId);
    return this.players.filter(p => p.party === player.party).length;
  }

  player(id) {
    return this.players.find(p => p.id === id);
  }

  getParty(id) {
    return this.parties.find(p => p.id === id);
  }

  getPartyIdByName(name) {
    let party = this.parties.find(p => p.name === name);
    if (!party) {
      party = {
        id: this.parties.reduce((a, b) => Math.max(a, b.id) + 1, 0),
        name,
        picks: [],
        savepoint: null,
      };
      this.parties.push(party);
    }
    return party.id;
  }

  isMultiplayer() {
    return this.players.length > 1;
  }

  isInParty(playerId) {
    const player = this.players.find(p => p.id === playerId);
    if (player) {
      return player.party === this.partyId;
    }
    return false;
  }

  add(id, name, main = false, partyName = 'main') {
    this.players.push({
      id,
      name,
      username: name,
      main,
      party: this.getPartyIdByName(partyName),
    });
  }

  closeParty(party=null) {
    if (!party) {
      party = this.party;
    }
    if (party) {
      party.savepoint = null;
    }
    const idx = this.parties.findIndex(p => p.id === this.party.id);
    // this.partyId = (this.partyId + 1) % this.parties.length;
    if (idx > -1) {
      this.parties.splice(idx, 1);
    }
    // console.log(` -> party closed, remaining:`, this.parties.map(p => p.id));
    // if (this.party) {
    //   return this.party.savepoint;
    // }
    // return null;
  }

  nextParty(savepoint) {
    this.party.savepoint = savepoint;
    this.partyId = (this.partyId + 1) % this.parties.length;
    // console.log(' -> next party', this.partyId);
    if (this.party) {
      return this.party.savepoint;
    }
    return null;
  }

  waitFor(party) {
    this.choices.push({ party });
  }

  minPicks(choiceId, min) {
    this.min[choiceId] = min;
  }

  maxPicks(choiceId, max) {
    this.max[choiceId] = max;
  }

  choice(id, playerId, party = null, opts = {}, evaluate=true) {
    // console.log('choices', id, playerId, party, this.choices);
    const previous = this.choices.find(c => c.playerId === playerId);
    if (previous) {
      previous.id = id;
      previous.switch = party;
      previous.opts = opts;
    } else {
      const player = this.players.find(p => p.id === playerId);
      this.party.picks.push({
        playerId,
        id,
        party: player.party,
        switch: party,
        opts
      });
    }
    if (evaluate) {
      return this.evaluateChoices();
    }
  }

  evaluateChoices(force = false) {
    // console.log('----');
    const choices = this.choices.filter(c => c.id || c.id === 0);
    const tally = {};

    for (const choice of choices) {
      if (!tally[choice.id]) {
        tally[choice.id] = {
          count: 0,
          min: choice.opts.min,
          max: choice.opts.max,
          party: (choice.switch || choice.switch === 0) ? choice.switch : choice.party,
        };
      }
      tally[choice.id].count += 1;
    }

    if (! force) {
      // verify everyone has voted
      const counts = {};

      // console.log('my choices', this.choices);

      // using `this.choices` instead of `choices` to include "waitFor" parties
      for (const group of this.choices.filter(c => !c.id && c.id !== 0)) {
        if (group.party !== this.partyId) {
          const party = this.getParty(group.party);
          // console.log('importing picks', party);
          for (const pick of party.picks.filter(p => p.id || p.id === 0)) {
            // console.log('pick', pick);
            choices.push(pick);
          }
        }
      }
      for (const pick of choices) {
        // console.log('o pick', pick);
        counts[pick.party] = (counts[pick.party] || 0) + (pick.weight || 1);
      }

      const parties = new Set(this.choices.map(p => p.party));
      // console.log('recorded choices', this.choices);
      for (const partyId of parties) {
        const players = this.players.filter(p => p.party === partyId);
        // console.log('party', partyId, counts[partyId], players.length);
        if (counts[partyId] !== players.length) {
          // not all players have picked
          return false;
        }
      }

      // enforce min and max
      for (const cid in tally) {
        // console.log('   ** tally', tally[cid]);
        if (tally[cid].min && tally[cid].count < tally[cid].min) {
          // console.log('  <> minimum picks not met', tally[cid].count, tally[cid].min);
          return false;
        }
        if (tally[cid].max && tally[cid].count > tally[cid].max) {
          // console.log('  <> max picks exceeded', tally[cid].count, tally[cid].max);
          return false;
        }
      }
    }

    // console.log(' -> getting choice');

    // sort choices into parties
    const groups = [];
    for (const pick of choices) {
      const party = pick.party;  // tally[pick.id].party;
      let item = groups.find(i => i.partyId === party);
      if (!item) {
        item = { partyId: party, choices: [], players: [], tally: [] };
        groups.push(item);
      }
      item.choices.push(pick.id);
      if (!item.tally.find(row => row.id === pick.id)) {
        item.tally.push({ id: pick.id, count: tally[pick.id].count });
      }
      item.players.push(pick.playerId);
    }

    // pick one per party
    for (const group of groups) {
      group.tally.sort(sortBy('-count'));
      const idx = Math.floor(Math.random() * group.choices.length);
      const choiceId = group.choices[idx];
      const choice = choices.find(c => c.id === choiceId);
      group.choice = choice;
    }

    // console.log('result', groups);

    this.party.picks = [];

    return groups.length > 0 ? groups : false;
  }

  splitParty(partyId, players) {
    let party = this.parties.find(p => p.id === partyId);
    for (const player of this.players.filter(p => players.indexOf(p.id) > -1)) {
      player.party = partyId;
    }

    // clean out empty parties
    // this.parties = this.parties.filter(
    //   p => this.players.filter(p2 => p2.party === p.id).length > 0
    // );

    // console.log('party split', this.parties.length);
  }

  clearChoices() {
    // console.log(' -* clear choices');
    this.choices.length = 0;
    this.min = {};
    this.max = {};
  }
};