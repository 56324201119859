<template>
  <div :style="{'--size': `${size}px`}">
    <app-header v-if="!popup" :show-menu="false">
      <router-link :to="{name: 'home'}">
        <i class="fa fa-arrow-left" />
      </router-link>
      <div class="flex-row flex-space-between flex-align-center">
        <b>Gallery</b>
        <span class="flex-row">
          <a
            v-if="status === 'Selecting'"
            @click.prevent="status = null"
            class="button"
          >
            <i class="fa fa-check-square"/> Cancel
          </a>
          <a
            v-else-if="!status"
            @click.prevent="status = 'Selecting'"
            class="button"
          >
            <i class="fa fa-check-square-o"/>
          </a>
          <router-link
            v-if="!status"
            :to="{name: 'gallery-create'}"
            class="button"
          >
            <i class="fa fa-plus-circle"/>
          </router-link>
          <a
            v-if="status === 'Selecting'"
            @click.prevent="handleDelete"
            class="button"
          >
            <i class="fa fa-trash" /> Delete
          </a>
        </span>
      </div>
    </app-header>
    <div class="responsive-max scroll">
      <div v-if="images.length > 0" class="card accented flex-column">
        <div class="flex-row">
          <input type="search" v-model="filter" placeholder="Filter..." class="flex-stretch" />
          <a
            @click.prevent="typeFilter === 'audio' ? typeFilter = null : typeFilter = 'audio'"
            :class="{selected: typeFilter === 'audio'}"
          >
            <vi name="music" />
          </a>
          <a
            @click.prevent="typeFilter === 'image' ? typeFilter = null : typeFilter = 'image'"
            :class="{selected: typeFilter === 'image'}"
          >
            <vi name="background" />
          </a>
        </div>
      </div>
      <p v-if="status">
        {{ status }}...
      </p>
      <p v-else-if="status !== 'Loading' && images.length === 0">
        <i>Nothing is here :(</i>
      </p>
      <div class="image-grid">
        <drawing-thumb
          v-for="image of results.map((item) => item.item)" :key="image.id"
          :object="image"
          :size="size"
          :class="{
            notSelected: status === 'Selecting' && !selected[image.id],
            selected: selected[image.id],
          }"
          :thumb="true"
          @click="handleClick($event, image)"
          @pointermove="status === 'Selecting' ? handleClick($event, image) : null"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js';
import Media from '../../models/media';
import DrawingThumb from './thumb.vue';

export default {
  components: {
    DrawingThumb,
  },
  props: {
    popup: { type: Boolean, default: false },
    size: { type: Number, default: 150 },
    accept: { type: String, default: null },
  },
  data() {
    return {
      status: 'Loading',
      images: [],
      selected: {},
      filter: null,
      typeFilter: null,
      fuse: null,
      results: [],
    };
  },
  computed: {
    filtered() {
      let retval = this.images;
      if (this.filter) {
        const regex = new RegExp(this.filter, 'i');
        retval = retval.filter((i) => {
          return regex.test(i.name);
        });
      }
      return retval;
    },
  },
  watch: {
    filter() {
      this.loadItems();
    },
    typeFilter() {
      this.loadItems();
    },
  },
  async mounted() {
    await this.$root.ready;
    const acceptFilter = this.accept ? new RegExp(`^${this.accept}`) : null;
    const mediaList = await Media.list();
    this.images = mediaList.filter((m) => {
      return (
        m.id
        && (acceptFilter ? acceptFilter.test(m.type) : true)
        && !m.isPrivate
      );
    });
    this.fuse = new Fuse(this.images, {
      keys: ['name', 'type'],
      includeMatches: true,
      findAllMatches: true,
    });
    this.status = null;
    await this.loadItems();

    // check for bad types
    for (const media of mediaList.filter((m) => m.type === 'application/octet-stream')) {
      media.type = 'image/png';
      try {
        await media.thumbnailImage(null);
        await media.getThumbnail();
        await media.save();
        console.warn('fixing type', media);
      } catch (ex) {

      }
    }
  },
  methods: {
    loadItems() {
      if (this.fuse && (this.filter || this.typeFilter)) {
        const $and = [];
        if (this.filter) {
          $and.push({ name: this.filter });
        }
        if (this.typeFilter) {
          $and.push({ type: this.typeFilter });
        }
        this.results = this.fuse.search({ $and });
      } else {
        this.results = this.images.map((image) => {
          return {item: image};
        }).reverse();
      }
    },
    handleClick(e, image) {
      if (e) {
        e.stopImmediatePropagation();
      }
      if (this.popup && image) {
        this.$emit('select', image);
      } else if (/Selecting/.test(this.status)) {
        if (e.type !== 'pointermove' && this.selected[image.id]) {
          this.selected[image.id] = false;
        } else {
          this.selected[image.id] = true;
        }
      } else {
        this.$router.push({ name: 'gallery-item', params: { id: image.id } });
      }
    },
    async handleDelete() {
      this.status = 'Deleting';

      const remove = [];
      for (const key in this.selected) {
        if (this.selected[key]) {
          remove.push(this.images.find((i) => i.id == key));
        }
      }
      await Promise.all(remove.map((image) => image.delete()));
      this.images = this.images.filter((i) => !remove.includes(i));

      this.status = null;
    },
  },
};
</script>

<style scoped>
.image-grid {
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, calc(5px + var(--size)));
  flex: 1 1 auto;
  /* width: 100vw;
  max-width: 900px; */
  user-select: none;
}
.image-grid > * {
  transition: opacity 300ms, transform 300ms;
}
#app .notSelected {
  border: 4px solid var(--color3_text);
  opacity: 0.5;
  transform: scale(0.95);
}
#app .selected {
  border: 4px solid var(--color3_text);
  opacity: 1;
  transform: scale(1.0);
}
</style>
