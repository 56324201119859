<style scoped>
  .input-array {
    width: 100%;
    flex: 1 1 auto;
  }
  .input-array > div {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .input-array a {
    font-size: 25px;
    cursor: pointer;
  }
  .new-entry * {
    font-size: .9em;
    opacity: .7;
  }
  .delete, .add {
    color: var(--text);
  }
</style>

<template>
  <div class="input-array">
    <div v-for="(v, i) of data" :key="i" :class="`entry-${i}`">
      <slot :value="v" :index="i" :input="(e) => handleInput(i, e)">
        <input
          type="text"
          :class="{last: i == data.length - 1}"
          :value="v"
          @update:value="handleInput(i, $event.target.value)"
        >
      </slot>
      <a class="delete" @click="handleRemove(i)">&times;</a>
    </div>
    <div class="new-entry" v-if="allowAnother">
      <a class="add" href="#" @click.prevent>+</a>
      <slot :value="objectTemplate" :index="-1" :input="handleAdd">
        <input
          type="text"
          :value="objectTemplate"
          @update:value="handleAdd($event.target.value)"
          key="new"
          :placeholder="placeholder"
        >
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "input-array",
  props: {
    value: Array,
    objectTemplate: Object,
    allowAnother: { type: Boolean, default: true },
    placeholder: { type: String, default: '' },
  },
  computed: {
    data() {
      return this.value || [];
    }
  },
  methods: {
    async handleAdd(obj) {
      let entries = this.data.slice(0);
      entries.push(obj);

      this.$emit("update:value", entries);
      await this.$nextTick();

      let focusable = this.$el.querySelector(`.entry-${entries.length - 1}`);
      if (focusable) {
        const el = focusable.querySelector('input, select, [tabindex]');
        if (el) {
          el.focus();
        }
      }
    },
    handleInput(index, value) {
      if (index == -1) {
        this.handleAdd();
      } else {
        let entries = this.data.slice(0);
        if (typeof value == 'object') {
          for (const key in value) {
            entries[index][key] = value[key];
          }
        } else {
          entries[index] = value;
        }
        this.$emit("update:value", entries);
      }
    },
    handleRemove(i) {
      let data = this.data.slice(0);
      data.splice(i, 1);
      this.$emit("update:value", data);
    },
  }
}
</script>
