<template>
  <searching-modal
    :config="{
      keys: ['name', 'label'],
      includeMatches: true,
      findAllMatches: true,
    }"
    :options="options"
    @show="$emit('show', $event)"
    @update:value="$emit('update:value', $event)"
    @action="$emit('action', $event)"
  >
    <template v-slot:header>
      <div class="flex-column">
        <div v-if="character && emote">
          <div class="flex-row flex-center">
            <b>{{ $t("character_modal.change-character") }}</b>
          </div>
          <div class="accented">
            <router-link
              :to="{
                name: 'character',
                params: { id: character.id, action: 'edit' },
                query: { back: $route.path },
              }"
              class="flex-stretch result flex-row flex-align-center"
            >
              <div class="image flex-static flex-center flex-align-center">
                <blob-image :blob="emote.blob_id" v-if="emote.blob_id" :thumb="true" />
                <div class="placeholder" v-else />
              </div>
              <div class="emote flex-static">
                {{ emote.name }}
              </div>
              <div class="flex-stretch" />
              <div class="name flex-static">
                {{ character.name }}
              </div>
            </router-link>
          </div>
        </div>
        <div v-else>
          <div class="flex-row flex-center">
            <b>{{ $t("character_modal.select-character") }}</b>
          </div>
        </div>
        <div v-if="tag" class="accented">
          <router-link
            :to="{
              name: 'tags',
              params: { story: story.id, id: tag.id },
              query: { back: $route.path },
            }"
            class="flex-stretch result flex-row flex-align-center"
          >
            <div class="emote flex-static">
              <span v-if="tag.category">{{ tag.category }} / </span> {{ tag.name }}
            </div>
          </router-link>
        </div>
      </div>
    </template>
    <template v-slot:pre-search="">
      <div class="row padded center">
        <div class="static position row">
          Position:
          <button :class="{ active: position == 'left' }" @click="$emit('position', 'left')">
            <i class="fa fa-chevron-right" />
          </button>
          <button :class="{ active: position == 'center' }" @click="$emit('position', 'center')">
            <i class="fa fa-circle"></i>
          </button>
          <button :class="{ active: position == 'right' }" @click="$emit('position', 'right')">
            <i class="fa fa-chevron-left"></i>
          </button>
        </div>
      </div>
    </template>
    <template v-slot:default="{ result }">
      <div class="image flex-static flex-center flex-align-center">
        <blob-image :thumb="true" :blob="result.item.image" v-if="result.item.image" />
        <div class="placeholder" v-else />
      </div>
      <div class="emote flex-static">
        {{ result.item.emote }}
      </div>
      <div class="flex-stretch" />
      <div class="name flex-static">
        {{ result.item.name }}
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex-row flex-center">
        <router-link :to="{ name: 'characters', query: { back: $route.path } }" class="button">
          {{ $t("character_modal.character-list") }}
        </router-link>
      </div>
    </template>
  </searching-modal>
</template>

<script>
import SearchingModal from "./searching_modal.vue";

export default {
  name: "CharacterModal",
  components: {
    SearchingModal,
  },
  props: {
    story: Object,
    character: Object,
    emote: Object,
    position: String,
    tag: Object,
  },
  computed: {
    options() {
      const retval = [
        {
          charId: null,
          emoteId: null,
          label: "",
          emote: "(none)",
        },
      ];
      const emoteNames = new Set();
      for (const char of this.story.characters) {
        if (char.emotes) {
          for (const emote of char.emotes) {
            if (emote.name) {
              emoteNames.add(emote.name);
            }
            retval.push({
              charId: char.id,
              emoteId: emote.id,
              name: char.name,
              emote: emote.name,
              label: emote.label,
              image: emote.blob_id,
            });
          }
        }
      }
      const charTags = this.story.tags.filter(
        (t) => t.type === "story-object-ref" && t.referencing === "characters",
      );
      for (const tag of charTags) {
        for (const emote of emoteNames) {
          retval.push({
            charTag: tag.id,
            name: tag.name,
            emote: emote,
          });
        }
      }
      return retval;
    },
  },
};
</script>

<style scoped>
.accented {
  border-radius: 15px;
}
.image {
  width: 40px;
}
.image img {
  background-color: white;
  border: 2px solid black;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.image .placeholder {
  border-radius: 50%;
  background: var(--spark);
  border: 2px dotted var(--spark_text);
  width: 30px;
  height: 30px;
}
.name,
.emote {
  margin: 2px 8px;
}
.name {
  font-size: 0.9em;
  opacity: 0.7;
}
</style>
