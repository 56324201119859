/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */

import { nanoid } from "nanoid";
import copy from "../../functions/copy";

export default class Manager {
  constructor(name) {
    this.name = name;
    if (!localStorage.clientid) {
      localStorage.clientid = nanoid();
    }
  }

  async list(ref) {
    throw new Error("not implemented");
  }

  async load(id) {
    throw new Error("not implemented");
  }

  async save(object) {
    throw new Error("not implemented");
  }

  async delete(id) {
    return new Error("not implemented");
  }

  async push(object, opts = {}) {
    if (!window.app.disconnected) {
      if (!object.id) {
        object.id = nanoid();
      }
      if (object._server) {
        if (object._server.level === "viewer") {
          console.warn("you cannot push as a viewer");
        }
        delete object._server;
      }

      // if (typeof (object.updated) === 'number') {
      //   object.updated = new Date(object.updated);
      // }
      object.updated = new Date().toISOString();

      // TODO: don't use globals
      try {
        const response = await window.app.http("db_push", {
          id: object.id,
          model: this.name,
          updated: object.updated,
          client: localStorage.clientid,
          content: opts.content || (await this.pushContent(object)),
          ...opts,
        });
        if (response.error && window.app && window.app.addAlert) {
          console.error("server error", object, response);
          if (response.code === "nsfw") {
            window.app.addAlert({
              title: "Server save failed",
              message: response.error,
              level: "error",
            });
          } else {
            window.app.addAlert({
              title: "Server save failed",
              message: response.error,
              level: "error",
            });
          }
        }
        if (object._server) {
          // eslint-disable-next-line no-param-reassign
          delete object._server;
        }
        object._server = copy(object);
        return response;
      } catch (ex) {
        console.error("Failed to push", ex);
      }
      if (window.app && window.app.addAlert) {
        window.app.addAlert({
          title: "Sync problem",
          message: `Failed to push to server (${this.name}:${object.id})`,
          level: "error",
        });
      }
      return { error: "failed to push" };
    }
    console.log("offline, not pushing");
    return { error: "offline" };
  }

  async pull(id, object = {}) {
    const response = await window.app.http("db_pull", { id, model: this.name });
    if (response.ok) {
      if (response.record.public) {
        object.content = response.record.content;
        object.updated = response.record.updated;
      } else {
        try {
          const value = JSON.parse(response.record.content);
          for (const prop in value) {
            object[prop] = value[prop];
          }
          if (value._server) {
            delete value._server;
          }
          object._server = value;
        } catch (ex) {
          console.log("trying to update from record", response);
          console.error(ex);
        }
      }
      // } else {
      //   object.error = 'Not found';
    }
    return object;
  }

  async pullList() {
    const retval = [];
    const response = await window.app.http("db_list", { model: this.name });
    if (response.ok) {
      // const promises = [];
      for (const row of response.records) {
        row.id = row.key;
        delete row.key;
        retval.push(row);
        // promises.push(window.app.http('db_pull', { id: row.id, model: this.name }));
      }
      // const rows = await Promise.all(promises);
      // for (const row of rows) {
      //   const value = JSON.parse(row.record.content);
      //   const object = retval.find((r) => r.id === value.id);
      //   for (const prop in value) {
      //     object[prop] = value[prop];
      //   }
      // }
    }
    return retval;
  }

  async pushDelete(id) {
    return window.app.http("db_destroy", {
      id,
      model: this.name,
    });
  }
}
