<template>
  <router-link :to="link" class="back-button flex-row flex-static flex-align-center flex-center">
    <vi name="arrowleft" />
  </router-link>
</template>

<script>
import VectorImage from "./VectorImage.vue";

export default {
  name: 'BackButton',
  components: {
    vi: VectorImage,
  },
  props: {
    back: [Object, String],
  },
  computed: {
    link() {
      if (this.$route.query.back) {
        return this.$route.query.back;
      }
      if (this.back) {
        return this.back;
      }
      if (this.$route.matched.length > 0) {
        const up = this.$route.matched[0].path;
        const parts = up.split('/');
        if (parts.length > 0 && parts[parts.length - 1][0] === ':') {
          parts.pop();
        }
        parts.pop();
        return parts.map((key) => {
          let seg = key;
          if (key[0] === ':') {
            seg = this.$route.params[key.slice(1)];
          }
          return seg;
        }).join('/') || '/';
      }
      return '/';
    },
  },
};
</script>

<style scoped>
.back-button {
  display: inline-flex;
  padding: 0 10px 0 0;
}
</style>
