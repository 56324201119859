import en from "@/locales/en.json";
import { createI18n } from "vue-i18n";

const locales = import.meta.glob("@/locales/*.json");

export const i18n = createI18n({
  legacy: false,
  locale: navigator.language, // set locale
  messages: { en },
  fallbackLocale: "en",
  silentFallbackWarn: true,
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $t: `$t('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    ru: function (n, choicesLength) {
      let retval = choicesLength - 1;
      if (n === 0) {
        retval = 0;
      } else {
        const nMod10 = n % 10;
        const nMod100 = n % 100;
        const teen = nMod100 > 10 && nMod100 < 20;
        const endsWithOne = nMod10 === 1;
        if (choicesLength < 4) {
          retval = !teen && endsWithOne ? 1 : 2;
        } else if (!teen && endsWithOne) {
          retval = 1;
        } else if (!teen && nMod10 >= 2 && nMod10 <= 4) {
          retval = 2;
        } else {
          retval = 3;
        }
      }
      return retval;
    },
  },
});

export async function loadLanguageAsync() {
  const locale = i18n.global.locale.value;
  document.querySelector("html").setAttribute("lang", locale);
  const module = `/locales/${locale}.json`;
  if (locales[module]) {
    const messages: any = await locales[module]();
    i18n.global.setLocaleMessage(locale, messages.default);
  }
}
