<template>
  <ObjectList
    @update:id="emit('update:id', $event)"
    @update:action="emit('update:action', $event)"
    :action="props.action"
    v-model:list="book.styles"
    :id="props.id"
    :show-highlight="false"
    prop="styles"
  >
    <template #highlight="{ object }">
      <div :data-id="object.id" class="flex-row flex-align-center style padded highlighted">
        <div class="flex2 text-center padded" :style="textStyle(object)">
          {{ object.name }}
        </div>
      </div>
    </template>
    <template #item="{ object }">
      <div :data-id="object.id" class="flex-row flex-align-center style padded item">
        <div class="flex2 text-center padded" :style="textStyle(object)">
          {{ object.name }}
        </div>
      </div>
    </template>
    <template #edit="{ object }">
      <StyleDetail :id="object.id" />
    </template>
  </ObjectList>
</template>

<script setup lang="ts">
import Story from "@/models/story.js";
import { textStyle } from "@/utils/tags";

import { useStoryStore } from "@/stores/story";
import ObjectList from "../../admin/ObjectList.vue";
import StyleDetail from "../detail/StyleDetail.vue";

const props = defineProps<{
  story: Story;
  action?: string;
  id?: string;
}>();
const emit = defineEmits(["update:story", "update:action", "update:id"]);

const book = useStoryStore();
</script>
