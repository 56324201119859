import { create, sendwait } from "@morphosis/vue-websocket";
import type Socket from "@morphosis/vue-websocket/lib/websocket";
import { defineStore } from "pinia";
import { ref, shallowRef } from "vue";
import { Deferred } from "../utils/deferred";
import { RemoteCall } from "../utils/jsonapi";
import { useUserStore } from "./user/users";

export const useWebsocketStore = defineStore("websocket", () => {
  const users = useUserStore();
  const loggedIn = new Deferred();
  const isLoggedIn = ref(false);

  let socket = shallowRef<Socket>();

  async function send(opts: RemoteCall) {
    if (!socket.value.connected) {
      console.error("websocket not connected");
      return;
    }

    await isLoggedIn;
    return sendwait(opts.event, opts.data);
  }

  function setup() {
    socket.value = create(import.meta.env.VITE_APP_TSUNAMI_SERVER.replace("/api/", "/ws/"));
    socket.value.on("connect", () => {
      login();
    });
    socket.value.on("disconnect", () => {
      loggedIn.reset();
      isLoggedIn.value = false;
    });
    // socket.value.on("_message_", (packet) => {
    //   console.log("message", packet);
    // });
  }

  async function login(attempt = 0) {
    if (!isLoggedIn.value && socket.value.connected) {
      const user = users.current;
      if (attempt > 0 || !user?.token) {
        await users.login();
      }

      const response = await sendwait("login", {
        id: user.id,
        username: user.username,
        token: user.token,
      });
      if (response.error) {
        console.error("bad token", user.token);
        if (attempt > 1) {
          throw new Error("Login attempt failed");
        } else {
          // TODO: verify the login worked?
          // user.token = "";
          // await login(attempt + 1);
          console.error("Login attempt failed", response.error);
        }
      } else {
        isLoggedIn.value = true;
        loggedIn.resolve(true);
      }
    }
  }

  return {
    setup,
    login,
    send,
    socket,
    isLoggedIn,
    loggedIn: loggedIn.promise,
  };
});
