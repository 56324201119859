<template>
  <button
    v-if="$root.showNotificationTab && fid"
    @click.prevent="toggleWatch()"
    class="watch-button icon"
  >
    <i class="fa fa-bell" v-if="watching" />
    <i class="fa fa-bell-o" v-else />
    <template v-if="!noLabel">
      {{ watching ? 'Watching' : 'Silenced' }}
    </template>
  </button>
</template>

<script>
export default {
  name: 'WatchButton',
  props: {
    fid: { type: String, required: true },
    noLabel: { type: Boolean, default: true },
  },
  data() {
    return {
      watching: this.fetch(`watch:${this.fid}`, false),
    };
  },
  computed: {
    watchId() {
      return `${this.$root.user.id}:${this.fid}`;
    },
  },
  methods: {
    async toggleWatch() {
      if (this.watching) {
        await this.http('watch_remove', { id: this.watchId, fid: this.fid });
      } else {
        await this.http('watch_add', { id: this.watchId, fid: this.fid });
      }
      this.watching = !this.watching;
      this.put(`watch:${this.fid}`, this.watching);
    },
  },
};
</script>

<style scoped>
#app .watch-button i {
  font-size: 15px;
}
#app .watch-button {
  flex: 0 0 auto;
  padding: 9px;
  width: auto;
  min-width: 0;
}
</style>
