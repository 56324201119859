<template>
  <div :name="props.name" class="media-uploader flex-column">
    <div v-if="!data.tab" class="uploader-main flex-row flex-center flex-align-center">
      <img v-if="data.thumbnail" :src="data.thumbnail" class="image flex1" />
      <img v-else-if="media?.permalink" :src="media.permalink" class="image flex1" />
      <div v-else-if="accept === 'image'" class="image placeholder flex-static" />
      <div v-else-if="accept === 'audio' && media" class="audio-sample flex-static">
        <div v-if="media && !data.audioLoaded" class="block flex-static">
          <button class="flex-row flex-align-center flex-center" @click="data.audioLoaded = true">
            <vi name="package" />
          </button>
        </div>
        <div v-if="media && data.audioLoaded" class="block flex-static">
          <button class="flex-row flex-align-center flex-center" @click="data.audioLoaded = false">
            <vi name="unpackage" />
          </button>
        </div>
      </div>

      <div class="options flex-column flex-align-top padded">
        <b v-if="media?.name" class="flex-static text-left">
          <span class="ellipsis">{{ media.name || "Unnamed" }}</span>
          <span v-if="media" class="ellipsis muted">{{ media.type }}</span>
        </b>
        <div v-if="data.audioLoaded" class="padded">
          <blob-audio v-if="media" controls autobuffer tabindex="-1" :blob="media.id" />
          <!-- @canplaythrough="handleLoaded" -->
        </div>

        <a v-if="media.id" @click.prevent="handleEdit">
          <i class="fa fa-pencil" /> {{ $t("detail.edit") }}
        </a>
        <a v-if="media?.id" @click.prevent="data.tab = 'remove'">
          <i class="fa fa-trash" /> {{ $t("media_uploader.remove") }}
        </a>
        <!-- <a v-if="media?.id" @click.prevent="handlePush">
          <i class="fa fa-arrow-right" /> Push Sync
        </a> -->
        <a @click.prevent="data.tab = 'upload'">
          <i class="fa fa-upload" /> {{ $t("backgrounds.form-field-upload") }}
        </a>
        <a @click.prevent="data.tab = 'url'" class="url-button">
          <i class="fa fa-link" /> {{ $t("media_uploader.url") }}
        </a>
        <a @click.prevent="data.tab = 'gallery'" class="gallery-button">
          <i v-if="accept === 'image'" class="fa fa-paint-brush" />
          <i v-if="accept === 'audio'" class="fa fa-music" />
          {{ $t("media_uploader.gallery") }}
        </a>
      </div>
    </div>
    <div v-else-if="data.tab === 'gallery'" class="padded flex-column flex-left">
      <popover @show="handlePopoverShown" :include-close-button="true" :value="true">
        <gallery :size="120" :popup="true" :accept="accept" @select="handleGalleryImport($event)" />
      </popover>
      <a @click.prevent="data.tab = null">{{ $t("import_backup.cancel") }}</a>
    </div>
    <div v-else-if="media && data.tab === 'remove'" class="padded flex-column flex-left">
      <div>{{ $t("media_uploader.remove-media") }}</div>
      <b>{{ media.name || media.id }} ({{ media.type }})</b>
      <div class="padded">
        <button @click="removeImage">{{ $t("media_uploader.confirm") }}</button>
      </div>
      <a @click.prevent="data.tab = null">&times; {{ $t("media_uploader.cancel") }}</a>
    </div>
    <div v-else-if="data.tab === 'upload'" class="padded flex-column flex-left">
      <label>{{ $t("backgrounds.form-field-upload") }}</label>
      <input
        type="file"
        class="flex-static"
        ref="upload"
        :accept="`${accept}/*`"
        @change="uploadImage"
      />

      <div class="flex-row flex-right flex-align-center mellow padded">
        <label class="flex-align-center flex-row flex-static">
          <i class="fa fa-cloud" />
          <span @click.prevent="$refs.cloudSafe.show()" class="cloudSafeNotice padded">
            {{ $t("media_uploader.cloud-safe") }}
          </span>
          <input type="checkbox" v-model="media.cloudSafe" />
          <popover ref="cloudSafe" target=".cloudSafeNotice">
            <cloud-safe />
          </popover>
        </label>
        <label v-if="media?.cloudSafe" class="flex-align-center flex-static">
          <span class="padded">{{ $t("media_uploader.send-to-cloud") }}</span>
          <input type="checkbox" v-model="media.cloud" />
        </label>
      </div>
      <a @click.prevent="data.tab = null">&times; {{ $t("media_uploader.cancel") }}</a>
    </div>
    <div v-else-if="data.tab === 'url'" class="padded flex-column flex-left">
      <label>{{ $t("media_uploader.url-permalink") }}</label>
      <input type="url" v-model="media.permalink" />
      <div class="flex-row padded">
        <button @click.prevent="importImage" class="confirm-url">
          {{ $t("media_uploader.confirm") }}
        </button>
        <a @click.prevent="data.tab = null">&times; {{ $t("media_uploader.cancel") }}</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import CloudSafe from "@/components/content/cloud_safe.vue";
import { i18n } from "@/i18n";
import Media from "@/models/media";
import { basename } from "@/utils/basename";
import { fixCDNPermalink } from "@/utils/cdn";
import { cleanImage } from "@/utils/images";
import Gallery from "@/views/drawings/gallery.vue";
import { useField } from "vee-validate";
import { computed, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const props = withDefaults(
  defineProps<{
    name: string;
    showTitle?: boolean;
    accept?: string;
  }>(),
  { accept: "image" },
);

const router = useRouter();
const route = useRoute();
const { value, errors } = useField<string>(props.name);

const data = reactive({
  url: null,
  tab: null,
  src: null,
  defaultObject: {},
  audioLoaded: false,
  thumbnail: null,
});

const media = ref<Media>(new Media());

const accept = computed(() => {
  return props.accept;
});

onMounted(async () => {
  await window.app.ready;
  if (value.value) {
    media.value = await Media.load(value.value as string);
    if (media.value) {
      data.thumbnail = await media.value.getThumbnail();
    } else {
      media.value = new Media();
    }
  }
});

function handlePopoverShown(e) {
  if (!e) {
    data.tab = null;
  }
}

async function removeImage() {
  await media.value.delete();
  updateValue(undefined);
  data.thumbnail = null;
  data.tab = null;
}

async function uploadImage(ev) {
  errors.value = [];
  const file = ev.target.files[0];
  let result = null;

  if (accept.value === "image") {
    const cleanResult = await cleanImage(file);
    if (cleanResult.result) {
      result = cleanResult.result;
    } else {
      errors.value.push("Failed to interpret file [1]");
      console.log("error", cleanResult);
    }
  } else {
    result = await new Promise((r) => {
      let reader = new FileReader();
      reader.onload = async () => r(reader.result);
      reader.readAsDataURL(file);
    });
  }

  if (result) {
    await media.value.importBlob(result);
    media.value.name = basename(file.name);
    media.value.type = file.type;

    if (media.value.cloudSafe && media.value.cloud) {
      await media.value.push();
    }

    data.thumbnail = await media.value.getThumbnail();
    updateValue(media.value.id);
    data.tab = null;
  } else {
    errors.value.push("Failed to interpret file [2]");
  }
}

async function importImage(e) {
  errors.value = [];
  const value = data.url;
  if (value) {
    let url = fixCDNPermalink(value);
    try {
      await media.value.importBlob(url);
      media.value.name = basename(url);
      media.value.permalink = url;
      await media.value.save();
    } catch (ex) {
      alert(i18n.global.t("backgrounds.bad-permalink"));
    }
    if (media.value.id) {
      data.thumbnail = null;
      updateValue(media.value.id);
      data.thumbnail = await media.value.getThumbnail();
      data.tab = null;
    }
  }
}

async function handleGalleryImport(image) {
  updateValue(image.id);
  media.value = image;
  data.thumbnail = await media.value.getThumbnail();
  data.tab = null;
}

async function handleEdit() {
  if (media.value) {
    router.push({
      name: "gallery-item",
      params: {
        id: media.value.id,
      },
      query: {
        back: route.path,
      },
    });
  }
}

async function handlePush() {
  media.value.updated = new Date().toISOString();
  await media.value.save();
  await media.value.push();
}

async function updateValue(v) {
  // emits("update:modelValue", value);
  console.log("saving value", v);
  value.value = v;
}
</script>

<style scoped>
.media-uploader {
  margin: 5px;
  min-width: 250px;
  padding: 5px;
  border-radius: 5px;
  flex-wrap: nowrap;
}
.ellipsis {
  max-width: 200px;
}
.image {
  min-width: 90px;
  max-width: 120px;
  max-height: 120px;
  object-fit: cover;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
}
.audio {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
}
.placeholder {
  width: 90px;
  height: 90px;
  background: linear-gradient(40deg, var(--spark), var(--color3));
  border: 5px dotted var(--spark_text);
  opacity: 0.7;
}
a {
  padding: 8px;
}
.options a {
  white-space: nowrap;
}
.uploader-main {
  flex-wrap: nowrap;
}
.switch-list {
  background: var(--color2);
  border-radius: 10px;
}
.switch-list > div {
  margin: 5px;
  padding: 5px;
  flex: 0 0 auto;
  border: 2px solid;
  color: var(--color3_text);
  background: var(--color3);
  border-radius: 10px;
}
.switch-list > div a {
  color: var(--color3_text);
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
}
.image-grid > * {
}
</style>
