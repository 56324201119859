<style scoped>
.color-wheel {
  box-sizing: border-box;
  flex: 0 0 auto;
  min-width: 200px;
  min-height: 200px;
  padding: 10px 0 0 0;
  cursor: crosshair;
  width: 100%;
  /* height: 100%; */
  position: relative;
  padding: 0;
  margin: 0;
  flex: 1 1 auto;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
}
canvas {
  width: 100%;
  max-width: 100%;
}
.meta {
  color: white;
  text-align: center;
}
</style>

<template>
  <div
    class="color-wheel"
    @touchstart.prevent="changeColor"
    @touchmove.prevent="changeColor"
    @touchend.prevent="changeColor"
    @pointerdown.prevent="changeColor"
    @pointermove.prevent="changeColor"
    @pointerup.prevent="changeColor"
    @mousedown.prevent="changeColor"
    @mousemove.prevent="changeColor"
    @mouseup.prevent="changeColor"
  >
    <canvas ref="drawing"></canvas>
    <!-- <div class="meta">
      hsl: {{ (h * 360).toFixed(0) }} {{ s.toFixed(1) }} {{ l.toFixed(1) }}<br>
    </div> -->
  </div>
</template>

<script>
import {
  BufferAttribute,
  BufferGeometry,
  CircleGeometry,
  Color,
  DoubleSide,
  Group,
  Mesh,
  MeshBasicMaterial,
  PlaneGeometry,
  RingGeometry,
  Scene,
  Vector3,
  WebGLRenderer
} from "three";

import createCanvasCamera from "./utils/canvas_camera";

export default {
  name: "color-picker",
  props: {
    value: {
      type: Object,
      default() {
        return { h: 0, s: 0, l: 1 };
      }
    },
    size: { type: Number, default: 0 }
  },
  data() {
    return {
      h: this.value.h,
      s: this.value.s,
      l: this.value.l,

      px: 0,
      py: 0,
      x: 0,
      y: 0,
      boxSize: this.size,

      selecting: null,
      down: false,
      r: 1,
      triBase: 1,
      triHeight: 1,
      triNub: 1
    };
  },
  computed: {
    offset() {
      return this.boxSize / 2 - this.triBase / 2;
    },
    markerX() {
      return this.s * (1 - 2 * Math.abs(this.l - 0.5));
    },
    markerY() {
      return this.l;
    }
  },
  watch: {
    h() {
      this.updateColor();
      this.updateColorMarker();
    },
    s() {
      this.updateColorMarker();
    },
    l() {
      this.updateColorMarker();
    },
    value: {
      handler() {
        if (this.value.h != this.h || this.s != this.value.s || this.l != this.value.l) {
          this.h = this.value.h;
          this.l = this.value.l;
          this.s = this.value.s;
          this.updateColor();
          this.updateColorMarker();
          this.requestDisplay();
        }
      },
      deep: true
    },
    size() {
      this.boxSize = this.size;
      this.resizeBox();
    }
  },
  async mounted() {
    this.observer = new MutationObserver(async () => {
      this.resizeBox();
    });
    this.observer.observe(this.$el, { attributes: true, childList: true });

    this.visualObserver = new IntersectionObserver(async () => {
      this.resizeBox();
    });
    this.visualObserver.observe(this.$el);

    await new Promise(r => setTimeout(r, 1));
    this.resizeBox();

    // show
    this.setupScene();
    this.updateColor();
    this.updateColorMarker();
    this.requestDisplay();
  },
  destroyed() {
    if (this.observer) {
      this.observer.disconnect();
    }
    if (this.visualObserver) {
      this.visualObserver.disconnect();
    }
    this.disposeChildren(this.scene);
    this.renderer.dispose();
  },
  methods: {
    resizeBox() {
      const canvas = this.$refs.drawing;
      if (canvas) {
        if (!this.boxSize) {
          const box = canvas.getBoundingClientRect();
          this.boxSize = Math.max(200, box.width);
        }

        this.$el.style.width = this.boxSize;
        this.$el.style.height = this.boxSize;
        canvas.width = this.boxSize;
        canvas.height = this.boxSize;

        this.r = this.boxSize / 2;
        this.triOffset = this.r - 55;
        this.triHeight = this.triOffset + Math.sin(Math.PI / 6) * this.triOffset;
        this.triNub = (this.triOffset * 2 - this.triHeight) / 2;
        this.triBase = Math.cos(Math.PI / 6) * this.triOffset * 2;

        this.requestDisplay();
      }
    },
    disposeChildren(parent) {
      for (const mesh of parent.children) {
        if (mesh.geometry) mesh.geometry.dispose();
        if (mesh.material) mesh.material.dispose();
        if (mesh.children) this.disposeChildren(mesh);
      }
    },
    updateColor() {
      let o = 0;
      const color = new Color();
      const colors = this._triangle.geometry.attributes.color.array;
      color.setHSL(this.h, 1, 0.5).toArray(colors, o * 3);
      o += 1;
      color.setHSL(this.h, 0, 0).toArray(colors, o * 3);
      o += 1;
      color.setHSL(this.h, 0, 1).toArray(colors, o * 3);
      this._triangle.geometry.attributes.color.needsUpdate = true;

      this._hue.material.color.setHSL(this.h, 1, 0.5);
    },
    updateColorMarker() {
      this._marker.material.color.setHSL(this.h, this.s, this.l);
      this._marker.position.x = this.triNub + (this.markerX - 0.5) * this.triHeight;
      this._marker.position.y = -(this.markerY - 0.5) * this.triBase;
    },
    requestDisplay() {
      if (!this._draw) {
        this._draw = true;
        requestAnimationFrame(this.display.bind(this));
      }
    },
    display() {
      this._draw = false;
      if (this._triangle && this._marker) {
        this._triangle.rotation.z = -this.h * 2 * Math.PI;
        this._marker.rotation.z = this.h * 2 * Math.PI;
        this.renderer.render(this.scene, this.camera);
      }
    },
    changeColor(e) {
      const box = this.$refs.drawing.getBoundingClientRect();
      this.x = e.pageX - box.left;
      this.y = e.pageY - box.top;
      this.down = e.buttons >= 1;

      if (e.touches && e.touches.length > 0) {
        this.x = e.touches[0].pageX - box.left;
        this.y = e.touches[0].pageY - box.top;
        this.down = e.type != "touchend";
      }

      if (this.down) {
        this.pos = [this.x - this.r, this.y - this.r];
        const pos = new Vector3(this.pos[0], this.pos[1]);

        if ((!this.selecting || this.selecting === "hue") && pos.length() > this.triOffset) {
          this.selecting = "hue";
          pos.normalize();
          let angle = pos.angleTo(new Vector3(1, 0));
          if (this.y < this.r) {
            angle = Math.PI * 2 - angle;
          }

          this.h = angle / (Math.PI * 2);
        } else if (!this.selecting || this.selecting === "color") {
          this.selecting = "color";

          // adjust cursor position
          pos.applyAxisAngle(new Vector3(0, 0, -1), this.h * 2 * Math.PI);
          pos.sub(new Vector3(-this.triNub * 2, 0, 0));

          // saturation
          this.l = this.clamp(pos.y / this.triBase + 0.5);
          const my = 1 - 2 * Math.abs(this.l - 0.5);
          this.s = this.clamp(pos.x / this.triHeight / my);
        }
        this.updateColor();
        this.updateColorMarker();
        this.requestDisplay();
        this.$emit("update:value", { h: this.h, s: this.s, l: this.l });
      } else {
        this.selecting = null;
      }
    },
    clamp(x, mi = 0, ma = 1) {
      return Math.min(ma, Math.max(mi, x));
    },
    setupScene() {
      this.renderer = new WebGLRenderer({
        alpha: true,
        antialias: true,
        canvas: this.$refs.drawing
      });
      this.renderer.setPixelRatio(1); //window.devicePixelRatio);
      this.renderer.setSize(this.boxSize, this.boxSize);

      this.scene = new Scene();

      this.camera = createCanvasCamera(this.boxSize, this.boxSize);

      const group = new Group();
      this.scene.add(group);

      // build color wheel
      const material = new MeshBasicMaterial({
        side: DoubleSide,
        vertexColors: true
      });
      const bordermat = new MeshBasicMaterial({
        color: "black",
        side: DoubleSide
      });
      const whitemat = new MeshBasicMaterial({
        color: "white",
        side: DoubleSide
      });

      const steps = 100;

      let positions = new Float32Array(steps * 6 * 3);
      let colors = new Float32Array(steps * 6 * 3);
      let geometry = new BufferGeometry();
      geometry.setAttribute("position", new BufferAttribute(positions, 3));
      geometry.setAttribute("color", new BufferAttribute(colors, 3));

      let o = 0;
      const outer = this.r - 5;
      const inner = this.triOffset + 10;
      const color = new Color();
      const vector = new Vector3();
      const step = 360 / steps;
      const maxSaturation = 0.5;
      const minSaturation = 0.5;
      for (let i = 1; i < 360; i += step) {
        const rad1 = (i * (2 * Math.PI)) / 360 + Math.PI / 2;
        const rad2 = ((i - step) * (2 * Math.PI)) / 360 + Math.PI / 2;
        // for (const [r, l] of [[outer, 0.5], [inner, 1]]) {

        const a = [outer * Math.sin(rad1), outer * Math.cos(rad1), 0];
        const b = [inner * Math.sin(rad1), inner * Math.cos(rad1), 0];
        const c = [outer * Math.sin(rad2), outer * Math.cos(rad2), 0];
        const d = [inner * Math.sin(rad2), inner * Math.cos(rad2), 0];

        vector.set(...a).toArray(positions, o * 3);
        color.setHSL(i / 360, 1, minSaturation).toArray(colors, o * 3);
        o += 1;
        vector.set(...b).toArray(positions, o * 3);
        color.setHSL(i / 360, 1, maxSaturation).toArray(colors, o * 3);
        o += 1;
        vector.set(...c).toArray(positions, o * 3);
        color.setHSL(i / 360, 1, minSaturation).toArray(colors, o * 3);
        o += 1;

        vector.set(...d).toArray(positions, o * 3);
        color.setHSL(i / 360, 1, maxSaturation).toArray(colors, o * 3);
        o += 1;
        vector.set(...c).toArray(positions, o * 3);
        color.setHSL(i / 360, 1, minSaturation).toArray(colors, o * 3);
        o += 1;
        vector.set(...b).toArray(positions, o * 3);
        color.setHSL(i / 360, 1, maxSaturation).toArray(colors, o * 3);
        o += 1;
      }

      // vector.set(positions[0], positions[1], positions[2]).toArray(positions, o * 3);
      // color.setHSL(0, 1, 0.5).toArray(colors, o * 3);
      // o += 1;

      // vector.set(positions[3], positions[4], positions[5]).toArray(positions, o * 3);
      // color.setHSL(0, 1, 1).toArray(colors, o * 3);
      // o += 1;

      const mesh = new Mesh(geometry, material);
      group.add(mesh);

      let border = new Mesh(new CircleGeometry(outer + 5, 45, 1), bordermat);
      group.add(border);
      border.position.z -= 0.1;

      // make triangle
      o = 0;
      positions = new Float32Array(3 * 3);
      colors = new Float32Array(3 * 3);

      geometry = new BufferGeometry();
      geometry.setAttribute("position", new BufferAttribute(positions, 3));
      geometry.setAttribute("color", new BufferAttribute(colors, 3));

      let rad = Math.PI / 6 + Math.PI;
      for (let i = 0; i < 3; i += 1) {
        rad -= (Math.PI * 2) / 3;
        vector
          .set(this.triOffset * Math.sin(rad), this.triOffset * Math.cos(rad), 0)
          .toArray(positions, o * 3);
        o += 1;
      }
      this._triangle = new Mesh(geometry, material);
      group.add(this._triangle);

      // color marker
      this._marker = new Mesh(new PlaneGeometry(20, 20));
      this._triangle.add(this._marker);
      this._marker.position.z += 4;
      border = new Mesh(new PlaneGeometry(25, 25), whitemat);
      this._marker.add(border);
      border.position.z -= 1;
      border = new Mesh(new PlaneGeometry(30, 30), bordermat);
      this._marker.add(border);
      border.position.z -= 2;

      // hue highlight
      this._hue = new Mesh(new RingGeometry(inner - 5, outer + 5, 5, 1, -0.09, 0.18));
      this._triangle.add(this._hue);
    }
  }
};
</script>
